import { CSSProperties } from 'react';

export const StarIcon = ({ solid, style }: { solid?: boolean; style?: CSSProperties }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path
        d="M11.9808 2.86932L13.8979 8.12668C14.2222 9.01562 15.0722 9.61394 16.0151 9.61394H21.2053L16.733 13.2801C16.0098 13.8732 15.7234 14.87 16.0203 15.7602L17.8654 21.274L13.2702 17.9602C12.8844 17.6828 12.4307 17.5355 11.956 17.5355C11.4813 17.5355 11.0184 17.6854 10.63 17.9681L6.15508 21.2385L7.96492 15.751C8.25654 14.8647 7.97015 13.8732 7.25223 13.2828L2.7904 9.61394H8.00285C8.95354 9.61394 9.80746 9.00904 10.1265 8.10695L11.9821 2.86932M11.9756 0C11.3858 0 10.7961 0.335324 10.5594 1.00466L8.27877 7.44551C8.23692 7.56254 8.12708 7.64144 8.00285 7.64144H1.50494C0.0926412 7.64144 -0.54028 9.42326 0.554255 10.3227L6.01124 14.8095C6.10539 14.8871 6.14201 15.0146 6.10408 15.1303L3.84571 21.9789C3.49525 23.0414 4.33478 23.9724 5.27763 23.9724C5.57185 23.9724 5.87524 23.8817 6.15639 23.6765L11.7847 19.5632C11.8357 19.5251 11.8958 19.5067 11.9573 19.5067C12.0188 19.5067 12.0776 19.5251 12.1286 19.5619L17.8798 23.7094C18.1584 23.9106 18.4604 24 18.7507 24C19.6962 24 20.5357 23.0637 20.18 21.9999L17.8824 15.1303C17.8432 15.0146 17.8798 14.8858 17.9753 14.8082L23.4466 10.3227C24.5425 9.42458 23.9109 7.64013 22.4973 7.64013H16.0177C15.8947 7.64013 15.7849 7.56254 15.7431 7.44683L13.3866 0.991507C13.1447 0.330064 12.5601 0 11.9756 0Z"
        fill="currentColor"
      />
      <path
        fill={solid === true ? 'currentColor' : 'none'}
        d="M23.9041 8.66153C23.7941 8.36845 23.5965 8.11562 23.3377 7.9368C23.0789 7.75798 22.7713 7.66167 22.4558 7.66075H16.1857C16.0791 7.66082 15.975 7.62805 15.8879 7.56695C15.8008 7.50585 15.735 7.41944 15.6995 7.31966L13.4488 0.995335C13.3386 0.703027 13.1409 0.451074 12.8823 0.273196C12.6236 0.0953175 12.3164 0 12.0016 0C11.6868 0 11.3795 0.0953175 11.1209 0.273196C10.8622 0.451074 10.6646 0.703027 10.5543 0.995335L10.5492 1.01167L8.30359 7.31966C8.26822 7.41927 8.20258 7.50557 8.11569 7.56665C8.0288 7.62774 7.92494 7.66061 7.81842 7.66075H1.54628C1.22883 7.66051 0.919006 7.75722 0.658953 7.93772C0.3989 8.11821 0.201238 8.37374 0.0928505 8.66955C-0.0155371 8.96535 -0.0293896 9.28708 0.0531771 9.59096C0.135744 9.89485 0.310722 10.1661 0.554314 10.368L5.89424 14.7591C5.97411 14.8249 6.0321 14.913 6.06059 15.0119C6.08908 15.1109 6.08675 15.216 6.0539 15.3137L3.81039 21.9832C3.70671 22.2915 3.70466 22.6246 3.80454 22.9342C3.90443 23.2438 4.10106 23.5139 4.36601 23.7054C4.63096 23.8968 4.95048 23.9998 5.27835 23.9994C5.60623 23.9989 5.92546 23.8951 6.18987 23.7029L11.6967 19.6997C11.785 19.6356 11.8916 19.601 12.0011 19.601C12.1105 19.601 12.2171 19.6356 12.3054 19.6997L17.8102 23.7018C18.0745 23.8947 18.3939 23.9991 18.722 24C19.0502 24.0009 19.3701 23.8981 19.6354 23.7066C19.9008 23.5152 20.0977 23.2449 20.1977 22.935C20.2977 22.6252 20.2956 22.2918 20.1917 21.9832L17.9482 15.3096C17.9154 15.212 17.913 15.1068 17.9415 15.0078C17.97 14.9089 18.028 14.8208 18.1079 14.7551L23.4581 10.3577C23.6986 10.1542 23.8703 9.88246 23.95 9.57914C24.0298 9.27582 24.0138 8.95554 23.9041 8.66153Z"
      />
    </svg>
  );
};
