import { specialcharacters } from '../special-characters/special-characters.utils';

// Providers
export const tv2DisplayName = `TV${specialcharacters.nbsp}2${specialcharacters.nbsp}Play`;
export const tv2FavorittDisplayName = `${tv2DisplayName} Favoritt`;
export const tv2TotalDisplayName = `${tv2DisplayName} Total`;
export const maxDisplayName = `Max`;
export const viaplayDisplayName = 'Viaplay';
export const nrkDisplayName = 'NRK TV';
export const skyShowtimeDisplayName = 'SkyShowtime';
export const nordiskFilmDisplayName = 'Nordisk Film+';
export const disneyPlusDisplayName = 'Disney+';

// Packages in provider

export const tv2playSportDisplayName = 'TV 2 Play Sport';
export const viaplayPackageName = 'Film & Serier';
export const sportPremiumPackageName = 'Sport Premium';
export const tv2PackageName = 'Film og serier';
export const tv2SportsPackageDisplayName = 'TV 2 Play Champions League, LaLiga, Sport, Film og Serier';
export const tv2playBasisDisplayName = 'TV 2 Play Basis (med reklame)';

// Channels
export const vsportDisplayName = 'V Sport';
export const vsportplussDisplayName = 'V Sport +';
export const vsport1DisplayName = 'V Sport 1';
export const vsport2DisplayName = 'V Sport 2';
export const vsport3DisplayName = 'V Sport 3';
export const eurosportDisplayName = 'Eurosport';
export const tv2sportDisplayName = 'TV 2 Sport';

// Others
export const formel1DisplayName = `Formel${specialcharacters.nbsp}1`;
export const formel2DisplayName = `Formel${specialcharacters.nbsp}2`;
export const premierLeagueDisplayName = `Premier${specialcharacters.nbsp}League`;
export const championsLeagueDisplayName = `UEFA Champions${specialcharacters.nbsp}League`;
