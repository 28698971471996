import { create } from 'zustand';

import { TrailerObject } from '@rikstv/play-common/src/pages/StackedCoverPage/contentStack.types';
import { isDevFeatureEnabled } from '@rikstv/play-common/src/utils/featureFlags/devFeatureFlags';

interface TrailerPlayerStore {
  disableBackgroundTrailer: boolean;
  playBackgroundTrailer: boolean;
  trailerSources: TrailerSource[] | undefined;
  setDisableBackgroundTrailer: (enableTrailer: boolean) => void;
  setPlayBackgroundTrailer: (play: boolean) => void;
  setTrailerSources: (trailerSources: TrailerObject[] | undefined) => void;
}

export type TrailerSource = { src: string };

export const useTrailerPlayerStore = create<TrailerPlayerStore>()(set => ({
  disableBackgroundTrailer: isDevFeatureEnabled('disableBackgroundTrailers'),
  playBackgroundTrailer: true,
  trailerSources: undefined,
  //Actions
  setDisableBackgroundTrailer: (disableTrailer: boolean) => set({ disableBackgroundTrailer: disableTrailer }),
  setPlayBackgroundTrailer: (play: boolean) => set({ playBackgroundTrailer: play }),
  setTrailerSources: (trailerSources: TrailerObject[] | undefined) =>
    set({
      trailerSources: trailerSources
        ? trailerSources.map(trailer => ({
            src: trailer.playUri,
            type: trailer.format === 'Dash' ? 'application/dash+xml' : 'application/x-mpegurl',
          }))
        : undefined,
    }),
}));
