import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GatewayAPI from '@strim/gateway-api';

import { ExternalServiceStatus } from '@rikstv/play-common/src/forces/externalService/types';
import { serviceMaxActivated } from '@rikstv/play-common/src/forces/webSocket/actions';

import { LogoutReason } from './constants';
import { mapMaxResponseToExternalServiceStatus, toSubscribedPackage } from './mappers';
import { UserInfo } from './user.types';

type UserInfoState = UserInfo | null;

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: { userInfo: null as UserInfoState },
  reducers: {
    loadedUser: (state, { payload }: PayloadAction<UserInfo>) => {
      if (state.userInfo) {
        Object.assign(state.userInfo, payload);
      } else {
        state.userInfo = payload;
      }
    },
    loadedSubscriptionStatus: (state, { payload }: PayloadAction<GatewayAPI.SubscriptionResponse>) => {
      const subscribedPackage = toSubscribedPackage(payload);
      if (state.userInfo) {
        if (state.userInfo?.subscribedPackage) {
          Object.assign(state.userInfo.subscribedPackage, subscribedPackage);
        } else {
          state.userInfo.subscribedPackage = subscribedPackage;
        }
      }
    },
    externalServiceStatusUpdated: (state, { payload }: PayloadAction<ExternalServiceStatus>) => {
      if (state.userInfo) {
        if (state.userInfo.externalServicesStatus) {
          state.userInfo.externalServicesStatus[payload.serviceId] = payload;
        } else {
          state.userInfo.externalServicesStatus = {
            [payload.serviceId]: payload,
          };
        }
      }
    },
    changeEmail: (state, { payload }: PayloadAction<string>) => {
      if (state.userInfo) {
        state.userInfo.personalia.email = payload;
      }
    },
    changePhoneNumber: (state, { payload }: PayloadAction<string>) => {
      if (state.userInfo) {
        state.userInfo.personalia.phoneNumber = payload;
      }
    },
    // Empty actions
    userLoginRegistrationValidationError: (_state, _action: PayloadAction<string>) => {},
    logoutWithReason: (_state, _action: PayloadAction<LogoutReason>) => {},
  },
  extraReducers: builder =>
    builder.addCase(serviceMaxActivated.type, state => {
      // triggered by web socket event
      const maxStatus = mapMaxResponseToExternalServiceStatus({ isActivated: true, activationUrl: '' }, true);
      if (state.userInfo) {
        if (state.userInfo.externalServicesStatus) {
          state.userInfo.externalServicesStatus['max'] = maxStatus;
        } else {
          state.userInfo.externalServicesStatus = {
            ['max']: maxStatus,
          };
        }
      }
    }),
});

export const userActions = userInfoSlice.actions;

export default userInfoSlice.reducer;
