import { FC } from 'react';

import { Link } from '../../router/Link';

import style from './badge.module.css';

interface Props {
  href: string;
}

export const PlayStoreBadge: FC<Props> = ({ href }) => (
  <Link to={href} title="Last ned Android app">
    <img
      className={style.googlePlay}
      alt="Last ned Android app"
      src="https://imageservice.rikstv.no/static/strim/icons/google-play-badge.png?width=200&lossless=true"
    />
  </Link>
);
