import { FC } from 'react';

import { Tag } from '@rikstv/shared-components';

import { Pill } from '../pill/Pill';

import { ImageOverlayWrapper } from './ImageOverlayWrapper';

import styles from '../coverPage/episodes/Episode.module.css';

export const BroadcastTimeOverlay: FC<{ label: string | undefined; className?: string }> = ({ label, className }) => {
  return (
    <ImageOverlayWrapper className={className} style={{ padding: 'var(--rds-spacing--8)' }}>
      <BroadcastTimePill label={label} className={styles.broadcastTimePill} />
    </ImageOverlayWrapper>
  );
};

export const BroadcastTimePill: FC<{ className?: string; label: string | undefined }> = ({ label, className }) => {
  return (
    <Pill className={className} style={{ width: 'fit-content', backgroundColor: 'var(--rds-background-secondary)' }}>
      <Tag>{label?.replace(/^sendes\s?/i, '')}</Tag>
    </Pill>
  );
};
