import { useEffect, useRef, useState } from 'react';
import useSwrInfinite from 'swr/infinite';

import { SwimlaneItem } from '../../forces/assetDetails/assetDetails.types';
import { mapResponseToSwimlaneAssetItem } from '../../forces/assetDetails/swimlaneItemMapper';
import { request } from '../../forces/utils/request';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useElementVisible } from '../../hooks/useElementVisible';
import { authService } from '../../utils/auth/AuthService';
import { captureExceptionInSentry } from '../../utils/errorTracker/tracking';
import { getLogger } from '../../utils/logger/logger';
import { Swimlane } from '../pages/types';

const logger = getLogger('[paginatedSwimlane]');
const PAGE_SIZE = 30;

export const usePaginatedSwimlane = (
  swimlane: Swimlane,
  setSwimlaneItems: (data: SwimlaneItem[]) => void,
  enablePagination = true
) => {
  const { link, style, type, supportsPaging } = swimlane;
  const accessToken = useAuthToken();
  const [gotMoreData, setGotMoreData] = useState(enablePagination);

  const getKey = (idx: number, previousData: SwimlaneItem[] | null) => {
    if (!gotMoreData || !link) return null; // no action for unsupported things
    // stop fetching when reached the end of the data
    if (previousData && (!supportsPaging || previousData.length < PAGE_SIZE)) {
      setGotMoreData(false);
      return null;
    }

    const url = new URL(link);
    url.searchParams.append('page', `${idx + 1}`);
    url.searchParams.append('pageSize', PAGE_SIZE.toString());
    return url.toString();
  };

  const fetcher = (url: string) => {
    logger.log('fetching', { url });
    const headers = { 'accept-model-version': '2.0' };
    // TODO (tolu) response can be MenuItem[]... which we dont handle well here
    return request<RiksTV.ClientAPI.AssetSwimlaneResponse>({ url, headers }, accessToken).then(data =>
      mapResponseToSwimlaneAssetItem(data, { style, type, loggedIn: authService.isAuthenticated() })
    );
  };

  const { data, error, isLoading, size, setSize } = useSwrInfinite<SwimlaneItem[]>(getKey, fetcher, {
    initialSize: 0,
    revalidateIfStale: false,
    revalidateOnMount: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 120_000,
  });

  const refLastVisible = useRef(false); // to keep track of last visibility state
  const [paginationTriggerRef, isRefVisible] = useElementVisible();

  // handle updating current page
  useEffect(() => {
    if (!gotMoreData) {
      return;
    }
    // for some reason, setting initialSize in swr config to 1
    // does not always work locally on clean refresh, this however - does.
    if (size === 0) {
      setSize(1);
    } else if (!refLastVisible.current && isRefVisible) {
      setSize(size + 1);
    }
    refLastVisible.current = isRefVisible;
  }, [enablePagination, size, setSize, isRefVisible, gotMoreData]);

  useEffect(() => {
    data && setSwimlaneItems(data.flat());
  }, [data, setSwimlaneItems]);

  useEffect(() => error && captureExceptionInSentry(error), [error]);

  return { isLoading, paginationTriggerRef: gotMoreData ? paginationTriggerRef : null };
};
