import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { newVersionReleased } from '../webSocket/actions';

import { HeaderTheme, THEME_DEFAULT, UI_MENU_MODAL_ID } from './constants';

const routerSlice = createSlice({
  name: 'router',
  initialState: { pathname: null as string | null },
  reducers: {
    locationChange: (state, { payload }: PayloadAction<{ pathname: string; isLoggedIn: boolean }>) => {
      state.pathname = payload.pathname;
    },
  },
});

const modalSlice = createSlice({
  name: 'modal',
  initialState: {} as Record<string, boolean>,
  reducers: {
    modalOpen: (state, { payload }: PayloadAction<{ id: string }>) => {
      state[payload.id] = true;
    },
    modalClose: (state, { payload }: PayloadAction<{ id: string }>) => {
      state[payload.id] = false;
    },
    menuModalClose: state => {
      state[UI_MENU_MODAL_ID] = false;
    },
    menuModalOpen: state => {
      state[UI_MENU_MODAL_ID] = true;
    },
  },
  // reset initial state on location change
  extraReducers: builder =>
    builder.addCase(routerSlice.actions.locationChange, () => {
      // locationChange action handled in modalSlice, here just reset state
      return {};
    }),
});

const themeSlice = createSlice({
  name: 'theme',
  initialState: THEME_DEFAULT as HeaderTheme,
  reducers: {
    changeHeaderTheme: (_, { payload }: PayloadAction<HeaderTheme>) => payload,
  },
});

const newVersionSlice = createSlice({
  name: 'newVersionReleased',
  initialState: false,
  reducers: {},
  extraReducers: builder => builder.addCase(newVersionReleased, () => true),
});

export const { menuModalClose, menuModalOpen, modalClose, modalOpen } = modalSlice.actions;
export const { locationChange } = routerSlice.actions;
export const { changeHeaderTheme } = themeSlice.actions;

export default combineReducers({
  router: routerSlice.reducer,
  modal: modalSlice.reducer,
  theme: themeSlice.reducer,
  newVersionReleased: newVersionSlice.reducer,
});
