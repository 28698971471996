import { useEffect, useState } from 'react';

export const useDebouncedValue = <T>(value: T, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    // debounce set value
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, setDebouncedValue, delay]);

  return debouncedValue;
};
