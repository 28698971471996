export const copyToClipboard = async (text: string) => {
  const result = await navigator.permissions.query({ name: 'clipboard-write' as any });
  if (/granted|prompt/.test(result.state)) {
    await navigator.clipboard.writeText(text);
  }
};

export const canUseShareApi = (data: ShareData) => {
  return 'canShare' in navigator && navigator.canShare(data);
};
