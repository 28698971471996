export class Deferred<T = unknown> {
  promise: Promise<T>;

  constructor() {
    const promise = (this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    }));
    this.then = promise.then.bind(promise) as any;
    this.catch = promise.catch.bind(promise) as any;
    this.finally = promise.finally.bind(promise) as any;
  }
  /* Placeholders methods that are set in constructor */
  resolve(_value: T) {}
  reject() {}
  then: Promise<T>['then'] = (..._args: any[]) => {
    return this as any;
  };
  catch: Promise<T>['catch'] = (..._args: any[]) => {
    return this as any;
  };
  finally: Promise<T>['finally'] = (..._args: any[]) => {
    return this as any;
  };
}
