import React, { MouseEvent, useState } from 'react';
import Truncate from 'react-text-truncate';

import './truncateText.scss';
interface Props {
  children: string;
  lines: number;
  disableShowMoreButton?: boolean;
  showMoreButtonText?: string;
  className?: string;
  itemProp?: string;
  textStyle: React.FunctionComponent<React.HTMLAttributes<HTMLElement>>;
}

const TruncateText: React.FunctionComponent<Props> = React.memo(
  ({
    children,
    lines,
    className,
    itemProp,
    disableShowMoreButton,
    textStyle: TextStyle,
    showMoreButtonText = 'Les mer',
  }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleLines = (event: MouseEvent) => {
      event.preventDefault();
      setExpanded(!expanded);
    };

    return (
      <TextStyle className={className} itemProp={itemProp}>
        <Truncate
          element="span"
          line={!expanded && lines}
          text={children}
          containerClassName="common-truncate-text"
          textTruncateChild={
            <>
              {!disableShowMoreButton && (
                <button className="rds-link truncate-button-reset" onClick={toggleLines}>
                  {showMoreButtonText}
                </button>
              )}
            </>
          }
        />
      </TextStyle>
    );
  }
);

export { TruncateText };
