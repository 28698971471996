import React from 'react';

import { UnstyledLink } from '@rikstv/play-common/src/router/Link';
import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';

import { letUserAgentHandleEvent } from '../../../utils/events/event.utils';

type ItemWrapperType = FCC<{
  linkTo: string;
  className: string;
  ariaLabel?: string;
  preventDefault?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}>;
const ItemWrapper: ItemWrapperType = ({ linkTo, className, ariaLabel, children, preventDefault, onClick }) => (
  <UnstyledLink
    aria-label={ariaLabel}
    to={linkTo}
    className={className}
    onClick={event => {
      if (letUserAgentHandleEvent(event)) {
        return;
      }

      onClick?.(event);

      if (preventDefault) {
        /* navigation is handled elsewhere */
        event.preventDefault();
      }
    }}>
    {children}
  </UnstyledLink>
);

export { ItemWrapper };
