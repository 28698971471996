/* eslint-disable @typescript-eslint/ban-types */
import { call, CallEffect } from 'redux-saga/effects';

import { authService } from '../../utils/auth/AuthService';
import { captureExceptionInSentry } from '../../utils/errorTracker/tracking';
import { AccessToken } from '../auth/auth.types';

function* handleErrorAllowEmpty(err: any) {
  const code = err.statusCode;

  if (code === 401 && !authService.isAuthenticated()) {
    // attempt refresh tokens, will try silent and if it fails redirect to sts
    yield call([authService, authService.renewTokens]);
  }
  throw err;
}

function* handleError(err: any) {
  captureExceptionInSentry(err);
  yield call(handleErrorAllowEmpty, err);
}

// TODO (tolu): fix types here, this is not ok
const makeCallAuthenticated = (errorHandler: (err: any) => Generator<CallEffect<unknown>, void, unknown>) =>
  function* callAuthenticated(fn: (...args: any[]) => any, ...rest: any): Generator<any, any, AccessToken> {
    // TODO (tolu) replaced current logic without improvement of refreshing tokens BEFORE making the call, but we can fix that later
    const accessToken = authService.getToken();
    if (!accessToken.value) {
      throw new Error('No token available');
    }
    try {
      return yield (call as any)(fn, ...rest, accessToken.value); //We need to twist TS's arm to use call with spread
    } catch (err) {
      yield call(errorHandler, err);
    }
  };

export const callAuthenticated = makeCallAuthenticated(handleError);
export const callAuthenticatedAllowEmpty = makeCallAuthenticated(handleErrorAllowEmpty);

function* callWithErrorHandling(fn: Function, ...rest: any): Generator<any, any, unknown> {
  try {
    return yield (call as any)(fn, ...rest); //We need to twist TS's arm to use call with spread
  } catch (err) {
    yield call(handleError, err);
  }
}

export function* callWithTokenIfAuthenticated(fn: Function, ...rest: any): Generator<any, any, unknown> {
  // only care if we HAVE tokens here, not if they are valid, that check comes later
  if (authService.getToken().value) {
    return yield (call as any)(callAuthenticated, fn, ...rest); //We need to twist TS's arm to use call with spread
  }
  return yield (call as any)(callWithErrorHandling, fn, ...rest); //We need to twist TS's arm to use call with spread
}
