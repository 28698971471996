import { CSSProperties, FC } from 'react';
import classNames from 'classnames';

import { optimizeSkyShowTimeLogo } from '../../utils/imagePack/imagePack.utils';
import { ImageOverlayWrapper } from '../imageOverlays/ImageOverlayWrapper';

import styles from './ProviderImage.module.css';

interface Props {
  variants: Array<{ name: string; logo: string }>;
  width?: CSSProperties['width'];
  className?: string;
  portrait?: boolean;
}
export const StackedProviderImage: FC<Props> = ({ variants, width, className = '', portrait = false }) => {
  const styleObj = width ? ({ '--logo-size': `${width}` } as CSSProperties) : {};

  return (
    <ImageOverlayWrapper className={[styles.stackedLogoWrapper, className].join(' ')} style={styleObj}>
      {[...variants].reverse().map(({ name, logo }, idx) => (
        <img
          key={`variant-logo-${name}`}
          alt={name}
          src={optimizeSkyShowTimeLogo(logo, 64)}
          className={classNames(styles.stackedLogo, { [styles.stackedLogoPortrait]: portrait })}
          style={{ zIndex: idx }}
        />
      ))}
    </ImageOverlayWrapper>
  );
};
