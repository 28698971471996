import config from '../../config';
import { AccessTokenValue } from '../auth/auth.types';
import { request } from '../utils/request';

import { mapResponseToTvSeriesDetails } from './mappers';
import { TvSeriesDetails } from './tvSeriesDetails.types';

export const getTvSeriesDetails = async (
  id: string,
  accessTokenValue: AccessTokenValue | null | undefined
): Promise<TvSeriesDetails> => {
  const url = config.contentSearchApiUrl;
  const response = await request(
    {
      url,
      path: `/series/${id}/`,
      headers: {
        'Cache-Control': 'no-cache',
      },
    },
    accessTokenValue
  );

  return mapResponseToTvSeriesDetails(response);
};
