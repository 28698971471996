import React, { memo } from 'react';
import classNames from 'classnames';

import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';

import type { IconComponentType, IconSize } from '../../icons/svgToIconElement';
import { Link } from '../../router/Link';

import './ActionButton.scss';

export type ButtonStyle = 'primary' | 'secondary' | 'tertiary' | 'inline-link' | 'inline-link-light';

export interface CommonButtonProps extends Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  id?: string;
  buttonStyle?: ButtonStyle;
  selected?: boolean;
  className?: string;
  ariaLabel?: string;
  disabled?: boolean;
  isLoading?: boolean;

  icon?: IconComponentType;
  iconPosition?: 'left' | 'right';
  hasDarkBackground?: boolean;
}

type ActionButtonProps = CommonButtonProps & {
  iconOnly: boolean;
  iconSize: IconSize;
} & (
    | {
        buttonType: 'button';
        onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
      }
    | {
        buttonType: 'link';
        to: string;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
      }
  );

const InternalActionButton: FCC<ActionButtonProps> = memo(
  ({
    id,
    buttonStyle = 'primary',
    selected = false,
    className,
    ariaLabel,
    disabled,
    icon: Icon,
    iconPosition,
    iconOnly,
    isLoading,
    iconSize,
    children,
    hasDarkBackground = true,
    ...props
  }) => {
    const commonProps = {
      id,
      className: classNames(
        className,
        'action-button',
        `action-button--${hasDarkBackground ? 'light' : 'dark'}`,
        `action-button--${buttonStyle}`,
        {
          selected,
          'action-button--icon-only': iconOnly,
          'action-button--icon-only--large': iconOnly && iconSize === 'large',
        }
      ),
      'aria-disabled': disabled,
      'aria-label': ariaLabel,
      'aria-busy': isLoading || undefined,
    };

    const contents =
      Icon && iconOnly ? (
        <Icon iconSize={iconSize} />
      ) : (
        <>
          {Icon && iconPosition === 'left' && <Icon spacingRight iconSize={iconSize} />}
          {children}
          {Icon && iconPosition === 'right' && <Icon spacingLeft iconSize={iconSize} />}
        </>
      );

    if (props.buttonType === 'link') {
      return (
        <Link withDefaultStyle={false} to={props.to} onClick={props.onClick} {...commonProps}>
          {contents}
        </Link>
      );
    } else {
      const clickHandler = isLoading ? undefined : props.onClick;
      const { buttonType: _, ...buttonProps } = props;
      return (
        <button onClick={clickHandler} {...commonProps} disabled={disabled || isLoading} {...buttonProps}>
          {contents}
        </button>
      );
    }
  }
);

export { InternalActionButton };
