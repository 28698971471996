import { deepLinkRoutes } from '@rikstv/play-common/src/router/router.deepLinks';

import { labelFor, language, LanguageCode } from '../../language';
import { ActionExtended } from '../cast-actions';
import type { CastTrackLanguage } from '../chromecastApi';
import * as _chromecastApiImported from '../chromecastApi';

export interface LangItem {
  key: string;
  label: string;
  selected: boolean;
}

export interface MiniControllerState {
  assetId?: string;
  assetLink: string;
  audioLanguages: LangItem[];
  castState?: cast.framework.CastState;
  currentTime: number;
  streamStartInSeconds: number;
  streamEndInSeconds: number;
  deviceName: string;
  duration: number;
  enabled: boolean;
  isDirect: boolean;
  isLinear: boolean;
  muted: boolean;
  playing: boolean;
  posterUrl: string;
  sessionState?: cast.framework.SessionState;
  streamType?: chrome.cast.media.StreamType;
  textlanguages: LangItem[];
  title: string;
  volume: number;
  error?: string;
}

export function updateFromCastState(castState?: cast.framework.CastState): boolean {
  switch (castState) {
    case 'CONNECTED' as cast.framework.CastState:
      return true;
    case 'CONNECTING' as cast.framework.CastState:
    case 'NO_DEVICES_AVAILABLE' as cast.framework.CastState:
    case 'NOT_CONNECTED' as cast.framework.CastState:
      return false;
  }
  return false;
}

export function updateFromSession(sessionState?: cast.framework.SessionState): boolean {
  switch (sessionState) {
    case 'SESSION_STARTED' as cast.framework.SessionState:
    case 'SESSION_RESUMED' as cast.framework.SessionState:
      return true;
    case 'NO_SESSION' as cast.framework.SessionState:
    case 'SESSION_STARTING' as cast.framework.SessionState:
    case 'SESSION_START_FAILED' as cast.framework.SessionState:
    case 'SESSION_ENDING' as cast.framework.SessionState:
    case 'SESSION_ENDED' as cast.framework.SessionState:
      return false;
  }
  return false;
}

export function mapLanguage(type: chrome.cast.media.TrackType, languages: CastTrackLanguage[]): LangItem[] {
  return languages
    .filter(l => l.type === type)
    .map(l => ({
      key: l.language,
      label: labelFor(l.language as LanguageCode),
      selected: l.active,
    }));
}

export function getLanguages(getCastTracks = _chromecastApiImported.getCastTracks): {
  audioLanguages: LangItem[];
  textlanguages: LangItem[];
} {
  const tracks = getCastTracks();
  const textTracks = mapLanguage('TEXT' as chrome.cast.media.TrackType, tracks);

  return {
    audioLanguages: mapLanguage('AUDIO' as chrome.cast.media.TrackType, tracks),
    textlanguages: [
      {
        key: 'off',
        label: language.labels.none.label,
        selected: textTracks.every(item => !item.selected),
      },
      ...textTracks,
    ],
  };
}

function isDirect(isLinear: boolean, currentTime: number, streamEndTimeInSeconds: number): boolean {
  return isLinear && currentTime > streamEndTimeInSeconds - 50;
}

// enables simple dependency injection in tests
export const reducerFactory = (_castApiOverrides: Partial<typeof _chromecastApiImported> = {}) => {
  const {
    getSeekableRange,
    getCastPlayerState,
    getCustomMediaInfo,
    isCastingLinearAsset,
    getCastDeviceName,
    isCastMuted,
    getCastVolume,
    getCastTracks,
    // Allow simple override for test with default to imported API
  } = { ..._chromecastApiImported, ..._castApiOverrides };

  // This reducer is not pure, and many cases call functions outside the reducer, instead of each action containing all the necessary data.
  // Ideally this should be refactored.
  return function reducer(state: MiniControllerState, action: ActionExtended): MiniControllerState {
    // update link to asset
    if (state.streamType && state.assetId && !state.assetLink.includes(state.assetId)) {
      state.assetLink = getAssetLink(state.assetId, state.streamType);
    }
    switch (action.type) {
      case 'UPDATE_CURRENT_TIME': {
        const currentTime = action.currentTime!;
        const seekableRange = getSeekableRange();
        return {
          ...state,
          currentTime,
          isDirect: isDirect(state.isLinear, currentTime, state.streamEndInSeconds),
          duration: seekableRange.duration,
          streamStartInSeconds: seekableRange.start,
          streamEndInSeconds: seekableRange.end,
        };
      }
      case 'UPDATE_CAST_STATE':
        const isCastState = updateFromCastState(action.castState);
        const customData = isCastState ? getCustomMediaInfo(null) : null;
        const castPlayerState = getCastPlayerState();
        return {
          ...state,
          ...customData,
          castState: action.castState,
          enabled: isCastState && !!castPlayerState && castPlayerState !== 'IDLE',
        };
      case 'SET_ENABLED':
        return {
          ...state,
          enabled: true,
        };
      case 'UPDATE_MEDIA_INFO': {
        const seekableRange = getSeekableRange();
        return {
          ...state,
          ...getLanguages(getCastTracks),
          deviceName: language.player.casting_to.replace('[DEVICE]', getCastDeviceName()),
          duration: seekableRange.duration,
          streamStartInSeconds: seekableRange.start,
          streamEndInSeconds: seekableRange.end,
          isLinear: isCastingLinearAsset(),
          playing: getCastPlayerState() === 'PLAYING',
          muted: isCastMuted(),
        };
      }
      case 'UPDATE_META_DATA':
        return {
          ...state,
          ...action.customData,
        };
      case 'UPDATE_MUTED':
        return {
          ...state,
          muted: !!action.muted,
        };
      case 'UPDATE_LANGUAGE':
        if (action.audioLanguage) {
          return {
            ...state,
            audioLanguages: state.audioLanguages.map(lang => ({
              ...lang,
              selected: lang.key === action.audioLanguage,
            })),
          };
        } else {
          return {
            ...state,
            textlanguages: state.textlanguages.map(lang => ({
              ...lang,
              selected: lang.key === action.textLanguage,
            })),
          };
        }
      case 'UPDATE_PLAYING': {
        const isLinear = isCastingLinearAsset();
        const seekableRange = getSeekableRange();
        return {
          ...state,
          ...getCustomMediaInfo(null),
          isLinear,
          playing: action.playerState === 'PLAYING',
          enabled: (!!action.playerState && action.playerState !== 'IDLE') || state.enabled,
          volume: getCastVolume(),
          duration: seekableRange.duration,
          streamStartInSeconds: seekableRange.start,
          streamEndInSeconds: seekableRange.end,
        };
      }
      case 'UPDATE_SESSION_STATE':
        const isSessionActive = updateFromSession(action.sessionState);
        const customData2 = isSessionActive ? getCustomMediaInfo(null) : null;

        return {
          ...state,
          enabled: isSessionActive,
          sessionState: action.sessionState,
          ...customData2,
        };
      case 'UPDATE_VOLUME':
        return {
          ...state,
          volume: action.volume!,
        };
      case 'SET_ERROR':
        return {
          ...state,
          error: action.errorMessage,
        };
      case 'CLEAR_ERROR':
        return {
          ...state,
          error: undefined,
        };
      default:
        return state;
    }
  };
};

export const reducer = reducerFactory();

const getAssetLink = (assetId: string, streamType: chrome.cast.media.StreamType) => {
  return streamType === 'BUFFERED' ? deepLinkRoutes.program(assetId) : deepLinkRoutes.liveChannel(assetId);
};
