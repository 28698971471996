import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { isRunningTests } from '@rikstv/play-common/src/utils/isRunningTests';

import cxoneCss from './cxone.css?raw';

export const Chatbot = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    loadCXOneBot();
  }, [pathname]);
  return null;
};

declare global {
  interface Window {
    cxone: (a: string, b: string, c?: any) => void;
  }
}

const loadCXOneBot = () => {
  if (isRunningTests()) {
    return;
  }

  const id = 'cxone-chatbot';
  if (document.getElementById(id)) {
    // script already loaded
    window.cxone('analytics', 'sendPageView', { url: window.location.href });
    return;
  }

  const scriptContent = /* js */ `(function(n,u){
    window.CXoneDfo=n,
    window[n]=window[n]||function(){(window[n].q=window[n].q||[]).push(arguments)},window[n].u=u,
    e=document.createElement("script"),e.type="module",e.src=u+"?"+Math.round(Date.now()/1e3/3600),
    document.head.appendChild(e)
    })('cxone','https://web-modules-de-eu1.niceincontact.com/loader/1/loader.js');
    cxone('init', '1401');
    cxone('guide','init');
    cxone('chat','showSendButton');`;
  const tag = document.createElement('script');
  tag.id = id;
  tag.textContent = scriptContent;
  document.head.appendChild(tag);

  const styles = document.createElement('style');
  styles.textContent = cxoneCss;
  document.head.appendChild(styles);
};
