import { request } from '@rikstv/play-common/src/forces/utils/request';

import config from '../config';

const defaultHeaders = Object.fromEntries(
  new Headers({
    Accept: 'application/json; api-version=1.0',
  }).entries()
);

export const get = (path: string, headers: Record<string, string> = {}): Parameters<typeof request>[0] => {
  return {
    path,
    url: config.gatewayUrl,
    headers: {
      ...defaultHeaders,
      ...headers,
    },
  };
};

export const put = (path: string, body?: object, headers: Record<string, string> = {}) => {
  const req = get(path, headers);
  req.method = 'PUT';
  if (body) {
    req.body = JSON.stringify(body);
  }
  return req;
};

export const post = (path: string, body?: object, headers: Record<string, string> = {}) => {
  const req = get(path, headers);
  req.method = 'POST';
  if (body) {
    req.body = JSON.stringify(body);
  }
  return req;
};
