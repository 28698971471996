import { type FC, useCallback, useEffect, useRef } from 'react';
import * as THEOplayer from '@theoplayer/extended';
import classNames from 'classnames';
import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import { IconButton } from '@rikstv/play-common/src/components/button/IconButton';
import config from '@rikstv/play-common/src/config';
import { CloseIcon } from '@rikstv/play-common/src/icons';
import logger from '@rikstv/play-common/src/utils/logger/logger';
import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';

import { FullscreenIcon, LeaveFullscreenIcon, SeekBackward10Icon, SeekForward10Icon } from '../../icons';
import { language } from '../../language';
import { isFullscreenSupported } from '../../utils/supportedFeatureUtils';
import { KeyboardListener } from '../KeyboardListener/KeyboardListener';
import { Overlay } from '../Overlay/Overlay';
import { ProgressBarWithTime } from '../ProgressBar/ProgressBar';
import { RoundPlayPauseToggle } from '../RoundPlayPauseToggle/RoundPlayPauseToggle';
import { Volume } from '../Volume/Volume';

import type { TrailerSource } from './useTrailerPlayerStore';

import styles from './TrailerPlayer.module.css';

interface TrailerProps {
  trailerSources: TrailerSource[];
  className?: string;
  onPlayStart?: () => void;
  loop?: boolean;
  muted?: boolean;
  disableHud?: boolean;
  autoPlay?: boolean;
  onCloseModalPlayer?: () => void;
  play?: boolean;
}

const _initialPlayerState = {
  currentTime: 0,
  showMenuBecauseOfUserActivity: false,
  streamStartSeconds: 0,
  streamEndSeconds: 0,
  duration: 0,
  volume: 0,
  paused: false,
  temporarilyDisableMouseActivityHandler: false,
  muted: false,
};
type PlayerState = typeof _initialPlayerState;
type PlayerStateActions = {
  reset: () => void;
  setState: (state: Partial<PlayerState>) => void;
};
const usePlayerStateStore = create<PlayerState & PlayerStateActions>()(set => ({
  ..._initialPlayerState,
  // Actions
  reset: () => set(_initialPlayerState),
  setState: state => set(state),
}));

export const TrailerPlayer = ({
  trailerSources,
  className = '',
  onPlayStart,
  loop = false,
  muted = false,
  disableHud = false,
  autoPlay = true,
  onCloseModalPlayer,
  play,
}: TrailerProps) => {
  const state = usePlayerStateStore(
    useShallow(s => ({
      showMenuBecauseOfUserActivity: s.showMenuBecauseOfUserActivity,
      streamStartSeconds: s.streamStartSeconds,
      streamEndSeconds: s.streamEndSeconds,
      duration: s.duration,
      volume: s.volume,
      paused: s.paused,
      temporarilyDisableMouseActivityHandler: s.temporarilyDisableMouseActivityHandler,
      muted: s.muted,
    }))
  );
  const setState = usePlayerStateStore(s => s.setState);
  const reset = usePlayerStateStore(s => s.reset);
  useEffect(() => {
    // reset state store when unmounting
    return () => reset();
  }, [reset]);

  const playerElement = useRef<HTMLDivElement | null>(null);
  const playerContainer = useRef<HTMLDivElement | null>(null);
  const player = useRef<THEOplayer.ChromelessPlayer | null>(null);
  const userActivityTimer = useRef<number | undefined>(undefined);
  const disableMouseActivityTimer = useRef<number | undefined>(undefined);
  const kbdActivityTimer = useRef<number | undefined>(undefined);
  const playPauseTarget = [styles.topControls, styles.bottomControls, styles.controlsContainer];

  const onTimeUpdate = useCallback(() => {
    if (player.current?.seekable.length) {
      setState({ currentTime: player.current.currentTime });
      const streamStartSeconds = player.current?.seekable.start(0) ?? 0;
      const streamEndSeconds = player.current?.seekable.end(0) ?? 0;
      setState({
        streamStartSeconds,
        streamEndSeconds,
        duration: streamEndSeconds - streamStartSeconds,
      });
    }
  }, [setState]);

  useEffect(() => {
    if (playerElement.current && !player.current) {
      player.current = new THEOplayer.ChromelessPlayer(playerElement.current, {
        // The URL where other JavaScript files from this package will be hosted on your web server.
        // THEOplayer may need to load these files as Web Workers in order to play certain streams.
        libraryLocation: '/theoplayer/', // copied as part of build, see vite.config.ts
        license: config.player.license,
        cast: {
          strategy: 'disabled',
        },
        persistVolume: true,
      });

      // player.current.src = src;
      player.current.abr.strategy = 'performance';
      player.current.source = {
        sources: trailerSources,
      };
      player.current.muted = muted;
      player.current.loop = loop;
      if (autoPlay) {
        player.current.play();
      }

      if (onPlayStart) {
        player.current.addEventListener('loadedmetadata', onPlayStart);
      }
      player.current.addEventListener('timeupdate', onTimeUpdate);
      player.current.addEventListener('error', errorEvent => {
        logger.error('Problem playing trailer', errorEvent.errorObject.message);
      });
    }
  }, [autoPlay, loop, muted, onPlayStart, onTimeUpdate, trailerSources]);

  useEffect(() => {
    if (!player.current || play === undefined) {
      return;
    }

    if (play && player.current.paused) {
      player.current.play();
      return;
    }
    player.current.pause();
  }, [play]);

  const handleUserActivity = () => {
    if (userActivityTimer.current) window.clearTimeout(userActivityTimer.current);
    setState({ showMenuBecauseOfUserActivity: true });
    userActivityTimer.current = window.setTimeout(() => {
      setState({ showMenuBecauseOfUserActivity: false });
    }, 2_000);
  };

  const handleMouseLeave = () => {
    if (userActivityTimer.current) {
      window.clearTimeout(userActivityTimer.current);
      setState({ showMenuBecauseOfUserActivity: false });
    }
  };

  const hideControlsAndTemporarilyDisableMouseMovementTrigger = () => {
    window.clearTimeout(disableMouseActivityTimer.current);
    setState({ temporarilyDisableMouseActivityHandler: true });
    disableMouseActivityTimer.current = window.setTimeout(
      () => setState({ temporarilyDisableMouseActivityHandler: false, showMenuBecauseOfUserActivity: false }),
      1_000
    );
  };

  const playOnClick: React.MouseEventHandler<HTMLDivElement> = ({ target }) => {
    if (!(target instanceof HTMLDivElement)) {
      return;
    }

    if (playPauseTarget.some(className => target.classList.contains(className))) {
      togglePlayPause();
    }
  };

  const toggleFullscreen = () => {
    if (!playerContainer.current) {
      return;
    }

    if (playerContainer.current === document.fullscreenElement) {
      document.exitFullscreen();
      return;
    }

    hideControlsAndTemporarilyDisableMouseMovementTrigger();
    playerContainer.current.requestFullscreen();
  };

  const seekSecondsInStream = (secondsToSeek: number) => {
    if (player.current) {
      player.current.currentTime = player.current.currentTime + secondsToSeek;
    }
  };

  const togglePlayPause = () => {
    if (player.current) {
      handleUserActivity();
      if (player.current.paused) {
        player.current.play();
      } else {
        player.current.pause();
      }

      setState({ paused: player.current.paused });
    }
  };

  const toggleMute = (muted?: boolean) => {
    if (player.current) {
      player.current.muted = muted ?? !player.current.muted;

      if (!player.current.muted && player.current.volume <= 0) {
        player.current.volume = 0.1;
      }

      setState({ muted: player.current.muted });
    }
  };

  const adjustVolume = (volume: number) => {
    if (player.current) {
      player.current.volume = volume;

      if (volume > 0) {
        toggleMute(false);
      }
      if (volume <= 0) {
        player.current.volume = 0;
        toggleMute(true);
      }
      if (volume > 1) {
        player.current.volume = 1;
      }

      setState({ volume: player.current?.volume });
    }
  };

  const onSetCurrentPositionInStreamByPercent = (progressFromStartBetween0and1: number) => {
    if (player.current) {
      const { streamStartSeconds, streamEndSeconds } = state;
      const secondsFromStart = (streamEndSeconds - streamStartSeconds) * progressFromStartBetween0and1;
      player.current.currentTime = streamStartSeconds + secondsFromStart;
      setState({ showMenuBecauseOfUserActivity: true });
    }
  };

  const handleKeyboardActivity = () => {
    if (kbdActivityTimer.current) window.clearTimeout(kbdActivityTimer.current);
    setState({ showMenuBecauseOfUserActivity: true });
    kbdActivityTimer.current = window.setTimeout(() => {
      setState({ showMenuBecauseOfUserActivity: false });
    }, 2_000);
  };

  return (
    <div
      ref={playerContainer}
      className={classNames(styles.playerContainer, {
        [styles.noCursor]: !state.showMenuBecauseOfUserActivity,
      })}
      onMouseMove={handleUserActivity}
      onMouseLeave={handleMouseLeave}>
      <div className={className} ref={playerElement} />

      {!disableHud && (
        <>
          <Overlay
            title={'trailer'}
            isLinear={false}
            showPlayButton={false}
            showOverlay={state.showMenuBecauseOfUserActivity || !!player.current?.paused}
            onClick={() => {
              togglePlayPause();
            }}
            isPlayerPlaying={!player.current?.paused}
          />
          {player.current && (
            <KeyboardListener
              isPaused={player.current.paused}
              onPlayPause={() => togglePlayPause()}
              onSeekSeconds={seekSecondsInStream}
              onSeekPercent={(percent: number) => {
                const percentage = percent / 100;
                onSetCurrentPositionInStreamByPercent(percentage);
              }}
              onChangeVolume={adjustVolume}
              onGoToStreamEnd={undefined}
              onToggleFullscreen={toggleFullscreen}
              onToggleMute={toggleMute}
              onSkipOrVolume={handleKeyboardActivity}
              onToggleMetricsPanel={() => {}} //TODO
            />
          )}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className={classNames(styles.controlsContainer, {
              [styles.visible]: state.showMenuBecauseOfUserActivity || player.current?.paused,
            })}
            onClick={playOnClick}>
            <div className={styles.topControls}>
              {onCloseModalPlayer && (
                <IconButton
                  className={styles.exitButton}
                  id="player-controls-close-modal"
                  buttonStyle="tertiary"
                  icon={CloseIcon}
                  ariaLabel="Steng spiller"
                  onClick={() => onCloseModalPlayer()}
                />
              )}
            </div>
            <div className={styles.middleControls}>
              <MobilePlaybackControls
                onSeek={seekSecondsInStream}
                onPlayPauseClick={togglePlayPause}
                paused={!!player.current?.paused}
              />
            </div>
            <div>
              <ProgressBarWrapper onSetCurrentPositionInStreamByPercent={onSetCurrentPositionInStreamByPercent} />
              <div className={styles.bottomControls}>
                <PlaybackControls
                  paused={!!player.current?.paused}
                  onPlayPauseClick={togglePlayPause}
                  onSeek={seekSecondsInStream}
                  muted={!!player.current?.muted}
                  onMute={() => toggleMute()}
                  volume={player.current?.volume ?? 0}
                  onVolume={adjustVolume}
                />
                <PlaybackOptions
                  isPlayerInFullscreen={playerContainer.current === document.fullscreenElement}
                  onFullscreenClick={toggleFullscreen}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

/**
 * Wrapper so that this component is the only one that subscribes to currentTime changes,
 * thus minimizing re-renders of entire component tree.
 */
const ProgressBarWrapper: FCC<{ onSetCurrentPositionInStreamByPercent: (update: number) => void }> = ({
  onSetCurrentPositionInStreamByPercent,
}) => {
  const { currentTime, duration, streamStartSeconds, streamEndSeconds } = usePlayerStateStore(
    useShallow(s => ({
      streamStartSeconds: s.streamStartSeconds,
      streamEndSeconds: s.streamEndSeconds,
      duration: s.duration,
      currentTime: s.currentTime,
    }))
  );
  return (
    <ProgressBarWithTime
      currentTime={currentTime}
      streamStartSeconds={streamStartSeconds}
      streamEndSeconds={streamEndSeconds}
      onSetProgressFromStartBetween0and1={onSetCurrentPositionInStreamByPercent}
      isLinear={false}
      isCasting={false}
      duration={duration}
    />
  );
};

type PlaybackControlProps = {
  paused: boolean;
  onPlayPauseClick: () => void;
  onSeek: (secondsToSeek: number) => void;
  muted: boolean;
  onMute: () => void;
  volume: number;
  onVolume: (volume: number) => void;
};

export const PlaybackControls: FC<PlaybackControlProps> = ({
  paused,
  onPlayPauseClick,
  onSeek,
  muted,
  onMute,
  onVolume,
  volume,
}) => {
  return (
    <div className={styles.playbackControls}>
      <RoundPlayPauseToggle
        className={classNames(styles.playButton, styles.hideOnMobile)}
        paused={paused}
        onPlayPauseClick={onPlayPauseClick}
        iconSize="normal"
      />
      <IconButton
        id="player-controls-seek-bwd"
        icon={SeekBackward10Icon}
        onClick={() => onSeek(-config.player.seekBackSeconds)}
        ariaLabel={language.player.seekbackward}
        title={language.player.seekbackward}
        size="normal"
        buttonStyle="tertiary"
        className={styles.hideOnMobile}
      />
      <IconButton
        id="player-controls-seek-fwd"
        icon={SeekForward10Icon}
        onClick={() => onSeek(config.player.seekForwardSeconds)}
        ariaLabel={language.player.seekforward}
        title={language.player.seekforward}
        size="normal"
        buttonStyle="tertiary"
        className={styles.hideOnMobile}
      />
      <Volume muted={muted} onClick={onMute} onVolumeChange={onVolume} volume={volume} />
    </div>
  );
};

const PlaybackOptions = ({
  isPlayerInFullscreen,
  onFullscreenClick,
}: {
  isPlayerInFullscreen: boolean;
  onFullscreenClick: () => void;
}) => {
  return (
    <div>
      {isFullscreenSupported && (
        <IconButton
          id="player-controls-fullscreen"
          buttonStyle="tertiary"
          icon={isPlayerInFullscreen ? LeaveFullscreenIcon : FullscreenIcon}
          ariaLabel={language.player.fullscreen}
          title={language.player.fullscreen}
          onClick={onFullscreenClick}
        />
      )}
    </div>
  );
};

export const MobilePlaybackControls = ({
  onSeek,
  onPlayPauseClick,
  paused,
}: {
  onSeek: (seek: number) => void;
  onPlayPauseClick: () => void;
  paused: boolean;
}) => {
  return (
    <div className={styles.mobilePlaybackContainer}>
      <IconButton
        id="player-controls-seek-bwd"
        icon={SeekBackward10Icon}
        onClick={() => onSeek(-config.player.seekBackSeconds)}
        ariaLabel={language.player.seekbackward}
        size="normal"
        buttonStyle="tertiary"
      />
      <RoundPlayPauseToggle
        className={styles.playButton}
        paused={paused}
        onPlayPauseClick={onPlayPauseClick}
        iconSize="normal"
      />
      <IconButton
        id="player-controls-seek-fwd"
        icon={SeekForward10Icon}
        onClick={() => onSeek(config.player.seekForwardSeconds)}
        ariaLabel={language.player.seekforward}
        size="normal"
        buttonStyle="tertiary"
      />
    </div>
  );
};
