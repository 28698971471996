import { ReactNode, useEffect, useMemo, useState } from 'react';

import { CastContext } from './CastContext';
import { loadCastSDK } from './castSdkLoader';
import ccConfig from './chromecast-config';

interface CastProviderProps {
  children?: ReactNode | ReactNode[];
}

export const CastProvider = ({ children }: CastProviderProps) => {
  const [connected, setConnected] = useState(false);
  const [castInitialized, setCastInitialized] = useState(false);

  useEffect(() => {
    loadCastSDK().then(
      () => setCastInitialized(true),
      // handle rejection to avoid logs to sentry (Firefox, Chrome on iOS etc)
      () => {
        /* do nothing */
      }
    );
  }, []);

  /* onCast Initialized */
  useEffect(() => {
    const onSessionStateChange = (data: cast.framework.SessionStateEventData) => {
      if (
        data.sessionState === window.cast.framework.SessionState.SESSION_RESUMED ||
        data.sessionState === window.cast.framework.SessionState.SESSION_STARTED
      ) {
        setConnected(true);
      }
      if (data.sessionState === window.cast.framework.SessionState.SESSION_ENDED) {
        setConnected(false);
      }
    };

    if (window.chrome && window.chrome.cast && window.cast) {
      cast.framework.CastContext.getInstance().setOptions({
        receiverApplicationId: ccConfig.receiverApplicationId(),
        autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
      });

      window.cast.framework.CastContext.getInstance().addEventListener(
        window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
        onSessionStateChange
      );
    }
  }, [castInitialized]);

  const value = useMemo(() => {
    return {
      connected,
      initialized: castInitialized,
    };
  }, [castInitialized, connected]);

  return <CastContext.Provider value={value}>{children}</CastContext.Provider>;
};
