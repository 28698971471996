import { getTemporalProperty } from '../../components/swimlane/swimlane.utils';
import { commonRoutes } from '../../router/router.path';
import { mapToProgress, mapToSeasonId } from '../assetDetails/mappers';
import { mapCardToProgramState } from '../assetDetails/swimlaneItemMapper';

import { TvSeriesDetails, TvSeriesEpisode, TvSeriesSeason } from './tvSeriesDetails.types';

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

type EpisodeResponse = WithRequired<RiksTV.ClientAPI.TitleWithCard, 'season' | 'name'>;

const mapToEpisode =
  () =>
  (response: EpisodeResponse): TvSeriesEpisode => {
    const programState = mapCardToProgramState(response.card);

    return {
      id: response.id,
      episodeNumber: response.episode,
      programState,
      behavior: getTemporalProperty(response.card.behaviors),
      title: response.card.title,
      image: response.card.image,
      description: response.card.description ?? '',
      progress: mapToProgress(response),
      duration: response.duration,
      subtitle: getTemporalProperty(response.card.subtitles)?.value,
      episodeLink: commonRoutes.watchAsset({ id: response.id }),
      isPlayable:
        response.streamingMode === 'Live' || (response.streamingMode === 'OnDemand' && programState.type !== 'Planned'),
      logos: response.card.logos.map(({ href, name }) => ({ logo: href, name })),
      _links: response.card._links,
    };
  };

const mapToSeason =
  (tvSeriesId: string) =>
  (response: any): TvSeriesSeason => {
    const seasonId = mapToSeasonId(tvSeriesId, response.number) as string;
    return {
      id: seasonId,
      seriesId: tvSeriesId,
      number: response.number,
      episodes: response.episodes.map(mapToEpisode()),
      episodeCount: response.episodes.length,
    };
  };

export const mapResponseToTvSeriesDetails = (response: any): TvSeriesDetails => {
  const seasons = response.seasons.map(mapToSeason(response.seriesId));

  return {
    id: response.seriesId,
    episodeCount: response.episodeCount,
    description: response.description,
    actors: response.actors ?? [],
    directors: response.directors ?? [],
    name: response.name,
    seasons,
    availableSeasons: seasons.length, // TODO: Possibly not the correct way to get availableSeasons. Consider fetching tvSeries from state.
    image: response.image,
  };
};
