export const isError = (value: any): boolean => {
  // Yanked from https://git.io/vS8DV re-used under CC0
  // with the same modifications as in raven.
  switch ({}.toString.call(value)) {
    case '[object Error]':
      return true;
    case '[object Exception]':
      return true;
    case '[object DOMException]':
      return true;
    default:
      return value instanceof Error;
  }
};
