import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { AnyAction, Reducer } from 'redux';

/**
 * Somewhat hacky according to https://github.com/reduxjs/redux/issues/580
 * but works and is useful to contain logic in a component.
 */

export type LastActionState = { lastAction: AnyAction };

export const lastActionReducer: Reducer = (_state = null, action) => {
  return action;
};

/**
 * For this hook to work lastActionReducer must be added last in combineReducers
 */
export const useReduxEffect = (effect: (action: any) => void, types: string[]) => {
  const store = useStore();
  // simple way to ensure the input array does not trigger re-runs of this hook (since they will be different every time)
  const type = types.join('|');

  useEffect(() => {
    const types = type.split('|');
    const unsubscribe = store.subscribe(() => {
      try {
        const action = (store.getState() as LastActionState).lastAction;
        if (types.includes(action.type)) {
          effect(action);
        }
      } catch (err) {
        /* om nom nom */
      }
    });
    return (): void => unsubscribe();
  }, [store, type, effect]);
};
