const debounce = <T extends (...args: any[]) => void>(handler: T, debounceDuration: number) => {
  let timeout: number | undefined;
  return (...args: Parameters<T>) => {
    if (timeout) {
      window.clearTimeout(timeout);
    }

    timeout = window.setTimeout(() => handler(...args), debounceDuration);
  };
};

export { debounce };
