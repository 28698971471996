import { lazy } from 'react';

import { AppRoutes, ROUTE_TYPE_NORMAL } from '@rikstv/play-common/src/router/routes.type';

import { LayoutDark } from '../containers/layout/LayoutDark';

import { routerPaths } from './router.path';

const Strim = lazy(() => import('../../domain/landingPage/pages/strim.no/Strim'));

const Apps = lazy(() =>
  import('../components/apps/Apps').then(module => ({
    default: module.Apps,
  }))
);

const routes: AppRoutes = [
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.frontpage,
    component: Strim,
    layout: LayoutDark,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.app,
    component: Apps,
    layout: LayoutDark,
    waitForStartup: false,
  },
];

export { routes as routesLandingPage };
