import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useSetScrollRestoration() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const setRestoreScrollPoint = useCallback(
    (swimlaneId: string, elementIndex: number) => {
      navigate(pathname + search, {
        state: {
          verticalScroll: window.scrollY,
          scrollRestore: { swimlaneId, elementIndex: elementIndex },
        },
        replace: true,
      });
    },
    [navigate, pathname, search]
  );

  return { setRestoreScrollPoint };
}
