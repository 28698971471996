import { FunctionComponent, useCallback } from 'react';
import classNames from 'classnames';

import { PrimaryIconButton } from '@rikstv/shared-components';

import { PlayTriangleIcon } from '../../icons';

import styles from './Overlay.module.css';

interface Props {
  title: string;
  isLinear: boolean;
  showOverlay: boolean;
  showPlayButton: boolean;
  onClick: () => void;
  isPlayerPlaying: boolean;
}

export const Overlay: FunctionComponent<Props> = ({
  onClick,
  showOverlay,
  showPlayButton,
  isPlayerPlaying,
  title,
  isLinear,
}) => {
  const playButtonRef = useCallback((element: HTMLElement | null) => {
    if (element !== null) {
      element.focus();
    }
  }, []);
  return (
    <div
      onClick={onClick}
      role="none"
      className={classNames(
        styles.overlay,
        { [styles.overlayHidden]: !showOverlay },
        { [styles.hideGradient]: showPlayButton }
      )}>
      {!isLinear && (
        <div className={classNames(styles.horizontalGradient, { [styles.showVerticalGradient]: !isPlayerPlaying })} />
      )}
      {showPlayButton && (
        <>
          <PrimaryIconButton
            className={styles.playButton}
            title="Start avspilling"
            aria-label={`Spill av ${title}`}
            icon={() => <PlayTriangleIcon style={{ paddingLeft: '13%' }} />}
            iconClass={styles.innerPlayIcon}
            ref={playButtonRef}
          />
        </>
      )}
    </div>
  );
};
