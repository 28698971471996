import { FC, PropsWithChildren, ReactNode } from 'react';

export type TODO = any;

/**
 * Helper to avoid writing React.FC<React.PropsWithChildren<T>> all the time
 */
export type FCC<T = unknown> = FC<PropsWithChildren<T>>;

/**
 * @description Unpacks promises in a returned type Very useful for typing yields in sagas.
 * @example const x: AwaitedReturnType<typeof someAsyncFn> = yield call(someAsyncFn)
 */
export type AwaitedReturnType<T extends (...args: any) => any> = Awaited<ReturnType<T>>;

/**
 * @description Unpacks return value from generator (*) function
 * @example const x: GeneratorReturnType<typeof myGeneratorFunction> = yield call(myGeneratorFunction)
 */
export type GeneratorReturnType<T extends (...args: any[]) => Generator> = UnwrapGeneratorReturn<ReturnType<T>>;
type UnwrapGeneratorReturn<T extends Generator> = T extends Generator<any, infer R, any> ? R : never;

export type WithChildren<T = unknown> = T & { children?: ReactNode };

export type LoadingStatus = 'INITIAL' | 'LOADING' | 'FAILED' | 'DONE';

export const INCOMPLETE_LOADING_STATUSES: LoadingStatus[] = ['INITIAL', 'LOADING'];

export type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> };

export type ElementProps<T extends HTMLElement> = React.DetailedHTMLProps<React.HTMLAttributes<T>, T>;

export type RequiredProps<T, K extends keyof T = keyof T> = Omit<T, K> & Pick<Required<T>, K>;

/** Similar to RequiredProps but also disallow [null | undefined] as value */
export type NonNullableProps<T, K extends keyof T = keyof T> = Omit<T, K> & Pick<RequiredNonNullable<T>, K>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type LooseStringUnion<T extends string> = T | (string & {});

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;
export type UpperCaseSnake<S extends string> = Uppercase<CamelToSnakeCase<Uncapitalize<S>>>;

type RequiredNonNullable<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

/**
 * Type guard useful for union types that also narrow type when used in if statement
 * @example
 * ```ts
 * const x: '1'|'2'|'3'|'4'|'5' = '1';
 * if (isOneOf(x, ['1', '2'])) {
 *   x; // type narrowed to '1' | '2'
 * }
 * ```
 */
export const isOneOf = <const TThing, TListOfThings extends TThing[]>(
  thing: TThing,
  listOfThings: TListOfThings
): thing is TListOfThings[number] => listOfThings.includes(thing);
