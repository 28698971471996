import { FC } from 'react';

import { getLogger } from '@rikstv/play-common/src/utils/logger/logger';
import { RequiredProps } from '@rikstv/play-common/src/utils/types/typeUtils';
import { H4, Tag } from '@rikstv/shared-components';

import { SwimlaneItem } from '../../../forces/assetDetails/assetDetails.types';
import { DurationBar } from '../../durationBar/DurationBar';
import { Breakpoints } from '../../grid/breakpoints';
import { ImageFade } from '../../imageFade/ImageFade';
import { ClampLines } from '../../truncateText/ClampLines';
import { FavoriteChannelButton } from '../favoriteChannelButton/favoriteChannelButton';
import { SwimlaneSettings } from '../Swimlane.types';
import { getLinkTo } from '../swimlane.utils';

import { ItemWrapper } from './ItemWrapper';

import './LiveChannelItem.scss';

const logger = getLogger('[Swimlane]');

const swimlaneConfig: SwimlaneSettings = {
  placeholderHeight: {
    [Breakpoints.XS]: 275,
    [Breakpoints.SM]: 320,
    [Breakpoints.MD]: 320,
    [Breakpoints.ML]: 330,
    [Breakpoints.LG]: 350,
    [Breakpoints.XL]: 370,
    [Breakpoints.XXL]: 350,
    [Breakpoints.DEFAULT]: 350,
  },
};

const ProgramTimeInfo: FC<{ asset: SwimlaneItem }> = ({ asset }) => {
  if (asset.programState.type !== 'Live') {
    return null;
  }
  return (
    <>
      <Tag className="live-channel-item__time">{asset.subtitle}</Tag>
      <DurationBar percent={asset.programState.progressPercentage ?? 0} className="live-channel-item__duration-bar" />
    </>
  );
};

interface LiveChannelItemProps {
  asset: RequiredProps<SwimlaneItem, 'channel'>;
  onClick?: () => void;
  preventNavigation?: boolean;
}

const LiveChannelItem: FC<LiveChannelItemProps> = ({ asset, onClick, preventNavigation }) => {
  if (asset.channel == null) {
    // Extra check to prevent crashes since we've seen this happen
    // when backup/restore logic in OVP fails.
    logger.warn('LiveChanelItem missing channel info', asset);
    return null;
  }

  return (
    <ItemWrapper
      className="live-channel-item"
      linkTo={getLinkTo(asset)}
      ariaLabel={asset.channel.name}
      preventDefault={preventNavigation}
      onClick={onClick}>
      <ImageFade src={asset.channel.logo} alt={asset.channel.name} className="live-channel-item__logo" />
      <ProgramTimeInfo asset={asset} />
      <ClampLines textStyle={H4} className="live-channel-item__program-name">
        {asset.title}
      </ClampLines>
      {asset.links.myList?.href && (
        <FavoriteChannelButton
          isDefaultFavorite={asset.userData?.isInMyList ?? false}
          url={asset.links.myList.href}
          channelId={asset.channel.id}
          className="live-channel-item__favorite-button"
        />
      )}
    </ItemWrapper>
  );
};
export { LiveChannelItem, swimlaneConfig };
