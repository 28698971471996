type ErrorTrackerConfig = {
  enabled: boolean;
  enableForPercentage?: number;
};

export class ErrorTrackerFeature {
  private environmentShouldLogToServer: boolean;
  readonly enableForPercentage: number;

  constructor(config: ErrorTrackerConfig) {
    this.environmentShouldLogToServer = config.enabled;

    this.enableForPercentage = config.enableForPercentage ?? 100;

    if (this.isInvalidPercentage(this.enableForPercentage)) {
      throw Error(
        `Error tracking is enabled, but enableForPercentage is not an integer between 1 and 100. config.enableForPercentage: ${config.enableForPercentage}, config.enabled: ${config.enabled}.`
      );
    }
  }

  private isInvalidPercentage(percentage: number) {
    return percentage <= 0 || percentage > 100;
  }

  shouldTrackErrors(): boolean {
    return this.enableForPercentage > 0;
  }

  thisEnvironmentShouldLogToServer(): boolean {
    return this.environmentShouldLogToServer;
  }
}
