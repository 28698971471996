import { FC, useRef } from 'react';
import classNames from 'classnames';
import { SwimlaneType } from 'ContentLayout';

import { ProgramState, SwimlaneItem } from '@rikstv/play-common/src/forces/assetDetails/assetDetails.types';
import { PadlockIcon, TvodIcon } from '@rikstv/play-common/src/icons';
import { CssBemHelper } from '@rikstv/play-common/src/utils/css/CssBemHelper';
import { Bold, H3, Meta, SubBody, Tag, TertiaryIconButton } from '@rikstv/shared-components';

import { getImagePackSet } from '../../../utils/imagePack/imagePack.utils';
import { AssetImage } from '../../assetImage/AssetImage';
import { AssetImageContainer } from '../../assetImage/AssetImageContainer';
import { Breakpoints } from '../../grid/breakpoints';
import { SwimlaneItemImageOverlay } from '../../imageOverlays';
import { ImdbBadge } from '../../imdbBadge/ImdbBadge';
import { Pill } from '../../pill/Pill';
import { usePinStore } from '../../pin/usePinStore';
import { ProviderImageWrapper } from '../../providerImage';
import { SwimlaneItemProps, SwimlaneSettings } from '../Swimlane.types';
import { getLinkTo } from '../swimlane.utils';

import { ItemWrapper } from './ItemWrapper';

import './StandardItem.scss';

interface OwnProps {
  showImdbRating?: ImdbProps['showImdbRating'];
  featured?: boolean;
  onClick?: () => void;
  preventNavigation?: boolean;
  noMargin?: boolean;
  showDescription?: boolean;
  swimlaneType: SwimlaneType;
  classes?: string;
  showProviderLogos?: boolean;
  contextMenuItems?: ContextMenuItem[];
  portrait?: boolean;
  isHeroPortrait?: boolean;
}

export interface ContextMenuItem {
  icon: () => JSX.Element;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: SwimlaneItem) => void;
  title: string;
  ariaLabel: string;
}

export type StandardItemProps = SwimlaneItemProps & OwnProps;

const bem = new CssBemHelper('swimlane-standard-item');

export const StandardItem: FC<StandardItemProps> = ({
  asset,
  swimlaneType,
  featured,
  showImdbRating = asset.programState.type === 'Live' ? 'never' : 'onHover',
  noMargin = false,
  preventNavigation,
  showDescription = false,
  onClick,
  classes = '',
  showProviderLogos = true,
  contextMenuItems,
  portrait = false,
  isHeroPortrait = false,
}) => {
  const renderImageOverlay = swimlaneType !== 'MyList';
  const titleRef = useRef<HTMLHeadingElement>(null);
  const metaRef = useRef<HTMLElement>(null);

  addScrollIfTextOverflow([titleRef, metaRef]);

  return (
    <ItemWrapper
      className={classNames(bem.base, classes, {
        [bem.modifier('featured')]: featured,
        [bem.modifier('no-margin')]: noMargin,
      })}
      linkTo={getLinkTo(asset)}
      preventDefault={preventNavigation}
      onClick={onClick}>
      <AssetImageContainer>
        <StandardImage image={asset.image.url} blackWhite={asset.image.bwFilter} portrait={portrait} />
        {renderImageOverlay && <SwimlaneItemImageOverlay programState={asset.programState} />}
        <AgeLimitPill ageLimit={asset.ageLimit}>
          {/* ImdbPill will be rendered if ageLimit isn't relevant */}
          <ImdbPill
            imdbRating={asset.imdbRating}
            showImdbRating={showImdbRating}
            alignRight={asset.programState.type === 'Live'}
          />
        </AgeLimitPill>
        {showProviderLogos && <ProviderImageWrapper logos={asset.logos} portrait={portrait} />}
        <TvodTag state={asset.programState.type} price={asset.rentalPrice} />
      </AssetImageContainer>
      <div className={isHeroPortrait ? 'sr-only' : ''} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ overflow: 'hidden' }}>
          <H3
            ref={titleRef}
            lookLike="title-4"
            className={bem.element('title')}
            title={titleRef.current?.classList.contains('scrollable') ? asset.title : undefined}>
            {asset.title}
          </H3>
          <Meta ref={metaRef} className={bem.element('meta')}>
            {asset.subtitle}
          </Meta>
        </div>
        {contextMenuItems &&
          contextMenuItems.map((contextMenuItem, idx) => {
            return (
              <TertiaryIconButton
                key={`contextMenuItem-${idx}-${asset.id}`}
                title={contextMenuItem.title}
                aria-label={contextMenuItem.ariaLabel}
                className="swimlane-standard-item__context-menu"
                icon={contextMenuItem.icon}
                onClick={e => {
                  contextMenuItem.onClick(e, asset);
                }}
              />
            );
          })}
      </div>
      {showDescription && <SubBody className={bem.element('description')}>{asset.description}</SubBody>}
    </ItemWrapper>
  );
};

const StandardImage: FC<{ image: string; blackWhite: boolean; portrait: boolean }> = ({
  image,
  blackWhite,
  portrait,
}) => {
  const imageVariations = getImagePackSet({
    url: image,
    variations: [
      { width: portrait ? 150 : 230, height: portrait ? 225 : 129, breakpoint: Breakpoints.SM },
      { width: portrait ? 200 : 296, height: portrait ? 300 : 166, breakpoint: Breakpoints.MD },
      { width: portrait ? 260 : 460, height: portrait ? 420 : 258, breakpoint: Breakpoints.DEFAULT },
    ],
  });

  return (
    <AssetImage imageSet={imageVariations} ratio={portrait ? 'portrait' : '16_9'} alt="" blackWhite={blackWhite} />
  );
};

interface ImdbProps {
  imdbRating?: string;
  showImdbRating: 'onHover' | 'always' | 'never';
  alignRight?: boolean;
}
const pillBem = new CssBemHelper(bem.element('imdb-pill'));
const ImdbPill: FC<ImdbProps> = ({ imdbRating, showImdbRating, alignRight = false }) => {
  if (!imdbRating || showImdbRating === 'never') {
    return null;
  }
  return (
    <Pill
      className={classNames(pillBem.base, {
        [pillBem.modifier('show-on-hover')]: showImdbRating === 'onHover',
        [pillBem.modifier('right')]: alignRight,
      })}>
      <ImdbBadge imdbRating={imdbRating} />
    </Pill>
  );
};

export const AgeLimitPill = ({ ageLimit, children }: { ageLimit?: number; children?: React.ReactNode }) => {
  const { parentalSetting } = usePinStore();
  const showAgeRating = !!(
    parentalSetting?.isParentalControlActive &&
    ageLimit &&
    parentalSetting.ageRestriction <= ageLimit
  );

  // Used to display IMDb rating for standard items when age limit does not apply
  if (!showAgeRating) {
    return children;
  }

  return (
    <Pill className={pillBem.base}>
      <PadlockIcon style={{ marginRight: '0.25rem' }} iconSize={17} />
      <Bold>{ageLimit}+</Bold>
    </Pill>
  );
};

/** Displays price tag or TVOD icon for rental assets. */
export const TvodTag = ({ state, price }: { state: ProgramState['type']; price?: string }) => {
  if (state !== 'Rented' && !price) {
    return;
  }

  return (
    <Tag className={'swimlane-standard-item_price-pill'}>
      {state === 'Rented' ? (
        <TvodIcon style={{ ['--size']: '1.5rem', marginBottom: '-2px', marginRight: '-1px' } as React.CSSProperties} />
      ) : (
        price
      )}
    </Tag>
  );
};

export const standardConfig: SwimlaneSettings = {
  placeholderHeight: {
    [Breakpoints.XS]: 265,
    [Breakpoints.SM]: 410,
    [Breakpoints.MD]: 300,
    [Breakpoints.ML]: 275,
    [Breakpoints.LG]: 275,
    [Breakpoints.XL]: 300,
    [Breakpoints.XXL]: 315,
    [Breakpoints.DEFAULT]: 340,
  },
};

export const standardPortraitConfig: SwimlaneSettings = {
  placeholderHeight: {
    [Breakpoints.XS]: 315,
    [Breakpoints.SM]: 340,
    [Breakpoints.MD]: 390,
    [Breakpoints.ML]: 390,
    [Breakpoints.LG]: 420,
    [Breakpoints.XL]: 460,
    [Breakpoints.XXL]: 460,
    [Breakpoints.DEFAULT]: 460,
  },
};

function addScrollIfTextOverflow(refArray: React.RefObject<HTMLElement>[]) {
  refArray?.forEach(ref => {
    if (ref?.current) {
      const titleElement = ref.current;

      if (titleElement.scrollWidth > titleElement.offsetWidth) {
        titleElement.classList.add('scrollable');
      }
    }
  });
}
