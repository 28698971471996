import useSWRImmutable from 'swr/immutable';

import { useAuthToken } from '../../hooks/useAuthToken';

import { getTvSeriesDetails } from './api';

export const useTvSeries = (seriesId?: string) => {
  const token = useAuthToken();

  const fetcherKeys = seriesId ? ([seriesId, token, 'useTvSeries'] as const) : null;
  const seriesFetcher = (seriesId: string, token: string | null) => getTvSeriesDetails(seriesId, token);

  const { data, error, ...rest } = useSWRImmutable(fetcherKeys, ([seriesId, token]) => seriesFetcher(seriesId, token), {
    dedupingInterval: 120_000,
    keepPreviousData: true,
  });

  return { data: data, error, ...rest };
};
