import type { CommonAnalytics } from '../utils/analytics/CommonAnalytics.types';

import { getCommonAnalyticsMiddleware } from './analytics/commonAnalyticsMiddleware';
import { CommonAnalyticsService } from './analytics/commonAnalyticsService';
import { progressUpdateMiddleware } from './progressUpdater/progressUpdateMiddleware';
import { webSocketMiddleware } from './webSocket/webSocketMiddleware';

export const getCommonMiddleware = (analyticsPoster: CommonAnalytics) => [
  getCommonAnalyticsMiddleware(new CommonAnalyticsService(analyticsPoster)),
  webSocketMiddleware,
  progressUpdateMiddleware,
];
