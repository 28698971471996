import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccessToken } from './auth.types';

export interface AuthorizationState {
  accessToken: AccessToken | null | undefined;
  isLoggingIn: boolean;
  postAuthRedirectUrl: string | null;
}

const initialState: AuthorizationState = {
  accessToken: null,
  isLoggingIn: true,
  postAuthRedirectUrl: null,
};

export const authSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    authInitialized: state => {
      state.isLoggingIn = false;
    },
    loginSuccess: (state, { payload }: PayloadAction<AccessToken>) => {
      state.accessToken = payload;
    },
    tokenRenewed: (state, { payload }: PayloadAction<AccessToken>) => {
      state.accessToken = payload;
    },
    setPostAuthRedirectUrl: (state, { payload }: PayloadAction<string | null>) => {
      state.postAuthRedirectUrl = payload;
    },
    clearReAuthenticationRedirectUrl: state => {
      state.postAuthRedirectUrl = null;
    },
  },
});

export const { authInitialized, loginSuccess, tokenRenewed, clearReAuthenticationRedirectUrl, setPostAuthRedirectUrl } =
  authSlice.actions;

export default authSlice.reducer;
