import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { BaseLinkButton, NotificationDot, SecondaryButton, useElementVisible } from '@rikstv/shared-components';

import { SearchSvg, StrimLogoSvg } from '../svg';

import { Hamburger } from './Hamburger';
import { If } from './If';
import { baseItems, getMobileNavItems, NavigationItemType, salesItems } from './navigationConfig';
import { navItemFactory } from './navItemFactory';
import { useMobileMenu } from './useMobileMenu';

import './global.css';
import navItemStyles from './NavItem.module.scss';
import styles from './StrimNavigation.module.scss';

interface NavigationProps {
  isLoggedIn: boolean;
  currentUrl: string;
  accountNotifications?: number;
  hideHelpInAppNavigation?: boolean;
  login: () => void;
  linkComponent: (props: UnstyledLinkProps) => ReactNode | null;
  getAppNavigation: (salesItems: NavigationItemType[]) => NavigationItemType[];
}

interface UnstyledLinkProps {
  href: string;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

export const StrimNavigation: FC<NavigationProps> = ({
  isLoggedIn,
  login,
  currentUrl,
  getAppNavigation,
  linkComponent,
  accountNotifications,
  hideHelpInAppNavigation = false,
}) => {
  const { mobileMenuOpen, setMobileMenuOpen, mobileNavRef, mobileMenuFocusTrapper } = useMobileMenu(currentUrl);

  const mainNavItems = getAppNavigation(salesItems);
  const { MainNavItem, MobileNavItem } = navItemFactory(linkComponent, currentUrl);
  const mobileNavItems = getMobileNavItems(mainNavItems, isLoggedIn, login);
  const displayingAppNavigation = mainNavItems !== salesItems;
  const hideTopHelpItem = displayingAppNavigation && hideHelpInAppNavigation;

  // use to apply drop shadow to menu when scrolled down from top
  // the header element is static which is why this works
  const [headerRef, isHeaderVisible] = useElementVisible({ rootMargin: '-35px' });
  const hideSignUpLink = isLoggedIn || isInSignUpFlow(currentUrl);

  return (
    // Disable a11y rules here to implement focus-trapping in mobile menu
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <header
      ref={headerRef}
      className={classNames(styles.header, { [styles.mobileMenuOpen]: mobileMenuOpen })}
      onKeyDown={mobileMenuFocusTrapper}>
      <nav
        className={styles.nav}
        aria-label={'Hovednavigasjon'}
        role="navigation"
        data-scrolled={isHeaderVisible === false}>
        <ul className={styles.topMenu}>
          {/* Left part of navigation */}
          <MainNavItem action="/" className={classNames(navItemStyles.strimLogo, styles.spacerMobile)}>
            <StrimLogoSvg title={'Strim logo'} />
            <span className="sr-only">Forsiden av Strim.no</span>
          </MainNavItem>
          {mainNavItems.map(({ action, children }) => (
            <MainNavItem key={action} className={styles.desktopOnly} action={action}>
              {children}
            </MainNavItem>
          ))}

          {/* Right part of navigation */}
          <If condition={hideSignUpLink === false}>
            <MainNavItem className={classNames(styles.mobileOnly)} {...baseItems.signUp} />
          </If>
          {/* Search */}
          <MainNavItem
            action={baseItems.search.action}
            className={classNames(navItemStyles.searchItem, styles.spacerDesktop)}>
            <SearchSvg role="presentation" />
            <span className={navItemStyles.searchLabel}>{baseItems.search.children}</span>
          </MainNavItem>
          {/* SignUp & Login */}
          <If condition={!isLoggedIn}>
            <If condition={hideSignUpLink === false}>
              <li className={classNames(navItemStyles.signup, styles.desktopOnly)}>
                <BaseLinkButton element={linkComponent} buttonStyle="tertiary" href={baseItems.signUp.action}>
                  {baseItems.signUp.children}
                </BaseLinkButton>
              </li>
            </If>
            <li className={styles.desktopOnly}>
              <SecondaryButton data-testid="login-btn" onClick={login}>
                Logg inn
              </SecondaryButton>
            </li>
          </If>
          {/* Account */}
          <If condition={isLoggedIn}>
            <li className={classNames(styles.desktopOnly, styles.relative)}>
              <BaseLinkButton element={linkComponent} buttonStyle="secondary" href={baseItems.account.action}>
                {baseItems.account.children}
              </BaseLinkButton>
              {Boolean(accountNotifications) && (
                <NotificationDot testid="top-level-notification" numberOfNotifications={accountNotifications} />
              )}
            </li>
          </If>
          {/* Help & Hamburger */}
          {hideTopHelpItem ? <li>&nbsp;&nbsp;</li> : <MainNavItem className={styles.desktopOnly} {...baseItems.help} />}
          <Hamburger
            mobileMenuOpen={mobileMenuOpen}
            toggleMobileMenu={() => setMobileMenuOpen(prevOpen => !prevOpen)}
            className={styles.mobileOnly}
            accountNotifications={accountNotifications}
          />
        </ul>
        {/* MOBILE MENU */}
        <ul
          ref={mobileNavRef}
          className={classNames(
            styles.mobileMenu,
            { [styles.open]: mobileMenuOpen },
            { [styles.appNavigation]: displayingAppNavigation }
          )}>
          {mobileNavItems.map(item => {
            const nrOfNotifications = baseItems.account.action === item.action ? accountNotifications : undefined;
            const grouped = displayingAppNavigation && mainNavItems.includes(item);
            return (
              <MobileNavItem
                key={item.action}
                notificationDotNr={nrOfNotifications}
                isPartOfGroup={grouped}
                {...item}
              />
            );
          })}
        </ul>
      </nav>
    </header>
  );
};

const signUpFlowUrlPatterns = [/^\/bli-kunde/i, /^\/signup\//i];
function isInSignUpFlow(currentUrl: string) {
  return signUpFlowUrlPatterns.some(pattern => pattern.test(currentUrl));
}
