import { sharedStrimUrls, webAppLinks } from '@rikstv/shared-strim-paths';

export interface NavigationItemType {
  children: string;
  action: string;
  onClick?: () => void;
}

export const salesItems: NavigationItemType[] = [
  {
    children: 'Utforsk innhold',
    action: '/start',
  },
  {
    children: 'Hvorfor velge Strim?',
    action: webAppLinks.hvaErStrim,
  },
];

// TODO: länkar borde inte vara magic strings men delade via shared-strim-paths eller nåt sånt
type BaseItems = Record<'signUp' | 'search' | 'help' | 'account', NavigationItemType>;
export const baseItems: BaseItems = {
  signUp: {
    children: 'Bli kunde',
    action: sharedStrimUrls.signup,
  },
  search: {
    children: 'Søk',
    action: sharedStrimUrls.search,
  },
  help: {
    children: 'Hjelp',
    action: webAppLinks.hjelp,
  },
  account: {
    children: 'Min konto',
    action: sharedStrimUrls.account,
  },
};

export const getMobileNavItems = (
  mainNavItems: NavigationItemType[],
  isLoggedIn: boolean,
  login: () => void
): NavigationItemType[] => {
  return [
    ...mainNavItems,
    baseItems.help,
    isLoggedIn ? baseItems.account : { action: '#', children: 'Logg inn', onClick: login },
  ];
};
