import { createAction } from '@reduxjs/toolkit';

export const searchResultClicked = createAction(
  'searchResultClicked',
  (
    search: string,
    {
      title,
      position,
      listTitle,
      listPosition,
    }: { title: string; position: number; listTitle: string; listPosition: number }
  ) => ({
    payload: {
      search,
      title,
      position,
      listTitle,
      listPosition,
    },
  })
);

export const searchResultViewed = createAction('searchResultViewed', (search: string) => ({ payload: { search } }));
