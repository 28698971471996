import config from '@rikstv/play-common/src/config';
import { commonRouterPaths } from '@rikstv/play-common/src/router/router.path';

export const getCanonicalUrl = (slug: string, origin: string, isCollection: boolean): string => {
  // When Rendertron parses this URL, it is mounted in share.strim.no/render
  // So we need to strip that in order for it to actually set the correct canonical url
  const originalOrigin = origin.replace(config.share.url, '');

  const pathname = isCollection ? commonRouterPaths.collections.replace(':slug', slug) : `/${slug}`;

  return `${originalOrigin}${pathname}`;
};
