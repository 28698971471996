import { commonRoutes } from '@rikstv/play-common/src/router/router.path';

import { ContentStackMetadata, SuperCoverPageData } from '../contentStack.types';

export const getStackContent = (data: SuperCoverPageData): ContentStackMetadata => {
  switch (data.type) {
    case 'episode':
      return data.episode;
    case 'series':
      return data.series;
    case 'singleProgramOrMovie':
      return data.singleProgramOrMovie;
  }
};

export const getSeriesLinkIfEpisode = (media: ContentStackMetadata) => {
  if (media.type !== 'episode' || media._links.superCoverPage?.id == null || media.metadata.seriesName == null) {
    return { seriesLink: null, seriesName: null };
  }
  return {
    seriesLink: commonRoutes.stackedSeriesOrMovie({
      id: media._links.superCoverPage.id,
      isSeries: true,
      title: media.metadata.seriesName,
    }),
    seriesName: media.metadata.seriesName,
  };
};
