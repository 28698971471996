import { FC } from 'react';

import { Tag } from '@rikstv/shared-components';

import { Pill } from '../pill/Pill';

import { ImageOverlayWrapper } from './ImageOverlayWrapper';

export const DefaultOverlay: FC<{
  label: string;
  className?: string;
}> = ({ label, className }) => {
  return (
    <ImageOverlayWrapper style={{ padding: '8px 8px 10px' }}>
      <div>
        <Pill className={className}>
          <Tag>{label}</Tag>
        </Pill>
      </div>
    </ImageOverlayWrapper>
  );
};
