import { svgToIconElement } from '@rikstv/play-common/src/icons/svgToIconElement';

import { ReactComponent as CastAvailableSvg } from './svg/cast-available.svg';
import { ReactComponent as CastConnectedSvg } from './svg/cast-connected.svg';
import { ReactComponent as CastConnectingSvg } from './svg/cast-connecting.svg';
import { ReactComponent as EpisodeOverviewSvg } from './svg/episode-overview.svg';
import { ReactComponent as FullscreenSvg } from './svg/fullscreen.svg';
import { ReactComponent as HelpSvg } from './svg/help.svg';
import { ReactComponent as LanguageMenuSvg } from './svg/language-menu.svg';
import { ReactComponent as LeaveFullscreenSvg } from './svg/leave-fullscreen.svg';
import { ReactComponent as MuteVolumeSvg } from './svg/mute-volume.svg';
import { ReactComponent as PauseSvg } from './svg/pause.svg';
import { ReactComponent as PipLeaveSvg } from './svg/pip-leave.svg';
import { ReactComponent as PipOpenSvg } from './svg/pip-open.svg';
import { ReactComponent as PlayNextSvg } from './svg/play-next.svg';
import { ReactComponent as PlayRoundSvg } from './svg/play-round.svg';
import { ReactComponent as PlayTriangleSvg } from './svg/play-triangle.svg';
import { ReactComponent as SeekForward10Svg } from './svg/seek-forward-10.svg';
import { ReactComponent as SeekRewind10Svg } from './svg/seek-rewind-10.svg';
import { ReactComponent as SettingsMenuSvg } from './svg/settings-menu.svg';
import { ReactComponent as StartOverSvg } from './svg/start-over.svg';
import { ReactComponent as VolumeSvg } from './svg/volume.svg';

import style from './player-icons.module.css';

// Chromecast icons
export const CastNotAvailableIcon = svgToIconElement(CastAvailableSvg, { style: { opacity: '0.5' } });
export const CastAvailableIcon = svgToIconElement(CastAvailableSvg);
export const CastConnectedIcon = svgToIconElement(CastConnectedSvg);
export const CastConnectingIcon = svgToIconElement(CastConnectingSvg, { className: style.castConnecting });

// Player HUD
export const FullscreenIcon = svgToIconElement(FullscreenSvg);
export const LanguageMenuIcon = svgToIconElement(LanguageMenuSvg);
export const LeaveFullscreenIcon = svgToIconElement(LeaveFullscreenSvg);
export const MuteVolumeIcon = svgToIconElement(MuteVolumeSvg);
export const PauseIcon = svgToIconElement(PauseSvg);
export const PipLeaveIcon = svgToIconElement(PipLeaveSvg);
export const PipOpenIcon = svgToIconElement(PipOpenSvg);
export const SeekForward10Icon = svgToIconElement(SeekForward10Svg);
export const SeekBackward10Icon = svgToIconElement(SeekRewind10Svg);
export const SettingsMenuIcon = svgToIconElement(SettingsMenuSvg);
export const VolumeIcon = svgToIconElement(VolumeSvg);
export const StartOverIcon = svgToIconElement(StartOverSvg);
export const PlayTriangleIcon = svgToIconElement(PlayTriangleSvg);
export const PlayRoundIcon = svgToIconElement(PlayRoundSvg, { className: style.playRound });
export const PlayNextIcon = svgToIconElement(PlayNextSvg);
export const HelpIcon = svgToIconElement(HelpSvg);
export const EpisodeOverviewIcon = svgToIconElement(EpisodeOverviewSvg);
