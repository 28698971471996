/* eslint-disable react/forbid-elements */
// This page is outside our apps context, therefor cannot use the Link component

import { Body, H2 } from '@rikstv/shared-components';

import styles from './ErrorPage.module.css';

export const ErrorPage = () => (
  <article className={styles.errorPage}>
    <div className={styles.errorPageContent}>
      <H2>Au da, det har oppstått en feil</H2>
      <Body>Feilen er meldt inn til oss og vi skal forsøke å løse den. Klikk under for å gå til forsiden</Body>
      <br />
      <a href="/">Til forsiden</a>
    </div>
  </article>
);
