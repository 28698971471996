export const paymentErrorCodes = {
  SUBSCRIPTION_FAILED: 'SUBSCRIPTION_FAILED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  COULD_NOT_CHARGE_CARD: 'COULD_NOT_CHARGE_CARD',
  CLIENT_TOKEN_LOAD_FAILED: 'CLIENT_TOKEN_LOAD_FAILED',
  CARD_ALREADY_REGISTERED: 'CARD_ALREADY_REGISTERED',
  DEFAULT: 'DEFAULT',
  INVALID_VOUCHER: 'INVALID_VOUCHER',
  CARD_3DSECURE_FAILED: 'CARD_3DSECURE_FAILED',
  CARD_3DSECURE_FAILED_LIABILITYSHIFT_NOT_POSSIBLE: 'CARD_3DSECURE_FAILED_LIABILITYSHIFT_NOT_POSSIBLE',
  CARD_NOT_NORWEGIAN: 'CARD_NOT_NORWEGIAN',
  PP_ACCOUNT_NOT_NORWEGIAN: 'PP_ACCOUNT_NOT_NORWEGIAN',
  SUBCTIPTION_CANCELED: 'SUBCTIPTION_CANCELED',
} as const;

export type PaymentErrorCode = (typeof paymentErrorCodes)[keyof typeof paymentErrorCodes];
