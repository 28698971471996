import React from 'react';

import { ButtonLink } from '../button';

import './SkipLink.scss';

interface Props {
  elementId: string;
  buttonText: string;
}

export const SkipLink = ({ elementId, buttonText }: Props) => {
  const preventDefaultEvent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    document.getElementById(elementId)?.focus();
  };

  return (
    <ButtonLink
      to={`#${elementId}`}
      buttonStyle="inline-link-light"
      className="skip-link"
      onClick={preventDefaultEvent}>
      {buttonText}
    </ButtonLink>
  );
};
