import { SwimlaneType } from 'ContentLayout';

import { StreamUrls } from '@rikstv/play-player/src/player.types';

import { getRelativeTimeInfo, nonZeroYearOrUndefined } from '../../utils/date/date.utils';
import { mustBeDefined } from '../../utils/mapping/mapping.utils';
import { Progress } from '../root.types';
import { getAssetTypeForResponse, isCatchUp } from '../utils/type.utils';

import {
  AssetDetails,
  AssetDetailsBase,
  AssetDetailsLinks,
  AssetDetailsMetadata,
  ExternalAssetMetadata,
  LogoUrls,
  MenuDetails,
  OriginChannel,
  OriginChannelLinks,
  PlayableBase,
  TvSeriesMetadata,
} from './assetDetails.types';

export const mapToProgress = (response: RiksTV.ClientAPI.Title): Progress | undefined => {
  if (!response.progressPercent || !response.progressSeconds) {
    return undefined;
  }
  const percent = response.progressPercent || 0;
  const seconds = response.progressSeconds || 0;
  const credits = response.creditsStartTime;
  const hasStartedWatching = seconds > 30;
  const hasReachedEnd = credits != null ? seconds > credits : percent > 0.9;

  const suggestContinueWatching = hasStartedWatching && !hasReachedEnd;

  return {
    percent,
    startAtSeconds: suggestContinueWatching ? seconds : undefined,
  };
};

export const mapToSeasonId = (seriesId: string, seasonNumber?: number) =>
  seasonNumber ? `${seriesId}_${seasonNumber}` : undefined;

const mapToLogoUrls = (originChannel: RiksTV.ClientAPI.OriginChannel): LogoUrls => {
  originChannel.logoUrl = mustBeDefined(originChannel, 'logoUrl');
  originChannel.logoUrlSvg = mustBeDefined(originChannel, 'logoUrlSvg');
  originChannel.logoUrlSvgSquare = mustBeDefined(originChannel, 'logoUrlSvgSquare');

  return {
    png: originChannel.logoUrl,
    svg: originChannel.logoUrlSvg,
    svgSquare: originChannel.logoUrlSvgSquare,
  };
};

const mapToOriginChannelLinks = (originChannel: any): OriginChannelLinks => {
  return {
    logoPackUrl: originChannel._links.logopack && originChannel._links.logopack.href,
    placeholderImageUrl: originChannel._links.placeholderImage.href,
  };
};

export const mapToStreamUrls = (response: any): StreamUrls => {
  const links = (response && response._links) || {};

  const streamUrls = {
    widevine: links.playDash ? links.playDash.href : '',
    playready: links.playDashPR ? links.playDashPR.href : '',
    fairplay: links.playHLSF ? links.playHLSF.href : '',
  };

  return streamUrls;
};

const mapToAssetDetailsLinks = (response: RiksTV.ClientAPI.Title): AssetDetailsLinks => {
  const superCoverPage = response._links.superCoverPage;
  return {
    seriesUrl: response._links.series?.href,
    progressUrl: response._links?.progress?.href,
    similarUrl: response._links?.similar?.href,
    wishlistUrl: response._links.wish?.href,
    imagePackUrl: response.imagePackUri,
    nextEpisodeUrl: response._links?.nextEpisode?.href,
    excludeFromContinueWatchingUrl: response._links?.excludeFromContinueWatching?.href,
    superCoverPage:
      superCoverPage?.href && superCoverPage?.id
        ? (superCoverPage as Required<RiksTV.ClientAPI.ResourceLink>)
        : undefined,
  };
};

export const mapToOriginChannel = (response: Partial<RiksTV.ClientAPI.Title>): OriginChannel => {
  response.originChannel = mustBeDefined(response, 'originChannel');
  response.originChannel.serviceName = mustBeDefined(response.originChannel, 'serviceName');
  response.originChannel.colorCode = mustBeDefined(response.originChannel, 'colorCode');

  return {
    channelId: response.originChannel.channelId,
    serviceName: response.originChannel.serviceName,
    colorCode: response.originChannel.colorCode,
    logoUrls: mapToLogoUrls(response.originChannel),
    links: mapToOriginChannelLinks(response.originChannel),
  };
};

export const mapChannelToOriginChannel = (response: RiksTV.ClientAPI.Channel): OriginChannel => {
  const channel = {
    originChannel: {
      serviceName: response.serviceName,
      stbId: response.stbId,
      cridAuthority: response.cridAuthority,
      channelId: response.channelId,
      colorCode: response.colorCode,
      logoUrl: `${response._links.logopack.href}square.svg`,
      logoUrlSvg: `${response._links.logopack.href}square.svg`,
      logoUrlSvgSquare: `${response._links.logopack.href}square.svg`,
      _links: {
        ...response._links,
      },
    },
  };

  return mapToOriginChannel(channel);
};

const mapToPlayable = (response: RiksTV.ClientAPI.Title): PlayableBase => {
  return {
    id: response.id,
    name: response.name || '',
    type: getAssetTypeForResponse(response),
    originChannel: mapToOriginChannel(response),
    // default value is false, but for UI false is only relevant if the user is logged in
    isInSubscription: response.subscription,
    posterUrl: response.imagePackUri || '',
    assetDetailsUrl: response._links.details?.href || '',
    progress: mapToProgress(response),
    streamUrls: mapToStreamUrls(response),
    interactionPoints: response.interactionPoints,
  };
};

const mapToExternalAssetMetadata = (response: RiksTV.ClientAPI.Title): ExternalAssetMetadata | undefined => {
  const externalPlaybackLink = response.externalPlaybackLinks;
  if (externalPlaybackLink?.webPlaybackUri && response._links?.externalPlayback?.href) {
    externalPlaybackLink.providerId;
    return {
      reportExternalServicePlaybackUrl: response._links.externalPlayback.href,
      playbackUrl: externalPlaybackLink.webPlaybackUri,
    };
  }

  const embeddedPlayLink = response?._links?.embeddedPlay?.href;
  if (embeddedPlayLink && response._links?.externalPlayback?.href) {
    // This is the legacy apphopping implementation, which in practice is only used for RiksTV Play NRK catch-up assets
    // If, at some point in the future, these assets start returning externalPlaybackLinks, we can remove this handling.
    // We hard code these assets to NRK, because we don't have any reasonably structured field to use.
    // The super interested can find a discussion on Slack: https://rikstv.slack.com/archives/CJ73TMD6X/p1588676095022500
    return {
      reportExternalServicePlaybackUrl: response._links.externalPlayback.href,
      playbackUrl: embeddedPlayLink,
    };
  }

  // Not an external (apphopping) asset
  return;
};

const getSeriesId = (response: RiksTV.ClientAPI.Title) => {
  if (response.seriesId) {
    return response.seriesId;
  }
  // HACK!!! SeriesID  should be moved to the link object
  // Example url: "https://client-api.test.rikstv.no/2/series/1346666/"
  // Example 2  : "https://client-api.rikstv.no/2/series/73b84446-907e-4f2c-b958-19fd9bea8d5d/"
  const seriesLink = response._links.series?.href;
  if (seriesLink && seriesLink.includes('/series/')) {
    const seriesId = seriesLink.replace(/\/$/, '').split('/').pop();
    return seriesId;
  }
  return undefined;
};

const mapToTvSeriesMetadata = (response: RiksTV.ClientAPI.Title): TvSeriesMetadata | undefined => {
  const seriesId = getSeriesId(response);
  if (!seriesId) {
    return;
  }
  return {
    seriesId: seriesId,
    seriesName: response.seriesName,
    seasonId: mapToSeasonId(seriesId, response.season),
    seasonNumber: response.season,
    episodeNumber: response.episode,
    availableSeasons: response.availableSeasons,
    hasStartedWatching: !!response.watchNext,
  };
};

const mapToMetadata = (response: RiksTV.ClientAPI.Title): AssetDetailsMetadata => {
  const tvSeriesMetadata = mapToTvSeriesMetadata(response);
  const externalAssetMetadata = mapToExternalAssetMetadata(response);

  let metadata: AssetDetailsMetadata = {};
  if (tvSeriesMetadata) {
    metadata = {
      ...metadata,
      tvSeries: tvSeriesMetadata,
    };
  }

  if (externalAssetMetadata) {
    metadata = {
      ...metadata,
      external: externalAssetMetadata,
    };
  }

  return metadata;
};

export const mapToAssetDetailsBase = (response: RiksTV.ClientAPI.Title): AssetDetailsBase => {
  const broadcastedTime =
    typeof response.broadcastedTime === 'string' ? new Date(response.broadcastedTime) : response.broadcastedTime;
  return {
    ageLimit: response.ageLimit,
    broadcastedTime,
    broadcastTimeInfo: getRelativeTimeInfo(broadcastedTime, response.duration),
    description: response.description ?? '',
    duration: response.duration,
    productionYear: nonZeroYearOrUndefined(response.productionYear),
    genres: response.externalGenres ?? [],
    actors: response.actors ?? [],
    directors: response.directors ?? [],
    creditsStartTime: response.creditsStartTime,
    links: mapToAssetDetailsLinks(response),
    isCatchUp: isCatchUp(response),
    isInWishlist: response.isInWishList,
    metadata: mapToMetadata(response),
    streamingMode: response.streamingMode,
    imdbRating: response.imdbRating ? response.imdbRating.toFixed(1) : undefined,
    entitlementKeys: response.entitlementKeys,
  };
};

export const mapResponseToAssetDetails = (response: RiksTV.ClientAPI.Title): AssetDetails => {
  return {
    ...mapToPlayable(response),
    ...mapToAssetDetailsBase(response),
  };
};

export const mapResponseToMenuDetails = (response: RiksTV.ClientAPI.MenuItem): MenuDetails => {
  return response;
};

export const isMenuDetails = (
  data: RiksTV.ClientAPI.SwimlaneResponse,
  type: SwimlaneType
): data is RiksTV.ClientAPI.MenuItem[] => {
  if (data.length) {
    // array can be empty
    return 'menuItemType' in data[0];
  }
  return type === 'Menu';
};
