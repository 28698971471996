import classNames from 'classnames';

import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';

import './Section.scss';

export type SectionType = 'light' | 'dark' | 'very-dark' | 'transparent';

interface Props {
  className?: string;
  id?: string;
  type: SectionType;
  fullHeight?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  hasStickyHeader?: boolean;
  extraPadding?: boolean;
}

export const Section: FCC<Props> = ({
  id,
  type,
  children,
  fullHeight,
  noPaddingTop,
  noPaddingBottom,
  hasStickyHeader,
  extraPadding,
  className,
  ...restProps
}) => {
  const containerClassName = classNames(
    'section__container',
    `section__container--${type}`,
    {
      'section__container--full-height': fullHeight,
      'section__container--no-padding-top': noPaddingTop,
      'section__container--no-padding-bottom': noPaddingBottom,
      'section__container--has-sticky-header': hasStickyHeader,
      'section__container--extra-padding': extraPadding,
    },
    className
  );
  return (
    <section className={containerClassName} id={id} {...restProps}>
      {children}
    </section>
  );
};
