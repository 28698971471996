import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { usePages } from '@rikstv/play-common/src/components/pages/usePages';
import { SkipLink } from '@rikstv/play-common/src/components/skipLink/SkipLink';
import { selectIsLoggedIn } from '@rikstv/play-common/src/forces/auth/selectors';
import { UnstyledLink } from '@rikstv/play-common/src/router/Link';
import { commonRouterPaths } from '@rikstv/play-common/src/router/router.path';
import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';
import { StrimNavigation, UnstyledLinkProps } from '@rikstv/shared-strim-components';

import { selectNotifications } from '../../../domain/user/forces/selectors';
import { routerPaths } from '../../router/router.path';

const UnstyledNavigationLink: FC<UnstyledLinkProps> = props => <UnstyledLink to={props.href} {...props} />;

/**
 * The underlying component is shared between Strim.Play and the CMS
 */

export const Navigation: FC = () => {
  const { pathname } = useLocation();
  const appNavigationItems = useAppNavigation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { notifications } = useSelector(selectNotifications(pathname));

  return (
    <>
      <SkipLink elementId="main" buttonText="Hopp til hovedinnholdet" />
      <StrimNavigation
        isLoggedIn={isLoggedIn}
        login={() => authService.login()}
        currentUrl={pathname}
        getAppNavigation={salesItems => {
          return showSalesItems(pathname) ? salesItems : appNavigationItems;
        }}
        linkComponent={UnstyledNavigationLink}
        accountNotifications={notifications.length}
      />
    </>
  );
};

const pathsWithSalesItems = [routerPaths.signUpPackageSelect, routerPaths.purchaseProducts];
const showSalesItems = (pathname: string) => {
  if (pathname === routerPaths.frontpage) {
    return true;
  }
  if (pathname === routerPaths.paymentMethodChange) {
    return false;
  }

  return pathsWithSalesItems.some(path => pathname.startsWith(path));
};

const tvGuideItem = { action: commonRouterPaths.tvGuide, children: 'TV-guide' };
const useAppNavigation = () => {
  const { pages = [] } = usePages();
  return pages
    .map(pages => ({
      children: pages.name,
      action: `/${pages.slug}`,
    }))
    .concat(tvGuideItem);
};
