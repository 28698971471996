import React from 'react';

import { ReactComponent as ImdbIcon } from './imdb.svg';

import './ImdbBadge.scss';

interface Props {
  imdbRating: string | undefined;
}

const ImdbBadge: React.FunctionComponent<Props> = ({ imdbRating }) => {
  if (!imdbRating) {
    return null;
  }
  return (
    <>
      <ImdbIcon className="imdb-badge__svg" />
      {imdbRating}
    </>
  );
};

export { ImdbBadge };
