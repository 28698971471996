import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';
import { captureExceptionInSentry } from '@rikstv/play-common/src/utils/errorTracker/tracking';

import ccConfig from './chromecast-config';

let remotePlayer: cast.framework.RemotePlayer;
let remotePlayerController: cast.framework.RemotePlayerController;

export const isCastAvailable = () =>
  (globalThis.chrome && globalThis.cast && chrome.cast && chrome.cast.isAvailable === true) ?? false;

interface CustomData extends Record<string, unknown> {
  assetId: string;
  streamType?: chrome.cast.media.StreamType;
  title: string;
  posterUrl: string;
}

export function addOrRemoveCurrentTimeChangedEventHandler(
  addOrRemoveEventListener: 'addEventListener' | 'removeEventListener',
  handler: (event: { value: number }) => void
) {
  if (isCastAvailable()) {
    remotePlayerController[addOrRemoveEventListener](
      cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
      handler
    );
  }
}

export function addCastStateListener(handler: (event: cast.framework.CastStateEventData) => void) {
  cast.framework.CastContext.getInstance().addEventListener(
    cast.framework.CastContextEventType.CAST_STATE_CHANGED,
    handler
  );
}

export function removeCastStateListener(handler: (event: cast.framework.CastStateEventData) => void) {
  cast.framework.CastContext.getInstance().removeEventListener(
    cast.framework.CastContextEventType.CAST_STATE_CHANGED,
    handler
  );
}

export function addCastSessionListener(handler: (event: cast.framework.SessionStateEventData) => void) {
  cast.framework.CastContext.getInstance().addEventListener(
    cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
    handler
  );
}

export function removeCastSessionListener(handler: (event: cast.framework.SessionStateEventData) => void) {
  cast.framework.CastContext.getInstance().removeEventListener(
    cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
    handler
  );
}

export function openCastDialog() {
  cast.framework.CastContext.getInstance()
    .requestSession()
    .then(() => console.log('Chromecast success'), castError);
}

export function isCastConnected(): boolean {
  if (!isCastAvailable()) return false;
  const sessionState = cast.framework.CastContext.getInstance().getCurrentSession()?.getSessionState();
  return (
    sessionState === cast.framework.SessionState.SESSION_STARTED ||
    sessionState === cast.framework.SessionState.SESSION_RESUMED
  );
}

export function isCastMediaLoaded(): boolean {
  const media = cast.framework.CastContext.getInstance().getCurrentSession()?.getMediaSession();
  return !(!media || media.playerState === chrome.cast.media.PlayerState.IDLE);
}

export function setAppData(ccConfigOverrides: Partial<typeof ccConfig> = ccConfig) {
  const { namespace, getAppSessionId, sender } = { ...ccConfig, ...ccConfigOverrides };
  if (isCastConnected()) {
    cast.framework.CastContext.getInstance()
      .getCurrentSession()
      ?.sendMessage(
        namespace(),
        JSON.stringify({
          messageType: 'SET_APP_DATA',
          messageData: {
            appSessionId: getAppSessionId(),
            features: {
              binge: true,
            },
            sender: sender(),
          },
        })
      )
      .then(onSuccess, castError);
  }
}

export function setCastSource(
  assetId: string,
  accessToken: string,
  streamingSessionId: string,
  isLinear: boolean,
  startAtMilliseconds: number,
  audioLanguage: string | undefined,
  textLanguage: string | undefined
) {
  if (isCastConnected()) {
    cast.framework.CastContext.getInstance()
      .getCurrentSession()
      ?.sendMessage(
        ccConfig.namespace(),
        JSON.stringify({
          messageType: 'SET_SOURCE',
          messageData: {
            assetId,
            isLinear,
            accessToken,
            currentTime: startAtMilliseconds,
            streamingSessionId,
            audioLang: audioLanguage,
            textLang: textLanguage,
            viewerUserId: authService.getUserData()?.cid,
          },
        })
      )
      .then(onSuccess, castError);
  }
}

export const getErrorMessageHandler =
  (errorHandler: (errorMessage: string) => void) => (_namespace: string, message: string) => {
    if (message) {
      try {
        const msg = JSON.parse(message);
        if (msg.messageType === 'ERROR') {
          errorHandler(msg?.messageData?.errorType);
        }
      } catch (e) {
        captureExceptionInSentry(e);
      }
    }
  };

export const addOrRemoveMessageHandler = (
  addOrRemoveHandler: 'addMessageListener' | 'removeMessageListener',
  handler: (namespace: string, message: string) => void
) => {
  if (cast.framework) {
    cast.framework.CastContext.getInstance().getCurrentSession()?.[addOrRemoveHandler](ccConfig.namespace(), handler);
  }
};

export function setCastAudioTrack(language?: string) {
  if (language && isCastConnected()) {
    setLanguage(language, true);
  }
}

export function setCastTextTrack(language?: string) {
  if (language && isCastConnected()) {
    setLanguage(language, false);
  }
}

function initRemoteController() {
  if (isCastAvailable()) {
    if (!remotePlayer) {
      remotePlayer = new cast.framework.RemotePlayer();
    }
    if (!remotePlayerController) {
      remotePlayerController = new cast.framework.RemotePlayerController(remotePlayer);
    }
  }
}

export function addRemotePlayerControlListener(
  type: cast.framework.RemotePlayerEventType,
  handler: (event: cast.framework.RemotePlayerChangedEvent) => void
) {
  initRemoteController();
  remotePlayerController?.addEventListener(type, handler);
}

export function removeRemotePlayerControlListener(
  type: cast.framework.RemotePlayerEventType,
  handler: (event: cast.framework.RemotePlayerChangedEvent) => void
) {
  remotePlayerController?.removeEventListener(type, handler);
}

export function startCasting(
  assetId: string,
  accessToken: string,
  streamingSessionId: string,
  isLinear: boolean,
  currentTime: number,
  audioLanguage: string | undefined,
  textLanguage: string | undefined
) {
  if (isCastConnected()) {
    const mediaSession = cast.framework.CastContext.getInstance().getCurrentSession()?.getMediaSession();
    if (!mediaSession || mediaSession.playerState === chrome.cast.media.PlayerState.IDLE) {
      setAppData();
      setCastSource(assetId, accessToken, streamingSessionId, isLinear, currentTime, audioLanguage, textLanguage);
    }
  }
}

export function getCustomMediaInfo(castSession: cast.framework.CastSession | null): CustomData | null {
  if (castSession) {
    return getCustomData(castSession);
  } else if (isCastConnected()) {
    return getCustomData(cast.framework.CastContext.getInstance().getCurrentSession());
  }
  return null;
}

function getCustomData(castSession: cast.framework.CastSession | null): CustomData {
  if (castSession) {
    const media = castSession.getMediaSession()?.media;
    if (media) {
      const metadata = media.metadata as chrome.cast.media.GenericMediaMetadata;
      const title = metadata?.title || (media.customData as any).title;
      const posterUrl =
        (metadata && metadata.images.length > 0 && metadata.images[0].url) || (media.customData as any).posterUrl;
      return {
        assetId: media.contentId,
        streamType: media.streamType,
        ...media.customData,
        title,
        posterUrl,
      };
    }
  }
  return {
    assetId: '',
    streamType: undefined,
    title: '',
    posterUrl: '',
  };
}

export function getCastDeviceName(): string {
  if (isCastConnected()) {
    return cast.framework.CastContext.getInstance().getCurrentSession()?.getCastDevice().friendlyName || '';
  }
  return '';
}

export interface CastTrackLanguage {
  language: string;
  active: boolean;
  type: chrome.cast.media.TrackType;
}

export function getCastTracks(): CastTrackLanguage[] {
  if (isCastConnected()) {
    const tracks = cast.framework.CastContext.getInstance().getCurrentSession()?.getMediaSession()?.media?.tracks || [];
    const activeTracks =
      cast.framework.CastContext.getInstance().getCurrentSession()?.getMediaSession()?.activeTrackIds || [];
    return tracks.map(track => ({
      language: track.language,
      active: activeTracks.indexOf(track.trackId) > -1,
      type: track.type,
    }));
  }
  return [];
}

export function playOrPause() {
  if (isCastConnected()) {
    initRemoteController();
    remotePlayerController?.playOrPause();
  }
}

export function mute() {
  if (isCastConnected()) {
    initRemoteController();
    remotePlayerController?.muteOrUnmute();
  }
}

export function setCastVolume(volume: number) {
  if (isCastConnected()) {
    initRemoteController();
    if (remotePlayer) {
      remotePlayer.volumeLevel = volume;
      remotePlayerController?.setVolumeLevel();
    }
  }
}

export function getCastVolume(): number {
  if (isCastConnected()) {
    initRemoteController();
    return remotePlayer?.volumeLevel;
  }
  return 1;
}

export function isCastMuted(): boolean {
  return remotePlayer?.isMuted;
}

export function castPlayerState() {
  if (remotePlayer) {
    return remotePlayer?.savedPlayerState;
  }
}

export function onSetProgressFromStartBetween0and1(
  progressFromStartBetween0and1: number,
  startSeconds: number,
  endSeconds: number
) {
  const currentTime = startSeconds + (endSeconds - startSeconds) * progressFromStartBetween0and1;
  setCastCurrentTime(currentTime);
}

export function setCastCurrentTime(currentTimeInSeconds: number) {
  if (isCastConnected()) {
    initRemoteController();
    if (remotePlayer?.canSeek) {
      const mediaSession = cast.framework.CastContext.getInstance().getCurrentSession()?.getMediaSession();
      if (mediaSession) {
        const seekRequest = new chrome.cast.media.SeekRequest();
        seekRequest.currentTime = currentTimeInSeconds;
        mediaSession.seek(seekRequest, (event: any) => console.log('success', event), castError);
      }
    }
  }
}

class StartEndDuration {
  readonly start: number;
  readonly end: number;
  readonly duration: number;

  constructor(start: number | undefined, end: number | undefined) {
    this.start = start ?? 0;
    this.end = end ?? 0;
    this.duration = this.end - this.start;
  }
}

export function getSeekableRange(mediaInfo?: chrome.cast.media.Media | null) {
  if (isCastConnected()) {
    mediaInfo = mediaInfo ?? cast.framework.CastContext.getInstance().getCurrentSession()?.getMediaSession();
    if (isCastingLinearAsset() && mediaInfo?.liveSeekableRange) {
      return new StartEndDuration(mediaInfo.liveSeekableRange.start, mediaInfo.liveSeekableRange.end);
    }
    // media is null in rare cases, therefore we set the end to 0 instead of blowing up.
    return new StartEndDuration(0, mediaInfo?.media?.duration ?? 0);
  }

  return new StartEndDuration(0, 0);
}

export function isCastingLinearAsset() {
  if (isCastConnected()) {
    return (
      cast.framework.CastContext.getInstance().getCurrentSession()?.getMediaSession()?.media?.streamType ===
      chrome.cast.media.StreamType.LIVE
    );
  }
  return false;
}

export function getCastPlayerState(): chrome.cast.media.PlayerState | undefined {
  if (isCastConnected()) {
    return cast.framework.CastContext.getInstance().getCurrentSession()?.getMediaSession()?.playerState;
  }
}

export function seek(time: number) {
  if (isCastConnected()) {
    initRemoteController();
    const updatedTime = remotePlayer.currentTime + time;
    setCastCurrentTime(updatedTime);
  }
}

function setLanguage(language: string, isAudio: boolean) {
  cast.framework.CastContext.getInstance()
    .getCurrentSession()
    ?.sendMessage(
      ccConfig.namespace(),
      JSON.stringify({
        messageType: isAudio ? 'SET_AUDIO_TRACK' : 'SET_TEXT_TRACK',
        messageData: {
          language,
        },
      })
    )
    .then(onSuccess, castError);
}

function onSuccess(event: any) {
  console.log('onSuccess', event);
}

export function castError(castError: chrome.cast.Error) {
  switch (castError.code) {
    case chrome.cast.ErrorCode.CANCEL:
      break; // This error is thrown when clicking exit on the cast dialog, and does not need logging.
    case chrome.cast.ErrorCode.API_NOT_INITIALIZED:
    case chrome.cast.ErrorCode.EXTENSION_MISSING:
    case chrome.cast.ErrorCode.EXTENSION_NOT_COMPATIBLE:
    case chrome.cast.ErrorCode.INVALID_PARAMETER:
    case chrome.cast.ErrorCode.LOAD_MEDIA_FAILED:
    case chrome.cast.ErrorCode.RECEIVER_UNAVAILABLE:
    case chrome.cast.ErrorCode.SESSION_ERROR:
    case chrome.cast.ErrorCode.CHANNEL_ERROR:
    case chrome.cast.ErrorCode.TIMEOUT:
    default:
      return console.error('Plugin Cast Error: ' + JSON.stringify(castError));
  }
}
