import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router';

import { Gutter, H1 } from '@rikstv/shared-components';

import config from '../../config';
import { Link } from '../../router/Link';
import { AssetNotFound } from '../errorPage/AssetNotFound';
import { Section } from '../section/Section';
import { PaginatedSwimlane } from '../swimlane/PaginatedSwimlane';

import { usePageFromSlug } from './internal/usePage';
import { PageContext } from './PageContext';
import { Swimlane } from './types';
import { useGetCollectionUrl } from './useCollectionUrl';

export const SwimlanePage: FC = () => {
  const params = useParams<{ slug: string; swimlaneId: string }>();
  const slug = params.slug!;
  const swimlaneId = params.swimlaneId!;
  const getCollectionUrl = useGetCollectionUrl();

  const { swimlane, pageTitle, isLoadingSwimlane } = useSwimlaneData(slug, swimlaneId);

  if (!swimlane || !pageTitle) {
    return isLoadingSwimlane ? null : (
      <AssetNotFound contextInfo={`SwimlanePage, swimlaneId=${swimlaneId}}, slug=${slug}`} />
    );
  }
  const title = `${pageTitle} - ${swimlane.name}`;
  const description = `Alt du trenger for å streame dine favoritter i vår liste "${swimlane.name}"`;

  return (
    <>
      <PageContext.Provider value={{ hideSwimlaneTitle: true, slug, title: pageTitle }}>
        <Helmet title={title}>
          <meta property="og:title" content={`${title} | ${config.application}`} />
          <meta property="og:description" content={description} />
          <meta name="description" content={description} />
        </Helmet>
        <Section type="transparent" className="swimlane-page">
          <Gutter>
            <Link to={getCollectionUrl({ slug })}>{pageTitle}</Link>
            <H1>{swimlane.name}</H1>
          </Gutter>
          <PaginatedSwimlane swimlane={swimlane} hideSwimlaneTitle={true} />
        </Section>
      </PageContext.Provider>
    </>
  );
};

type LocationState = { swimlane?: Swimlane; pageTitle: string };

const useSwimlaneFromLocationState = () => {
  // For page -> paginated swimlane navigation
  // get data from history state
  const location = useLocation();
  const locationState = location.state as LocationState;
  const [cache] = useState<LocationState>(locationState ?? {});
  const [{ swimlane, pageTitle }, setState] = useState(cache);
  return { swimlane, pageTitle, setState };
};

const useSwimlaneData = (pageId: string, swimlaneId: string) => {
  const { swimlane, pageTitle, setState } = useSwimlaneFromLocationState();
  // For deep-links: fetch page and select swimlane
  const pageSlug = swimlane ? '' : pageId;
  const { page, loadingStatus } = usePageFromSlug(pageSlug);
  const [isLoadingSwimlane, setIsLoading] = useState(Boolean(pageSlug));

  useEffect(() => {
    if (!swimlane && page) {
      const sl = page.swimlanes.find(s => s.id.toLowerCase() === swimlaneId.toLowerCase());
      setState({ swimlane: sl, pageTitle: page.title });
      setIsLoading(false);
    }
    if (loadingStatus === 'FAILED') {
      setIsLoading(false);
    }
  }, [page, swimlane, swimlaneId, loadingStatus, setState]);

  return { swimlane, pageTitle, isLoadingSwimlane };
};
