import { lazy } from 'react';

import { AppRoutes, ROUTE_TYPE_INTERNAL_REDIRECT, ROUTE_TYPE_NORMAL } from '@rikstv/play-common/src/router/routes.type';
import { isDevFeatureEnabled } from '@rikstv/play-common/src/utils/featureFlags/devFeatureFlags';

import { LayoutDark } from '../containers/layout/LayoutDark';
import { LayoutDarker } from '../containers/layout/LayoutDarker';

import { routerPaths } from './router.path';
import { getRedirectRoutes } from './routes.redirects';

const TvGuide = lazy(() =>
  import('@rikstv/play-common/src/components/tvGuide-v2/TVGuide').then(module => ({
    default: module.TvGuide,
  }))
);
const SearchPage = lazy(() =>
  import('@rikstv/play-common/src/pages/search/SearchPage').then(module => ({
    default: module.SearchPage,
  }))
);
const CoverPageFactory = lazy(() =>
  import('../components/CoverPage/CoverPageFactory').then(module => ({
    default: module.CoverPageFactory,
  }))
);

const BrandLayout = isDevFeatureEnabled('darkerLayout') ? LayoutDarker : LayoutDark;

const routes: AppRoutes = [
  ...getRedirectRoutes(),
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.search,
    component: SearchPage,
    layout: LayoutDark,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: [
      /* Old cover page routes */
      routerPaths.watchPattern,
      routerPaths.watchPatternWithName,
      /* New super cover page*/
      routerPaths.singleProgramOrMovieWithTitle,
      routerPaths.singleProgramOrMovie,
      /* New SERIES super cover page*/
      routerPaths.stackedSeriesWithTitle,
      routerPaths.stackedSeries,
    ],
    component: CoverPageFactory,
    layout: LayoutDark,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: [routerPaths.tvGuide, routerPaths.tvGuideWithDate],
    component: TvGuide,
    layout: BrandLayout,
    waitForStartup: true,
  },
  { type: ROUTE_TYPE_INTERNAL_REDIRECT, path: '/live', redirectPath: routerPaths.tvGuide },
];

export { routes as routesPlayApp };
