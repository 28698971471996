import GatewayAPI from '@strim/gateway-api';
import { create } from 'zustand';

import config from '@rikstv/play-common/src/config';
import {
  maxDisplayName,
  nordiskFilmDisplayName,
  nrkDisplayName,
  skyShowtimeDisplayName,
  tv2DisplayName,
  viaplayDisplayName,
} from '@rikstv/play-common/src/utils/displayNames/displayNames';
import { LooseStringUnion } from '@rikstv/play-common/src/utils/types/typeUtils';

import { ServiceIds } from '../account/components/externalServiceDisplay/types';
import { Package } from '../packages/forces/packages.types';

export type LooseSourceNames = LooseStringUnion<GatewayAPI.KnownExternalServiceSourceNames>;
interface ExternalServiceStore {
  serviceNameLogoMap: Map<LooseSourceNames, string>;
  getLogoUrlFromSourceName: (sourceName: GatewayAPI.KnownExternalServiceSourceNames) => string;
  getLogoFromServiceId: (serviceId: ServiceIds) => string;
  getNameFromServiceId: (serviceId: ServiceIds) => string;
}

export const useExternalServiceStore = create<ExternalServiceStore>()((set, get) => ({
  // set in user/forces/api on success
  serviceNameLogoMap: new Map(),
  // Methods
  getLogoUrlFromSourceName: sourceName => {
    const baseName = get().serviceNameLogoMap.get(sourceName) ?? '';
    return logoUrlFromBaseName(baseName);
  },
  getLogoFromServiceId(serviceId: ServiceIds) {
    switch (serviceId) {
      case 'tv2play':
        return get().getLogoUrlFromSourceName('TV2 Play Film og serier');
      case 'viaplay':
        return get().getLogoUrlFromSourceName('Viaplay');
      case 'max':
        // "Max" source name is used for REX channel
        return logoUrlFromBaseName('Max');
      case 'nrk':
        return get().getLogoUrlFromSourceName('NRK Arkiv');
      case 'skyShowtime':
        return get().getLogoUrlFromSourceName('SkyShowtime');
      case 'nordiskFilm': {
        return get().getLogoUrlFromSourceName('Nordisk Film+');
      }
    }
    const _exhaustive_switch_check: never = serviceId;
  },
  getNameFromServiceId(serviceId) {
    switch (serviceId) {
      case 'tv2play':
        return tv2DisplayName;
      case 'viaplay':
        return viaplayDisplayName;
      case 'nordiskFilm':
        return nordiskFilmDisplayName;
      case 'max':
        return maxDisplayName;
      case 'nrk':
        return nrkDisplayName;
      case 'skyShowtime':
        return skyShowtimeDisplayName;
    }
    const _exhaustive_switch_check: never = serviceId;
  },
}));

const logoUrlFromBaseName = (baseImageName: string | undefined) => {
  return baseImageName ? `${config.staticFilesBasePath}/logopack/${baseImageName}_square.svg` : '';
};

export const setExternalServicesFromPackages = (packages: Package[]) => {
  const allChannels = packages.flatMap(p => p.channels);
  const channelSourceNameMap = new Map<LooseSourceNames, string>();
  allChannels.forEach(c => {
    channelSourceNameMap.set(c.name, c.logo ?? '');
  });
  useExternalServiceStore.setState({ serviceNameLogoMap: channelSourceNameMap });
};
