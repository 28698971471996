import { useCallback } from 'react';

import { commonRoutes } from '../../router/router.path';

import { PageShallow } from './types';
import { usePages } from './usePages';

export const isSlugTopLevelPage = (slugOrPathname: string, pages: PageShallow[] = []) =>
  pages.find(p => p.slug === slugOrPathname.toLowerCase().replace(/^\//, '')) != null;

export const useGetCollectionUrl = () => {
  const { pages } = usePages();
  return useCallback(
    ({ slug }: { slug: string }) => {
      const isTopLevelPage = isSlugTopLevelPage(slug, pages);
      return isTopLevelPage ? `/${slug}` : commonRoutes.collections({ slug });
    },
    [pages]
  );
};
