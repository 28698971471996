import React from 'react';
import classNames from 'classnames';

import './DurationBar.scss';

export interface DurationBarProps {
  percent: number;
  className?: string;
  rounded?: boolean;
}

const getPercentBoundaries = (percent: number) => {
  let percentBoundaries = percent;
  if (percent > 100) {
    percentBoundaries = 100;
  }
  if (percent < 0) {
    percentBoundaries = 0;
  }
  return percentBoundaries;
};

const DurationBar: React.FunctionComponent<DurationBarProps> = ({ percent, className }) => {
  const percentBoundaries = getPercentBoundaries(percent);
  return (
    <div className={classNames('duration-bar__container', className)}>
      <div className={'duration-bar__indicator'} style={{ width: `${percentBoundaries}%` }} />
    </div>
  );
};

export { DurationBar };
