import { CSSProperties, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import { getRect, percentage } from '../ProgressBar/progressBar.utils';

import styles from './ThumbnailPreview.module.css';

interface Sprite {
  url: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

interface ThumbnailPreviewProps {
  thumbnailTextTrack?: THEOplayer.TextTrack;
  hover: boolean;
  duration: number;
  streamStartSeconds: number;
  dragX: number;
  progressBarRef: RefObject<HTMLDivElement>;
  hoveredProgramImageUrl?: string;
}

export const ThumbnailPreview: React.FC<ThumbnailPreviewProps> = ({
  thumbnailTextTrack,
  hover,
  duration,
  streamStartSeconds,
  dragX,
  progressBarRef,
  hoveredProgramImageUrl,
}) => {
  const thumbnailCanvasElement = useRef<HTMLCanvasElement>(null);
  const [cue, setCue] = useState<THEOplayer.TextTrackCue>();
  const thumbnailWidth = 248;
  const canvasPosition = useMemo(
    () => Math.min(getRect(progressBarRef).width - 35, Math.max(35, dragX)) - thumbnailWidth / 2 + 'px',
    [dragX, progressBarRef]
  );

  useImageThumbnail({
    cue: cue as THEOplayer.TextTrackCue,
    canvasEl: thumbnailCanvasElement,
    hoveredProgramImageUrl: hoveredProgramImageUrl,
  });

  if (thumbnailTextTrack && thumbnailTextTrack.mode === 'disabled') {
    thumbnailTextTrack.mode = 'hidden';
  }

  useEffect(() => {
    if (progressBarRef.current && thumbnailTextTrack) {
      const progressFromStartBetween0and1 = percentage(dragX, 0, progressBarRef.current.offsetWidth);
      const secondsFromStart = duration * progressFromStartBetween0and1;
      const thumbnailPreviewTime = Math.floor(streamStartSeconds + secondsFromStart);

      const cue = getCueAtTime(thumbnailTextTrack.cues!, thumbnailPreviewTime);

      setCue(cue);
    }
  }, [dragX, thumbnailTextTrack, progressBarRef, duration, streamStartSeconds]);

  if (!hoveredProgramImageUrl && !thumbnailTextTrack) {
    return null;
  }

  return (
    <>
      <canvas
        ref={thumbnailCanvasElement}
        className={classNames(styles.thumbnail, { [styles.hide]: !hover })}
        style={
          {
            '--thumbnail-width': `${thumbnailWidth}px`,
            left: canvasPosition,
          } as CSSProperties
        }
      />
    </>
  );
};

function getCueAtTime(cues: THEOplayer.TextTrackCueList, time: number): THEOplayer.TextTrackCue | undefined {
  if (!cues || cues.length === 0) {
    return undefined;
  }
  let result = cues[0];
  for (const cue of cues) {
    if (cue.startTime <= time) {
      result = cue;
    } else if (time >= cue.endTime) {
      return result;
    }
  }
  return result;
}

function isSpriteMatch(imageUrl: string) {
  return imageUrl.match(/^(.+)#xywh=(\d+),(\d+),(\d+),(\d+)$/);
}

function parseSpriteUrl(spriteUrl: string): Sprite | undefined {
  const match = isSpriteMatch(spriteUrl);
  if (!match) {
    return undefined;
  }
  const [, url, x, y, w, h] = match;
  return {
    url,
    x: +x,
    y: +y,
    w: +w,
    h: +h,
  };
}

interface ThumbnailProps {
  cue: THEOplayer.TextTrackCue;
  canvasEl: RefObject<HTMLCanvasElement>;
  hoveredProgramImageUrl?: string;
}

const useImageThumbnail = ({ canvasEl, cue, hoveredProgramImageUrl }: ThumbnailProps) => {
  const content = hoveredProgramImageUrl ?? ((cue?.content as string) ?? '').trim();

  useEffect(() => {
    const canvasContext = canvasEl.current?.getContext('2d');

    if (!canvasContext || !content) {
      return;
    }

    const img = new Image();
    img.src = new URL(content).href;
    const sprite = parseSpriteUrl(content);

    const onLoad = () => {
      if (!canvasEl.current) {
        return;
      }

      if (sprite) {
        canvasEl.current.width = sprite.w;
        canvasEl.current.height = sprite.h;
        canvasContext.drawImage(img, sprite.x, sprite.y, sprite.w, sprite.h, 0, 0, sprite.w, sprite.h);
      } else {
        canvasEl.current.width = img.naturalWidth;
        canvasEl.current.height = img.naturalHeight;
        canvasContext.drawImage(img, 0, 0);
      }
    };

    img.addEventListener('load', onLoad);

    return () => img.removeEventListener('load', onLoad);
  }, [canvasEl, content, hoveredProgramImageUrl]);
};
