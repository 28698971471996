import { LogoutReason } from '../domain/user/forces/constants';

const LOGOUT_REASON_KEY = 'logoutReason';
const DEFAULT_REASON = JSON.stringify('UNKNOWN_REASON');

type Reason = LogoutReason | ReasonObject;
type ReasonObject = { logoutReason: LogoutReason; logoutSuccessRedirectPath?: string };
export const storeLogoutReason = (reason: Reason) => {
  localStorage.setItem(LOGOUT_REASON_KEY, JSON.stringify(reason));
};

export const getLogoutReason = (): ReasonObject => {
  const logoutReason = JSON.parse(localStorage.getItem(LOGOUT_REASON_KEY) ?? DEFAULT_REASON) as Reason;
  if (typeof logoutReason === 'string') {
    return { logoutReason };
  }
  return logoutReason;
};

export const clearLogoutReason = () => {
  localStorage.removeItem(LOGOUT_REASON_KEY);
};
