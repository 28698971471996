export const pluralize = (count: number, singular: string, plural: string) => {
  if (count === 1) {
    return singular;
  }
  return plural;
};

export const numericToLetters = (number: number) => {
  switch (number) {
    case 12:
      return 'tolv';
    case 11:
      return 'elleve';
    case 10:
      return 'ti';
    case 9:
      return 'ni';
    case 8:
      return 'åtte';
    case 7:
      return 'sju';
    case 6:
      return 'seks';
    case 5:
      return 'fem';
    case 4:
      return 'fire';
    case 3:
      return 'tre';
    case 2:
      return 'to';
    case 1:
      return 'en';
    case 0:
      return 'null';
    default:
      return number;
  }
};

export const formatList = (list: string[], type: Intl.ListFormatType) => {
  try {
    // try-catch because of https://rikstv-h0.sentry.io/issues/4291261805
    return new Intl.ListFormat('no', { style: 'long', type }).format(list);
  } catch {
    return list.join(', ');
  }
};
