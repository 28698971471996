export const AssetTypes = {
  CHANNEL: 'channelAT',
  KID: 'kidAT',
  CATCH_UP: 'catchUpAT',
  UPCOMING: 'upcomingAT',
  MOVIE: 'movieAT',
  TVSHOW: 'tvshowAT',
  UNKNOWN: 'unknownAT',
} as const;

export type AssetType = (typeof AssetTypes)[keyof typeof AssetTypes];

export interface Progress {
  percent: number;
  startAtSeconds?: number;
}
