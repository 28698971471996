import { FC, memo } from 'react';

import config from '@rikstv/play-common/src/config';
import { isModifierKeyPressed } from '@rikstv/play-common/src/utils/eventHandler/isModifierKeyPressed';

import { useEventListener } from '../../utils/useEventListener';

interface Props {
  isPaused: boolean;
  onPlayPause(): void;
  onSeekSeconds(seconds: number): void;
  onChangeVolume(volume: number): void;
  onSeekPercent(interval: number): void;
  onGoToStreamEnd: (() => void) | undefined;
  onToggleFullscreen(): void;
  onToggleMute(): void;
  onSkipOrVolume(): void;
  onToggleMetricsPanel: (() => void) | undefined;
}

const handle = (e: KeyboardEvent, func: () => void) => {
  func();
  e.preventDefault();
};

const defaultInteractiveKeys = ['Space', 'Tab', 'Enter'];
const fieldsetInteractiveKeys = [
  ...defaultInteractiveKeys,
  'ArrowLeft',
  'ArrowUp',
  'ArrowRight',
  'ArrowDown',
  'Home',
  'End',
];

const KeyboardListener: FC<Props> = memo(
  ({
    onPlayPause,
    onSeekSeconds,
    onChangeVolume,
    onSeekPercent,
    onGoToStreamEnd,
    onToggleFullscreen,
    onToggleMute,
    onSkipOrVolume,
    onToggleMetricsPanel,
  }) => {
    const onKeyDown = (e: KeyboardEvent) => {
      const { code, key, target } = e;
      const isModifierKey = isModifierKeyPressed(e);

      if (!(target instanceof HTMLElement) || target instanceof HTMLInputElement || isModifierKey) {
        // isModifierKey because a11y
        return;
      }

      // Global play/pause, but not if element is an input-field
      if (code === 'KeyK' && !['INPUT', 'TEXTAREA'].includes(target.nodeName)) {
        handle(e, onPlayPause);
        return;
      }

      const isKeyboardUser =
        !document.documentElement.dataset.mousenavigation && !document.documentElement.dataset.touchnavigation;

      // fieldsets needs arrows to funciton properly
      if (isKeyboardUser && target.closest('fieldset') && fieldsetInteractiveKeys.includes(code)) {
        return;
      }

      // Make sure anything inside the control panel DO NOT fire any global event
      if (isKeyboardUser && target.closest('.controls') && defaultInteractiveKeys.includes(code)) {
        return;
      }

      // Do not fire global events if target is a focusable element
      if (isKeyboardUser && target.tabIndex >= 0 && defaultInteractiveKeys.includes(code)) {
        return;
      }

      switch (code) {
        case 'Space':
          return handle(e, onPlayPause);
        case 'KeyJ':
        case 'ArrowLeft':
          onSkipOrVolume();
          return handle(e, () => onSeekSeconds(-config.player.seekBackSeconds));
        case 'ArrowUp':
        case 'ArrowDown':
          onSkipOrVolume();
          return handle(e, () => onChangeVolume(code === 'ArrowUp' ? 0.05 : -0.05));
        case 'ArrowRight':
        case 'KeyL':
          onSkipOrVolume();
          return handle(e, () => onSeekSeconds(config.player.seekForwardSeconds));
        case 'KeyF':
          return handle(e, onToggleFullscreen);
        case 'KeyM':
          return handle(e, onToggleMute);
        case 'KeyD':
          if (!onToggleMetricsPanel) {
            return;
          }
          return handle(e, onToggleMetricsPanel);
        case 'Home':
          return handle(e, () => onSeekPercent(0));
        case 'End':
          if (!onGoToStreamEnd) {
            return;
          }
          return handle(e, onGoToStreamEnd);
        /* case 'KeyP':
          return handle(e, this.onPictureInPicture); */
        default:
          break;
      }

      if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseFloat(key))) {
        handle(e, () => onSeekPercent(parseFloat(key) * 10));
      }
    };

    useEventListener('keydown', onKeyDown);
    return null;
  }
);

export { KeyboardListener };
