import { getLogger } from '../../utils/logger/logger';
import { AccessTokenValue } from '../auth/auth.types';
import { defaultHeaders, request } from '../utils/request';

const logger = getLogger('[progress]');

export const updateProgress = async (
  url: string,
  seconds: number,
  sessionId: string,
  accessTokenValue: AccessTokenValue | null | undefined
) => {
  try {
    await request(
      {
        url,
        method: 'POST',
        body: JSON.stringify({ ProgressSeconds: seconds }),
        headers: {
          'Cache-Control': 'no-cache',
          'X-RiksTV-StreamingSessionId': sessionId,
        },
      },
      accessTokenValue
    );
    logger.log(`Stored progress: ${seconds}s`);
  } catch (err) {
    logger.error('Failed to update progress', err);
  }
};

export const updateProgressSync = (
  url: string,
  seconds: number,
  sessionId: string,
  accessTokenValue: AccessTokenValue | null | undefined
) => {
  try {
    // Sync request is done here instead of in utils as this sync request is not something we want to
    // use anywhere else - putting it in utils would encourage its use elsewhere.
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, false);
    const headers: Record<string, string> = {
      'content-type': 'application/json',
      'X-RiksTV-StreamingSessionId': sessionId,
      ...defaultHeaders,
    };
    accessTokenValue && (headers['Authorization'] = `Bearer ${accessTokenValue}`);
    Object.entries(headers).forEach(([header, value]) => {
      xhr.setRequestHeader(header.toLowerCase(), value);
    });
    xhr.send(JSON.stringify({ ProgressSeconds: seconds }));

    logger.log(`Stored progress: ${seconds}s`);
  } catch (err) {
    logger.error('Failed to update progress synchronously', err);
  }
};
