import type GatewayAPI from '@strim/gateway-api';
import type { Products } from '@strim/gateway-api';
import API from '@strim/gateway-api';

import { getProductsPriceAndDiscount } from '../products/utils/productHelpers';

import { Voucher, VoucherDiscounts, VoucherPriceDiscount } from './forces/voucher.types';

const VOUCHER_KEY = 'preloadedVoucher';

interface SerializedVoucher {
  code: string;
  expiry: number;
}

export const checkForPreloadedVoucher = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const voucher = urlParams.get('vc');

  if (voucher && voucher.length > 0) {
    setVoucherToLocalStorage(voucher);
  }
};

export const setVoucherToLocalStorage = (code?: string) => {
  if (!code) {
    localStorage.removeItem(VOUCHER_KEY);
    return;
  }
  const voucher = {
    expiry: new Date(Date.now() + 24 * 3600 * 1000).getTime(),
    code,
  };
  localStorage.setItem(VOUCHER_KEY, JSON.stringify(voucher));
};

export const getVoucherFromLocalStorage = (): string | undefined => {
  try {
    const rawValue = localStorage.getItem(VOUCHER_KEY);
    if (rawValue) {
      const voucher: SerializedVoucher = JSON.parse(rawValue);
      if (voucher.expiry > new Date().getTime()) {
        return voucher.code;
      } else {
        localStorage.removeItem(VOUCHER_KEY);
      }
    }
  } catch (err) {
    localStorage.removeItem(VOUCHER_KEY);
  }
};

export const hasDiscountType = (voucher: Pick<Voucher, 'discounts'> | undefined, type: API.DiscountType): boolean => {
  return voucher?.discounts?.map(d => d.discountType)?.includes(type) ?? false;
};

export const hasOnlyNoDiscount = (voucher?: Voucher): boolean => {
  return voucher?.discounts?.map(d => d.discountType)?.length === 1 && hasDiscountType(voucher, 'NoDiscount');
};

export const hasOnlyTrialDiscount = (voucher?: Voucher): boolean => {
  return voucher?.discounts?.map(d => d.discountType)?.length === 1 && hasDiscountType(voucher, 'NewTrialLength');
};

/**
 * A valid voucher for a customer includes either price or trial discounts
 * and is only valid for trial when the user is allowed trial
 */
export const getValidVoucher = (voucher?: Voucher, subscriptionOperation?: GatewayAPI.SubscriptionOperation) => {
  const voucherOnlyHasTrialLengthDiscount = hasOnlyTrialDiscount(voucher);
  const isValidVoucherDiscount =
    subscriptionOperation?.isEligibleForTrialDiscount || !voucherOnlyHasTrialLengthDiscount;
  return voucher?.isValid && isValidVoucherDiscount ? voucher : undefined;
};

/**
 * Returns valid reduced price and percent from parsed price voucher description.
 * Returns null when there are any global campaigns on products
 */
export const getPriceVoucherDiscount = (voucher: Voucher | undefined, productsToBuy: Products.Product[]) => {
  const priceVoucherDetails = voucher?.discounts?.find(isPriceVoucherDiscount);
  if (!priceVoucherDetails) {
    return null;
  }
  const baseProduct = productsToBuy.find(p => p.baseProduct);
  if (!baseProduct) {
    return null;
  }
  // only apply voucher discount when no global campaign on (base) products
  const { totalDiscountAmount: globalDiscount } = getProductsPriceAndDiscount(productsToBuy);
  if (globalDiscount) {
    return;
  }

  // Yay we have a valid price voucher
  return {
    /** Price in NOK */
    price: baseProduct.price,
    /** Reduced amount in NOK */
    reducedAmount: parseFloat((baseProduct.price * (priceVoucherDetails.percent / 100)).toFixed(2)),
    /** Percent is an int on the range 1-100 */
    percent: priceVoucherDetails.percent,
    numberOfBillingCycles: priceVoucherDetails.durationMonths,
    productId: baseProduct.id,
  };
};

const isPriceVoucherDiscount = (discount: VoucherDiscounts): discount is VoucherPriceDiscount => {
  return discount.discountType === 'Price';
};
