import { errorTracker } from '../../utils/errorTracker/tracking';

import { fadeInOnLoad } from './fadeInOnLoad';

export type LazyImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  onComplete?: () => void;
};

const LazyImage = (props: LazyImageProps) => {
  const { onComplete, onLoad, onError, ...imageProps } = props;
  return (
    <img
      alt={props.alt}
      loading="lazy"
      {...imageProps}
      onLoad={e => {
        onLoad?.(e);
        onComplete?.();
      }}
      onError={e => {
        onError?.(e);
        onComplete?.();
        logError(e);
      }}
    />
  );
};

export const ImageFade = fadeInOnLoad(LazyImage);

// support images within <picture> tags (using srcset)
function getSrc(img: HTMLImageElement) {
  if (img.currentSrc === undefined) {
    return img.src;
  } else {
    return img.currentSrc;
  }
}

function logError(event: React.SyntheticEvent<HTMLImageElement, Event>) {
  const pageUrl = window.location.href;
  const imgSrc = getSrc(event.target as HTMLImageElement);
  const error = new Error(`Failed to load image`);
  // Don't log for localhost
  if (!/localhost:/i.test(pageUrl)) {
    errorTracker.captureException(error, { level: 'warning', tags: { failedImgUrl: imgSrc } });
  }
}
