import React, { FC } from 'react';

import { Breakpoints } from '../../grid/breakpoints';
import { SwimlaneSettings } from '../Swimlane.types';

import { StandardItem, StandardItemProps } from './StandardItem';

const swimlaneConfig: SwimlaneSettings = {
  placeholderHeight: {
    [Breakpoints.XS]: 250,
    [Breakpoints.SM]: 350,
    [Breakpoints.MD]: 325,
    [Breakpoints.ML]: 390,
    [Breakpoints.LG]: 310,
    [Breakpoints.XL]: 320,
    [Breakpoints.XXL]: 370,
    [Breakpoints.DEFAULT]: 340,
  },
};

const FeaturedItem: FC<StandardItemProps> = props => <StandardItem {...props} featured showImdbRating="always" />;

export { FeaturedItem, swimlaneConfig };
