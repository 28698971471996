import { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { ButtonLink } from '@rikstv/play-common/src/components/button';
import { Body, H2 } from '@rikstv/shared-components';

import { errorTracker } from '../../utils/errorTracker/tracking';

import styles from './ErrorPage.module.css';

export const AssetNotFound: FC<{ contextInfo?: string }> = ({ contextInfo }) => {
  // report 404 to Google Analytics
  window.dataLayer && window.dataLayer.push({ event: '404page' });
  errorTracker.capture404NotFound({ tags: { context: contextInfo } });
  return (
    <>
      <Helmet>
        {/*
          Remove not found assets from search index as url to this component will still be an asset/SCP-url
          https://support.google.com/webmasters/thread/11770516?hl=en&msgid=11778983
          https://developers.google.com/search/docs/crawling-indexing/robots-meta-tag
        */}
        <meta name="robots" content="noindex, noarchive" />
      </Helmet>
      <article className={styles.errorPage}>
        <div className={styles.errorPageContent}>
          <H2>Au da, vi finner dessverre ikke innholdet du leter etter.</H2>
          <br />
          <Body>Klikk under for å gå til forsiden</Body>
          <br />
          <ButtonLink to="/">Til forsiden</ButtonLink>
        </div>
      </article>
    </>
  );
};
