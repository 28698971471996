import { FC, useContext } from 'react';

import { MenuButtonContext } from './MenuButtonContext';
import { MenuItem, VideoQualityMenuItem } from './MenuItem';

import styles from './Menu.module.css';

export interface Props {
  menuItems: MenuItem[] | VideoQualityMenuItem[];
  onSelect: (key: string, height?: number) => void;
  title: string | null;
}

export const MenuColumn: FC<Props> = ({ menuItems, onSelect, title }) => {
  const { hideContextMenu, btnRef } = useContext(MenuButtonContext);

  return (
    <fieldset className={styles.column}>
      {title && <legend className="title rds-title-4">{title}</legend>}
      {menuItems.map(menuItem => {
        const { key, selected, label } = menuItem;
        const height = 'height' in menuItem ? menuItem.height : undefined;

        return (
          <label className={`with-keyboard-focus-within ${styles.radio}`} key={key}>
            <span className={styles.radioInput}>
              <input
                id={`${title}-${key}`}
                checked={selected}
                onChange={() => onSelect(`${key}`, height)}
                onClick={() => {
                  if (selected) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore the shape is controlled, so this works fine
                    btnRef?.current?.firstElementChild?.focus();
                    hideContextMenu();
                  }
                }}
                type="radio"
                name={`radio_group-${title}`}
              />
              <span className={styles.radioMarker}></span>
            </span>
            <span className={`${styles.radioLabel} rds-body ${selected && styles.selected}`}>{label}</span>
          </label>
        );
      })}
    </fieldset>
  );
};
