import { useLocation } from 'react-router-dom';

import { SecondaryButton } from '@rikstv/shared-components';

import { getDeepLinkWithoutSuperIdFromPathname } from '../../router/router.deepLinks';
import { isDevFeatureEnabled } from '../../utils/featureFlags/devFeatureFlags';
import { usePages } from '../pages/usePages';

import { copyToClipboard } from './share.utils';

export const DeepLinkClipboardButton = () => {
  return isDevFeatureEnabled('deepLinkGeneratorButton') ? <DeepLinkGeneratorButton /> : null;
};

const DeepLinkGeneratorButton = () => {
  const { pathname } = useLocation();
  const { pages } = usePages();

  const onClick = async () => {
    const linkStr = await getDeepLinkWithoutSuperIdFromPathname(pathname, { pages });
    if (!linkStr) {
      return alert('Hmmm, här vet jag inte vad som förväntas av mig 🤔');
    }
    try {
      await copyToClipboard(linkStr);
      alert('Deep link copied to clipboard 🙌\n' + linkStr);
    } catch {
      alert('Clipboard interaction failed 🤔\n' + linkStr);
    }
  };
  const style: any = { '--rds-background-primary': 'var(--rds-feedback-error-dark)' };
  return (
    <div style={{ position: 'fixed', zIndex: 9999, right: '1rem', top: '84px' }}>
      <SecondaryButton onClick={onClick} style={style}>
        Copy Deep Link
      </SecondaryButton>
      <div id="deep-link-text" style={{ display: 'none' }}></div>
    </div>
  );
};
