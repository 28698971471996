import { useLocation, useParams } from 'react-router';

export const useSlug = () => {
  const { slug: collectionSlug } = useParams();
  const { pathname: pageSlug } = useLocation();

  const slugFromPath = collectionSlug || pageSlug.replace('/', '');

  return { slugFromPath, isCollection: slugFromPath === collectionSlug };
};
