import { captureExceptionInSentry } from '../errorTracker/tracking';
import { getLogger } from '../logger/logger';

import type { CommonAnalytics, PlayContentMeta, SwimlaneEventData } from './CommonAnalytics.types';

declare global {
  interface Window {
    dataLayer: any[];
  }
}
const logger = getLogger('[GTM]');

export type EventCategory =
  | 'show_payment'
  | 'play_content'
  | 'consumption_update'
  | 'swimlaneClick'
  | 'swimlaneImpression'
  | 'search'
  | 'view_search_result'
  | 'schedule_click'
  | 'trackPageView'
  | 'add_to_my_list'
  | 'remove_from_my_list'
  | 'remove_from_continue_watching'
  | 'add_favorite_channel'
  | 'remove_favorite_channel';

export type InteractionEvent = {
  event: 'user_interaction' | 'button_click';
  event_params: {
    event_type?: string; // general | back | continue | cta | menu | ..
    event_group?: string; // sharing, rating, search-history etc
    link_text?: string;
    link_path?: string;
    context?: string; // contextual context, current page or asset or some such
    /** Defined as custom dimension, cant aggregate well if used as metric */
    days_since_created?: number;
    /** Defined as metric (seconds) in GA */
    duration?: number;
  };
};
type GtmEvent<T extends string = EventCategory> =
  | ({ event?: T } & Record<string, string | number | boolean | Date | undefined>)
  | InteractionEvent;

const push = <T extends string = EventCategory>(data: GtmEvent<T>) => {
  if (window.dataLayer) {
    window.dataLayer.push(data);
  }
  if (data) {
    const { event, ...params } = data;
    if (event === 'user_interaction') {
      logger.log('push:', event, params.event_params);
    } else {
      logger.log('push:', event, params);
    }
  }
};

const getTrack =
  <T extends string = EventCategory>() =>
  (category: T, customData?: Record<string, any>) => {
    push({
      event: category,
      ...customData,
    });
  };

const track = getTrack();

const commonGoogleTagManager = {
  streamStart: (customData: PlayContentMeta) => {
    track('play_content', customData);
  },
  streamStop: (streamConsumption: number) => {
    track('consumption_update', { streamConsumption });
  },
  streamKeepAlive: (streamConsumption: number) => {
    track('consumption_update', { streamConsumption });
  },
  streamPause: (streamConsumption: number) => {
    track('consumption_update', { streamConsumption });
  },
  trackScreen: (path: string) => {
    track('trackPageView', {
      virtualPagePath: path,
    });
  },
  swimlaneClicked: (data: SwimlaneEventData) => {
    track('swimlaneClick', {
      swimlane: data,
    });
  },
  swimlaneImpression: (data: SwimlaneEventData) => {
    track('swimlaneImpression', {
      swimlane: data,
    });
  },
  searchResultClicked: (
    searchText: string,
    title: string,
    position: number,
    listTitle: string,
    listPosition: number
  ) => {
    track('search', {
      searchTerm: searchText,
      element_name: title,
      element_position: position,
      swimlane_name: listTitle,
      swimlane_position: listPosition,
    });
  },
  scheduleClicked: (item: {
    schedule_date: string;
    schedule_name: string;
    element_id: string;
    element_name: string;
    /* zero based index even though it's named "position" */
    timeslot_position: number;
    timeslot_live: 0 | 1;
  }) => {
    track('schedule_click', item);
  },
  searchResultViewed: (searchText: string) => {
    track('view_search_result', {
      searchTerm: searchText,
    });
  },
  addedToMyList: () => {
    track('add_to_my_list');
  },
  removeFromMyList: () => track('remove_from_my_list'),
  removeFromContinueWatching: () => track('remove_from_continue_watching'),
  toggleFavoriteChannel: (channelId: string, isFavorite: boolean) => {
    track(isFavorite ? 'add_favorite_channel' : 'remove_favorite_channel', { channel_id: channelId });
  },
  // Not part of CommonAnalyticsTypes
  rateFilmOrSeries: (actionType: 'like' | 'dislike' | 'remove-like' | 'remove-dislike', assetTitle: string) => {
    push({
      event: 'user_interaction',
      event_params: {
        event_group: 'rating',
        event_type: actionType,
        context: assetTitle,
      },
    });
  },
  clickShareLink: (assetTitle: string, link: string) => {
    push({
      event: 'user_interaction',
      event_params: {
        event_group: 'share',
        event_type: 'click share button',
        context: assetTitle,
        link_path: link,
      },
    });
  },
  changeEpisodeInPlayer: () => {
    push({
      event: 'user_interaction',
      event_params: {
        event_group: 'player-controls',
        event_type: 'change episode',
      },
    });
  },
  changeQualityInPlayer: (quality: string) => {
    push({
      event: 'user_interaction',
      event_params: {
        event_group: 'player-controls',
        event_type: `change quality - ${quality}`,
      },
    });
  },
  skippingContentInPlayer: () => {
    push({
      event: 'user_interaction',
      event_params: {
        event_group: 'player-controls',
        event_type: `user skipped content`,
      },
    });
  },
  trackButtonClick: (event_params: InteractionEvent['event_params']) => {
    push({ event: 'button_click', event_params });
  },
  clickSearchHistoryItem: (assetTitle: string, link: string) => {
    push({
      event: 'user_interaction',
      event_params: {
        event_group: 'search-history',
        event_type: 'click search history item',
        context: assetTitle,
        link_path: link,
      },
    });
  },
  errorReceived(errorMessage: string, extra?: string) {
    captureExceptionInSentry(new Error(errorMessage), { extra: { message: extra } });
  },
} satisfies CommonAnalytics & Record<string, unknown>;

export { commonGoogleTagManager, getTrack, push };
