import { SwimlaneStyle } from 'ContentLayout';

import { SwimlaneItem } from '../../forces/assetDetails/assetDetails.types';
import { commonRoutes } from '../../router/router.path';
import { captureExceptionInSentry } from '../../utils/errorTracker/tracking';
import logger from '../../utils/logger/logger';

export const getTemporalProperty = <T extends RiksTV.ClientAPI.TemporalPropBase>(list: T[] = []): T | undefined => {
  const now = Date.now();
  return list.find(b => {
    if (b.from == null || b.to == null) {
      return false;
    }
    return now > Date.parse(b.from) && now < Date.parse(b.to);
  });
};

const getLinkTo = (asset: SwimlaneItem, autoPlay = false) => {
  const behavior = getTemporalProperty(asset.behaviors);
  if (behavior) {
    switch (behavior.target) {
      case 'OpenCoverPage':
        return getVodAssetLink(asset);
      case 'PlayChannel':
      case 'PlayExternalChannel': {
        if (!asset.channel) {
          const error = new Error(`SwimlaneItem with behavior "${behavior.target}" is missing channel info`);
          captureExceptionInSentry(error);
          return ``;
        }
        return commonRoutes.watchLive(asset.channel.id, asset.channel.name);
      }
      case 'OpenEpisodeCoverPage': {
        if (!asset.links.episodeCoverPage?.id) {
          const error = new Error('SwimlaneItem with behavior OpenEpisodeCoverPage is missing episodeCoverPage-link');
          captureExceptionInSentry(error);
          return '';
        }
        return commonRoutes.stackedSeriesOrMovie({
          id: asset.links.episodeCoverPage.id,
          title: asset.title,
          autoPlay,
          isSeries: true,
        });
      }
      case 'PlayExternalAsset': {
        return asset.links.externalPlayback!.playbackUrl;
      }
      case 'OpenSuperCoverPage': {
        if (asset.links.superCoverPage?.id) {
          return commonRoutes.stackedSeriesOrMovie({
            id: asset.links.superCoverPage?.id,
            title: asset.title,
            isSeries: asset.tracking.seriesId != null,
            autoPlay,
          });
        }
        break;
      }
      case 'PlayAsset': {
        break;
      }
      case 'OpenCollection': {
        if (asset.links.collectionLink) {
          return commonRoutes.collections({ slug: asset.links.collectionLink.slug });
        }
        break;
      }
      default: {
        const _exhaustiveCheck: never = behavior.target;
        logger.warn('Unsupported behavior for link creation', behavior.target);
      }
    }
  }
  // Fallback behavior
  if (asset.behaviors?.some(a => a.target === 'PlayChannel') && asset.channel) {
    return commonRoutes.watchLive(asset.channel.id, asset.channel.name);
  } else {
    if (asset.links.superCoverPage?.id) {
      return commonRoutes.stackedSeriesOrMovie({
        id: asset.links.superCoverPage?.id,
        title: asset.title,
        isSeries: asset.tracking.seriesId != null,
        autoPlay,
      });
    }
    return getVodAssetLink(asset);
  }
};

const getVodAssetLink = (asset: SwimlaneItem) => {
  // deprecated OpenCoverPage behavior
  if (asset.tracking.seriesId) {
    return commonRoutes.watchTvSeries(asset.tracking.seriesId);
  } else if (asset.links.details.id) {
    return commonRoutes.watchAsset({ id: asset.links.details.id });
  } else {
    logger.error('Cannot create link to asset without id', asset);
    return '';
  }
};

export const takeMinSwimlaneItems = (
  swimlaneItems: SwimlaneItem[],
  swimlaneStyle: SwimlaneStyle,
  minItemsForShowMore: number | undefined
) => {
  if (swimlaneStyle === 'SwimmingPool' || swimlaneStyle === 'SwimmingPoolPortrait' || !minItemsForShowMore) {
    return swimlaneItems;
  }
  return swimlaneItems.slice(0, minItemsForShowMore);
};

export const shouldShowSwimlanePageLink = (
  minItemsForShowMore: number | undefined,
  hideSwimlaneTitle: boolean,
  nrOfItems: number
) => {
  if (hideSwimlaneTitle || !minItemsForShowMore) {
    return false;
  }
  return nrOfItems >= minItemsForShowMore;
};

export { getLinkTo };
