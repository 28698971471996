import { FC } from 'react';
import { subSeconds } from 'date-fns';

import config from '@rikstv/play-common/src/config';
import { Channel, Program } from '@rikstv/play-common/src/forces/channels/channel.types';
import { TertiaryButton } from '@rikstv/shared-components';

import { dateNow } from '../ProgressBar/progressBar.utils';

import styles from './ProgramPoints.module.css';

interface Props {
  channel: Channel;
}

export const programFilter = (channel: Channel, program: Program) => {
  const now = dateNow();
  const broadcastStartTime = dateNow(program.time);
  const bufferLengthSeconds = channel.bufferLength ? channel.bufferLength / 1000 : config.player.liveBufferInSeconds;
  const bufferStart = subSeconds(now, bufferLengthSeconds);
  return bufferStart < broadcastStartTime && broadcastStartTime < now;
};

export const ProgramPoints: FC<Props> = ({ channel }) => {
  const programs = channel.programs.filter(p => programFilter(channel, p));

  const theoPlayer = window.THEOplayer;
  const setTheoCurrentTime = (startAtSeconds: number) => {
    const player = theoPlayer.players[0];
    const streamEndSeconds = player.seekable.end(0);
    const nowEpoch = new Date().getTime() / 1000;
    const secondsRewind = nowEpoch - startAtSeconds;
    player.currentTime = streamEndSeconds - secondsRewind;
  };

  return (
    <div className={styles.pointsWrapper}>
      {programs.map((program, idx) => (
        <TertiaryButton
          key={`${program.name}-${program.time}-${idx}`}
          onClick={() => setTheoCurrentTime(new Date(program.time).getTime() / 1000)}>
          {new Date(program.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h24' })}{' '}
          {program.seriesName}
        </TertiaryButton>
      ))}
    </div>
  );
};
