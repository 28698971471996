import { AssetDetails } from '@rikstv/play-common/src/forces/assetDetails/assetDetails.types';
import { Channel } from '@rikstv/play-common/src/forces/channels/channel.types';

export type Playable = Channel | AssetDetails;

export const isPlayableChannel = (playable: Playable): playable is Channel => {
  return playable.type === 'channelAT' || 'programs' in playable;
};

export const isPlayableChannelOrCatchup = (playable: Playable) => {
  return isPlayableChannel(playable) || playable.type === 'catchUpAT';
};
