import { Component, PropsWithChildren } from 'react';

import { Body, Gutter, H2, H3 } from '@rikstv/shared-components';

import { authService } from '../../utils/auth/AuthService';

type Props = {
  title?: string;
  message?: string;
  onCatch?: (error: Error) => void;
};
const defaultProps: Required<Props> = {
  title: 'Noe gikk galt',
  message: 'Vi setter vår beste Strimere på saken! Og unskyld for ulempen 🙏.',
  onCatch: () => void 0,
};

export class GeneralErrorBoundary extends Component<
  PropsWithChildren<Props>,
  { hasError: boolean; error: Error | null }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }
  static defaultProps = defaultProps;
  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }
  componentDidCatch(error: Error) {
    /* do nothing */
    this.props.onCatch?.(error);
  }
  render() {
    if (this.state.hasError) {
      return (
        <Gutter>
          <H2>{this.props.title}</H2>
          <br />
          <Body>{this.props.message}</Body>
          {authService.isInternalUser() && (
            <div>
              <br />
              <H3>Error stack (vises bare for interne brukere)</H3>
              <pre>{`${this.state.error?.stack?.toString()}`}</pre>
              <br />
            </div>
          )}
        </Gutter>
      );
    }
    return this.props.children;
  }
}
