import { CSSProperties, FC } from 'react';
import classNames from 'classnames';

import { isRunningTests } from '../utils/isRunningTests';

import styles from './icons.module.css';

export type IconSize = 'match-text' | 'normal' | 'large';

export type IconProps = {
  iconSize?: IconSize | number;
  spacingLeft?: boolean;
  spacingRight?: boolean;
  className?: string;
  ariaLabel?: string | undefined;
};

export type IconComponentType = React.FunctionComponent<IconProps>;

interface SvgIconProps {
  style?: CSSProperties;
  className?: string;
}
type SvgComponent = FC<React.SVGProps<SVGSVGElement>>;

/**
 * Pass a <svg> element to be enhanced with standard classes for styling, spacing and sizing
 */
export const svgToIconElement =
  (IconComponent: SvgComponent, svgIconProps: SvgIconProps = {}) =>
  ({
    iconSize = 'normal',
    spacingLeft = false,
    spacingRight = false,
    className,
    style,
    ariaLabel,
  }: IconProps & { style?: CSSProperties }) => {
    const _style = style ?? svgIconProps.style ?? {};
    if (typeof iconSize === 'number') {
      (_style as any)['--size'] = `${iconSize}px`;
    }

    if (isRunningTests()) {
      return null;
    }

    return (
      <IconComponent
        className={classNames(styles.icon, className, svgIconProps.className, {
          [styles.iconLarge]: iconSize === 'large',
          [styles.matchText]: iconSize === 'match-text',
          [styles.spacingLeft]: spacingLeft,
          [styles.spacingRight]: spacingRight,
        })}
        style={_style}
        aria-label={ariaLabel}></IconComponent>
    );
  };
