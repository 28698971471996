import {
  addDays,
  addSeconds,
  differenceInCalendarDays,
  differenceInSeconds,
  format as dateFnsFormat,
  formatISO,
  formatRelative,
  getUnixTime,
  isBefore,
  isFuture,
  isToday as isTodaysDate,
  isTomorrow as isTomorrowsDate,
  isWithinInterval,
  minutesToMilliseconds as minutesToMillisecondsDate,
} from 'date-fns';
import { nb } from 'date-fns/locale';

import config from '../../config';
import { captureExceptionInSentry } from '../errorTracker/tracking';
import { specialcharacters } from '../special-characters/special-characters.utils';

/**
 * @description Format date with nb locale
 */
export const formatDate = (date: Date, formatString: string) => {
  return dateFnsFormat(date, formatString, { locale: nb });
};

export const toDate = (date: string | Date): Date => {
  if (date instanceof Date) {
    return date;
  }
  return new Date(date);
};
export const withTime = (date: Date, time: `${number}${number}:${number}${number}`): Date => {
  const newDate = new Date(date.getTime());
  const [hours, minutes] = time.split(':').map(Number);
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  newDate.setSeconds(0);
  return newDate;
};

export const relativeTimeFromNow = (date: Date) => {
  return formatRelative(date, new Date(), { locale: nb });
};

export const getYearFormatted = (): string => formatDate(new Date(), 'yyyy');
export const getSecondsBetweenDates = (date1: Date, date2: Date): number => {
  return differenceInSeconds(date1, date2);
};
export const getUnix = (date: string | Date): number => getUnixTime(toDate(date));
export const getTimeFromUTC = (utc: number): number => {
  return utc * 1000;
};
export const getISO8601 = (date: string | Date): string => formatISO(toDate(date));
export const formatTime = (date: string | Date): string => formatDate(toDate(date), 'HH:mm');

export const getDateAndTimeFormattedShort = (date: string | Date): string => formatDate(toDate(date), 'd. MMM HH:mm');

export const getDateFormattedShort = (date: string | Date | undefined): string =>
  date ? formatDate(toDate(date), `d.${specialcharacters.nbsp}MMMM`) : '';

export const getDateFormattedLong = (date: string | Date): string => formatDate(toDate(date), 'd. MMMM yyyy');

export const getDateSortable = (date: string | Date): string => formatDate(toDate(date), 'yyyy-MM-dd');

export const getAnalyticsDate = (date: string | Date): string => formatDate(toDate(date), 'dd-MM-yyyy HH:mm:ss');

export const getStartEndTime = (
  startDate: string | Date,
  duration: number
): {
  start: Date;
  end: Date;
} => {
  const startTime = toDate(startDate);
  const endTime = addSeconds(startTime, duration);
  return {
    start: startTime,
    end: endTime,
  };
};

export const getStartEndTimeFormatted = (
  startDate: string | Date,
  duration: number
): {
  start: string;
  end: string;
} => {
  const { start, end } = getStartEndTime(startDate, duration);
  return {
    start: formatTime(start),
    end: formatTime(end),
  };
};

export const isDateInRange = (currentDate: string | Date, startDate: string | Date, duration: number): boolean => {
  currentDate = toDate(currentDate);
  startDate = toDate(startDate);
  const endDate = addSeconds(startDate, duration);

  if (isBefore(endDate, startDate)) {
    captureExceptionInSentry(new Error(`End of interval is before start. startDate: ${startDate} endDate: ${endDate}`));
    return false;
  }

  return isWithinInterval(currentDate, { start: startDate, end: endDate });
};

export const isDateBetweenDates = (
  currentDate: string | Date,
  startDate: string | Date,
  endDate: string | Date
): boolean => {
  return isWithinInterval(toDate(currentDate), { start: toDate(startDate), end: toDate(endDate) });
};

export const isDateInThePast = (date: string | Date): boolean => {
  return isBefore(toDate(date), new Date());
};

export const isLive = (date: string | Date) => {
  const liveBuffer = 60 + config.player.liveDelay;
  return new Date() <= addSeconds(toDate(date), liveBuffer);
};

export const getDateInTheFuture = (daysToAdd: number) => {
  return addDays(new Date(), daysToAdd);
};

export const isDateInTheFuture = (date: string | Date): boolean => {
  return isFuture(toDate(date));
};

export const minutesToMilliseconds = (minutes: number): number => {
  return minutesToMillisecondsDate(minutes);
};

export const isToday = (date: string | Date): boolean => {
  return isTodaysDate(toDate(date));
};

export const isTomorrow = (date: string | Date): boolean => {
  return isTomorrowsDate(toDate(date));
};

export const getDaysAgo = (date: string | Date, useBroadcastDate = true): number => {
  const dateObj = toDate(date);
  const diff = differenceInCalendarDays(new Date(), dateObj);
  // if the date is tomorrow but before 06.00, it is considered "today" in the EPG
  // i.e. "broadcast date"
  if (useBroadcastDate && diff === -1 && dateObj.getHours() < 6) {
    return 0;
  }
  return diff;
};

export const getDaysInTheFuture = (date: string | Date): number => {
  const daysAgo = getDaysAgo(date);
  return Math.abs(daysAgo);
};

export const getRatioOfCurrentTimeInRange = (startDate: string | Date, endDate: string | Date): number => {
  const nowUnix = new Date().getTime() / 1000;
  const startUnix = getUnix(toDate(startDate));
  const endUnix = getUnix(toDate(endDate));
  const ratio = (nowUnix - startUnix) / (endUnix - startUnix);
  const ratioRounded = Math.round(ratio * 100) / 100;
  return ratioRounded;
};

export const nonZeroYearOrUndefined = (year: number | null | undefined) => {
  if (year) {
    return year > 0 ? year : undefined;
  }
  return undefined;
};

type NoTime = { type: 'Default' };
type PlannedTime = { type: 'Planned' };
type PresentTime = { type: 'Live'; progress: number };
export type RelativeTimeInfo = NoTime | PlannedTime | PresentTime;
export const getRelativeTimeInfo = (broadcastedTime?: Date | string, duration = 0): RelativeTimeInfo => {
  if (!broadcastedTime) {
    return { type: 'Default' };
  }
  const broadcastDate = toDate(broadcastedTime);
  const now = Date.now() / 1000;
  const start = broadcastDate.getTime() / 1000;
  const end = start + duration;
  if (start > now) {
    return { type: 'Planned' };
  }
  if (now > end) {
    return { type: 'Default' };
  }
  return { type: 'Live', progress: Math.round(((now - start) / duration) * 100) };
};
