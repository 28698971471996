import React from 'react';

export const letUserAgentHandleEvent = (event: React.MouseEvent | React.KeyboardEvent) => {
  // when a user presses modifier keys, we should not intercept and prevent default actions
  // doing so is bad UX and UU
  const { altKey, shiftKey, metaKey, ctrlKey } = event;
  const metaKeyPressed = altKey || shiftKey || metaKey || ctrlKey;

  if ('key' in event && event.key === 'Tab') {
    return false;
  }
  return metaKeyPressed;
};
