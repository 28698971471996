import { FC, HTMLAttributes } from 'react';

import { Tag } from '@rikstv/shared-components';

import { CssBemHelper } from '../../utils/css/CssBemHelper';

import './ImageOverlayWrapper.scss';

const bem = new CssBemHelper('image-overlay-wrapper');
interface Props extends HTMLAttributes<HTMLDivElement> {
  centerContent?: boolean;
  backdropStyle?: 'none' | 'dark' | 'gradient';
  displayChildrenOnHover?: boolean;
  label?: string;
}
export const ImageOverlayWrapper: FC<Props> = ({
  children,
  centerContent,
  backdropStyle = 'none',
  displayChildrenOnHover,
  className = '',
  label,
  ...rest
}) => {
  const classes = [
    bem.base,
    centerContent && bem.modifier('center'),
    backdropStyle === 'dark' && bem.modifier('dark'),
    backdropStyle === 'gradient' && bem.modifier('gradient'),
    displayChildrenOnHover && bem.modifier('show-on-hover'),
    className,
  ]
    .filter(Boolean)
    .join(' ');
  return (
    <div className={classes} {...rest}>
      {children}
      {label && <Tag className="image-overlay-title">{label}</Tag>}
    </div>
  );
};
