import { FC } from 'react';

import { ExpiredIcon } from '../../icons';

import { ImageOverlayWrapper } from './ImageOverlayWrapper';

interface Props {
  iconSize?: 'normal' | 'large';
  label?: string;
}
export const ExpiredOverlay: FC<Props> = ({ iconSize = 'normal', label }) => {
  const marginBottom = label ? '.5rem' : '0';
  const ariaLabel = label ? label : 'IKKE TILGJENGELIG I ARKIVET';

  return (
    <ImageOverlayWrapper centerContent backdropStyle="dark" label={label} aria-label={ariaLabel}>
      <ExpiredIcon iconSize={iconSize} style={{ marginBottom, color: 'white' }} />
    </ImageOverlayWrapper>
  );
};
