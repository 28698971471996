import { authService } from '../../utils/auth/AuthService';
import { request } from '../utils/request';

const addExternalAssetToContinueWatching = async (url: string) => {
  await request({ url, method: 'POST' }, authService.getToken().value);
};

export const api = {
  addExternalAssetToContinueWatching,
};
