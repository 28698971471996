import { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import { NotificationDot } from '@rikstv/shared-components';

import { ArrowRightSvg } from '../svg';

import navItemStyles from './NavItem.module.scss';

export interface UnstyledLinkProps {
  href: string;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

type LinkFn = (props: UnstyledLinkProps) => ReactNode | null;

type Props = PropsWithChildren<{
  className?: string;
  action: string;
  isPartOfGroup?: boolean;
  isMobileNavItem?: boolean;
  notificationDotNr?: number;
  onClick?: () => void;
}>;
type ExportedProps = Omit<Props, 'isMobileNavItem'>;

export const navItemFactory = (Link: LinkFn, currentPath: string) => {
  const NavItem: FC<Props> = ({
    children,
    action,
    className,
    isMobileNavItem: mobileNavItem = false,
    isPartOfGroup = false,
    onClick,
    notificationDotNr,
  }) => {
    const active = isLinkActive(currentPath, action);
    const showArrow = mobileNavItem && isPartOfGroup === false;
    const showNotifications = Boolean(notificationDotNr);
    return (
      <li
        className={classNames(className, {
          [navItemStyles.mainNavItem]: !mobileNavItem,
          [navItemStyles.mobileNavItem]: mobileNavItem,
          [navItemStyles.linkGroup]: isPartOfGroup,
        })}>
        <Link
          href={action}
          aria-current={active ? true : undefined}
          onClick={onClick}
          className={classNames('with-keyboard-focus-within', navItemStyles.link)}>
          <span className={navItemStyles.linkLabel}>{children}</span>
          {showArrow && <ArrowRightSvg aria-label="" />}
          {showNotifications && (
            <NotificationDot className={navItemStyles.linkNotification} numberOfNotifications={notificationDotNr} />
          )}
        </Link>
      </li>
    );
  };

  const MainNavItem = (props: Omit<ExportedProps, 'notificationDotNr'>) => <NavItem {...props} />;
  const MobileNavItem = (props: ExportedProps) => <NavItem {...props} isMobileNavItem />;

  return { MainNavItem, MobileNavItem };
};

const isLinkActive = (currentPath: string, action: string) => {
  // Normalize to start with slash
  const path = currentPath.startsWith('/') ? currentPath : `/${currentPath}`;
  const link = action.startsWith('/') ? action : `/${action}`;
  // Compare first non empty path segment
  return path.split('/')[1] === link.split('/')[1];
};
