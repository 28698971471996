import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';

import './AssetImageContainer.scss';

const AssetImageContainer: FCC<{ className?: string; rounded?: boolean }> = ({
  children,
  className = '',
  rounded = true,
}) => (
  <div className={`asset-image-container ${className} ${rounded ? 'asset-image-container--rounded' : ''}`}>
    <>{children}</>
  </div>
);

export { AssetImageContainer };
