import { secondsFormatted, timeFormatted } from '../../utils/dateTimeUtil';

import { Action } from './Actions';
import {
  calculateLiveTime,
  calculateProgressWidthPercent,
  calculateVodTime,
  dateNow as dateNowImported,
} from './progressBar.utils';

export interface State {
  hover: boolean;
  dragging: boolean;
  dragX: number;
  time: string;
  totalTime: string;
  width: any;
  currentTime: number;
  duration: number;
  isLinear: boolean;
}

type Dependencies = Partial<{
  dateNow?: typeof dateNowImported;
}>;
export const _reducerFactory = (deps: Dependencies = {}) => {
  const { dateNow = dateNowImported } = deps;

  return function reducer(state: State, action: Action): State {
    switch (action.type) {
      case 'START_HOVER':
        return { ...state, hover: true, dragX: action.value };
      case 'STOP_HOVER':
        return { ...state, hover: false, dragging: false };
      case 'START_DRAG':
        if (!state.hover) return state;
        return { ...state, dragging: true, dragX: action.value };
      case 'STOP_DRAG':
        return {
          ...state,
          dragging: false,
          width: 100 * action.value + '%',
        };
      case 'DRAG':
        const { offsetX, percentage } = action.value;
        const secondsTotal = state.isLinear
          ? calculateLiveTime(percentage, dateNow().getTime() / 1000)
          : calculateVodTime(state.duration, percentage);
        const time = state.isLinear ? timeFormatted(dateNow(secondsTotal * 1000)) : secondsFormatted(secondsTotal);

        return {
          ...state,
          time,
          dragX: offsetX,
        };
      case 'UPDATE_CURRENT_TIME':
        if (state.dragging) {
          return state;
        }

        const { streamStartSeconds, streamEndSeconds, currentTime } = action.value;
        const width = calculateProgressWidthPercent(streamStartSeconds, streamEndSeconds, currentTime);
        const widthPercentString = width + '%';

        if (state.isLinear) {
          return {
            ...state,
            width: widthPercentString,
            totalTime: timeFormatted(dateNow()),
          };
        } else {
          return {
            ...state,
            currentTime: action.value.currentTime,
            width: widthPercentString,
            totalTime: '',
          };
        }
      case 'UPDATE_DURATION': {
        const { streamStartSeconds, streamEndSeconds, currentTime } = action.value;
        const width = calculateProgressWidthPercent(streamStartSeconds, streamEndSeconds, currentTime);
        const widthPercentString = width + '%';

        if (state.isLinear) {
          const newDuration = streamEndSeconds - streamStartSeconds;

          // Duration can vary for live stream. We assume it's approx the same for a certain stream
          // and will never go down in duration. This is to avoid jumping progress indicator
          const updatedDuration = Math.max(state.duration, newDuration);

          return {
            ...state,
            duration: updatedDuration,
            width: widthPercentString,
            totalTime: timeFormatted(dateNow()),
          };
        } else {
          return {
            ...state,
            duration: streamEndSeconds - streamStartSeconds,
            width: widthPercentString,
            totalTime: '',
          };
        }
      }
      case 'UPDATE_LIVE':
        return {
          ...state,
          isLinear: action.value,
          totalTime: action.value === true ? timeFormatted(dateNow()) : '',
        };
      default:
        const _exhaustiveCheck: never = action.type;
        return state;
    }
  };
};

export const reducer = _reducerFactory();
