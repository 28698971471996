import config from '../../config';
import { AccessTokenValue } from '../auth/auth.types';
import { request } from '../utils/request';

import { AssetDetails, SeriesAssetDetails } from './assetDetails.types';
import { mapResponseToAssetDetails } from './mappers';

// TODO use _links from api instead
export const fetchAssetDetails = async (
  id: string,
  accessTokenValue: AccessTokenValue | null | undefined
): Promise<AssetDetails> => {
  const url = config.contentSearchApiUrl;
  const path = `/title/${id}`;

  const response = await request(
    {
      url,
      path,
    },
    accessTokenValue
  );

  return mapResponseToAssetDetails(response);
};

export const fetchAssetDetailsBySeriesId = async (
  seriesId: string,
  accessTokenValue: AccessTokenValue | null | undefined
): Promise<SeriesAssetDetails> => {
  const url = config.contentSearchApiUrl;
  const path = `/coverpage/series/${seriesId}`;

  const response = await request(
    {
      url,
      path,
    },
    accessTokenValue
  );

  return mapResponseToAssetDetails(response) as SeriesAssetDetails;
};
