import { AssetDetails } from '@rikstv/play-common/src/forces/assetDetails/assetDetails.types';
import { Channel } from '@rikstv/play-common/src/forces/channels/channel.types';

const SHOPPER_SESSION_STORAGE_KEY = 'signup-from-asset';

interface AssetInSessionStorage {
  name: string;
  image: string;
  genres: string[];
  recommendedPackageId: string | undefined;
  provider: string;
}

export const persistShopperAssetOnSignup = (asset: AssetDetails | Channel, packageId?: string) => {
  if (!asset || !packageId) {
    window.sessionStorage.removeItem(SHOPPER_SESSION_STORAGE_KEY);
    return;
  }

  let name = asset.name;

  if (asset.type !== 'channelAT') {
    name = asset.metadata.tvSeries?.seriesName || asset.name;
  }

  const sessionStorageAsset: AssetInSessionStorage = {
    name: name,
    image: asset.posterUrl,
    genres: [], // Not used, so not set
    recommendedPackageId: packageId,
    provider: asset.originChannel.serviceName,
  };

  window.sessionStorage.setItem(SHOPPER_SESSION_STORAGE_KEY, JSON.stringify(sessionStorageAsset));
};
