import { createExternalRedirectRoute, createRedirectRoute } from '@rikstv/play-common/src/router/routes.type';
import { sharedStrimUrls, webAppLinks } from '@rikstv/shared-strim-paths';

import { routerPaths } from './router.path';

export const getRedirectRoutes = () => [
  // new url for personvern
  createRedirectRoute('/policy', sharedStrimUrls.personvern, { trackRedirect: true }),

  // common 404's for some reason
  createRedirectRoute('/archive/category/all', '/tv'),
  createRedirectRoute('/movies/category/all', '/filmer'),
  createRedirectRoute('/series/category/all', '/serier'),

  // open pages
  createExternalRedirectRoute('/erfaringer', webAppLinks.strimErfaringer),
  createExternalRedirectRoute('/strim-erfaringer', webAppLinks.strimErfaringer),
  createExternalRedirectRoute('/hva-er-streaming', webAppLinks.hvaErStreaming),

  // ABtest er skrudd av og litt trafikk til denne siden. Sender brukerne videre til Bli kunde
  createRedirectRoute('/tv-pakker', sharedStrimUrls.signup),

  // Add redirects for signup and account url's, also configured (for server side redirects) in s3_website.yml
  createRedirectRoute('/signup/*', routerPaths.signUpPackageSelect, { wildcardKeepPath: true, trackRedirect: true }),
  createRedirectRoute('/account/*', routerPaths.account, { trackRedirect: true }),
  createRedirectRoute('/password/reset/*', routerPaths.resetPassword, { wildcardKeepPath: true, trackRedirect: true }),
  createRedirectRoute('/password/*', routerPaths.changePassword, { trackRedirect: true }),
  createRedirectRoute('/email/*', routerPaths.changeEmail, { trackRedirect: true }),
  createRedirectRoute('/phone-number/*', routerPaths.changePhoneNumber, { trackRedirect: true }),
  createRedirectRoute('/package/change', routerPaths.accountSubscriptionPage, { trackRedirect: true }),

  // Convenience on localhost to avoid 404
  createRedirectRoute('/webapp-auth/*', '/'),
];
