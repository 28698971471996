import { useCallback, useEffect, useReducer } from 'react';

import { UPDATE_CURRENT_TIME } from '../../components/ProgressBar/Actions';
import { VolumeRange } from '../../components/Volume/Volume';
import { language } from '../../language';
import {
  CLEAR_ERROR,
  SET_ENABLED,
  SET_ERROR,
  UPDATE_CAST_STATE,
  UPDATE_MEDIA_INFO,
  UPDATE_META_DATA,
  UPDATE_MUTED,
  UPDATE_PLAYING,
  UPDATE_SESSION_STATE,
  UPDATE_VOLUME,
} from '../cast-actions';
import {
  addCastSessionListener,
  addCastStateListener,
  addOrRemoveCurrentTimeChangedEventHandler,
  addOrRemoveMessageHandler,
  addRemotePlayerControlListener,
  getCastDeviceName,
  getCastPlayerState,
  getCastVolume,
  getCustomMediaInfo,
  getErrorMessageHandler,
  isCastingLinearAsset,
  removeCastSessionListener,
  removeCastStateListener,
  removeRemotePlayerControlListener,
} from '../chromecastApi';
import { useCastContext } from '../useCastContext';

import { getLanguages, MiniControllerState, reducer } from './reducer';

export const useCastMiniControllerReducer = () => {
  const { initialized, connected } = useCastContext();

  const deviceName = getCastDeviceName();
  const { audioLanguages, textlanguages } = getLanguages();
  const initialState: MiniControllerState = {
    assetLink: '',
    audioLanguages,
    currentTime: 0,
    streamStartInSeconds: 0,
    streamEndInSeconds: 0,
    deviceName: deviceName
      ? language.player.casting_to.replace('[DEVICE]', deviceName)
      : language.player.casting_to_init,
    duration: 0,
    enabled: false,
    isDirect: true,
    isLinear: isCastingLinearAsset(),
    muted: false,
    playing: getCastPlayerState() === 'PLAYING',
    posterUrl: '',
    textlanguages,
    title: '',
    volume: getCastVolume() ?? VolumeRange.max,
    error: undefined,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // Setup listeners and state handlers
  const updateMediaInfo = useCallback((castSession: cast.framework.CastSession | null) => {
    const customData = getCustomMediaInfo(castSession);
    if (customData) {
      dispatch({ type: UPDATE_META_DATA, customData });
    }
  }, []);
  useEffect(() => {
    const sessionHandler = (event: cast.framework.SessionStateEventData) => {
      dispatch({ type: UPDATE_SESSION_STATE, sessionState: event.sessionState });
      updateMediaInfo(event.session);
    };

    const castStateHandler = (event: cast.framework.CastStateEventData) => {
      dispatch({ type: UPDATE_CAST_STATE, castState: event.castState });
      dispatch({ type: CLEAR_ERROR });
    };

    const playerState = (event: cast.framework.RemotePlayerChangedEvent) => {
      dispatch({ type: UPDATE_PLAYING, playerState: event.value });
      dispatch({ type: CLEAR_ERROR });
    };

    const mediaInfoHandler = (event: cast.framework.RemotePlayerChangedEvent) =>
      dispatch({ type: UPDATE_MEDIA_INFO, mediaInfo: event.value });

    const volumeHandler = (event: cast.framework.RemotePlayerChangedEvent) =>
      dispatch({ type: UPDATE_VOLUME, volume: event.value });

    const muteHandler = (event: cast.framework.RemotePlayerChangedEvent) =>
      dispatch({ type: UPDATE_MUTED, muted: event.value });

    const currentTimeChangedHandler = (e: { value: number }) => {
      dispatch({ type: UPDATE_CURRENT_TIME, currentTime: e.value });
    };

    if (initialized) {
      addCastSessionListener(sessionHandler);
      addCastStateListener(castStateHandler);
      addRemotePlayerControlListener(cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED, playerState);
      addRemotePlayerControlListener(cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED, mediaInfoHandler);
      addRemotePlayerControlListener(cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED, volumeHandler);
      addRemotePlayerControlListener(cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED, muteHandler);
      addOrRemoveCurrentTimeChangedEventHandler('addEventListener', currentTimeChangedHandler);
    }

    if (connected) {
      dispatch({ type: SET_ENABLED });
      updateMediaInfo(null);
    }

    return () => {
      if (initialized) {
        removeCastSessionListener(sessionHandler);
        removeCastStateListener(castStateHandler);
        removeRemotePlayerControlListener(cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED, playerState);
        removeRemotePlayerControlListener(cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED, mediaInfoHandler);
        removeRemotePlayerControlListener(cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED, volumeHandler);
        removeRemotePlayerControlListener(cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED, muteHandler);
        addOrRemoveCurrentTimeChangedEventHandler('removeEventListener', currentTimeChangedHandler);
      }
    };
  }, [connected, initialized, updateMediaInfo]);

  // Setup error handling
  const errorHandler = getErrorMessageHandler(e =>
    dispatch({ type: SET_ERROR, errorMessage: `Chromecast error: ${e}` })
  );
  useEffect(() => {
    if (initialized && state.castState === 'CONNECTED') {
      addOrRemoveMessageHandler('addMessageListener', errorHandler);
    }
    return () => {
      initialized && addOrRemoveMessageHandler('removeMessageListener', errorHandler);
    };
  }, [errorHandler, state.castState, initialized]);

  return [state, dispatch] as const;
};
