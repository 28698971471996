import { useCallback, useEffect, useState } from 'react';

import config from '../../config';
import { request } from '../../forces/utils/request';
import { useDebouncedValue } from '../../hooks/useDebouncedValue';
import { authService } from '../../utils/auth/AuthService';
import { errorTracker } from '../../utils/errorTracker/tracking';

import { CollectionPage } from './types';

const IGNORED_PAGES: readonly RegExp[] = [/^mitt-innhold$/i, /^mitt-innhold-rikstv$/i, /^person-/i];
const isIgnored = (slug: string) => IGNORED_PAGES.some(s => s.test(slug));

export type ItemCountReporterFunction = (swimlaneId: string, swimlaneItemCount: number) => void;

export const useEmptyCollectionReporting = (page?: CollectionPage) => {
  const [swimlaneItemCountMap, setSwimlaneContentMap] = useState<Record<string, number>>({});
  const reportSwimlaneItemCount = useCallback<ItemCountReporterFunction>((id, itemCount) => {
    setSwimlaneContentMap(previous => ({ ...previous, ...{ [id]: itemCount } }));
  }, []);

  // Debounce the map to ensure that all swimlane-loading have completed
  const debouncedIteCountMap = useDebouncedValue(swimlaneItemCountMap, 4000);

  useEffect(() => {
    if (!page || isIgnored(page.slug) || authService.isInternalUser()) {
      return;
    }
    if (page.swimlanes.length !== Object.keys(debouncedIteCountMap).length) {
      return;
    }
    if (Object.values(debouncedIteCountMap).every(number => number === 0)) {
      reportPageIfEmpty(page);
    }
  }, [debouncedIteCountMap, page]);

  return { reportSwimlaneItemCount };
};

export const reportPageIfEmpty = async (page: CollectionPage) => {
  // extra check here that entire page is empty
  // get all swimlanes in parallel and check item length
  const swimlaneRequests = page.swimlanes.map(s =>
    request<RiksTV.ClientAPI.SwimlaneResponse>({ url: s.link }, authService.getToken().value)
  );
  Promise.all(swimlaneRequests)
    .catch(_ => null) // on any error, return empty array
    .then(results => {
      if (!results || results.some(s => s.length !== 0)) {
        return;
      }
      const postBody = {
        url: location.href,
        dvtPageUrl: `https://desking.rikstv.no/page/${page.id}`,
      };

      // Report to sentry. Could use alert in sentry to post to slack as well and have it trigger only every 30 minutes
      // But that is an improvement that we can fix is this gets too noisy.
      // In that case, add the page id to the message to get one issue per page.
      errorTracker.logMessage('Empty collection/page', {
        tags: { dvtPageUrl: postBody.dvtPageUrl },
        fingerprint: ['empty-page-' + page.id],
      });
      if (!config.isDevelopment) {
        fetch('https://hooks.slack.com/workflows/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', {
          method: 'POST',
          body: JSON.stringify(postBody),
        });
      }
    });
};
