import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import { IconButton } from '@rikstv/play-common/src/components/button/IconButton';
import { useClickOutsideListener } from '@rikstv/play-common/src/hooks/useClickOutsideListener';
import type { IconComponentType } from '@rikstv/play-common/src/icons/svgToIconElement';
import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';

import { MenuButtonContext } from './MenuButtonContext';

import styles from './Menu.module.css';

interface Props {
  id: string;
  icon: IconComponentType;
  ariaLabel: string;
  title?: string;
  className?: string;
  onClick?: () => void;
}

const MenuButton: FCC<Props> = ({ children, id, icon, ariaLabel, title, className, onClick }) => {
  const [show, setShow] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  // TODO: when button takes a ref, move the ref to button and fix in MenuColumn
  const btnRef = useRef<HTMLDivElement>(null);

  const onClickOutsideOfMenu = useCallback(
    (e: MouseEvent) => {
      setShow(false);
      e.stopImmediatePropagation();
    },
    [setShow]
  );

  useClickOutsideListener({ ref: menuRef, callback: onClickOutsideOfMenu, condition: show });

  const handleClick = () => {
    onClick?.();
    setShow(!show);
  };
  const hideContextMenu = () => setShow(false);

  return (
    <MenuButtonContext.Provider value={{ hideContextMenu, btnRef }}>
      <div ref={btnRef}>
        <IconButton
          id={id}
          className={classNames(className, { [styles.menuButtonOpen]: show })}
          buttonStyle="tertiary"
          selected={show}
          onClick={e => (e.stopPropagation(), handleClick())}
          icon={icon}
          ariaLabel={ariaLabel}
          title={title}
        />
      </div>
      {show && (
        <div className={styles.menu} ref={menuRef}>
          {children}
        </div>
      )}
    </MenuButtonContext.Provider>
  );
};

export { MenuButton };
