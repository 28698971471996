import { playbackActions } from '@rikstv/play-player/src/forces/player.slice';
import { selectCurrentPlayable } from '@rikstv/play-player/src/forces/selectors';
import { Playable } from '@rikstv/play-player/src/utils/playable';

import { captureExceptionInSentry } from '../../utils/errorTracker/tracking';
import { getLogger } from '../../utils/logger/logger';
import { Deferred } from '../../utils/promises/Deferred';
import { isOneOf } from '../../utils/types/typeUtils';
import { loginSuccess } from '../auth/auth.slice';
import { CommonMiddleware } from '../root.reducer';
import { AssetTypes } from '../root.types';

import { wssConnect, wssLogger, wssSubscribe } from './api';
import {
  ClientToServerEvents,
  MediaPlayerState,
  MediaPlayerStateLiveChannel,
  Socket,
  StreamingState,
} from './webSocket.types';

const defaultDomain = 'rikstv';

const logger = getLogger('[wss-middleware]');
const socketDeferred = new Deferred<Socket>();

export const webSocketMiddleware: CommonMiddleware = store => next => action => {
  // Handle connect socket on login
  if (loginSuccess.match(action)) {
    // Connect to WSS and subscribe to events
    // WssConnect returns singleton
    wssConnect(action.payload.value)
      .then(socket => {
        logger.info('got socket');
        if (!socket.hasListeners('disconnect')) {
          logger.info('subscribing to events');
          wssSubscribe(socket, store.dispatch);
          socket.onAny((event, ...args) => logger.info('socket.on', { event, args }));
          socketDeferred.resolve(socket);
        }
      })
      .catch(e => {
        captureExceptionInSentry(e, { fingerprint: ['wss-connect'], tags: { type: 'WebSocketServer' } });
        wssLogger.error(e);
      });
  } else if (playbackActions.onPlayerStateEvent.match(action)) {
    const {
      payload: { manifestUrl, domain = defaultDomain, state },
    } = action;
    if (manifestUrl) {
      const playable = selectCurrentPlayable(store.getState());
      const channelId = getChannelIdForLiveChannel(playable);
      const currentDateTimeISOString = action.payload.currentDateTime?.toISOString();

      if (isOneOf(state, ['start', 'resume'])) {
        deferredSocketEmit('StreamingStart', { domain });
        deferredSocketEmit(
          'MediaPlayerState',
          streamingState(manifestUrl, 'PLAY', channelId, currentDateTimeISOString)
        );
      } else if (isOneOf(state, ['stop', 'pause'])) {
        deferredSocketEmit('StreamingStop', { domain });
        deferredSocketEmit(
          'MediaPlayerState',
          streamingState(manifestUrl, 'STOP', channelId, currentDateTimeISOString)
        );
      } else if (state === 'seeked') {
        deferredSocketEmit(
          'MediaPlayerState',
          streamingState(manifestUrl, 'SETPOSITION', channelId, currentDateTimeISOString)
        );
      }
    }
  } else if (playbackActions.onManifestError.match(action)) {
    deferredSocketEmit('ManifestError', {
      Stage: action.payload.stage,
      failedUrl: action.payload.src,
      manifestUrl: action.payload.manifestUrl,
      secondaryManifestUrl: action.payload.secondaryManifestUrl,
    });
  }

  return next(action);
};

const deferredSocketEmit = <T extends keyof ClientToServerEvents>(
  event: T,
  ...data: Parameters<ClientToServerEvents[T]>
) =>
  socketDeferred.then(socket => {
    logger.info('emit', { event }, ...data);
    socket.emit(event, ...data);
  });

const streamingState = (
  manifestUrl: string,
  state: StreamingState,
  channelId?: string,
  positionDateTime?: string
): MediaPlayerState | MediaPlayerStateLiveChannel => {
  return {
    url: manifestUrl,
    state: state,
    channel_id: channelId,
    positionDateTime,
  };
};

const getChannelIdForLiveChannel = (playable: Playable | null | undefined) => {
  if (playable?.type === AssetTypes.CHANNEL) {
    return playable.originChannel.channelId.toString();
  }
};
