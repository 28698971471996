import { FC, MouseEvent as ReactMouseEvent, useState } from 'react';
import ResponsiveModal from 'react-responsive-modal';

import { H1, SecondaryButton } from '@rikstv/shared-components';

import { SwimlaneItem } from '../../../forces/assetDetails/assetDetails.types';
import { getImagePackSet } from '../../../utils/imagePack/imagePack.utils';
import { AssetImage } from '../../assetImage/AssetImage';
import { AssetImageContainer } from '../../assetImage/AssetImageContainer';
import { Breakpoints } from '../../grid/breakpoints';
import { Swimlane } from '../../pages/types';
import { ProviderImageWrapper } from '../../providerImage';
import { RemoveFromContinueWatching } from '../removeFromContinueWatching/removeFromContinueWatching';
import { useRemoveFromSwimlaneCache } from '../utils/useRemoveFromSwimlaneCache';

import 'react-responsive-modal/styles.css';
import styles from './ContextMenu.module.css';

interface Props {
  asset: SwimlaneItem;
  swimlane: Swimlane;
  ariaLabel?: string;
  setOptimisticFilter?: (filterToAppend: string) => void;
}

export const ContextMenu: FC<Props> = ({ asset, swimlane, ariaLabel, setOptimisticFilter }) => {
  // The modal mounts outside root context by default,
  // that loses the BrandProvider, so in order to get the correct
  // brand we'll mount it inside that container
  const container = document.querySelector('.rtv, .strm');
  const {
    links: { excludeFromContinueWatchingUrl },
  } = asset;

  const showContextMenu = swimlane.type === 'ContinueWatching';
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const removeFromCache = useRemoveFromSwimlaneCache(swimlane.link);

  // removeFromCache will delete asset from swr cache, but as this is rendered as modal above
  // the page with swimlanes, we optimistically filter out the item before the cache is
  // revalidated. This ensures this action is immediate and not rely on a rerender or other
  const removeFromCacheAndSwimlane = () => {
    removeFromCache();
    setOptimisticFilter?.(asset.id);
  };

  const toggleMenu = (event: ReactMouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => setIsMenuOpen(false);

  const preventClickBubbling = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  if (!showContextMenu) {
    return null;
  }
  //with-keyboard-focus
  return (
    <div>
      <div className={styles.contextMenu}>
        <button
          type="button"
          className={styles.contextMenuTrigger}
          onClick={toggleMenu}
          aria-label={ariaLabel}
          data-context-menu>
          <div className={styles.contextMenuIcon} />
        </button>
      </div>
      <ResponsiveModal
        container={container}
        classNames={{ modal: styles.contextMenuModal }}
        open={isMenuOpen}
        onClose={closeMenu}
        onOverlayClick={toggleMenu}
        center
        blockScroll={false}
        showCloseIcon={false}>
        {/* This weird component stops clicks bubbling, performing unwanted navigation event */}
        <div role="none" tabIndex={-1} className={styles.contextMenuModalClickArea} onClick={preventClickBubbling}>
          <AssetImageContainer rounded={false}>
            <AssetImage
              imageSet={getImagePackSet({
                url: asset.image.url,
                variations: [{ width: 460, height: 258, breakpoint: Breakpoints.DEFAULT }],
              })}
              alt=""
            />
            <ProviderImageWrapper logos={asset.logos} />
          </AssetImageContainer>
          <div className={styles.contextMenuModalContent}>
            <H1 lookLike="title-2">{asset.title}</H1>
            <div>
              <RemoveFromContinueWatching
                closeMenu={toggleMenu}
                url={excludeFromContinueWatchingUrl!}
                removeFromCache={removeFromCacheAndSwimlane}
              />
            </div>
            <div>
              <SecondaryButton type="button" onClick={toggleMenu}>
                Lukk
              </SecondaryButton>
            </div>
          </div>
        </div>
      </ResponsiveModal>
    </div>
  );
};
