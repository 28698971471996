import config from '@rikstv/play-common/src/config';

const {
  player: { liveBufferInSeconds },
} = config;

export const calculateLiveTime = (progress: number, currentTime: number = +dateNow() / 1000) =>
  currentTime - liveBufferInSeconds * (1 - progress);

export const calculateVodTime = (duration: number, progress: number) => duration * progress;

export const percentage = (pageX: number, offsetLeft: number, width: number): number => (pageX - offsetLeft) / width;

export const calculateProgressWidthPercent = (
  streamStartSeconds: number,
  streamEndSeconds: number,
  currentTime: number
) => {
  const timeRemainingInSeconds = streamEndSeconds - currentTime;
  const duration = streamEndSeconds - streamStartSeconds;
  const roundOffTime = duration > 300 && timeRemainingInSeconds <= 20;

  // If you are less than 20s from the end and total duration is over 5 minutes, we show the progress bar as being right at the end
  const currentTimeRounded = roundOffTime ? 0 : timeRemainingInSeconds;
  return duration <= 0 ? 0 : ((1 - currentTimeRounded / duration) * 100).toFixed(2);
};

export const dateNow = (time?: number | string) => (time ? new Date(time) : new Date());

export const getRect = (ref: React.RefObject<HTMLElement>) => {
  if (!ref.current) {
    return {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
      x: 0,
      y: 0,
    };
  }

  const rect = ref.current.getBoundingClientRect();
  return {
    bottom: rect.bottom,
    height: rect.height,
    left: rect.left,
    right: rect.right,
    top: rect.top,
    width: rect.width,
    x: rect.x,
    y: rect.y,
  };
};
