import { useCallback, useRef } from 'react';

import { letUserAgentHandleEvent } from '@rikstv/play-common/src/utils/events/event.utils';

import heroStyles from './HeroListScroller.module.css';
import styles from './ListScroller.module.css';

const Left = 'ArrowLeft';
const Right = 'ArrowRight';
const Tab = 'Tab';
const lastFocusAttribute = 'data-previous-focus';
const swimlaneWrapperSelector = '[data-swimlane-wrapper]';
const ctxMenuSelector = '[data-context-menu]';
const focusableElSelector = ['a', 'button', 'input', 'select', '[tabindex]']
  .map(el => `${el}:not([disabled]):not([tabindex="-1"])`)
  .join(', ');

export const useRovingIndex = () => {
  const shouldHandleFocus = useRef(true);

  const onFocusCallback = useCallback((event: React.FocusEvent<HTMLUListElement, Element>) => {
    if (shouldHandleFocus.current) {
      onFocus(event);
    }
  }, []);
  const onMouseDownCallback = useCallback(() => {
    // Disable focus handling on mouse clicks since that is not what it is designed for
    // it only works well for when getting focus via keyboard navigation
    shouldHandleFocus.current = false;
    // reset via timeout since onMouseUp might not always dispatch (right click + ctx menu for instance)
    setTimeout(() => {
      shouldHandleFocus.current = true;
    }, 500);
  }, []);

  return {
    onFocus: onFocusCallback,
    onMouseDown: onMouseDownCallback,
    onKeyDown,
  };
};

function onKeyDown(event: React.KeyboardEvent<HTMLUListElement>, userInteracted?: () => void) {
  if (letUserAgentHandleEvent(event)) {
    return;
  }

  const { key, target, shiftKey } = event;

  //If keyDown != left or right arrow key or the target isnt an html element, make an early return
  if (![Left, Right, Tab].includes(key) || !(target instanceof HTMLElement)) {
    return;
  }

  if ([Left, Right].includes(key)) {
    //target the closest swimlaneItem

    const parent = target.closest(`.${styles.scrollerItem}, .${heroStyles.scrollerItem}`);

    if (parent) {
      //See if the parent element has an context menu
      const contextMenu = parent.querySelector(ctxMenuSelector);
      //If the current focus isn't already an context menu and user pressed right, focus on context menu
      if (key === Right && contextMenu instanceof HTMLElement && document.activeElement !== contextMenu) {
        contextMenu.focus();
        event.preventDefault();
        return;
      }

      //if  right arrowkey was pressed, select next swimlaneItem. Otherwise select previous
      const next = key === Right ? parent.nextElementSibling : parent.previousElementSibling;
      //Target the anchor element within the swimlaneItem
      const nextElement = next?.querySelector('a');
      if (nextElement) {
        nextElement.focus();
        userInteracted?.();
        //Check the swimlane for a elements and remove any data-previous-focus attribute
        parent.querySelectorAll('a').forEach(a => a.removeAttribute(lastFocusAttribute));
        //Set the focus attribute on the element we now have in focus
        nextElement.setAttribute(lastFocusAttribute, 'true');
        event.preventDefault();
      }
    }
  }
  // Tab to next/previous swimlane/scroller - or next/previous tabbable element
  if (key === Tab) {
    // Get the swimlane container
    const parent = target.closest(swimlaneWrapperSelector);
    if (!parent) {
      return;
    }

    // Try-get "all" focusable elements on page and inside parent
    const focusableInParent = getFocusableElements(parent);
    const allFocusable = getFocusableElements(document);

    // 1. figure out the tabbable elements that
    // 1.1 come just before swimlane's first el (shift key pressed)
    // 1.2 come just after swimlane's last el (no shift key pressed)
    // 2. => focus on that element
    const firstOrLastElInParent = focusableInParent.at(shiftKey ? 0 : -1);
    const nextEl =
      firstOrLastElInParent && allFocusable[allFocusable.indexOf(firstOrLastElInParent) + (shiftKey ? -1 : 1)];

    if (nextEl instanceof HTMLElement) {
      event.preventDefault();
      nextEl.focus();
    }
  }
}

// Override onFocus-event and place focus on the "last focused" element
const onFocus = (event: React.FocusEvent<HTMLUListElement, Element>) => {
  const { target, relatedTarget } = event;
  if (!(target instanceof HTMLElement)) {
    return;
  }
  const parent = target.closest(swimlaneWrapperSelector);
  if (!parent) {
    return;
  }
  const focusableInParent = getFocusableElements(parent);
  // Early return when last focused element is a sibling
  if (relatedTarget && focusableInParent.includes(relatedTarget)) {
    return;
  }
  // Set focus to last focused element, or default to first element in list
  const elementToFocus =
    parent.querySelector(`[${lastFocusAttribute}]`) ??
    parent.querySelector(`[aria-current]`) ??
    focusableInParent.at(0);
  if (elementToFocus instanceof HTMLElement) {
    event.preventDefault();
    focusableInParent.forEach(e => e.removeAttribute(lastFocusAttribute));
    elementToFocus.setAttribute(lastFocusAttribute, 'true');
    elementToFocus.focus();
  }
};

const getFocusableElements = (root: ParentNode) =>
  Array.from(root.querySelectorAll(focusableElSelector)).filter(element => {
    //check for visibility and always include the current activeElement
    if (element instanceof HTMLElement) {
      return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement;
    }
    return false;
  });
