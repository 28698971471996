import { FC } from 'react';

import { PadlockIcon } from '../../icons';
import type { IconSize } from '../../icons/svgToIconElement';

import { ImageOverlayWrapper } from './ImageOverlayWrapper';

interface Props {
  iconSize?: IconSize;
  label?: string;
  /**
   * Overrides `iconSize` when set
   */
  iconHeight?: number;
}
export const PadlockOverlay: FC<Props> = ({ iconSize = 'large', iconHeight, label }) => {
  const ariaLabel = label ? label : 'Du har ikke tilgang til denne tilbyderen';
  const size = iconHeight ? { height: `${iconHeight}px`, width: 'auto' } : {};
  return (
    <ImageOverlayWrapper label={label} aria-label={ariaLabel} centerContent backdropStyle="dark">
      <PadlockIcon
        iconSize={iconSize}
        style={{ marginBottom: '.5rem', color: 'var(--rds-foreground-primary)', ...size }}
      />
    </ImageOverlayWrapper>
  );
};
