import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dropdown, Gutter } from '@rikstv/shared-components';

import { ChevronDownLightIcon, SortingIcon } from '../../icons';
import { commonRoutes } from '../../router/router.path';
import { FadeIn } from '../animation/fade-in/FadeIn';
import { GeneralErrorBoundary } from '../errorBoundary/GeneralErrorBoundary';
import { SwimlaneTrackingData, useReportSwimlaneClick } from '../swimlane/coreScrollSwimlane/useImpressionTracking';
import { ListScrollerSection } from '../swimlane/listScroller/ListScroller';
import { MenuSwimlaneItem } from '../swimlane/styles/MenuSwimlaneItem';

import { useSlug } from './internal/useSlug';
import { ExplorationPage } from './types';

const getLink = (link: string, isCollection: boolean) =>
  isCollection ? commonRoutes.collections({ slug: link.split('/').pop() ?? '' }) : `/${link.split('/').pop()}`;

export const PageFilterMenu: FC<{ page: ExplorationPage }> = ({ page }) => {
  const { reportFilterClick } = useFilterTracking(page);
  const navigate = useNavigate();
  const { isCollection } = useSlug();

  const selectedOption =
    page.sortMenus?.find(a => a.isDefaultSelected)?.link ??
    page.sortMenus?.find(a => a.title === 'Populært')?.link ??
    '';
  const defaultSelectedIndex = page.filterMenus.map(a => a.isDefaultSelected).indexOf(true);

  return (
    <GeneralErrorBoundary>
      <FadeIn>
        <ListScrollerSection
          // Use key to force re-render ListScroller on page change (between film/series)
          // When no re-render the "scroll to initial element" never triggers, keeping the previous page's scroll position in the list
          key={'filter-menu-' + page.id}
          noPaddingBottom
          hideSectionTitle
          swimlane={{
            id: 'filter-menu-' + page.id,
            name: `Filtreringsalternativ for ${page.title}`,
            style: 'MenuText',
          }}
          defaultSelectedIndex={defaultSelectedIndex}>
          {page.filterMenus.map((item, idx) => {
            const { link, isDefaultSelected, title } = item;

            return (
              <MenuSwimlaneItem
                key={link}
                href={getLink(link, isCollection)}
                style={'MenuText'}
                name={title}
                selected={isDefaultSelected}
                onClick={() => {
                  reportFilterClick({ id: item.tracking.elementId, name: item.tracking.elementName }, idx);
                }}
              />
            );
          })}
        </ListScrollerSection>
        {page.sortMenus && (
          <Gutter style={{ display: 'flex', justifyContent: 'flex-end', marginBlock: '-26px' }}>
            <Dropdown
              label={<span className="sr-only">Endre sortering</span>}
              options={page.sortMenus.map(a => ({
                title: a.title,
                value: a.link,
                selected: selectedOption === a.link,
              }))}
              id={'dd-sorting-for-' + page.id}
              onChange={({ value }) => navigate(getLink(value, isCollection))}
              rightIcon={<ChevronDownLightIcon iconSize={18} style={{ marginTop: '4px' }} />}
              leftIcon={<SortingIcon iconSize={22} style={{ marginTop: '2px' }} />}
            />
          </Gutter>
        )}
      </FadeIn>
    </GeneralErrorBoundary>
  );
};

const useFilterTracking = (page: ExplorationPage) => {
  const swimlaneAnalogue: SwimlaneTrackingData = {
    name: `Filter ${page.title}`,
    id: 'filterMenu',
    type: 'Default',
    style: 'MenuText',
  };

  const { reportSwimlaneClick: reportFilterClick } = useReportSwimlaneClick(swimlaneAnalogue);
  return { reportFilterClick };
};
