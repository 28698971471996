import type { SubscriptionsTypes } from '@strim/gateway-api';

import { webAppLinks } from '@rikstv/shared-strim-paths';

export type PackageKeys = SubscriptionsTypes.Ids;

type PackageRecord<T = string> = Record<PackageKeys, T>;

export const packageIds: PackageRecord<PackageKeys> = {
  STRIM_MINI: 'STRIM_MINI',
  STRIM_FILM_OG_SERIER: 'STRIM_FILM_OG_SERIER',
  STRIM_FILM_OG_SERIER_SPORT: 'STRIM_FILM_OG_SERIER_SPORT',
  STRIM_FILM_OG_SERIER_SPORT_2B: 'STRIM_FILM_OG_SERIER_SPORT_2B',
  STRIM_FAMILIE: 'STRIM_FAMILIE',
  STRIM_FAMILIE_SPORT: 'STRIM_FAMILIE_SPORT',
  STRIM_FAMILIE_SPORT_2B: 'STRIM_FAMILIE_SPORT_2B',
  STRIM_FILM_OG_SERIER_SPORT_VIAPLAY_TOTAL: 'STRIM_FILM_OG_SERIER_SPORT_VIAPLAY_TOTAL',
  STRIM_FAMILIE_SPORT_VIAPLAY_TOTAL: 'STRIM_FAMILIE_SPORT_VIAPLAY_TOTAL',
  STRIM_FILM_OG_SERIER_SPORTSKANALER: 'STRIM_FILM_OG_SERIER_SPORTSKANALER',
  STRIM_FAMILIE_SPORTSKANALER: 'STRIM_FAMILIE_SPORTSKANALER',
} as const;

export const PACKAGE_URLS: PackageRecord = {
  // regular
  STRIM_MINI: webAppLinks.subscriptionStrimLitt,
  STRIM_FILM_OG_SERIER: webAppLinks.subscriptionStrimMye,
  STRIM_FAMILIE: webAppLinks.subscriptionStrimMest,
  // sport
  STRIM_FILM_OG_SERIER_SPORT: webAppLinks.subscriptionStrimMyeSport,
  STRIM_FAMILIE_SPORT: webAppLinks.subscriptionStrimMestSport,
  // sport premium
  STRIM_FILM_OG_SERIER_SPORT_VIAPLAY_TOTAL: webAppLinks.subscriptionStrimMyeSportPremium,
  STRIM_FAMILIE_SPORT_VIAPLAY_TOTAL: webAppLinks.subscriptionStrimMestSportPremium,
  // sport 2B / viaplay total
  STRIM_FILM_OG_SERIER_SPORT_2B: webAppLinks.subscriptionStrimMyeViaplayTotal,
  STRIM_FAMILIE_SPORT_2B: webAppLinks.subscriptionStrimMestViaplayTotal,

  // sportskanaler
  STRIM_FILM_OG_SERIER_SPORTSKANALER: webAppLinks.subscriptionStrimMyeSportskanaler,
  STRIM_FAMILIE_SPORTSKANALER: webAppLinks.subscriptionStrimMestSportskanaler,
} as const;

// https://bitbucket.org/rikstv-prod/rikstv.api.staticfiles/src/master/site/logopack/
// NB! Order in this array is used to sort channels
export const channelOrderPriority: readonly string[] = [
  'TV2 HD',
  'TVNorge HD',
  'TV3',
  'Viasat 4',
  'TV2 Nyhetskanalen',
  'MAX',
  'FEM',
  'BBC World News',
  'TV6',
  'VOX',
  'TV2 Zebra',
  'TV2 Livsstil',
  'TLC Norge',
  'Discovery Science',
  'Animal Planet',
  'Investigation Discovery',
  'Discovery Channel',
  'BBCEarth',
  'BBC Brit',
  'CNN',
  'History',
  'National Geographic Channel',
  'Travel Channel',
  'VH1', // MTV00s is still marked as VH1 as name (SourceName)
  'Disney',
  'Disney Junior',
  'Nickelodeon',
  'Nick Jr.',
  'Food Networks',
  'Matkanalen',
  'Norway Live',
  'Heim TV',
  'Auto Motor & Sport TV',
  'Fatstone',
  // SPORT
  'TV2 Sport 1',
  'TV2 Sport 2',
  'Eurosport Norge',
  'Eurosport',
  'Viasport+',
  'Viasport 1',
  'Viasport 2',
  'Viasport 3',
  // PROVIDER
  'Viaplay Total',
  'TV 2 Play ChampionsLeague LaLiga Sport FilmSerier',
  'HBO Max',
  'Viaplay',
  'TV2 Play Film og serier',
  'TV2 Play Film og serier med reklame',
  'SkyShowtime',
  'Nordisk Film+',
];
