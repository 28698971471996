import { CoverPagePaths } from '../components/coverPage/coverpage.types';
import { toUrlFriendlyString } from '../utils/url/url.utils';

import { replaceParameters } from './router.utils';

const commonRouterPaths = {
  watchPattern: '/watch/:type/:id',
  watchPatternWithName: '/watch/:type/:id/:name',
  watchTvSeries: `/watch/${CoverPagePaths.SERIES}/:id`,
  watchVodAsset: `/watch/${CoverPagePaths.VOD}/:id`,
  singleProgramOrMovieWithTitle: `/${CoverPagePaths.STACKED_MOVIE}/:id/:title`,
  singleProgramOrMovie: `/${CoverPagePaths.STACKED_MOVIE}/:id`,
  stackedSeriesWithTitle: `/${CoverPagePaths.STACKED_SERIES}/:id/:title`,
  stackedSeries: `/${CoverPagePaths.STACKED_SERIES}/:id`,
  watchLiveAsset: `/watch/${CoverPagePaths.LIVE}/:id`,
  watchLiveAssetWithName: `/watch/${CoverPagePaths.LIVE}/:id/:name`,
  settings: '/innstillinger',
  search: '/search',
  collections: '/tema/:slug',
  swimlanePage: '/tema/:slug/liste/:swimlaneId',
  tvGuideWithDate: '/tv-guide/:date',
  tvGuide: '/tv-guide',
  frontpage: '/',
} as const;

const commonRoutes = {
  watchLive: (channelId: number | string, channelName?: string) => {
    if (channelName) {
      return replaceParameters({
        path: commonRouterPaths.watchLiveAssetWithName,
        parameters: {
          id: channelId.toString(),
          name: toUrlFriendlyString(channelName),
        },
      });
    }
    return replaceParameters({
      path: commonRouterPaths.watchLiveAsset,
      parameters: {
        id: channelId.toString(),
      },
    });
  },
  watchAsset: ({ id, disableScpRedirect }: { id: string; disableScpRedirect?: boolean }) => {
    return replaceParameters({
      path: commonRouterPaths.watchVodAsset,
      parameters: { id },
      qs: { disableScpRedirect: disableScpRedirect ? '' : undefined },
    });
  },
  stackedSeriesOrMovie: ({
    id,
    title,
    isSeries,
    autoPlay = false,
  }: {
    id: string;
    title?: string;
    isSeries: boolean;
    autoPlay?: boolean;
  }) => {
    // - Replace white space with '-'
    // - Remove '.', ':', '"' and '--' (two or more)
    const titleWithoutEpisodeNumber = title?.replace(/(^(S\d{1,2}E\d{1,3}:)|(\d{1,3}\.))[\s–]+/i, '');
    const sanitizedTitle =
      titleWithoutEpisodeNumber &&
      encodeURIComponent(titleWithoutEpisodeNumber.replace(/\s/g, '-').replace(/\.|:|\"|-{2,}/g, ''));
    if (sanitizedTitle) {
      return replaceParameters({
        path: isSeries ? commonRouterPaths.stackedSeriesWithTitle : commonRouterPaths.singleProgramOrMovieWithTitle,
        parameters: {
          id,
          title: sanitizedTitle,
        },
        qs: autoPlay ? { autoPlay: '1' } : undefined,
      });
    }
    return replaceParameters({
      path: isSeries ? commonRouterPaths.stackedSeries : commonRouterPaths.singleProgramOrMovie,
      parameters: { id },
      qs: autoPlay ? { autoPlay: '1' } : undefined,
    });
  },
  watchTvSeries: (id: string, disableScpRedirect?: boolean) =>
    replaceParameters({
      path: commonRouterPaths.watchTvSeries,
      parameters: { id },
      qs: { disableScpRedirect: disableScpRedirect ? '' : undefined },
    }),
  settings: () => commonRouterPaths.settings,
  search: () => commonRouterPaths.search,
  tvGuideRoute: (parameters?: { date: string }) => {
    if (parameters) {
      return replaceParameters({
        path: commonRouterPaths.tvGuideWithDate,
        parameters,
      });
    }
    return commonRouterPaths.tvGuide;
  },
  collections: (parameters: { slug: string }) =>
    replaceParameters({
      path: commonRouterPaths.collections,
      parameters,
    }),
  swimlane: (parameters: { slug: string; swimlaneId: string }) =>
    replaceParameters({
      path: commonRouterPaths.swimlanePage,
      parameters,
    }),
  frontpage: () => commonRouterPaths.frontpage,
};

export { commonRouterPaths, commonRoutes };
