import React from 'react';

import { ImageSetEntry } from '../../utils/imagePack/imagePack.utils';
import { Breakpoints } from '../grid/breakpoints';

import { ImageFade } from './ImageFade';

export interface Props {
  className?: string;
  imageSet: ImageSetEntry[];
  alt: string | React.ReactElement;
}

const fallbackImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const ResponsiveImageFade: React.FunctionComponent<Props> = ({ className, imageSet }) => {
  const sortedImgSet = sortOnBreakpoints(imageSet);
  return (
    <picture>
      {sortedImgSet.map(entry => (
        <source
          key={entry.breakpoint}
          media={entry.breakpoint !== 0 ? `(min-width: ${entry.breakpoint}px)` : undefined}
          srcSet={entry.url}
        />
      ))}
      <ImageFade alt="" srcSet={fallbackImage} className={className} />
    </picture>
  );
};

/**
 * Sort image set on breakpoints descending and set DEFAULT to 0
 * Source selection only works correctly if the sources are arranged from high to low min-width
 * so we must avoid this situation:
 * <picture>
 *  <source /> <- always selected, regardless of width
 *  <source media="(min-width: 768px)" />
 */
const sortOnBreakpoints = (sets: ImageSetEntry[]) => {
  return [...sets]
    .map(a => {
      if (a.breakpoint === Breakpoints.DEFAULT) {
        return { ...a, breakpoint: 0 };
      }
      return a; // sort so higher comes first
    })
    .sort((a, b) => b.breakpoint - a.breakpoint);
};
