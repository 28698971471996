import * as React from 'react';
import classNames from 'classnames';

import { Button } from '@rikstv/play-common/src/components/button/Button';
import { IconButton } from '@rikstv/play-common/src/components/button/IconButton';
import config from '@rikstv/play-common/src/config';
import { useAssetDetails } from '@rikstv/play-common/src/forces/assetDetails/useAssetDetails';
import { Link } from '@rikstv/play-common/src/router/Link';
import { H4, Tag } from '@rikstv/shared-components';

import { MenuButton } from '../../components/Menu/MenuButton';
import { MenuColumn } from '../../components/Menu/MenuColumn';
import { ProgressBarWithTime } from '../../components/ProgressBar/ProgressBar';
import { RoundPlayPauseToggle } from '../../components/RoundPlayPauseToggle/RoundPlayPauseToggle';
import { SkipContent } from '../../components/SkipContent/SkipContent';
import { Spacer } from '../../components/Spacer/Spacer';
import { Volume } from '../../components/Volume/Volume';
import { CastConnectedIcon, LanguageMenuIcon, SeekBackward10Icon, SeekForward10Icon } from '../../icons';
import { language } from '../../language';
import { UPDATE_LANGUAGE, UPDATE_VOLUME } from '../cast-actions';
import {
  mute,
  onSetProgressFromStartBetween0and1,
  openCastDialog,
  playOrPause,
  seek,
  setCastAudioTrack,
  setCastCurrentTime,
  setCastTextTrack,
  setCastVolume,
} from '../chromecastApi';

import { useCastMiniControllerReducer } from './useCastMiniControllerReducer';

import styles from './CastMiniControls.module.css';

export const CastMiniControls: React.FC = () => {
  const [state, dispatch] = useCastMiniControllerReducer();
  const { onGoLive, onSelectText, onSelectAudio, onSetProgress, onChangeVolume } = useMiniControllerEvents(
    state,
    dispatch
  );
  const { data: playable } = useAssetDetails(state.assetId);

  if (state.error) {
    return (
      <div className="chromecast-error-message">
        <div>
          <h1>Oisann!</h1>
          <div>Her ble det dessverre krøll med Chromecast.</div>
          <div className="message">Prøv igjen, eller ta kontakt med kundeservice hvis du trenger hjelp.</div>
        </div>
      </div>
    );
  }
  if (!state.enabled) {
    return null;
  }

  return (
    <div className={classNames(styles.miniController)}>
      <div className={styles.miniRow}>
        {state.posterUrl && (
          <Link
            to={state.assetLink ?? ''}
            className={classNames(styles.miniPoster, { [styles.channelLogo]: state.isLinear })}>
            <img src={state.posterUrl} alt="" />
          </Link>
        )}
        <Link to={state.assetLink} className={classNames(styles.miniColumn, styles.link)}>
          <Tag className={classNames(styles.text, styles.castText)}>{state.deviceName}</Tag>
          <H4>
            {state.title
              .split('•')
              .reverse()
              .map((text: string, index: number) => (
                <div key={`mini-title-${index}`} className={styles.text}>
                  {text}
                </div>
              ))}
          </H4>
        </Link>
        <div className={styles.miniColumnControls}>
          <div>
            {/* TODO: save progress directly in state */}
            <ProgressBarWithTime
              currentTime={state.currentTime}
              streamStartSeconds={state.streamStartInSeconds}
              streamEndSeconds={state.streamEndInSeconds}
              insideVideoPlayer={false}
              onSetProgressFromStartBetween0and1={onSetProgress}
              isLinear={state.isLinear}
              duration={state.streamEndInSeconds - state.streamStartInSeconds}
              isCasting
            />
          </div>
          <div className={classNames(styles.miniRow, styles.playerButtons)}>
            <RoundPlayPauseToggle paused={!state.playing} onPlayPauseClick={playOrPause} iconSize={'normal'} />
            {playable && (
              <SkipContent
                playable={playable}
                currentTime={state.currentTime}
                setCurrentTime={setCastCurrentTime}
                alwaysVisible={true}
                classes={{ default: styles.skipContentButton, hidden: styles.skipContentButtonHidden, visible: '' }}
              />
            )}
            <IconButton
              icon={SeekBackward10Icon}
              onClick={() => seek(-config.player.seekBackSeconds)}
              ariaLabel={language.player.seekbackward}
              buttonStyle="tertiary"
              className={styles.playerButton}
            />
            <IconButton
              icon={SeekForward10Icon}
              onClick={() => seek(config.player.seekForwardSeconds)}
              ariaLabel={language.player.seekforward}
              buttonStyle="tertiary"
              className={styles.playerButton}
            />
            <div className={styles.volume}>
              <Volume
                muted={state.muted}
                onClick={() => mute()}
                onVolumeChange={(volume: number) => onChangeVolume(volume)}
                volume={state.volume}
              />
            </div>
            <Spacer />
            <MenuButton
              id="mini-controller-language"
              icon={LanguageMenuIcon}
              ariaLabel={language.player.languagesettings}>
              <MenuColumn
                menuItems={state.textlanguages}
                onSelect={lang => onSelectText(lang)}
                title={language.player.subtitles}
              />
              <MenuColumn
                menuItems={state.audioLanguages}
                onSelect={lang => onSelectAudio(lang)}
                title={language.player.audio}
              />
            </MenuButton>
            <IconButton
              icon={CastConnectedIcon}
              onClick={openCastDialog}
              ariaLabel={language.player.chromecast}
              buttonStyle="tertiary"
              className={styles.playerButton}
            />

            {state.isLinear && (
              <>
                <Button
                  className={styles.playerButton}
                  onClick={onGoLive}
                  buttonStyle={state.isDirect ? 'primary' : 'tertiary'}>
                  {language.player.live}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const useMiniControllerEvents = (...[state, dispatch]: ReturnType<typeof useCastMiniControllerReducer>) => {
  const onChangeVolume = (volume: number) => {
    setCastVolume(volume);
    dispatch({ type: UPDATE_VOLUME, volume });
  };

  const onSelectText = (textLanguage: string) => {
    setCastTextTrack(textLanguage);
    dispatch({ type: UPDATE_LANGUAGE, textLanguage });
  };

  const onSelectAudio = (audioLanguage: string) => {
    setCastAudioTrack(audioLanguage);
    dispatch({ type: UPDATE_LANGUAGE, audioLanguage });
  };
  const onSetProgress = (progressFromStartBetween0and1: number) =>
    onSetProgressFromStartBetween0and1(
      progressFromStartBetween0and1,
      state.streamStartInSeconds,
      state.streamEndInSeconds
    );

  const onGoLive = () => onSetProgressFromStartBetween0and1(1, state.streamStartInSeconds, state.streamEndInSeconds);

  return {
    onSetProgress,
    onChangeVolume,
    onSelectText,
    onSelectAudio,
    onGoLive,
  };
};
