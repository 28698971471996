import type { Params } from 'react-router';
import { generatePath } from 'react-router-dom';

import config from '../config';
import { isUrlSameHost } from '../utils/url/url.utils';

export const _isExternal = (url: string, hostname: string) => {
  if (!isUrlSameHost(url, hostname)) {
    return true;
  }
  return config.interAppPaths.some(pathOrRegex => {
    if (pathOrRegex instanceof RegExp) return pathOrRegex.test(url);
    return url.toLowerCase() === pathOrRegex.toLowerCase();
  });
};

const isExternal = (url: string) => _isExternal(url, window.location.hostname);

const isMailTo = (url: string) => /mailto:/i.test(url);

const windowRedirect = (url: string) => (window.location.href = url);

type Dictionary = Record<string, string | string[] | undefined>;
const replaceParameters = ({ path, parameters, qs }: { path: string; parameters: Params<string>; qs?: Dictionary }) => {
  // generate path
  const generatedPath = generatePath(path, parameters);
  // add query string params
  const qp = new URLSearchParams();
  Object.entries(qs ?? {}).forEach(([key, value]) => {
    if (value == null) return;
    (Array.isArray(value) ? value : [value]).forEach(v => qp.append(key, v));
  });
  const search = qp.toString().trim().length ? `?${qp}` : '';

  return generatedPath + search;
};

export { isExternal, isMailTo, replaceParameters, windowRedirect };
