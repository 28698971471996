import { createContext } from 'react';

const CastContext = createContext<{
  initialized: boolean;
  connected: boolean;
}>({
  initialized: false,
  connected: false,
});

export { CastContext };
