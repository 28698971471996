import { useState } from 'react';

import { useIntersectionObserver } from '@rikstv/shared-components';

export const useElementVisible = (options: IntersectionObserverInit = { rootMargin: '150px' }, enabled = true) => {
  const [isVisible, setIsVisible] = useState(false);
  const [targetRef] = useIntersectionObserver(
    items => {
      const isIntersecting = items.some(i => i.isIntersecting);
      setIsVisible(isIntersecting);
    },
    options,
    undefined,
    enabled
  );

  return [targetRef, isVisible] as const;
};
