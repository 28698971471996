import type { Products, SubscriptionsTypes } from '@strim/gateway-api';

import { numericToLetters } from '@rikstv/play-common/src/utils/string-i18n/string.i18n.utils';

import { PriceWithDiscounts, ProductDiscount } from '../../payment/forces/payment.types';
import { GroupedProducts } from '../hooks/useProducts';
import { enrichmentKeyToPackageIdMap } from '../productEnrichments';

type MinimalProduct = Products.MinimalProduct;

type MinimalSubscription = { baseProduct: MinimalProduct; addonProducts: MinimalProduct[] };

type GetProductsNamesShorthand = {
  (baseOrSub: Products.Product[], addonProducts?: never): string;
  (baseOrSub: MinimalSubscription, addonProducts?: never): string;
  (baseOrSub: MinimalProduct, addonProducts: MinimalProduct[]): string;
};

export const getProductsNamesShorthand: GetProductsNamesShorthand = (baseOrSub, addonsOrNever) => {
  let baseProduct: MinimalProduct | undefined;
  let addonProducts: MinimalProduct[] = [];

  if (Array.isArray(baseOrSub)) {
    // Expect only one base product in array
    baseProduct = baseOrSub.find(p => p.baseProduct);
    addonProducts = baseOrSub.filter(p => !p.baseProduct);
  } else if ('addonProducts' in baseOrSub) {
    // Minimal subscription case
    baseProduct = baseOrSub.baseProduct;
    addonProducts = baseOrSub.addonProducts;
  } else {
    baseProduct = baseOrSub;
    addonProducts = addonsOrNever || [];
  }

  if (!baseProduct) {
    return '';
  }

  if (!addonProducts.length) {
    return baseProduct.name;
  }

  if (addonProducts.length === 1) {
    return `${baseProduct.name} + ${addonProducts[0].name}`;
  }

  return `${baseProduct.name} + ${numericToLetters(addonProducts.length)} tilleggspakker`;
};

export const getProductNames = ({ baseProduct, addonProducts }: MinimalSubscription) => {
  const lastProduct = addonProducts.length > 0 ? addonProducts.at(-1) : undefined;
  const middleProducts = addonProducts.length > 1 ? addonProducts.slice(0, -1) : undefined;

  const middleNames = middleProducts ? middleProducts.map(p => p.name).join(', ') : '';
  const lastName = lastProduct ? lastProduct.name : '';
  const baseProductSeparator = baseProduct && (lastProduct || middleProducts) ? ' + ' : '';
  const addonsSeparator = middleProducts ? ' og ' : '';
  const baseProductName = baseProduct?.name ?? '';

  return `${baseProductName}${baseProductSeparator}${middleNames}${addonsSeparator}${lastName}`;
};

export const getMinimalSubscriptionFromProducts = (products: Products.Product[]): MinimalSubscription => {
  const baseProduct = products.filter(p => p.baseProduct)[0];
  const addonProducts = products.filter(p => !p.baseProduct);
  return { baseProduct, addonProducts };
};

export const sumReducedAmounts = (product: Products.Product): number => {
  return sum(product.campaign?.discounts.map(d => d.reducedAmount));
};
/**
 * Returns a sum of all campaign discounts and the lowest number from #billingCycles
 */
export const getProductDiscount = (product: Products.Product): ProductDiscount | undefined => {
  const reducedAmount = sumReducedAmounts(product);
  // select the smallest shortest number of billing cycles
  const discounts = product.campaign?.discounts || [];
  const numberOfBillingCycles = discounts.map(discount => discount.numberOfBillingCycles).sort()[0];

  if (reducedAmount === 0) {
    return undefined;
  }
  return { reducedAmount, numberOfBillingCycles, productId: product.id };
};

export const getProductsPriceAndDiscount = (products: Products.Product[]): PriceWithDiscounts => {
  const price = sum(products.map(p => p.price));
  const discounts = products.map(getProductDiscount).filter(pd => pd !== undefined);
  const totalDiscountAmount = sum(discounts.map(d => d?.reducedAmount ?? 0));
  let numberOfBillingCycles: number | undefined;
  if (totalDiscountAmount) {
    // select the shortest number of billing cycles
    numberOfBillingCycles =
      discounts
        .filter(d => d != null)
        .map(d => d.numberOfBillingCycles)
        .sort()[0] ?? undefined;
  }

  return { price, totalDiscountAmount, numberOfBillingCycles, discounts };
};

type SubscriptionLikeObj = {
  baseProduct?: MinimalProduct;
  addonProducts: MinimalProduct[];
};

export const getProductIds = (subscriptionLikeObj: SubscriptionLikeObj): string[] => {
  const { baseProduct, addonProducts } = subscriptionLikeObj;
  if (!baseProduct) {
    return [];
  }
  return [baseProduct.id, ...addonProducts.map(p => p.id)];
};

export const withRelations = (
  product: Products.Product,
  otherProductIds: string | string[],
  relationType: Products.RelationType[]
) => {
  const _productIds = Array.isArray(otherProductIds) ? otherProductIds : [otherProductIds];
  const relations = product.relations.filter(r => _productIds.includes(r.id));
  if (_productIds.length !== relations.length) {
    throw Error('Could not find a relation for each provided product id');
  }
  return relations.every(r => relationType.includes(r.relationType));
};

export const isCombinable = (currentProduct: Products.Product, otherProductIds: string | string[]) => {
  return withRelations(currentProduct, otherProductIds, ['Combinable']);
};

export const isNotCombinable = (currentProduct: Products.Product, otherProductId: string | string[]) => {
  return !isCombinable(currentProduct, otherProductId);
};

export const isBaseProductOnly = (products: Products.Product[]) => {
  // only base products are discountable
  return products.every(p => p.baseProduct);
};

export const toProductList = <T extends Required<SubscriptionLikeObj>>(enhancedProducts: T) => {
  return [enhancedProducts.baseProduct, ...enhancedProducts.addonProducts];
};

export const toMinimalProductList = (subscription: SubscriptionLikeObj) => {
  if (!subscription.baseProduct) {
    return [];
  }
  return [subscription.baseProduct, ...subscription.addonProducts];
};

// TODO: delete this can of worms
export const hackConstructOldPackageName = (products: Products.Product[]) => {
  const baseEk = products.find(p => p.baseProduct)?.enrichmentKey;
  const addonEks = products.filter(p => !p.baseProduct).map(a => a.enrichmentKey);
  if (!baseEk) {
    throw Error('Could not find base product in list of products');
  }
  const baseSubKey = enrichmentKeyToPackageIdMap.get(baseEk);
  const addonSubKeys = addonEks.map(
    ek => enrichmentKeyToPackageIdMap.get(ek) || products.find(p => p.enrichmentKey === ek)?.name
  );
  // HACK: will stop working as soon as we introduce more than one addon subscription combination
  return [baseSubKey, ...addonSubKeys].join('_') as SubscriptionsTypes.Ids;
};

export const isSellable = (subscription: SubscriptionLikeObj, products: GroupedProducts): boolean => {
  const base = subscription.baseProduct;
  const addons = subscription.addonProducts;
  if (!base) {
    return false;
  }
  const sellableBase = products.baseProducts.some(b => b.id === base.id);
  const sellableAddons = addons.every(a => products.addonProducts.some(b => b.id === a.id));
  return sellableBase && sellableAddons;
};

export interface ECommerceItem {
  id: string;
  name: string;
  price: number;
  discount: number;
  quantity: number;
}
export const toECommerceItem = (product: Products.Product): ECommerceItem => ({
  id: product.id,
  name: product.name,
  price: product.price,
  discount: sumReducedAmounts(product),
  quantity: 1,
});

const sum = (input: number[] | undefined): number =>
  input
    ? input.reduce((a, b) => {
        return a + b;
      }, 0)
    : 0;
