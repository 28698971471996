import React from 'react';
import classNames from 'classnames';

import './fadeInOnLoad.scss';

export interface InputProps {
  className?: string;
}

interface State {
  isLoaded: boolean;
}

export function fadeInOnLoad<T>(
  WrappedComponent: React.ComponentType<T & InputProps>
): React.ComponentType<T & InputProps> {
  return class extends React.PureComponent<T & InputProps, State> {
    constructor(props: T & InputProps) {
      super(props);
      this.state = {
        isLoaded: false,
      };
    }

    setLoaded = () => this.setState({ isLoaded: true });

    render() {
      const { className } = this.props;
      const { isLoaded } = this.state;
      const rootClassName = classNames(className, 'Image-Fade', {
        'Image-Fade--loaded': isLoaded,
      });
      return <WrappedComponent {...this.props} className={rootClassName} onLoad={() => this.setLoaded()} />;
    }
  };
}
