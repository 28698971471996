import { Route } from 'react-router';

import { ExternalRedirectRoute } from './components/ExternalRedirectRoute';
import { InternalRedirectRoute } from './components/InternalRedirectRoute';
import {
  AppRoutes,
  ROUTE_TYPE_EXTERNAL_REDIRECT,
  ROUTE_TYPE_INTERNAL_REDIRECT,
  ROUTE_TYPE_NORMAL,
  ROUTE_TYPE_RAW,
} from './routes.type';
import { WaitForStartupWrapper } from './WaitForStartupWrapper';

export const routeFactory = (routes: AppRoutes, redirectPath: string) => {
  return routes.map(route => {
    const paths = Array.isArray(route.path) ? route.path : [route.path];
    if (route.type === ROUTE_TYPE_NORMAL) {
      const { component: Component, waitForStartup, layout: Layout, loginRequired, ...rest } = route;
      return paths.map(path => {
        return (
          <Route
            key={path}
            path={path}
            element={
              <WaitForStartupWrapper
                waitForStartup={waitForStartup}
                loginRequired={loginRequired ?? false}
                redirectPath={redirectPath}>
                <Layout {...rest?.props}>
                  <Component />
                </Layout>
              </WaitForStartupWrapper>
            }
          />
        );
      });
    } else if (route.type === ROUTE_TYPE_INTERNAL_REDIRECT) {
      const { redirectPath, options } = route;
      return paths.map(path => (
        <Route key={path} path={path} element={<InternalRedirectRoute {...{ path, redirectPath, options }} />} />
      ));
    } else if (route.type === ROUTE_TYPE_EXTERNAL_REDIRECT) {
      const { redirectPath } = route;
      return paths.map(path => (
        <Route key={path} path={path} element={<ExternalRedirectRoute redirectPath={redirectPath} />} />
      ));
    } else if (route.type === ROUTE_TYPE_RAW) {
      const { component: Component } = route;
      return paths.map(path => <Route key={path} path={path} element={<Component />} />);
    } else {
      const allRoutesAreHandled: never = route;
      throw new Error(allRoutesAreHandled);
    }
  });
};
