import type GatewayAPI from '@strim/gateway-api';
import { Products } from '@strim/gateway-api';
import { md5 } from 'pure-md5';

import { AccessTokenValue } from '@rikstv/play-common/src/forces/auth/auth.types';
import { ExternalServiceStatus } from '@rikstv/play-common/src/forces/externalService/types';
import logger from '@rikstv/play-common/src/utils/logger/logger';

import {
  getContentProviderStatus,
  getCustomer,
  postChangeSubscription,
  postConsents,
  postRegisterUser,
  postRegisterVippsUser,
  postStopSubscriptionRenewal,
  putMaxProvisioningUrl,
  putUpdateEmail,
  putUpdatePhoneNumber,
} from '../../../apis/StrimGatewayAPI';
import { sha256 } from '../../../utils/analytics/utils/sha256';
import { isValidPhoneNumber, normalizePhoneNumber } from '../../../utils/mobile-number/mobilenumber.utils';
import { paymentErrorCodes } from '../../payment/forces/constants';
import { PaymentError } from '../../payment/forces/payment.types';
import { BillingProvider } from '../../payment/forces/payment.utils';

import {
  getUnregisteredResponse,
  mapFromConsents,
  mapMaxResponseToExternalServiceStatus,
  mapToUserInfo,
  mapTV2PlayResponseToExternalServiceStatus,
  mapViaplayResponseToExternalServiceStatus,
} from './mappers';
import { Consents, UserInfo, UserToRegister, VippsUserToRegister } from './user.types';

const RegisterUserErrorCodes = {
  USER_EXISTS: 'USER_EXISTS',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  EMAIL_VALIDATION_FAILURE: 'EMAIL_VALIDATION_FAILURE',
  BACKEND_ERROR: 'BACKEND_ERROR',
} as const;
type TRegisterUserErrorCodes = (typeof RegisterUserErrorCodes)[keyof typeof RegisterUserErrorCodes];

export class RegisterUserError extends Error {
  readonly type = 'RegisterUserError';
  code: TRegisterUserErrorCodes;

  constructor(code: TRegisterUserErrorCodes, ...params: any) {
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RegisterUserError);
    }

    this.code = code;
  }
}

export const ChangeEmailErrorType = 'ChangeEmailErrorType';

export const ChangeEmailErrorCodes = {
  EMAIL_EXISTS: 'EMAIL_EXISTS',
  EMAIL_VALIDATION_FAILURE: 'EMAIL_VALIDATION_FAILURE',
  BACKEND_ERROR: 'BACKEND_ERROR',
  INCORRECT_PASSWORD: 'INCORRECT_PASSWORD',
};

export class ChangeEmailError extends Error {
  type: string;
  code: string;

  constructor(code: string, ...params: any) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ChangeEmailError);
    }

    this.type = ChangeEmailErrorType;
    this.code = code;
  }
}

export const ChangePhoneNumberErrorType = 'ChangePhoneNumberErrorType';

export const ChangePhoneNumberErrorCodes = {
  PHONE_NUMBER_VALIDATION_FAILURE: 'PHONE_NUMBER_VALIDATION_FAILURE',
  BACKEND_ERROR: 'BACKEND_ERROR',
  INCORRECT_PASSWORD: 'INCORRECT_PASSWORD',
};

export class ChangePhoneNumberError extends Error {
  type: string;
  code: string;

  constructor(code: string, ...params: any) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ChangePhoneNumberError);
    }

    this.type = ChangePhoneNumberErrorType;
    this.code = code;
  }
}

/**
 * Simplified registration where we get name and phone from Vipps instead of form input
 */
const registerUserUsingVipps = async (user: VippsUserToRegister) => {
  try {
    const response = await postRegisterVippsUser(user);
    return response;
  } catch (err) {
    throw getRegistrationError(err);
  }
};

const registerUserUsingEmail = async (user: UserToRegister) => {
  try {
    const response = await postRegisterUser(user);
    return response;
  } catch (err) {
    throw getRegistrationError(err);
  }
};

const getRegistrationError = (err: any) => {
  if (err.response) {
    const { status, statusText } = err.response;
    if (status === 409) {
      return new RegisterUserError(RegisterUserErrorCodes.USER_EXISTS, 'User already exists');
    }
    if (status === 400) {
      return new RegisterUserError(RegisterUserErrorCodes.EMAIL_VALIDATION_FAILURE, 'Email validation failed');
    } else {
      logger.error(`Unexpected error from register endpoint, status=${status}, text=${statusText}`);
      return err;
    }
  }
  logger.error('Unexpected error from register endpoint, no response received');
  return err;
};

const changeEmail = async (
  newEmail: string,
  password: string,
  accessTokenValue: AccessTokenValue | null | undefined
) => {
  try {
    await putUpdateEmail(
      {
        email: newEmail,
        password,
      },
      accessTokenValue
    );
  } catch (err) {
    if (err.response) {
      const { status, statusText } = err.response;
      if (status === 409) {
        throw new ChangeEmailError(ChangeEmailErrorCodes.EMAIL_EXISTS, 'Email already exists');
      } else if (status === 400) {
        throw new ChangeEmailError(ChangeEmailErrorCodes.EMAIL_VALIDATION_FAILURE, 'Email validation failed');
      } else if (status === 403) {
        throw new ChangeEmailError(ChangeEmailErrorCodes.INCORRECT_PASSWORD, 'Incorrect password');
      } else {
        logger.error(`Unexpected error from register endpoint, status=${status}, text=${statusText}`);
        throw err;
      }
    } else {
      logger.error('Unexpected error from register endpoint, no response received');
      throw err;
    }
  }
};

const changePhoneNumber = async (
  newPhoneNumber: string,
  password: string,
  accessTokenValue: AccessTokenValue | null | undefined
): Promise<string> => {
  try {
    const response = await putUpdatePhoneNumber(
      {
        phoneNumber: newPhoneNumber,
        password,
      },
      accessTokenValue
    );
    return response;
  } catch (err) {
    if (err.response) {
      const { status, statusText } = err.response;
      if (status === 400) {
        throw new ChangePhoneNumberError(
          ChangePhoneNumberErrorCodes.PHONE_NUMBER_VALIDATION_FAILURE,
          'Phone number validation failed'
        );
      } else if (status === 403) {
        throw new ChangePhoneNumberError(ChangePhoneNumberErrorCodes.INCORRECT_PASSWORD, 'Incorrect password');
      } else {
        logger.error(`Unexpected error from register endpoint, status=${status}, text=${statusText}`);
        throw err;
      }
    } else {
      logger.error('Unexpected error from register endpoint, no response received');
      throw err;
    }
  }
};

export const getUserInfo = async (accessTokenValue: AccessTokenValue): Promise<UserInfo> => {
  const response = await getCustomer(accessTokenValue);
  const { email, phoneNumber } = response.customer.personalia;
  // calculate hashes used in analytics
  const normalizedPhone = normalizePhoneNumber(phoneNumber);
  const [emailSha256, phoneSha256] = await Promise.all([
    sha256(email),
    sha256(isValidPhoneNumber(normalizedPhone) ? normalizedPhone : undefined),
  ]);
  const emailMd5 = md5(email.toLowerCase());
  return mapToUserInfo(response, { emailSha256, phoneSha256, emailMd5 });
};

export const toggleConsents = async (consents: Consents, accessTokenValue: AccessTokenValue) => {
  const mappedConsents = mapFromConsents(consents);
  await postConsents(mappedConsents, accessTokenValue);
};

export const changeSubscription = async (
  productsToBuy: Products.Product[],
  billingProvider: BillingProvider,
  accessTokenValue: AccessTokenValue
) => {
  try {
    return await postChangeSubscription(accessTokenValue, {
      productIds: productsToBuy.map(({ id }) => id),
      braintreeNonce: billingProvider.provider === 'Braintree' ? billingProvider.nonce : undefined,
      vippsReturnUrl: billingProvider.provider === 'Vipps' ? billingProvider.returnUrl : undefined,
    });
  } catch (error) {
    handleChangePackageError(error);
  }
};

const handleChangePackageError = (error: any) => {
  if (
    error &&
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500 &&
    error.response.body[0] != null
  ) {
    const body = error.response.body[0];
    throw new PaymentError(
      paymentErrorCodes.SUBSCRIPTION_FAILED,
      error,
      `Could not subscribe to package (${body.ErrorCode}: ${body.ErrorMessage})`
    );
  } else {
    throw new PaymentError(paymentErrorCodes.SUBSCRIPTION_FAILED, error, 'Failure calling subscription endpoint');
  }
};

export const stopSubscriptionRenewal = async (accessTokenValue: AccessTokenValue) => {
  await postStopSubscriptionRenewal(accessTokenValue);
};

export const fetchExternalServiceStatus = async (
  accessTokenValue: AccessTokenValue | null | undefined,
  serviceId: 'tv2play' | 'viaplay' | 'max',
  hasAccessToProvider: boolean
): Promise<ExternalServiceStatus> => {
  try {
    const response = await getContentProviderStatus(serviceId, accessTokenValue);
    switch (serviceId) {
      case 'viaplay':
        return mapViaplayResponseToExternalServiceStatus(
          response as GatewayAPI.ViaplayStatusResponse,
          hasAccessToProvider
        );
      case 'tv2play':
        return mapTV2PlayResponseToExternalServiceStatus(
          response as GatewayAPI.Tv2PlayStatusResponse,
          hasAccessToProvider
        );
      case 'max':
        return mapMaxResponseToExternalServiceStatus(response as GatewayAPI.MaxStatusResponse, hasAccessToProvider);
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      return getUnregisteredResponse(serviceId, hasAccessToProvider);
    } else {
      throw err;
    }
  }
};

const fetchMaxProvisionUrl = async (accessTokenValue: AccessTokenValue | null | undefined) => {
  return await putMaxProvisioningUrl(accessTokenValue);
};

export const userApi = {
  getUserInfo,
  toggleConsents,
  stopSubscriptionRenewal,
  registerUserUsingEmail,
  registerUserUsingVipps,
  changeEmail,
  changePhoneNumber,
  fetchExternalServiceStatus,
  fetchMaxProvisionUrl,
  changeSubscription,
};

export default userApi;
