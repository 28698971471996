import { SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { useAuthToken } from '../../hooks/useAuthToken';

import { fetchAssetDetails, fetchAssetDetailsBySeriesId } from './api';
import { AssetDetails } from './assetDetails.types';

export const useAssetDetails = (assetId: string | undefined, swrOptions?: SWRConfiguration) =>
  useAssetDetailsInternal(assetId, fetchAssetDetails, swrOptions);
/**
 * Given a series id returns an assetDetails that is either the last episode with progress or some default episode selected by the backend.
 */
export const useAssetDetailsFromSeriesId = (seriesId: string, swrOptions?: SWRConfiguration) =>
  useAssetDetailsInternal(seriesId, fetchAssetDetailsBySeriesId, swrOptions);

const useAssetDetailsInternal = <T extends AssetDetails>(
  assetOrSeriesId: string | undefined,
  dataFetcher: (id: string, token: string | null) => Promise<T>,
  swrOptions: SWRConfiguration = {}
) => {
  const token = useAuthToken();
  const fetcherKeys = assetOrSeriesId ? ([assetOrSeriesId, token, 'useAssetDetails'] as const) : null;
  const { data, error, ...rest } = useSWRImmutable(fetcherKeys, ([id, token]) => dataFetcher(id, token), {
    dedupingInterval: 120_000,
    ...swrOptions,
  });

  return { data, error, ...rest };
};
