import log, { LogLevelNumbers } from 'loglevel';

import config from '../../config';
import { isRunningTests } from '../isRunningTests';

// IMPORTANT!
//
// messages logged using logger are NOT sent to Sentry, i.e. they disappear
// in production. To send errors/messages to Sentry you either have to let an error propagate
// OR explicitly send it to sentry using errorTracker.captureException or captureMessage.

const override = /^\d$/.test(localStorage['loglevel'])
  ? (parseFloat(localStorage['loglevel']) as LogLevelNumbers)
  : undefined;
const logLevel = override ?? (config.isDevelopment ? log.levels.TRACE : log.levels.SILENT);
log.setDefaultLevel(logLevel);
// set to silent for tests
if (isRunningTests()) {
  log.setDefaultLevel(log.levels.SILENT);
}

type Logger = Pick<typeof log, 'log' | 'debug' | 'warn' | 'info' | 'error' | 'getLogger' | 'disableAll' | 'enableAll'>;

export const getLogger: (name: string) => Logger = (name: string) => ({
  ...(log as Logger),
  log: (...args) => log.log(name, ...args),
  debug: (...args) => log.debug(name, ...args),
  warn: (...args) => log.warn(name, ...args),
  info: (...args) => log.info(name, ...args),
  error: (...args) => log.error(name, ...args),
});

export const logTable = (label: string, tabularData: any[]) => {
  if (logLevel >= log.levels.TRACE) {
    console.group(`${label} (${tabularData.length})`);
    console.table(tabularData);
    console.groupEnd();
  }
};

export default log as Logger;
