import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { MenuDetails, SwimlaneItem } from '@rikstv/play-common/src/forces/assetDetails/assetDetails.types';
import { externalServiceLaunched } from '@rikstv/play-common/src/forces/externalService/actions';

import { getTemporalProperty } from '../swimlane.utils';

export const useReportPlayExternalAsset = () => {
  const dispatch = useDispatch();
  return useCallback(
    (item: MenuDetails | SwimlaneItem) => {
      if ('behaviors' in item && getTemporalProperty(item.behaviors)?.target === 'PlayExternalAsset') {
        if (item.links.externalPlayback) {
          dispatch(
            externalServiceLaunched({
              elementId: item.tracking.id,
              elementName: item.tracking.title,
              reportUrl: item.links.externalPlayback.reportUrl,
              serviceId: item.links.externalPlayback.providerId,
            })
          );
        }
      }
    },
    [dispatch]
  );
};
