import { FC } from 'react';

import { CommonRouter } from '@rikstv/play-common/src/router/CommonRouter';
import { OnRouteChange } from '@rikstv/play-common/src/router/OnRouteChange';
import { isDevFeatureEnabled } from '@rikstv/play-common/src/utils/featureFlags/devFeatureFlags';

import { LayoutDark } from '../containers/layout/LayoutDark';
import { LayoutDarker } from '../containers/layout/LayoutDarker';

import { routerPaths } from './router.path';
import { routes } from './routes';

const BrandLayout = isDevFeatureEnabled('darkerLayout') ? LayoutDarker : LayoutDark;

const RouterComponent: FC = () => (
  <CommonRouter
    routes={routes}
    redirectPath={routerPaths.frontpage}
    brandLayout={BrandLayout}
    onRouteChange={OnRouteChange}
  />
);

export default RouterComponent;
