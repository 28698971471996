import React from 'react';

import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';

import { CommonButtonProps, InternalActionButton } from './InternalActionButton';

interface Props extends CommonButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button: FCC<Props> = props => (
  <InternalActionButton buttonType="button" iconOnly={false} iconSize="match-text" {...props} />
);

export { Button };
