import { useEffect, useState } from 'react';
import { CoreScrollElement } from '@nrk/core-scroll';

export function useDetectDrag(element: CoreScrollElement | null) {
  const [userHasDragged, setUserHasDragged] = useState(false);
  useEffect(() => {
    function handleDragStart() {
      if (element) {
        element.addEventListener('mousemove', handleMove);
        element.addEventListener('touchmove', handleMove, { passive: true });
      }
    }

    function handleMove() {
      setUserHasDragged(true);
    }

    function handleDragEnd() {
      if (element) {
        element.removeEventListener('mousedown', handleDragStart);
        element.removeEventListener('mousemove', handleMove);
        document.removeEventListener('mouseup', handleDragEnd);

        element.removeEventListener('touchstart', handleDragStart);
        element.removeEventListener('touchmove', handleMove);
        document.removeEventListener('touchend', handleDragEnd);
      }
    }

    if (!userHasDragged) {
      if (element) {
        element.addEventListener('mousedown', handleDragStart);
        element.addEventListener('touchstart', handleDragStart, { passive: true });

        document.addEventListener('mouseup', handleDragEnd);
        document.addEventListener('touchend', handleDragEnd);
      }
    }

    return () => {
      handleDragEnd();
    };
  }, [element, userHasDragged]);

  return userHasDragged;
}
