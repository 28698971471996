import { FC } from 'react';

import { Link } from '../../router/Link';

import { ReactComponent as AppStoreBadgeSvg } from './appstoreBadge.svg';

import style from './badge.module.css';

interface Props {
  href: string;
}

export const AppStoreBadge: FC<Props> = ({ href }) => (
  <Link to={href} title="Last ned iOS app">
    <AppStoreBadgeSvg className={style.appleStore} />
    <span className="sr-only">Last ned Strim til iOS her</span>
  </Link>
);
