import { FC } from 'react';

import { IconButton } from '@rikstv/play-common/src/components/button/IconButton';
import { IconSize } from '@rikstv/play-common/src/icons/svgToIconElement';

import { PauseIcon, PlayTriangleIcon } from '../../icons';
import { language } from '../../language';

export const RoundPlayPauseToggle: FC<{
  paused: boolean;
  className?: string;
  onPlayPauseClick: () => void;
  iconSize?: IconSize;
}> = ({ paused, className, onPlayPauseClick, iconSize = 'large' }) => {
  return (
    <IconButton
      id={paused ? 'player-controls-play' : 'player-controls-pause'}
      className={className}
      icon={paused ? props => <PlayTriangleIcon {...props} style={{ paddingInline: '13.5% 7%' }} /> : PauseIcon}
      onClick={onPlayPauseClick}
      ariaLabel={paused ? language.player.play : language.player.pause}
      title={paused ? language.player.play : language.player.pause}
      size={iconSize}
    />
  );
};
