import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import CoreScroll from '@nrk/core-scroll/jsx';

import { Swimlane } from '@rikstv/play-common/src/components/pages/types';
import { Section } from '@rikstv/play-common/src/components/section/Section';
import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';
import { H2, useIntersectionObserver } from '@rikstv/shared-components';

import { ScrollArrow } from './ScrollArrow';
import { useDetectDrag } from './useDetectDrag';
import { useRovingIndex } from './useRovingIndex';

import styles from './HeroListScroller.module.css';

interface Props {
  swimlane: Swimlane;
  reportIsVisible?: (target: HTMLElement) => void;
  secondsBeforeScroll?: number;
}

const NavigationDots: FC<{
  onClick: (idx: number) => void;
  nrOfDots: number;
  swimlaneName: string;
  selectedDot: number;
}> = ({ nrOfDots, onClick, swimlaneName, selectedDot }) => {
  if (nrOfDots <= 1) {
    return null;
  }

  return (
    <div className={styles.coreScrollNavigationWrapper}>
      {Array.from({ length: nrOfDots })
        .fill(0)
        .map((_, idx: number) => {
          const selected = selectedDot === idx ? true : undefined;

          return (
            <button
              aria-hidden
              tabIndex={-1}
              data-selected={selected}
              className={styles.coreScrollNavigationDots}
              onClick={() => {
                onClick(idx);
              }}
              key={swimlaneName + '_dots_' + idx}></button>
          );
        })}
    </div>
  );
};

const useScrollDots = () => {
  const [currentDot, setCurrentDot] = useState(0);
  const scrollContainerRef = useRef<CoreScroll['el'] | null>(null);
  const scrollToIndex = useCallback((idx: number) => {
    if (scrollContainerRef.current && scrollContainerRef.current.items) {
      const item = scrollContainerRef.current.items[idx];
      item && scrollContainerRef.current.scroll(item);
      setCurrentDot(idx);
    }
  }, []);

  return {
    scrollContainerRef,
    scrollLeft: () => {
      if (!scrollContainerRef.current) {
        return;
      }

      const items = scrollContainerRef.current?.items;
      const previousItem = currentDot > 0 ? items[currentDot - 1] : items[items.length - 1];
      scrollContainerRef.current.scroll(previousItem);
      setCurrentDot(items.indexOf(previousItem));
      return;
    },
    scrollRight: () => {
      if (!scrollContainerRef.current) {
        return;
      }

      const items = scrollContainerRef.current?.items;
      const nextItem = currentDot < items.length - 1 ? items[currentDot + 1] : items[0];
      scrollContainerRef.current.scroll(nextItem);
      setCurrentDot(items.indexOf(nextItem));
      return;
    },
    scrollToIndex,
    currentDot,
    setCurrentDot,
  };
};

function setCurrentIndex(scroller: CoreScroll['el'], setCurrentDot: React.Dispatch<React.SetStateAction<number>>) {
  if (!scroller) {
    return;
  }
  const scrollElements = Array.from(scroller.items);

  const nextItem = scrollElements.reduce((previousElement, element) => {
    //Check wich item.left is closest to 0(start of viewport) and therefore should be seen as "current"
    return Math.abs(previousElement.getBoundingClientRect().left) <= Math.abs(element.getBoundingClientRect().left)
      ? previousElement
      : element;
  });

  const nextIndex = scrollElements.indexOf(nextItem);

  setCurrentDot(nextIndex);
}

export const HeroListScroller: FCC<Props> = ({ children, swimlane, reportIsVisible, secondsBeforeScroll = 0 }) => {
  const { name: title } = swimlane;
  const arrayChildren = React.Children.toArray(children) as React.ReactElement[];
  const { scrollContainerRef, scrollToIndex, currentDot, scrollLeft, scrollRight, setCurrentDot } = useScrollDots();
  const [userInteracted, setUserInteracted] = useState(false);
  const hasBeenDragged = useDetectDrag(scrollContainerRef.current);
  const rovingIndex = useRovingIndex();

  const [isInViewport, setIsInViewport] = useState(false);
  const [swimlaneItemTriggerRef] = useIntersectionObserver(
    list =>
      list.map(item => {
        if (item.isIntersecting && !isInViewport && reportIsVisible) {
          setIsInViewport(true);
        }
        if (!item.isIntersecting && isInViewport) {
          setIsInViewport(false);
        }
      }),
    { threshold: 0.95 }
  );

  const listId = title ? `swimlane-${title}` : undefined;

  //Automatically scrolls to the next heroItem unless the user has interacted with the Scroller
  useEffect(() => {
    if (userInteracted || hasBeenDragged || arrayChildren.length < 2 || secondsBeforeScroll <= 0) {
      return;
    }

    const intervalId = setInterval(() => {
      if (userInteracted || hasBeenDragged) {
        clearInterval(intervalId);
      } else {
        scrollRight();
      }
    }, secondsBeforeScroll * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [userInteracted, secondsBeforeScroll, scrollRight, arrayChildren, hasBeenDragged]);

  return (
    <Section type="transparent" noPaddingTop>
      <div className={styles.scrollerTitleWrapper}>
        <H2 id={listId} lookLike="title-3" className={`rds-gutter`}>
          {title}
        </H2>
      </div>
      <div className={styles.coreScrollContainer} ref={swimlaneItemTriggerRef}>
        <ScrollArrow
          className={styles.scrollNavigationArrow}
          disabled={arrayChildren.length === 1}
          onClick={() => {
            setUserInteracted(true);
            scrollLeft();
          }}
        />
        <CoreScroll
          data-swimlane-wrapper
          forwardRef={scrollContainerRef}
          items="li"
          onScrollChange={event => {
            if (event.target) {
              reportIsVisible?.(event.target);
            }

            if (scrollContainerRef.current) {
              setCurrentIndex(scrollContainerRef.current, setCurrentDot);
            }
          }}
          className={(styles.scrollerListGridHero, styles.coreScrollElement)}>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <ul
            aria-labelledby={listId}
            className={styles.scrollerListGridHero}
            onKeyDown={e =>
              rovingIndex.onKeyDown(e, () => {
                setUserInteracted(true);
              })
            }>
            {arrayChildren.map(child => (
              <li className={styles.scrollerItem} key={'scroller-list-' + child.key}>
                {child}
              </li>
            ))}
          </ul>
        </CoreScroll>
        <ScrollArrow
          className={styles.scrollNavigationArrow}
          disabled={arrayChildren.length === 1}
          onClick={() => {
            setUserInteracted(true);
            scrollRight();
          }}
          right
        />
      </div>
      <NavigationDots
        onClick={index => {
          scrollToIndex(index);
          setUserInteracted(true);
        }}
        swimlaneName={swimlane.name}
        nrOfDots={arrayChildren.length}
        selectedDot={currentDot}
      />
    </Section>
  );
};
