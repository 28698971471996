enum Breakpoints {
  XS = 568,
  SM = 768,
  MD = 992,
  ML = 1025,
  LG = 1281,
  XL = 1681,
  XXL = 1921,
  DEFAULT = 24000, // can be used as default, users (except Hans Arne Vartdal) will never reach this point before the app is obsolete)
}

function currentBreakpoint(currentWidth: number): Breakpoints {
  const breakpoint = Object.values(Breakpoints)
    .filter(entry => typeof entry === 'number')
    .map(entry => +entry)
    .sort((a, b) => a - b)
    .find(breakpoint => breakpoint > currentWidth);

  if (!breakpoint) {
    return Breakpoints.DEFAULT;
  }

  return breakpoint;
}

export { Breakpoints, currentBreakpoint };
