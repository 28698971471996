import { FC } from 'react';
import classNames from 'classnames';

import { NotificationDot } from '@rikstv/shared-components';

import styles from './Hamburger.module.scss';

export const Hamburger: FC<{
  className?: string;
  mobileMenuOpen: boolean;
  toggleMobileMenu: () => void;
  accountNotifications?: number;
}> = ({ className, mobileMenuOpen, toggleMobileMenu, accountNotifications }) => {
  const showNotifications = Boolean(accountNotifications) && mobileMenuOpen === false;
  return (
    <li className={classNames(styles.wrapper, 'with-keyboard-focus-within', className)}>
      <button
        aria-label="Meny"
        className={classNames(styles.hamburger, { [styles.open]: mobileMenuOpen })}
        onClick={() => toggleMobileMenu()}>
        <span></span>
      </button>
      {showNotifications && (
        <NotificationDot className={styles.notificationDot} numberOfNotifications={accountNotifications} />
      )}
    </li>
  );
};
