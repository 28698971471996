// TODO: delete this file when we are rid of direct usage of history

import { NavigateFunction, NavigateOptions } from 'react-router-dom';

interface History {
  navigate: NavigateFunction | null;
  push: (page: string, options?: NavigateOptions) => void;
}

const history: History = {
  navigate: null,
  push: (page: string, options?: NavigateOptions) => {
    if (history?.navigate) {
      history.navigate(page, options);
    }
  },
};

export default history;
