// eslint-disable-next-line no-restricted-imports
import { FC, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { commonRouterPaths } from '@rikstv/play-common/src/router/router.path';
import { Loader } from '@rikstv/shared-components';

import { Page } from '../components/pages/Page';
import { SwimlanePage } from '../components/pages/SwimlanePage';
import { PageShallow } from '../components/pages/types';
import { usePages } from '../components/pages/usePages';
import type { FCC } from '../utils/types/typeUtils';

import history from './history';
import { routeFactory } from './routeFactory';
import { deepLinkPathPattern, DeepLinkRedirector } from './router.deepLinks';
import { getPageRedirectRoutes } from './router.pageRedirects';
import { AppRoute, AppRoutes, ROUTE_TYPE_NORMAL } from './routes.type';
import { useAutoReloadStaleApp } from './useAutoReloadStaleApp';

interface Props {
  routes: AppRoutes;
  redirectPath: string;
  brandLayout: FCC;
  onRouteRender?: (() => void) | undefined;
  onRouteChange: FC;
}
export const CommonRouter = ({
  routes,
  redirectPath,
  brandLayout: BrandLayout,
  onRouteChange: OnRouteChange,
  onRouteRender,
}: Props) => {
  const { pages } = usePages();

  if (onRouteRender) {
    onRouteRender();
  }

  const mergedRoutes = mergeRoutes(routes, pages || [], BrandLayout);

  return (
    <BrowserRouter>
      <NavigationSetter />
      <AutoReloadStaleApp />
      <OnRouteChange />
      <Suspense
        fallback={
          <BrandLayout>
            <Loader size="large" style={{ paddingTop: 'var(--rds-spacing--32)' }} />
          </BrandLayout>
        }>
        <SentryRoutes>
          <Route path="/index.html" element={<Navigate to="/" replace />} />
          <Route
            path={deepLinkPathPattern}
            element={
              <BrandLayout>
                <DeepLinkRedirector />
              </BrandLayout>
            }
          />
          {routeFactory(mergedRoutes, redirectPath)}
        </SentryRoutes>
      </Suspense>
    </BrowserRouter>
  );
};

const mergeRoutes = (routes: AppRoutes, pages: PageShallow[], brandLayout: any) => {
  const appRoutes = routes.slice(0, -1); // all but last
  const fallbackRoute = routes.slice(-1)[0]; // last is fallback
  if (pages.length === 0) {
    // if pages have not resolved yet dont return the fallback route
    // as this will trigger a 404-event for any route that's part of pages
    return appRoutes;
  }
  const pageRoutes: AppRoutes = pages.map(page => {
    return {
      type: ROUTE_TYPE_NORMAL,
      path: `/${page.slug}`,
      layout: brandLayout,
      waitForStartup: true,
      component: Page,
    };
  });
  const collectionRoutes: AppRoute = {
    type: ROUTE_TYPE_NORMAL,
    path: commonRouterPaths.collections,
    layout: brandLayout,
    waitForStartup: true,
    component: Page,
  };
  const swimlaneRoutes: AppRoute = {
    type: ROUTE_TYPE_NORMAL,
    path: commonRouterPaths.swimlanePage,
    layout: brandLayout,
    waitForStartup: true,
    component: SwimlanePage,
  };
  return [...appRoutes, ...pageRoutes, collectionRoutes, swimlaneRoutes, ...getPageRedirectRoutes(), fallbackRoute];
};

const AutoReloadStaleApp: FC = () => {
  useAutoReloadStaleApp();

  return null;
};

// TODO: @lfberge this can be removed when we delete import of history
const NavigationSetter: FC = () => {
  history.navigate = useNavigate();
  return null;
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
