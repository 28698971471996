import type { KnownExternalServiceId } from './externalServiceIds';

export type ExternalServiceStatusCode =
  | 'Unknown' // Noe har gått skeis
  | 'NeedsConnection' // har abonnement hos ekstern leverandør, men det er ikke aktivt, og kontoene kan linkes
  | 'Connected' // mellomsteg mellom NeedsConnection og Completed - skal skje ganske fort
  | 'NeedsToSetPassword' // Konto er opprettet hos ekstern leverandør, men passord er ikke satt
  | 'NeedsToCancelExternalServiceSubscription' // har abonnement hos ekstern leverandør, men det er aktivt og må kanselleres før kontoene kan linkes
  | 'Completed'
  | 'Deactivated';

export interface ExternalServiceStatus {
  activationCode: string | null | undefined;
  statusCode?: ExternalServiceStatusCode;
  serviceId: KnownExternalServiceId;
  getActivationUrl: (callbackUrl: string) => string;
  inSubscription: boolean;
}

export const ExternalServiceMessages = {
  ACTIVATION_COMPLETE: 'ActivationComplete',
  ACTIVATION_ABORTED: 'ActivationAborted',
  ACTIVATION_FRAME_BUSTER: 'ActivationFrameBuster',
};
