import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GetUserInfoStatus = {
  userInfoLoadSuccess?: boolean;
  error?: Error;
};

const initialState: GetUserInfoStatus = {};

const getUserInfoStatusSlice = createSlice({
  name: 'getUserInfoStatus',
  initialState,
  reducers: {
    loadUserInfo: state => {
      state.userInfoLoadSuccess = undefined;
      state.error = undefined;
    },
    userInfoLoaded: (state, action: PayloadAction<GetUserInfoStatus>) => {
      state.userInfoLoadSuccess = action.payload.userInfoLoadSuccess;
      state.error = action.payload.error;
    },
  },
});

export const { loadUserInfo, userInfoLoaded } = getUserInfoStatusSlice.actions;

export default getUserInfoStatusSlice.reducer;
