import { lazy } from 'react';

import { ROUTE_TYPE_NORMAL } from '@rikstv/play-common/src/router/routes.type';

import { LayoutDark } from '../containers/layout/LayoutDark';

const DevPage = lazy(() =>
  import('@rikstv/play-common/src/pages/DevPage/DevPage').then(module => ({
    default: module.DevPage,
  }))
);

const PlayerTestPage = lazy(() =>
  import('@rikstv/play-player/src/pages/player-test-page/PlayerTestPage').then(module => ({
    default: module.PlayerTestPage,
  }))
);

// Dev-page
export const devRoute = [
  {
    type: ROUTE_TYPE_NORMAL,
    path: '/dev/null',
    component: DevPage,
    layout: LayoutDark,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: '/dev/player',
    component: PlayerTestPage,
    layout: LayoutDark,
    waitForStartup: false,
  },
];
