import { ExternalServiceMessages } from '@rikstv/play-common/src/forces/externalService/types';

import { routerPaths } from '../../../common/router/router.path';

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

// If application is loaded inside an iframe it may be our external service activation iframe.
// Try signaling to the parent that we want to render ourself outside of the iframe instead.
export const bustOutOfActivationIframe = () => {
  if (inIframe()) {
    if (window.parent && window.location.pathname !== routerPaths.externalActivationCallback) {
      window.parent.postMessage(
        {
          type: ExternalServiceMessages.ACTIVATION_FRAME_BUSTER,
          path: window.location.pathname,
        },
        '*'
      );
    }
  }
};
