import { END, eventChannel } from '@redux-saga/core';
import { all, call, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { authInitialized, loginSuccess, tokenRenewed } from '@rikstv/play-common/src/forces/auth/auth.slice';
import { AccessToken } from '@rikstv/play-common/src/forces/auth/auth.types';
import { customerClaimsChanged } from '@rikstv/play-common/src/forces/webSocket/actions';
import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';
import { captureExceptionInSentry } from '@rikstv/play-common/src/utils/errorTracker/tracking';
import { getLogger } from '@rikstv/play-common/src/utils/logger/logger';
import { AwaitedReturnType } from '@rikstv/play-common/src/utils/types/typeUtils';

import routerPath, { routerPaths } from '../../../common/router/router.path';
import { isVippsReturnPage } from '../../../common/router/strimrouter.utils';
import { strimAnalytics } from '../../../utils/analytics/strimAnalytics';
import { clearLogoutReason, storeLogoutReason } from '../../../utils/oauth.utils';

import { selectPostAuthRedirectUrl } from './selectors';
import { userActions } from './userInfo.slice';

const logger = getLogger('[auth]');

// TODO: HANDLE THIS
const getHistoryLocationWithSearch = (): string => {
  return window.location.pathname + window.location.search;
};

function* login() {
  // init handles login callbacks and automatically refreshes token if needed
  let onTokenRenewed: (input: AccessToken | END) => void;
  const tokenRenewedChannel = eventChannel<AccessToken>(_emitter => {
    onTokenRenewed = _emitter;
    return () => void 0;
  });
  const authStatus: AwaitedReturnType<typeof authService.init> = yield call([authService, authService.init], () => {
    const tokenObj = authService.getToken();
    onTokenRenewed(tokenObj);
    clearLogoutReason();
    logger.info('new token expires', new Date(tokenObj.expiry));
  });
  if (authStatus === 'signedOut') {
    strimAnalytics.userLoggedOut();
  }
  if (authService.isAuthenticated()) {
    yield call(clearLogoutReason);
    yield put(loginSuccess(authService.getToken()));
  }
  yield put(authInitialized());
  // listen for token renewed and update state
  while (true) {
    const tokenObj: AccessToken = yield take(tokenRenewedChannel);
    logger.info('got access token via channel emitter', tokenObj);
    yield put(tokenRenewed(tokenObj));
  }
}

function* pickReauthenticationRedirectUrl() {
  const redirectUrlOnNextReauthenticate: ReturnType<typeof selectPostAuthRedirectUrl> =
    yield select(selectPostAuthRedirectUrl);
  if (redirectUrlOnNextReauthenticate != null) {
    // URL to redirect to after next reauthentication. Used when a redirect is triggered by a change
    // to the user claims (token/subscription) - in that case this app will trigger a change in the
    // backend, which in turn forces a reauthentication in this app through the socket server. As
    // the socket server has no context, the app itself needs to know where to go if forced to
    // reauthenticate.
    return redirectUrlOnNextReauthenticate;
  }
  // if none above hit the mark, select a sane default here
  const historyLocation = getHistoryLocationWithSearch();
  if (
    historyLocation.startsWith(routerPaths.signUpPackageSelect) ||
    historyLocation.startsWith(routerPath.resetPassword()) ||
    historyLocation.startsWith(routerPath.logoutSuccess()) ||
    historyLocation.endsWith(routerPath.accountSubscriptionPage())
  ) {
    return '/';
  } else {
    return historyLocation;
  }
}

function* onCustomerClaimsChanged() {
  // On Vipps-callback page we need to wait for updated payment status in order to track "payment_confirmed"
  // The logic there handles re-auth as soon as that is done...
  if (isVippsReturnPage(window.location)) {
    return;
  }
  const redirectTo: string | null | undefined = yield call(pickReauthenticationRedirectUrl);
  logger.info('Customer claims changed: renewing tokens', { redirectTo });
  authService.renewTokens({ redirectUrl: redirectTo ?? undefined });
}

function* logout(action: ReturnType<typeof userActions.logoutWithReason>) {
  logger.info('Logging out');

  try {
    yield call(clearLogoutReason);
    if (action.payload != null) {
      yield call(storeLogoutReason, action.payload);
    }
    yield call([authService, authService.logout]);
  } catch (err) {
    captureExceptionInSentry(err, {
      fingerprint: ['logout-failed'],
      tags: { logoutReason: action.payload },
    });
    // Redirect to home with full refresh
    window.location.href = '/';
  }
}

export function* authenticationSagas() {
  yield fork(login);
  yield all([
    takeLatest(userActions.logoutWithReason.type, logout),
    takeEvery(customerClaimsChanged.type, onCustomerClaimsChanged),
  ]);
}
