import { getLogger } from '@rikstv/play-common/src/utils/logger/logger';
import { pathConfig } from '@rikstv/shared-strim-paths';

export const shouldAttemptRedirectToLowerCasePath = (): boolean => {
  // ensure case difference
  const lowercasePath = window.location.pathname.toLowerCase();
  if (lowercasePath === window.location.pathname) {
    return false;
  }
  const webAppRouteForPath = pathConfig
    .filter(p => p.application === 'Strim.WebApp')
    .find(p => p.pattern.test(lowercasePath));
  if (webAppRouteForPath) {
    getLogger('404').warn('REDIRECTING TO WEB_APP PATH', webAppRouteForPath);
    // do not redirect for local development
    if (window.location.origin.includes('strim.no')) {
      return true;
    }
  }
  return false;
};
