import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { selectIsLoggedIn } from '@rikstv/play-common/src/forces/auth/selectors';
import { locationChange } from '@rikstv/play-common/src/forces/ui/ui.slice';
import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';

// Export hook through a React component so it can easily and safely be passed
// as prop down to CommonRouter
export const OnRouteChange: FC = () => {
  useRouteChange();
  return null;
};

let lastLocation: string | undefined;

const useRouteChange = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const logLocationChange = useCallback(
    (pathname: string, isLoggedIn: boolean) => {
      // Avoid multiple events for the same pathname
      const newLocation = [pathname, isLoggedIn].join();
      if (lastLocation !== newLocation) {
        lastLocation = newLocation;
        dispatch(locationChange({ pathname, isLoggedIn }));
      }
    },
    [dispatch]
  );

  const { pathname, state } = useLocation();

  const s = state as { scrollRestore?: { swimlaneId: string; elementIndex: number }; verticalScroll?: number };

  useEffect(() => {
    let scrollRestorer: NodeJS.Timeout | undefined = undefined;
    // Track screen view on location changed
    authService.shouldRenewTokensOnRouteChange().then(shouldRenew => {
      if (shouldRenew) {
        authService.renewTokens({ redirectUrl: pathname });
      } else {
        logLocationChange(pathname, isLoggedIn);

        const restoreScroll = s?.verticalScroll || 0;
        scrollRestorer = setTimeout(() => window.scrollTo(0, restoreScroll), 0);
      }
    });
    return () => {
      if (scrollRestorer) {
        window.clearTimeout(scrollRestorer);
      }
    };
  }, [pathname, s, isLoggedIn, logLocationChange]);
};
