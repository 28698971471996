import { CSSProperties, FC } from 'react';
import classNames from 'classnames';

import styles from './ClampLines.module.css';

type Props = {
  style?: React.CSSProperties;
  textStyle: React.FunctionComponent<React.HTMLAttributes<HTMLElement>>;
  lines?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  children: string;
  className?: string;
};
export const ClampLines: FC<Props> = ({ children, textStyle: TextStyle, lines = 2, className, style }) => {
  return (
    <TextStyle
      style={{ ...style, ['--clamp-lines-length-var']: lines.toString() } as CSSProperties}
      className={classNames(`${className} ${styles.clampClass}`)}
      title={children}>
      {children}
    </TextStyle>
  );
};
