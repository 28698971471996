import { AssetType, AssetTypes } from '../root.types';

const hasKidElement = (elements: string[]) => {
  return (
    elements != null &&
    elements.find(element => {
      const lcElement = element.toLowerCase();
      return lcElement === 'barn' || lcElement === 'junior';
    })
  );
};

const hasMovieElement = (elements: string[]) => {
  const regEx = new RegExp(/\bfilm|film\b/gi);
  return elements.findIndex(string => regEx.test(string)) > -1;
};

export const isCatchUp = (response: RiksTV.ClientAPI.Title) => response.titleType === 'Linear';

// Upcoming is a future live/archive asset, something that either has not been sent yet or not been
// included in the archive.
export const isUpcoming = (response: RiksTV.ClientAPI.Title) => {
  return response.titleType === 'Linear' && response.inCatchupArchive === false;
};

export const isKidAsset = (categories: string[], genres: string[]) => {
  return hasKidElement(categories) || hasKidElement(genres);
};

export const isMovieAsset = (categories: string[], genres: string[]) => {
  return hasMovieElement(categories) || hasMovieElement(genres);
};

export const isTvSeriesAsset = (categories: string[]) => {
  return categories.find(category => category.toLowerCase() === 'serie') !== null;
};

export const getAssetTypeForResponse = (result: RiksTV.ClientAPI.Title): Exclude<AssetType, 'channelAT'> => {
  const categories = result.categories ?? [];
  const externalGenres = result.externalGenres ?? [];

  if (isUpcoming(result)) {
    return AssetTypes.UPCOMING;
  }

  if (isCatchUp(result)) {
    return AssetTypes.CATCH_UP;
  }

  if (isKidAsset(categories, externalGenres)) {
    return AssetTypes.KID;
  }

  if (isMovieAsset(categories, externalGenres)) {
    return AssetTypes.MOVIE;
  }

  if (isTvSeriesAsset(categories)) {
    return AssetTypes.TVSHOW;
  }

  return AssetTypes.UNKNOWN;
};
