import { StreamingDevice } from '@rikstv/play-player/src/player.types';

import { PlayerState } from './player.slice';

export const selectAutoPlayRequested = (state: PlayerState) => state.player.playback.autoPlayRequested;
export const selectViewMode = (state: PlayerState) => state.player.playback.viewMode;
export const selectStreamingDevice = (state: PlayerState): StreamingDevice => state.player.playback.streamingDevice;
export const selectIsCasting = (state: PlayerState): boolean => state.player.playback.streamingDevice === 'chromecast';
export const selectStreamingSessionId = (state: PlayerState) => state.player.playback.sessionId;
export const selectBingeWatching = (state: PlayerState) => state.player.playback.isBingeWatching;
export const selectCurrentPlayable = (state: PlayerState) => state.player.playback.playable;
