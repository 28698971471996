export class CssBemHelper {
  private block: string;
  constructor(blockClass: string) {
    this.block = blockClass;
  }

  get base() {
    return `${this.block}`;
  }
  element(element: string) {
    return this.base + '__' + element;
  }
  modifier(modifier: string) {
    return this.base + '--' + modifier;
  }
}
