const hasValue = (val: any) => typeof val !== 'undefined' && typeof val !== null;

export function mustBeDefined<T, K extends keyof T>(obj: T, prop: K) {
  const val = obj[prop];
  if (hasValue(val)) {
    return val!;
  }

  throw new Error(`Unable to map property ${String(prop)} from object ${JSON.stringify(obj)}`);
}
