import React, { FC } from 'react';

import { Breakpoints } from '../../grid/breakpoints';
import { SwimlaneSettings } from '../Swimlane.types';

import { StandardItem, StandardItemProps } from './StandardItem';

export const heroPortraitConfig: SwimlaneSettings = {
  placeholderHeight: {
    [Breakpoints.XS]: 175,
    [Breakpoints.SM]: 200,
    [Breakpoints.MD]: 225,
    [Breakpoints.ML]: 225,
    [Breakpoints.LG]: 250,
    [Breakpoints.XL]: 300,
    [Breakpoints.XXL]: 300,
    [Breakpoints.DEFAULT]: 300,
  },
};

export const HeroPortraitItem: FC<StandardItemProps> = props => <StandardItem {...props} portrait isHeroPortrait />;
