import { PageMeta } from 'ContentLayout';

import { AssetDetails } from '../../forces/assetDetails/assetDetails.types';

import { PageShallow, Swimlane } from './types';

export const mapResponseToShallowPages = (pages: Array<PageMeta>): Array<PageShallow> =>
  pages
    .sort((a, b) => a.order - b.order)
    .map(page => {
      const { id, name, slug, ...theRest } = page;
      return {
        ...theRest,
        id: id.toLowerCase(),
        name,
        slug: slug ?? name.toLowerCase(),
      };
    });

export type SwimlaneWithTitles = Swimlane & { titles: AssetDetails[] };
