import * as LDClient from 'launchdarkly-js-client-sdk';

import config from '../../config';
import { authService } from '../auth/AuthService';
import { isRunningTests } from '../isRunningTests';
import { getLogger } from '../logger/logger';
import { Deferred } from '../promises/Deferred';

import {
  AllFeatureFlags,
  LOCAL_FEATURE_FLAGS,
  LocalFeatureFlags,
  REMOTE_FEATURE_FLAGS,
  RemoteFeatureFlagKeys,
  RemoteFeatureFlags,
} from './featureFlags';

const logger = getLogger('[LD]');

export const getFlagKey = (name: AllFeatureFlags) => {
  if (LOCAL_FEATURE_FLAGS[name as LocalFeatureFlags]) {
    return undefined;
  }
  return REMOTE_FEATURE_FLAGS[name as RemoteFeatureFlags];
};

class FeatureFlagManager {
  private streaming = false;
  private contextDone = new Deferred<void>();
  private changeCallbacks: Record<string, Array<(val: any) => void>> = {};
  private client: LDClient.LDClient;
  constructor(_mockClient?: LDClient.LDClient) {
    if (_mockClient) {
      this.client = _mockClient;
    } else {
      this.client = LDClient.initialize(config.launchDarklyKey, { kind: 'user', anonymous: true });
      authService.whenReady(async () => {
        // enable targeting single users
        const user = authService.getUserData();
        if (user) {
          await this.client.identify({
            kind: 'user',
            key: user.userId, // only send email to LD for internal users
            email: authService.isInternalUser([/@example.com/]) ? user.email : undefined,
          });
          this.contextDone.resolve();
        } else {
          this.contextDone.resolve();
        }
      });
    }
  }
  getValue<T = boolean>(key: RemoteFeatureFlagKeys | undefined, defaultValue?: T): T | null {
    if (!key) {
      return null;
    }

    const value = this.client.variation(key, defaultValue ?? false);
    logger.log(`get flag`, [key, value]);
    return value as T;
  }
  getValueWhenReady<T = boolean>(key: RemoteFeatureFlagKeys | undefined, defaultValue?: T) {
    return this.client
      .waitUntilReady()
      .then(() => this.contextDone)
      .then(() => {
        return this.getValue<T>(key, defaultValue);
      });
  }
  notifyOnChanged<T = boolean>(key: RemoteFeatureFlagKeys | undefined, cb: (value: T) => void) {
    if (!key) {
      return;
    }
    this.streamChanges();
    this.changeCallbacks[key] = this.changeCallbacks[key] ?? [];
    this.changeCallbacks[key].push(cb);
    return () => {
      this.changeCallbacks[key] = this.changeCallbacks[key].filter(c => c !== cb);
    };
  }
  private onFlagChanged(flags: RemoteFeatureFlagKeys[]) {
    for (const flag of flags) {
      if (this.changeCallbacks[flag]) {
        const value = this.getValue(flag);
        console.log('[LD] setting callback value');
        this.changeCallbacks[flag].forEach(cb => cb(value));
      }
    }
  }
  private streamChanges() {
    try {
      if (this.streaming !== true) {
        this.streaming = true;
        this.client.on('change', (flags: Record<RemoteFeatureFlagKeys, any>) => {
          this.onFlagChanged(Object.keys(flags) as RemoteFeatureFlagKeys[]);
        });
      }
    } catch {
      /* */
    }
  }
}

/* Mock for testing, override flags with sessionStorage keys */
const ReversedFeatureMap = Object.fromEntries(Object.entries(REMOTE_FEATURE_FLAGS).map(([a, b]) => [b, a]));

class FlagManagerMock extends FeatureFlagManager {
  constructor() {
    // mock LD client
    super({} as any);
  }
  getValue<T = boolean>(key: RemoteFeatureFlagKeys, defaultValue?: T) {
    const mappedKey = ReversedFeatureMap[key];
    const globalFlags = (globalThis as any)._e2eFlags || {};
    if (mappedKey in globalFlags) {
      // This was only way I could get playwright working
      return globalFlags[mappedKey] as T;
    }
    const value = sessionStorage.getItem(mappedKey);
    if (value) {
      return Boolean(JSON.parse(value)) as T;
    }
    return defaultValue ?? null;
  }
  getValueWhenReady<T = boolean>(key: RemoteFeatureFlagKeys) {
    return Promise.resolve(this.getValue(key) as T);
  }
}

const isTestEnv = isRunningTests();

export const flagManager = isTestEnv ? new FlagManagerMock() : new FeatureFlagManager();
