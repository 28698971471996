import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  swimlaneClickedAction,
  swimlaneImpressionAction,
} from '@rikstv/play-common/src/forces/analytics/commonAnalyticsActions';
import { MenuDetails, SwimlaneItem } from '@rikstv/play-common/src/forces/assetDetails/assetDetails.types';
import { useDebouncedValue } from '@rikstv/play-common/src/hooks/useDebouncedValue';
import { toSwimlaneEventData } from '@rikstv/play-common/src/utils/analytics/analytics.mapper';

import { Swimlane } from '../../pages/types';

export type SwimlaneTrackingData = Omit<Swimlane, 'link' | 'lastUpdatedUtc' | 'supportsPaging' | 'minItemsForShowMore'>;
export type MenuItemTrackingData = Pick<MenuDetails, 'id' | 'name'>;
type ItemArray = Array<SwimlaneItem | MenuItemTrackingData>;

export const useImpressionTracking = (
  swimlaneItems: SwimlaneItem[] | undefined,
  menuItems: MenuItemTrackingData[] | undefined,
  swimlane: SwimlaneTrackingData,
  swimlaneIndex: number,
  enableImpressionTracking: boolean
) => {
  const [visibleItems, setVisibleItems] = useState<ItemArray>([]);
  const [dispatchedItems, setDispatchedItems] = useState<string[]>([]);
  const debouncedVisibleItems = useDebouncedValue<typeof visibleItems>(visibleItems, 500);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!enableImpressionTracking) {
      return;
    }
    const newVisibleItems = debouncedVisibleItems.filter(item => !dispatchedItems.includes(item.id));
    const items: ItemArray = swimlaneItems || menuItems || [];

    if (newVisibleItems.length !== 0 && items.length !== 0) {
      // Compare filter swimlaneItems/menuItems using visibleItems to get the correct order

      const filteredItems = items.filter(element => newVisibleItems.includes(element));

      if (!filteredItems || filteredItems.length === 0) {
        return;
      }

      const mappedAssets = filteredItems.map(item => {
        return { index: items.indexOf(item), item: getTrackingProps(item) };
      });

      const data = toSwimlaneEventData(swimlane, swimlaneIndex, mappedAssets);
      setDispatchedItems(items => items.concat(...filteredItems.map(i => i.id)));
      dispatch(swimlaneImpressionAction(data));
    }
  }, [
    debouncedVisibleItems,
    swimlaneIndex,
    dispatch,
    swimlane,
    swimlaneItems,
    menuItems,
    dispatchedItems,
    enableImpressionTracking,
  ]);

  const { reportSwimlaneClick } = useReportSwimlaneClick(swimlane, swimlaneIndex);

  return { setVisibleItems, reportSwimlaneClick };
};

function getTrackingProps(item: SwimlaneItem | MenuItemTrackingData) {
  return 'tracking' in item ? item.tracking : { id: item.id, title: item.name };
}

export const useReportSwimlaneClick = (swimlane: SwimlaneTrackingData, swimlaneIndex = 0) => {
  const dispatch = useDispatch();
  const reportSwimlaneClick = useCallback(
    (item: SwimlaneItem | MenuItemTrackingData, elementIndex: number) => {
      dispatch(
        swimlaneClickedAction(
          toSwimlaneEventData(swimlane, swimlaneIndex, [{ index: elementIndex, item: getTrackingProps(item) }])
        )
      );
    },
    [dispatch, swimlane, swimlaneIndex]
  );

  return { reportSwimlaneClick };
};
