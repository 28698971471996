import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const wssSlice = createSlice({
  name: 'wss',
  initialState: undefined,
  reducers: {
    wsErrorReceived: (_, _errorMessage: PayloadAction<string>) => {},
    serviceMaxActivated: () => {},
    newVersionReleased: () => {},
    customerClaimsChanged: () => {},
  },
});

export const { newVersionReleased, serviceMaxActivated, wsErrorReceived, customerClaimsChanged } = wssSlice.actions;
