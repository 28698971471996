import config from '@rikstv/play-common/src/config';

import { push } from './analytics/googleTagManager';
import { captureExceptionInSentry } from './errorTracker/tracking';

// Found in /public/cookie-tester.html in Strim and RiksTV app directories
const _iFramePath = '/cookie-tester.html';
const _sessionKey = '3rdPartyCookieSupport';
const _loadTime = Date.now();

/**
 * Returns `true`/`false` if test have been successful, otherwise `null`.
 * Always gets result from cache so this method is safe to call multiple times.
 * REQUIRES that `initThirdPartyCookiesTester()` have been called before.
 */
export const getSupports3rdPartyCookies = () => {
  // Only ever return cached value
  const cachedValue = sessionStorage.getItem(_sessionKey);
  if (cachedValue === '1' || cachedValue === '0') {
    return cachedValue === '1';
  }
  return null;
};
/**
 * Sets up iframe against strim, if current app is rikstv, or vice versa to check if third party cookies are supported.
 * This is highly interesting for silent token renew flows and viaplay activation.
 * Get result at a later time via:
 * ```ts
 * getSupports3rdPartyCookies()
 * ```
 */
export const initThirdPartyCookiesTester = () => {
  // check result in sessionStorage to avoid needless work
  const cachedValue = getSupports3rdPartyCookies();
  if (cachedValue != null) {
    // no need to report result to GA in this case
    return;
  }
  const iFrameDomain = getOtherDomain();

  const messageHandler = (event: MessageEvent<any>) => {
    // check for trusted origins here
    if (event.origin !== iFrameDomain) return;
    // we expect stringified json
    if (typeof event.data !== 'string') {
      return;
    }

    try {
      const data = JSON.parse(event.data);
      if ('cookieTestResult' in data) {
        const result = Boolean(data['cookieTestResult']);
        sessionStorage.setItem(_sessionKey, result ? '1' : '0');
        reportResultToGA(result);
        window.removeEventListener('message', messageHandler);
        document.body.removeChild(iframe);
      }
    } catch (err) {
      captureExceptionInSentry(err, {
        fingerprint: ['thirdPartyCookiesTest'],
        tags: { type: 'thirdPartyCookiesTest' },
      });
    }
  };

  // setup iframe and add eventlistener
  window.addEventListener('message', messageHandler);
  const iframe = document.createElement('iframe');
  iframe.setAttribute('style', 'display:none');
  iframe.title = '3rd party cookie tester';
  iframe.src = iFrameDomain + _iFramePath;
  iframe.onload = () => iframe.contentWindow?.postMessage(JSON.stringify({ test: 'cookie' }), '*');
  document.body.appendChild(iframe);
};

const reportResultToGA = (supports3rdPartyCookies: boolean) => {
  // Just report for now to get a ratio of how many users support 3rd party cookies
  // Later we can use this as input to viaplay activation and token renew flow
  const time = (Date.now() - _loadTime) / 1000;
  push({
    event: 'user_interaction',
    event_params: {
      event_group: 'feature/3rd_party_cookies',
      event_type: supports3rdPartyCookies.toString(),
      // report time since load in seconds to get a sense of how long this usually takes
      days_since_created: time,
      duration: time,
    },
  });
};

const getOtherDomain = () => {
  const env = getDomainEnv();
  if (config.application === 'Strim') {
    return `https://play.${env}rikstv.no` as const;
  }
  return `https://www.${env}strim.no` as const;
};
const getDomainEnv = () => {
  switch (config.environment) {
    case 'PROD':
      return '';
    case 'PT':
      return 'pt.';
    case 'UAT':
    case 'TEST':
    case 'LOCAL':
      return 'uat.';
    case 'DEV':
      return 'dev.';
  }
  const _test: never = config.environment;
};
