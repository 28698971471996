import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StreamKickData } from '../webSocket/webSocket.types';

export interface StreamingKickState {
  show: boolean;
  text: string;
}

const initialState = {
  show: false,
  text: '',
} as StreamingKickState;

const streamingKickSlice = createSlice({
  name: 'streamingKick',
  initialState,
  reducers: {
    streamingKickKicked: (state, { payload }: PayloadAction<StreamKickData>) => {
      state.show = true;
      state.text = payload.reason;
    },
    streamingKickResumePlaying: state => {
      state.show = false;
    },
  },
});

export const { streamingKickKicked, streamingKickResumePlaying } = streamingKickSlice.actions;

export default streamingKickSlice.reducer;
