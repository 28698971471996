import { FC } from 'react';
import classNames from 'classnames';

import { isIOS, isSafari } from '@rikstv/play-common/src/utils/device/device.utils';
import { ElementProps } from '@rikstv/play-common/src/utils/types/typeUtils';

import './FadeIn.scss';

type DivTypes = ElementProps<HTMLDivElement>;
export const FadeIn: FC<{ animationDelay?: number; durationMs?: number; swimlane?: boolean } & DivTypes> = ({
  animationDelay = 0,
  durationMs = 125,
  children,
  className,
  style = {},
  ...props
}) => {
  //For safari we don't use the fade in animation because of perfomance issues
  if (isIOS || isSafari) {
    return <div>{children}</div>;
  }
  return (
    <div
      style={{
        ...style,
        animationDelay: `${animationDelay > 8 ? 400 : animationDelay * 50}ms`,
        animationDuration: `${durationMs}ms`,
      }}
      className={classNames('common-fade-in', className)}
      {...props}>
      {children}
    </div>
  );
};
