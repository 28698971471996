import { FC } from 'react';
import classNames from 'classnames';

import { ReactComponent as Chevron } from '@rikstv/play-common/src/icons/svg/chevron-light.svg';
import { PrimaryButton } from '@rikstv/shared-components';

import './ScrollArrow.scss';

export const ScrollArrow: FC<{
  right?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  withBorder?: boolean;
}> = ({ right, disabled = false, onClick, className, withBorder = false }) => {
  return (
    <PrimaryButton
      aria-label={right ? 'Neste' : 'Forrige'}
      onClick={() => {
        onClick?.();
      }}
      className={classNames(
        'scroll-navigation-arrow',
        className,
        { 'scroll-navigation-arrow--right': right },
        { 'scroll-navigation-arrow--disabled': disabled },
        { 'scroll-navigation-arrow--with-border': withBorder }
      )}
      icon={Chevron as any}
      iconClass="inner-icon"
      disabled={disabled}
    />
  );
};
