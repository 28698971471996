import config from '@rikstv/play-common/src/config';
import {
  getAppSessionId as getAppSessionIdImported,
  getBrowser,
  getOS,
} from '@rikstv/play-common/src/utils/device/device.utils';

const sender = () => {
  return {
    os: getOS.name,
    osVersion: getOS.version,
    appVersion: config.appVersion,
    senderDevice: 'browser',
    senderBrowser: getBrowser.name,
    senderBrowserVersion: getBrowser.version,
  };
};

const receiverApplicationId = () => {
  // check local override, used for testing via ngrok
  const id = sessionStorage.getItem('castAppId');
  // default to config value
  return id || config.cast.receiverApplicationId;
};

const namespace = () => config.cast.namespace;

const getAppSessionId = () => getAppSessionIdImported();

export default {
  sender,
  namespace,
  getAppSessionId,
  receiverApplicationId,
};
