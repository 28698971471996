import { forwardRef } from 'react';

import { isIOS, isSafari } from '@rikstv/play-common/src/utils/device/device.utils';

import './SwimlanePlaceholder.scss';

export const SwimlanePlaceholder = forwardRef<HTMLDivElement, { animationDelay?: number; height: number }>(
  ({ animationDelay = 0, height }, ref) => {
    const isApple = isIOS || isSafari;

    return (
      <div className="common-page-swimlane__placeholder">
        <div
          ref={ref}
          style={{
            animationDelay: `${animationDelay * 75}ms`,
            height: `${height}px`,
            //set animationIterationCount = 0 for apple because of performance issues
            animationIterationCount: isApple ? '0' : 'infinite',
          }}
          className="common-page-swimlane__placeholder__content"
          aria-live="polite"
          aria-busy="true">
          <p className="sr-only">Laster inn karusell</p>
        </div>
      </div>
    );
  }
);
