export const middleDot = '\u00B7';
/**
 * Non breaking space
 */
export const nbsp = '\u00A0';
export const specialcharacters = Object.freeze({
  heavyRightPointingAngle: '\u276F', // ❯
  middleDot,
  softHypen: '\u00AD',
  nbsp,
  gear: '\u2699',
});

export const quotation = (txt: string) => `«${txt}»`;
