export const START_HOVER = 'START_HOVER';
export const STOP_HOVER = 'STOP_HOVER';
export const START_DRAG = 'START_DRAG';
export const STOP_DRAG = 'STOP_DRAG';
export const DRAG = 'DRAG';
export const UPDATE_CURRENT_TIME = 'UPDATE_CURRENT_TIME';
export const UPDATE_DURATION = 'UPDATE_DURATION';
export const UPDATE_LIVE = 'UPDATE_LIVE';

type ActionType =
  | typeof START_HOVER
  | typeof STOP_HOVER
  | typeof START_DRAG
  | typeof STOP_DRAG
  | typeof DRAG
  | typeof UPDATE_CURRENT_TIME
  | typeof UPDATE_DURATION
  | typeof UPDATE_LIVE;

export interface Action {
  type: ActionType;
  value?: any;
}
