import { KeyboardEventHandler, useEffect, useState } from 'react';

import { useIntersectionObserver } from '@rikstv/shared-components';

import navItemStyles from './NavItem.module.scss';
import styles from './StrimNavigation.module.scss';

type Listener<T extends keyof DocumentEventMap> = (this: Document, ev: DocumentEventMap[T]) => any;

export const useMobileMenu = (currentUrl: string) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // Close mobile menu on navigation
  useEffect(() => setMobileMenuOpen(false), [currentUrl]);
  // Close mobile menu on click outside link (backdrop)
  useEffect(() => {
    const clickListener: Listener<'click'> = ev => {
      if (mobileMenuOpen && ev.target instanceof HTMLElement && ev.target.classList.contains(styles.mobileMenu)) {
        setMobileMenuOpen(false);
      }
    };
    document.addEventListener('click', clickListener);
    return () => document.removeEventListener('click', clickListener);
  }, [mobileMenuOpen]);

  // Disable scrolling when mobile menu is open
  useEffect(() => {
    document.body.style.overflow = mobileMenuOpen ? 'hidden' : 'auto';
  }, [mobileMenuOpen]);
  // Explicitly "close" mobile menu when <ul> is hidden
  const [mobileNavRef] = useIntersectionObserver(entries => {
    const isIntersecting = entries.some(e => e.isIntersecting);
    if (!isIntersecting && mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  });
  return { mobileMenuOpen, setMobileMenuOpen, mobileNavRef, mobileMenuFocusTrapper };
};

const mobileMenuFocusTrapper: KeyboardEventHandler = evt => {
  if (evt.ctrlKey || evt.metaKey || evt.shiftKey) return;
  // Circles focus back to first element in menu when tabbing on last
  if (evt.key === 'Tab' && evt.target instanceof HTMLElement) {
    const lastMobileLinkSelector = `.${navItemStyles.mobileNavItem}:last-child .${navItemStyles.link}`;
    if (evt.target.matches(lastMobileLinkSelector)) {
      const logo = document.querySelector(`.${navItemStyles.strimLogo} .${navItemStyles.link}`);
      if (logo instanceof HTMLElement) {
        evt.preventDefault();
        logo.focus();
      }
    }
  }
};
