import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';
import { H2 } from '@rikstv/shared-components';

import './CoverPageSectionHeader.scss';

export const CoverPageSectionHeader: FCC = ({ children }) => (
  <H2 className="coverpage-section-header" lookLike="title-3">
    {children}
  </H2>
);
