import { FC, lazy, useEffect, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import useSWR from 'swr';

import { CloseIcon, InfoCircleIcon } from '@rikstv/play-common/src/icons';
import { BaseLinkButton, Body, H2, TertiaryButton } from '@rikstv/shared-components';

import config from '../../config';
import { getRequestPathWithAccessToken } from '../../forces/utils/request';
import { useAuthToken } from '../../hooks/useAuthToken';
import { IconButton } from '../button';

const Modal = lazy(() => import('../modal/Modal').then(module => ({ default: module.Modal })));

import { differenceInDays } from 'date-fns';

import { isLGTV } from '../../utils/device/device.utils';

import { lgBanner } from './lgBanner';

import styles from './TopBanner.module.css';

export interface InfoBanner {
  id: string;
  type: 'Info' | 'Warning' | 'Error';
  from: string;
  to: string;
  isDismissable: boolean;
  banner: {
    title: string;
    message: string;
    buttonText?: string;
  };
  details?: {
    title: string;
    message: string;
    linkConfig?: {
      link: string;
      linkText: string;
    };
    buttonText: string;
  };
}

export const TopBanner = ({ banner }: { banner: InfoBanner }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [parent] = useAutoAnimate({ duration: 500 });

  const handleClose = (isDismissable: boolean) => {
    setIsVisible(false);

    if (isDismissable) {
      localStorage.setItem(`banner-${banner.id}`, new Date().toISOString());
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className={styles.bannerWrapper}>
        <div id="messageContainer" className={styles.bannerContent} ref={parent}>
          {isVisible && (
            <>
              <div className={styles.infoContainer}>
                <div className={styles.bannerIconContainer}>
                  <InfoCircleIcon className={styles.bannerIcon} iconSize={40} />
                </div>

                <div className={styles.messageGrid}>
                  <H2 lookLike="title-3" className={styles.messageHeading}>
                    {banner.banner.title}
                  </H2>
                  <Body className={styles.messageInfo}>{banner.banner.message}</Body>
                </div>
                {banner.isDismissable && (
                  <IconButton
                    ariaLabel="Lukk info banner"
                    onClick={() => handleClose(banner.isDismissable)}
                    icon={CloseIcon}
                    className={styles.mobileCloseButton}
                  />
                )}
              </div>

              <div className={styles.buttonContainer}>
                {banner.details && (
                  <TertiaryButton className={styles.infoButton} onClick={() => setOpenModal(!openModal)}>
                    {banner.banner.buttonText ?? 'Les mer'}
                  </TertiaryButton>
                )}

                {banner.isDismissable && (
                  <TertiaryButton
                    className={styles.closeButton}
                    style={{ padding: 'var(--fluid-block)' }}
                    aria-label="Lukk info banner"
                    transparent
                    onClick={() => handleClose(banner.isDismissable)}>
                    <CloseIcon iconSize={24} />
                  </TertiaryButton>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <BannerModal
        modalOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        details={banner.details}
      />
    </>
  );
};

export const getPrioritizedBanner = (list: InfoBanner[] = []): InfoBanner | undefined => {
  //returns the first banner with a valid date range and that hasn't been dismissed
  const now = Date.now();

  //adds lg banner if user is on a LG TV
  if (isLGTV) {
    addLGBanner(list);
  }

  return list.find(b => {
    const hasBeenDismissed = localStorage.getItem(`banner-${b.id}`);
    if (hasBeenDismissed) {
      return false;
    }

    return now > Date.parse(b.from) && now < Date.parse(b.to);
  });
};

export function addLGBanner(list: InfoBanner[]) {
  const LS_KEY = 'banner-lg-banner';
  const lastDismissedDate = localStorage.getItem(LS_KEY) ? new Date(localStorage.getItem(LS_KEY) as string) : undefined;

  //adds lg banner if it hasn't been dismissed before or if it was dismissed over a month ago
  if (!lastDismissedDate || differenceInDays(new Date(), new Date(lastDismissedDate)) > 30) {
    localStorage.removeItem(LS_KEY);
    list.push(lgBanner);
  }
}

export const useFetchBanners = () => {
  const accessToken = useAuthToken();
  const messagesUrl = '/inappmessages';

  const { data } = useSWR<InfoBanner[]>(
    messagesUrl,
    async path => {
      const messagesResponse = await getRequestPathWithAccessToken<InfoBanner[]>(accessToken)(path, {
        url: config.apiBaseURL,
      });
      return messagesResponse;
    },
    { refreshInterval: 60000, shouldRetryOnError: false }
  );

  return data;
};

interface BannerModal {
  modalOpen: boolean;
  onClose: () => void;
  details: InfoBanner['details'];
}

const BannerModal: FC<BannerModal> = ({ modalOpen, onClose, details }) => {
  if (!details) {
    return null;
  }

  return (
    <Modal className={styles.bannerModal} center open={modalOpen} onClose={() => onClose()}>
      <div className={styles.bannerModalContainer}>
        <H2 className={styles.bannerModalTitle}>{details.title}</H2>
        <Body className={styles.bannerModalBody}>{details.message}</Body>
        <div className={styles.bannerModalButtonContainer}>
          {details.linkConfig && (
            <BaseLinkButton
              element="a"
              buttonStyle="highlighted"
              withArrow
              href={details.linkConfig.link}
              onClick={() => onClose()}>
              {details.linkConfig.linkText}
            </BaseLinkButton>
          )}
        </div>
      </div>
    </Modal>
  );
};
