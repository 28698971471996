import { Navigate, useParams } from 'react-router-dom';

import { Loader } from '@rikstv/shared-components';

import { AssetNotFound } from '../components/errorPage/AssetNotFound';
import { PageShallow } from '../components/pages/types';
import { isSlugTopLevelPage } from '../components/pages/useCollectionUrl';
import { fetchAssetDetails } from '../forces/assetDetails/api';
import { AssetDetails } from '../forces/assetDetails/assetDetails.types';
import { useAssetDetails } from '../forces/assetDetails/useAssetDetails';
import { TvSeriesDetails } from '../forces/tvSeries/tvSeriesDetails.types';
import { useTvSeries } from '../forces/tvSeries/useTvSeries';
import { getStackContent } from '../pages/StackedCoverPage/hooks/scp.utils';
import { fetchSuperCoverPage, useFetchSuperCoverPage } from '../pages/StackedCoverPage/hooks/useFetchSuperCoverPage';
import { authService } from '../utils/auth/AuthService';

import { commonRoutes } from './router.path';

type DeepLinkTypes = 'samling' | 'serie' | 'program' | 'kanal';
type ValidDeepLink = `${string}/se/${DeepLinkTypes}/${string}`;
type PagesResponse = PageShallow[] | undefined;

export const deepLinkPathPattern = '/se/:type/:id';

export const DeepLinkRedirector = () => {
  const { type, id } = useParams<{ type: DeepLinkTypes; id: string }>();

  if (type && id) {
    switch (type) {
      case 'kanal':
        return <Navigate to={commonRoutes.watchLive(id, undefined)} replace />;
      case 'program':
        return <ProgramRedirector id={id} />;
      case 'samling':
        return <Navigate to={commonRoutes.collections({ slug: id })} replace />;
      case 'serie':
        return <SeriesRedirector seriesId={id} />;
    }
  }
  // fallback redirect
  return <Navigate to="/" />;
};

const SeriesRedirector = ({ seriesId }: { seriesId: string }) => {
  // Attempt to get super cover page first and fall back to assuming id is an seriesId
  const { scpLink, error: superError, isLoading: superLoading } = useTryGetSuperCoverPageLink(seriesId);
  const { data, error, isLoading } = useTvSeries(superError != null ? seriesId : undefined);
  if (scpLink) {
    return <Navigate to={scpLink} replace />;
  }
  if (error) {
    return <AssetNotFound contextInfo={`SeriesRedirector, error=${error?.message}`} />;
  }
  if (superLoading || isLoading) {
    return <Loader size="large" />;
  }
  if (data) {
    const link = getSuperCoverPageLink(data) ?? commonRoutes.watchTvSeries(seriesId);
    return <Navigate to={link} replace />;
  }
  return null;
};

const ProgramRedirector = ({ id }: { id: string }) => {
  // Attempt to get super cover page first and fall back to assuming id is an assetId
  const { scpLink, error: superError, isLoading: superLoading } = useTryGetSuperCoverPageLink(id);
  const { data, error, isLoading } = useAssetDetails(superError != null ? id : undefined);
  if (scpLink) {
    return <Navigate to={scpLink} replace />;
  }
  if (error) {
    return <AssetNotFound contextInfo={`ProgramRedirector, error=${error?.message}`} />;
  }
  if (superLoading || isLoading) {
    return <Loader size="large" />;
  }
  if (data) {
    const redirectLink = getSuperCoverPageLink(data) ?? commonRoutes.watchAsset({ id });
    return <Navigate to={redirectLink} replace />;
  }
  return null;
};

export const getSuperCoverPageLink = (data: AssetDetails | TvSeriesDetails) => {
  if ('links' in data && data.links.superCoverPage) {
    return commonRoutes.stackedSeriesOrMovie({
      id: data.links.superCoverPage.id,
      title: data.name,
      isSeries: data.links.seriesUrl != null,
    });
  } else if ('episodeCount' in data) {
    try {
      const superId = data.seasons[0]?.episodes[0]?._links.superCoverPage?.id;
      if (superId) {
        const superSeriesId = superId.split(':').slice(0, 2).join(':');
        return commonRoutes.stackedSeriesOrMovie({ id: superSeriesId, isSeries: true, title: data.name });
      }
    } catch {
      /* om nom nom */
    }
  }

  return null;
};

const ID = 'abc123-321cba';
const SLASH_ID = `/${ID}`;
const scpMovieUrl = commonRoutes.stackedSeriesOrMovie({ id: ID, isSeries: false });
const scpSeriesUrl = commonRoutes.stackedSeriesOrMovie({ id: ID, isSeries: true });
const BASE_PATH = window.location.origin;

export const deepLinkRoutes = {
  program: (programId: string, absoluteUrl = false) =>
    `${absoluteUrl ? BASE_PATH : ''}/se/program/${programId}` as const,
  series: (seriesId: string, absoluteUrl = false) => `${absoluteUrl ? BASE_PATH : ''}/se/serie/${seriesId}` as const,
  collection: (collectionSlug: string, absoluteUrl = false) =>
    `${absoluteUrl ? BASE_PATH : ''}/se/samling/${collectionSlug}` as const,
  liveChannel: (channelId: string, absoluteUrl = false) =>
    `${absoluteUrl ? BASE_PATH : ''}/se/kanal/${channelId}` as const,
};

export const getDeepLinkWithoutSuperIdFromPathname = async (
  pathname: string,
  { pages }: { pages: PagesResponse }
): Promise<ValidDeepLink | null> => {
  const lowercasePath = pathname.toLowerCase();

  if (lowercasePath.startsWith(scpMovieUrl.split(ID)[0]) || lowercasePath.startsWith(scpSeriesUrl.split(ID)[0])) {
    const [_, id] = lowercasePath.split('/').filter(Boolean);
    const idInfo = await getIdForStackedAsset(id);
    if (idInfo) {
      return idInfo.isSeries ? deepLinkRoutes.series(idInfo.id, true) : deepLinkRoutes.program(idInfo.id, true);
    }
  }

  const isCollectionPage = lowercasePath.startsWith(commonRoutes.collections({ slug: ID }).split(SLASH_ID)[0]);
  const isTopLevelPage = isSlugTopLevelPage(pathname, pages);
  if (isCollectionPage || isTopLevelPage) {
    return deepLinkRoutes.collection(getLastPathSegment(pathname) ?? '', true);
  }

  const isLiveChannelPage = lowercasePath.startsWith(commonRoutes.watchLive(ID).split(ID)[0]);
  if (isLiveChannelPage) {
    return deepLinkRoutes.liveChannel(getNumericPathSegment(pathname) ?? '', true);
  }

  const isSeriesPage = lowercasePath.startsWith(commonRoutes.watchTvSeries(ID).split(SLASH_ID)[0]);
  if (isSeriesPage) {
    return deepLinkRoutes.series(getLastPathSegment(pathname) ?? '', true);
  }

  const isAssetPage = lowercasePath.startsWith(commonRoutes.watchAsset({ id: ID }).split(SLASH_ID)[0]);
  if (isAssetPage) {
    const id = getLastPathSegment(pathname);
    if (id) {
      const asset = await fetchAssetDetails(id, authService.getToken().value);
      if (asset?.metadata.tvSeries?.seriesId) {
        return deepLinkRoutes.series(asset.metadata.tvSeries.seriesId, true);
      } else {
        return deepLinkRoutes.program(id, true);
      }
    }
  }
  return null;
};

const getNumericPathSegment = (path: string) => path.split('/').find(v => /^\d+$/.test(v));
const getLastPathSegment = (path: string) => path.split('/').pop();

const getIdForStackedAsset = async (id: string): Promise<{ isSeries: boolean; id: string } | null> => {
  try {
    const res = await fetchSuperCoverPage(id, authService.getToken().value, () => ({ activated: true }));
    const data = getStackContent(res);
    let variant = data.defaultProviderVariant;
    // loop over sections and take first variant in list
    if (!variant || !variant.state) {
      data.sections.find(s => {
        if (s.type === 'providerVariants') {
          variant = s.providerVariants.variants[0];
          return true;
        }
      });
    }
    // SCP episodes have series as myList so fo them use fallback to `tracking.elementId` instead
    if (res.type !== 'episode' && variant && '_links' in variant && variant._links.myList) {
      const path = new URL(variant._links.myList.href).pathname.split('/');
      return { isSeries: path.includes('/serie/'), id: path.pop()! };
    }
    if (variant && 'tracking' in variant) {
      return {
        isSeries: false,
        id: variant.tracking.elementId,
      };
    }
  } catch {
    /* on-nom-nom */
  }
  return null;
};

/**
 * These prefixes are set by the API in InterAppShareLinks
 * and are used to identify/differentiate superId's from assetId's
 */
const ContentStackIdPrefix = 'cs_';
const useTryGetSuperCoverPageLink = (superId: string) => {
  /**
   * If an id contains a colon it is probably a super id
   * If an id start with "cs_" it is definitively a super id
   * Example id´s: 12345678:vod, 12345678:linear
   */
  const enableUseSuperIds = superId.startsWith(ContentStackIdPrefix) || superId.includes(':');
  const id = enableUseSuperIds ? superId.replace(ContentStackIdPrefix, '') : null;
  const { data, error, isLoading } = useFetchSuperCoverPage({ id });

  let superCoverPageLink = null;
  if (data) {
    const stack = getStackContent(data);
    superCoverPageLink = commonRoutes.stackedSeriesOrMovie({
      id: data.id,
      title: stack.metadata.title,
      isSeries: data.type === 'series',
    });
  }

  if (enableUseSuperIds === true) {
    return { error, isLoading, scpLink: superCoverPageLink };
  }

  return { error: new Error(), isLoading: false, scpLink: null };
};
