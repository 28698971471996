import { CSSProperties } from 'react';
import classNames from 'classnames';

import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';
import { Meta } from '@rikstv/shared-components';

import './Pill.scss';

interface Props {
  className?: string;
  style?: CSSProperties;
}

export const Pill: FCC<Props> = ({ children, className, style }) => (
  <Meta className={classNames('pill', className)} style={style}>
    {children}
  </Meta>
);

export const AccentPill: FCC<Props> = ({ className, ...props }) => (
  <Pill {...props} className={classNames('pill--accent', className)} />
);
