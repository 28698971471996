import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers, Middleware, ThunkDispatch } from '@reduxjs/toolkit';

import player from '@rikstv/play-player/src/forces/player.slice';

import auth from './auth/auth.slice';
import userInfoLoad from './loadUserInfo/loadUserInfo.slice';
import streamingKick from './streamingKick/streamingKick.slice';
import ui from './ui/ui.slice';

export const commonReducers = {
  ui,
  auth,
  player,
  streamingKick,
  userInfoLoad,
};

// create instance just to export type
const _commonReducers = combineReducers(commonReducers);

type CommonActions = Parameters<typeof _commonReducers>[1];
export type CommonRootState = ReturnType<typeof _commonReducers>;
export type CommonDispatch = ThunkDispatch<CommonRootState, unknown, CommonActions>;
export type CommonMiddleware = Middleware<unknown, CommonRootState, CommonDispatch>;

// Export typed hooks that can be reused to resolve types
export const useCommonDispatch: () => CommonDispatch = useDispatch;
export const useCommonSelector: TypedUseSelectorHook<CommonRootState> = useSelector;
