import { FC, useState } from 'react';

import { Body, H2, LightMode, PrimaryButton } from '@rikstv/shared-components';
import { sharedStrimUrls } from '@rikstv/shared-strim-paths';

import { CookiesSvg } from '../svg';

import styles from './Cookies.module.css';

const cookieName = 'wscrCookieConsent';
const cookieValueToSet = '1=true&2=true&3=true&4=true&5=true';
const cookieExpirationInDaysFromNow = 365;

export const Cookies: FC = () => {
  const [userHasAcceptedCookies, setUserHasAcceptedCookies] = useState<boolean>(hasSetConsentCookie());

  if (userHasAcceptedCookies) {
    return null;
  }

  return (
    <LightMode>
      <section className={styles.cookies} aria-label="Bruk av informasjonskapsler (cookies)">
        <div className={styles.article}>
          <div className={styles.icon}>
            <CookiesSvg />
          </div>
          <div className={styles.content}>
            <H2 className={styles.contentHeader} lookLike="title-3">
              Vi bruker cookies
            </H2>
            <Body>
              ... for å få nettsidene til å fungere, til analyse av webtrafikk og for å gi deg relevante tilbud på andre
              nettsider og i sosiale medier. Du kan aktivere alle cookies ved å klikke "Godta", eller
              {` `}
              {/* eslint-disable-next-line react/forbid-elements */}
              <a href={sharedStrimUrls.personvern} className="rds-link">
                lese mer og justere innstillingene dine her
              </a>
            </Body>
          </div>
          <div>
            <PrimaryButton
              data-testid="cookie-confirm"
              onClick={() => {
                setUserHasAcceptedCookies(true);
                setConsentCookie();
              }}>
              Godta
            </PrimaryButton>
          </div>
        </div>
      </section>
    </LightMode>
  );
};

function getFutureDate(daysFromNow: number) {
  const date = new Date();
  const msToIncrement = daysFromNow * 24 * 60 * 60 * 1000;
  date.setTime(date.getTime() + msToIncrement);
  return date;
}

function setConsentCookie() {
  const future = getFutureDate(cookieExpirationInDaysFromNow);
  const expires = `; expires=${future.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValueToSet} ${expires}; path=/; SameSite=Lax;`;
}

function hasSetConsentCookie() {
  const nameEQ = `${cookieName}=`;
  const crumbs = document.cookie.split(';').map(c => c.trim()); // 🍪🔨
  return crumbs.some(c => c.startsWith(nameEQ));
}
