import { FC } from 'react';

import { ProgramState } from '../../forces/assetDetails/assetDetails.types';

import { BroadcastTimeOverlay } from './BroadcastTimeOverlay';
import { DefaultOverlay } from './DefaultOverlay';
import { ExpiredOverlay } from './ExpiredOverlay';
import { LiveBroadcastOverlay } from './LiveBroadcastOverlay';
import { PadlockOverlay } from './PadlockOverlay';
interface SwimlaneItemImageOverlayProps {
  programState: ProgramState;
}

export const SwimlaneItemImageOverlay: FC<SwimlaneItemImageOverlayProps> = ({ programState }) => {
  if (programState.type === 'NoAccess') {
    return <PadlockOverlay iconSize="normal" label={programState.label} />;
  }

  if (programState.type === 'Live') {
    return (
      <LiveBroadcastOverlay
        state={programState.type}
        label={programState.label}
        progress={programState.progressPercentage}
      />
    );
  }

  if (programState.type === 'Planned') {
    return <BroadcastTimeOverlay label={programState.label} />;
  }

  if (programState.type === 'Expired') {
    return <ExpiredOverlay label={programState.label} />;
  }

  if (programState.type === 'Default' && programState.label) {
    return <DefaultOverlay label={programState.label} />;
  }

  return null;
};
