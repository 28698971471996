import UAParser from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

import config from '../../config';
import { isRunningTests } from '../isRunningTests';

const deviceIdKey = 'deviceId';
const APP_SESSION_ID_KEY = 'APP_SESSION_ID_KEY';

const parser = new UAParser();

export const getBrowser = parser.getBrowser();
export const getOS: { name: string | undefined; version: string | undefined } = parser.getOS();
export const getDevice: {
  model: string | undefined;
  type: string | undefined;
  vendor: string | undefined;
} = parser.getDevice();

export const isChrome = getBrowser.name === 'Chrome';
export const isSafari = getBrowser.name === 'Safari';
export const isMobileSafari = getBrowser.name === 'Mobile Safari';
export const isIOS = getOS.name === 'iOS';
export const isAndroid = getOS.name === 'Android';
export const isMobile = isIOS || isAndroid;
export const isHeadlessChrome = getBrowser.name === 'Chrome Headless';
export const isRendertron = isHeadlessChrome || Boolean(localStorage['pretend-rendertron']);
export const isFirefox = getBrowser.name === 'Firefox';
export const isLGTV =
  isChrome &&
  (getBrowser.version?.startsWith('79') ||
    getBrowser.version?.startsWith('87') ||
    getBrowser.version?.startsWith('94')) &&
  getDevice.vendor === 'LG';

export const getAppSessionId = () => {
  let id = sessionStorage.getItem(APP_SESSION_ID_KEY);

  if (id === null) {
    id = uuidv4();
    sessionStorage.setItem(APP_SESSION_ID_KEY, id);
  }

  return id;
};

export const getDeviceId = () => {
  let id = localStorage.getItem(deviceIdKey);

  if (id === null) {
    id = uuidv4();
    localStorage.setItem(deviceIdKey, id);
  }

  return id;
};

// JSDOM does not support matchMedia
export const isTouchDevice = isRunningTests() ? false : window.matchMedia('(pointer:coarse)').matches;

export const isMobileTouchDevice = (isIOS || isAndroid) && isTouchDevice;

export const getAppLink = () => {
  if (isAndroid) {
    return config.androidApp.link;
  }
  if (isIOS) {
    return config.iosApp.link;
  }
  return '';
};
