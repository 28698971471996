import { getSecondsBetweenDates } from '@rikstv/play-common/src/utils/date/date.utils';

const timeSeparator = ':';

export const zeroPad = (value: number) => (value < 10 ? `0${value}` : value);

export const timeFormatted = (date: Date) => hmsFormatted(date.getHours(), date.getMinutes(), date.getSeconds());

export const hmsFormatted = (hours: number, minutes: number, seconds: number) =>
  `${hours > 0 ? `${hours}${timeSeparator}` : ''}${zeroPad(minutes)}${timeSeparator}${zeroPad(seconds)}`;

export const secondsFormatted = (secondsTotal: number) => {
  if (secondsTotal <= 0) {
    return `00${timeSeparator}00`;
  }
  const hours = Math.floor(secondsTotal / 3600);
  const minutes = Math.floor(secondsTotal / 60 - hours * 60);
  const seconds = Math.floor(secondsTotal - (hours * 60 + minutes) * 60);
  return hmsFormatted(hours, minutes, seconds);
};

// Returns "now" at the very end of a stream, and "now" minus the duration of the stream at the beginning of the stream
export const streamTimeToDate = (videoTime: number, videoDuration: number): Date => {
  const unixNow = Math.floor(new Date().getTime() / 1000);
  const relativeTime = videoDuration - videoTime;
  const streamTimeInMillisecondsSinceUnixEpoch = (unixNow - relativeTime) * 1000;
  return new Date(streamTimeInMillisecondsSinceUnixEpoch);
};

export const isWithinLiveBufferWindow = (streamDateTime: Date) => {
  const deltaHoursFromNow = Math.abs(getSecondsBetweenDates(new Date(), streamDateTime)) / 3600;
  return deltaHoursFromNow < 3.5;
};
