import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers, Middleware, ThunkDispatch } from '@reduxjs/toolkit';

import { commonReducers } from '@rikstv/play-common/src/forces/root.reducer';
import { lastActionReducer } from '@rikstv/play-common/src/hooks/useReduxEffect';

import user from './domain/user/forces/userInfo.slice';

/*
  The selectors/reducers in the respective forces packages know of their key in the root reducer, to be able to define a subtype containing only their own part of the state.
  To make sure the key defined in that type is the same as the key defined here in the root reducer, we have this type check.
  It effectively makes sure that any key on the first level of the combined State object is present with the same name in the object that is sent to combineReducers."
*/
const appReducer = combineReducers({
  ...commonReducers,
  user,
  // MUST BE LAST
  lastAction: lastActionReducer,
});

// export store and dispatch types
type StrimActions = Parameters<typeof appReducer>[1];
export type StrimState = ReturnType<typeof appReducer>;
export type StrimDispatch = ThunkDispatch<StrimState, unknown, StrimActions>;
export type StrimMiddleware = Middleware<null, StrimState, StrimDispatch>;

// Export a hook that can be reused to resolve types
export const useStrimDispatch: () => StrimDispatch = useDispatch;
export const useStrimSelector: TypedUseSelectorHook<StrimState> = useSelector;

export default appReducer;
