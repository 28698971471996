type PaymentContext = {
  signUpLoadTime?: number;
  paymentFlow?: string;
  signUpFlow?: 'vipps' | 'email';
};

const _storageKey = 'payment_ctx';

export const paymentContextStorage = {
  setSignUpPageLoadTime: () => {
    const ctx = _getCtx();
    ctx.signUpLoadTime = Date.now();
    sessionStorage.setItem(_storageKey, JSON.stringify(ctx));
  },
  setSignUpAndPaymentFlow: (paymentFlow: 'new_subscription', signUpFlow: Required<PaymentContext>['signUpFlow']) => {
    const ctx = _getCtx();
    ctx.signUpFlow = signUpFlow;
    ctx.paymentFlow = paymentFlow;
    sessionStorage.setItem(_storageKey, JSON.stringify(ctx));
  },
  get: () => {
    const { paymentFlow, signUpFlow, signUpLoadTime } = _getCtx();
    // Calculate seconds since sign up, cap at 15 minutes
    let secondsSinceSignUpLoadTime = signUpLoadTime ? Math.round((Date.now() - signUpLoadTime) / 1000) : undefined;
    if (secondsSinceSignUpLoadTime) {
      if (secondsSinceSignUpLoadTime < 1) {
        // more than 0
        secondsSinceSignUpLoadTime = undefined;
      } else if (secondsSinceSignUpLoadTime > 60 * 15) {
        // less than 15 minutes
        secondsSinceSignUpLoadTime = undefined;
      }
    }
    return {
      paymentFlow,
      signUpFlow,
      secondsSinceSignUpLoadTime,
    };
  },
};
const _getCtx = () => {
  try {
    const val = sessionStorage.getItem(_storageKey);
    return val ? (JSON.parse(val) as PaymentContext) : {};
  } catch {
    return {};
  }
};
