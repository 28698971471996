import type GatewayAPI from '@strim/gateway-api';
import { Products } from '@strim/gateway-api';

import type { PaymentErrorCode } from './constants';

const PAYMENT_ERROR_TYPE = 'PaymentErrorType';

export class PaymentError extends Error {
  type: string;
  code: PaymentErrorCode;
  cause?: Error;

  constructor(code: PaymentErrorCode, cause?: Error | null, ...params: any) {
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PaymentError);
    }

    // workaround for missing instanceof in extends on built in types in babel.
    this.type = PAYMENT_ERROR_TYPE;
    this.code = code;
    this.cause = cause ?? undefined;
  }
}

export type PriceWithDiscounts = {
  price: number;
  totalDiscountAmount: number;
  numberOfBillingCycles?: number;
  discounts: ProductDiscount[];
};

export type ProductDiscount = {
  reducedAmount: number;
  percent?: number;
  numberOfBillingCycles?: number;
  productId: Products.ProductId;
};

export type ChangeOperation = Pick<GatewayAPI.SubscriptionOperation, 'isEligibleForTrialDiscount'> & {
  changeType: GatewayAPI.SubscriptionOperation['operation'] | 'RestartSubscription';
  productsToBuy: Products.Product[];
  nextCharge: number;
  discounts?: ProductDiscount[];
  totalDiscountAmount: number;
  changeSummary?: GatewayAPI.SubscriptionChangeSummaryResponse;
};
