import type { StreamingDevice } from '@rikstv/play-player/src/player.types';
import type { Playable } from '@rikstv/play-player/src/utils/playable';

import type { SwimlaneTrackingData } from '../../components/swimlane/coreScrollSwimlane/useImpressionTracking';
import { AssetType, AssetTypes } from '../../forces/root.types';

import type { PlayContentMeta, SwimlaneEventData } from './CommonAnalytics.types';

type Item = { title: string; id: string };
type Items = Array<{ index: number; item: Item }>;

export const toSwimlaneEventData = (
  swimlane: SwimlaneTrackingData,
  swimlaneIndex: number,
  items: Items
): SwimlaneEventData => {
  const { id, name, type, style } = swimlane;
  return {
    id,
    name,
    type,
    style,
    position: swimlaneIndex,
    location: window.location.pathname,
    elements: items.map(({ index, item }) => ({
      elementNumber: index,
      elementId: item.id,
      elementTitle: item.title,
    })),
  };
};

export const toPlayContentMeta = (streamingDevice: StreamingDevice, playable: Playable): PlayContentMeta => {
  const isChannel = playable.type === 'channelAT';
  return {
    channel: playable.originChannel.serviceName,
    channelId: playable.id,
    typeOfStream: mapTypeToTypeOfStream(playable.type),
    streamingDevice,
    assetTitle: isChannel ? undefined : playable.name,
  };
};

const mapTypeToTypeOfStream = (type: AssetType): PlayContentMeta['typeOfStream'] => {
  switch (type) {
    case AssetTypes.MOVIE:
    case AssetTypes.TVSHOW:
      return 'vod';
    case AssetTypes.CATCH_UP:
    case AssetTypes.UPCOMING:
      return 'arkiv';
    case AssetTypes.CHANNEL:
      return 'direkte';
    case AssetTypes.KID:
      return 'barn';
    default:
      throw new Error(`${type} not supported`);
  }
};
