import type GatewayAPI from '@strim/gateway-api';

import type {
  ExternalServiceStatus,
  ExternalServiceStatusCode,
} from '@rikstv/play-common/src/forces/externalService/types';
import { appendQueryParams } from '@rikstv/play-common/src/forces/utils/request.utils';
import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';
import type { LooseStringUnion } from '@rikstv/play-common/src/utils/types/typeUtils';

import { ServiceIds } from '../../account/components/externalServiceDisplay/types';

import { CONTENT_ACCESS_STATES } from './packageStates';
import type {
  Consents,
  ExternalServiceStatuses,
  InternalServiceStatuses,
  SubscribedPackage,
  UserInfo,
} from './user.types';

const getPackage = (customer: GatewayAPI.CustomerResponse['customer']): SubscribedPackage | undefined => {
  if (!customer.subscription) {
    return;
  }
  const { subscriptionState: state, grantsContentAccess } = customer.subscription;
  const nextPaymentDate =
    'nextPaymentDate' in customer.subscription ? getAsDate(customer.subscription.nextPaymentDate) : undefined;
  return {
    id: customer.subscription.packageName,
    state: state,
    hasAccessToContent: grantsContentAccess,
    nextPaymentDate,
    selectedProductIds: customer.selectedProductIds,
  };
};

export const toSubscribedPackage = (subscription?: GatewayAPI.SubscriptionResponse): SubscribedPackage | undefined => {
  if (!subscription) {
    return;
  }
  const nextPaymentDate = 'nextPaymentDate' in subscription ? getAsDate(subscription.nextPaymentDate) : undefined;
  return {
    // TODO: remove id and stop using it (subscription has a "package" property, just not in our types)
    id: (subscription as any).package?.id as SubscribedPackage['id'],
    state: subscription.state,
    hasAccessToContent: CONTENT_ACCESS_STATES.includes(subscription.state),
    nextPaymentDate,
    selectedProductIds: [],
  };
};

const getAsDate = (date?: string | null): Date | undefined => (date ? new Date(date) : undefined);

const fromConsentsIdMap: { [k in keyof Consents]: string } = {
  emails: 'receiveEmail',
  sms: 'receiveSms',
  targetedMarketingEmails: 'personalizedMarketing',
  shareWith3rdParty: 'shareContactInformationWithThirdParties',
  socialMedia: 'contactThroughSocialMedia',
};

export const mapFromConsents = (consents: Consents) => {
  type consentKey = keyof typeof consents;
  const filteredConsents: { [k in keyof typeof consents]: boolean | undefined } = {};
  Object.keys(consents).forEach(key => {
    if (consents[key as consentKey] != null) {
      filteredConsents[fromConsentsIdMap[key as consentKey] as keyof typeof consents] = consents[key as consentKey];
    }
  });
  return filteredConsents;
};

type HasProvider = Record<ServiceIds, boolean>;

export const determineAccessToProviders = (
  userEntitlements: LooseStringUnion<GatewayAPI.KnownExternalServiceSourceNames>[]
): HasProvider => {
  const hasAccessToProvider: HasProvider = {
    viaplay: false,
    max: false,
    tv2play: false,
    nrk: true,
    skyShowtime: false,
    nordiskFilm: false,
  };

  userEntitlements.forEach(channelName => {
    switch (channelName) {
      case 'Viaplay':
        hasAccessToProvider.viaplay = true;
        break;
      case 'HBO Max': // HACK Because we never got a separate channel for Max... but it is available in OVP/ContentSearch
      case 'WbdMax':
        hasAccessToProvider.max = true;
        break;
      case 'TV2 Play Film og serier':
      case 'TV2 Play Film og serier med reklame':
      case 'TV 2 Play ChampionsLeague LaLiga Sport FilmSerier':
        hasAccessToProvider.tv2play = true;
        break;
      case 'SkyShowtime':
        hasAccessToProvider.skyShowtime = true;
        break;
      case 'Nordisk Film+':
        hasAccessToProvider.nordiskFilm = true;
        break;
      default:
        break;
    }
  });

  return hasAccessToProvider;
};

export const getUserEntitlements = (): LooseStringUnion<GatewayAPI.KnownExternalServiceSourceNames>[] => {
  return authService.getUserData()?.entitlements ?? [];
};

const mapToExternalServicesStatus = (userInfoResponse: GatewayAPI.CustomerResponse): ExternalServiceStatuses => {
  // "ExternalServices" list all 3rd party services this user has access to.
  const inSubscription3rdPartyServices = userInfoResponse.externalServices.map(service => service.appHoppingService);

  const result: ExternalServiceStatuses = {};
  if (userInfoResponse.viaplay) {
    result.viaplay = mapViaplayResponseToExternalServiceStatus(
      userInfoResponse.viaplay,
      inSubscription3rdPartyServices.includes('viaplay')
    );
  }

  result.tv2play = mapTV2PlayResponseToExternalServiceStatus(
    userInfoResponse.tv2Play,
    inSubscription3rdPartyServices.includes('tv2Play')
  );

  // MAX is not present in userInfoResponse before MAX is active,
  // so it needs to be default mapped to result
  result.max = mapMaxResponseToExternalServiceStatus(
    userInfoResponse.max,
    inSubscription3rdPartyServices.includes('max')
  );

  result.nrk = getNrkExternalServiceStatus();
  return result;
};

const mapToInternalServiceStatus = (): InternalServiceStatuses => {
  const userEntitlements = getUserEntitlements();
  const hasAccessToProvider = determineAccessToProviders(userEntitlements);

  return {
    skyShowtime: hasAccessToProvider.skyShowtime,
    nordiskFilm: hasAccessToProvider.nordiskFilm,
  };
};

export const mapViaplayResponseToExternalServiceStatus = (
  response: GatewayAPI.ViaplayStatusResponse,
  inSubscription: boolean
): ExternalServiceStatus => {
  return {
    activationCode: response.activationCode,
    statusCode: response.activated ? 'Completed' : 'NeedsConnection',
    serviceId: 'viaplay',
    getActivationUrl: (callbackUrl: string): string =>
      appendQueryParams(response.signupUrl!, `returnTo=${callbackUrl}`),
    inSubscription,
  };
};

const mapTV2Status = (tv2Status?: GatewayAPI.Models.Tv2ServiceStatuses): ExternalServiceStatusCode | undefined => {
  switch (tv2Status) {
    case 'NeedsToCancelTv2PlaySubscription':
      return 'NeedsToCancelExternalServiceSubscription';
    default:
      return tv2Status;
  }
};

export const mapTV2PlayResponseToExternalServiceStatus = (
  response: GatewayAPI.Tv2PlayStatusResponse | undefined,
  inSubscription: boolean
): ExternalServiceStatus => {
  return {
    activationCode: undefined,
    statusCode: mapTV2Status(response?.status),
    serviceId: 'tv2play',
    getActivationUrl: () => '',
    inSubscription,
  };
};

export const mapMaxResponseToExternalServiceStatus = (
  response: GatewayAPI.MaxStatusResponse | undefined,
  inSubscription: boolean
): ExternalServiceStatus => {
  return {
    activationCode: response?.activationCode || null,
    statusCode: response?.isActivated ? 'Completed' : 'NeedsConnection',
    serviceId: 'max',
    getActivationUrl: () => response?.activationUrl ?? '',
    inSubscription,
  };
};

const getNrkExternalServiceStatus = (): ExternalServiceStatus => {
  return {
    activationCode: undefined,
    statusCode: 'Completed',
    serviceId: 'nrk',
    getActivationUrl: (): string => '',
    inSubscription: true,
  };
};

export const getUnregisteredResponse = (
  serviceId: 'tv2play' | 'viaplay' | 'max',
  hasAccessToProvider: boolean
): ExternalServiceStatus => {
  return {
    activationCode: null,
    serviceId,
    getActivationUrl: () => '',
    // a quirk to hide viaplay when their API is slow, and we cannot activate yet
    inSubscription: serviceId === 'viaplay' ? false : hasAccessToProvider,
  };
};

export const mapToUserInfo = (
  userInfoResponse: GatewayAPI.CustomerResponse,
  { emailSha256, phoneSha256, emailMd5 }: { emailSha256?: string; phoneSha256?: string; emailMd5: string }
): UserInfo => {
  const externalServicesStatus = mapToExternalServicesStatus(userInfoResponse);
  const internalServicesStatus = mapToInternalServiceStatus();
  const { createdDate, ...personalia } = userInfoResponse.customer.personalia;
  return {
    userId: userInfoResponse.customer.id,
    personalia: {
      createdDate: getAsDate(createdDate),
      ...personalia,
    },
    analytics: {
      emailMd5,
      emailSha256,
      phoneSha256,
    },
    subscribedPackage: getPackage(userInfoResponse.customer),
    isInTrialPeriod: userInfoResponse.customer.subscription.isInTrialPeriod,
    externalServicesStatus,
    internalServicesStatus,
    pendingDowngradeToPackageId: userInfoResponse.customer.subscription.pendingDowngradeToPackageName,
  };
};
