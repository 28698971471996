export const SET_ENABLED = 'SET_ENABLED';
export const TICK = 'TICK';
export const UPDATE_CAST_STATE = 'UPDATE_CAST_STATE';
export const UPDATE_CURRENT_TIME = 'UPDATE_CURRENT_TIME';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_META_DATA = 'UPDATE_META_DATA';
export const UPDATE_MEDIA_INFO = 'UPDATE_MEDIA_INFO';
export const UPDATE_PLAYING = 'UPDATE_PLAYING';
export const UPDATE_SESSION_STATE = 'UPDATE_SESSION_STATE';
export const UPDATE_VOLUME = 'UPDATE_VOLUME';
export const UPDATE_MUTED = 'UPDATE_MUTED';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const UPDATE_LOCAL_ASSET_ID = 'UPDATE_LOCAL_ASSET_ID';

type ActionType = typeof SET_ENABLED | typeof UPDATE_PLAYING | typeof UPDATE_CAST_STATE;

interface BaseAction {
  castState?: cast.framework.CastState;
  playerState?: chrome.cast.media.PlayerState;
}

type LocalAction = { type: typeof UPDATE_LOCAL_ASSET_ID; payload: { assetId: string } };

export type Action = RemoteAction | LocalAction;

export interface RemoteAction extends BaseAction {
  type: ActionType;
}

type ActionExtendedType =
  | typeof TICK
  | typeof UPDATE_CURRENT_TIME
  | typeof UPDATE_LANGUAGE
  | typeof UPDATE_MEDIA_INFO
  | typeof UPDATE_META_DATA
  | typeof UPDATE_MUTED
  | typeof UPDATE_SESSION_STATE
  | typeof UPDATE_VOLUME
  | typeof SET_ERROR
  | typeof CLEAR_ERROR
  | ActionType;

export interface ActionExtended extends BaseAction {
  type: ActionExtendedType;
  sessionState?: cast.framework.SessionState;
  mediaInfo?: chrome.cast.media.MediaInfo;
  customData?: Record<string, unknown>;
  currentTime?: number;
  textLanguage?: string;
  audioLanguage?: string;
  volume?: number;
  muted?: boolean;
  hover?: boolean;
  errorMessage?: string;
}
