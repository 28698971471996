import { useEffect, useState } from 'react';

import { authService } from '../auth/AuthService';

import { flagManager, getFlagKey } from './FeatureFlagManager';
import { RemoteFeatureFlags } from './featureFlags';

/**
 * Hooks for remote feature flags, no real time updates (reload requires refresh)
 * Available flags can be seen here:
 * https://app.launchdarkly.com/default/production/features?tag=RiksTV.Apps.WebApps
 */

interface FlagOptions<T = boolean> {
  defaultValue?: T;
}

const useStreamingUpdates = () => {
  // Enable streaming updates for internal users
  const [enableStreamingUpdates, setEnableStreamingUpdates] = useState(false);
  useEffect(() => {
    const abort = new AbortController();
    authService.whenReady(() => {
      if (abort.signal.aborted) {
        setEnableStreamingUpdates(authService.isInternalUser());
      }
    });
    return () => abort.abort();
  }, []);
  return [enableStreamingUpdates];
};

export const useRemoteFeatureFlag = <T = boolean>(
  flagName: RemoteFeatureFlags,
  opts: FlagOptions<T> = {}
): [T | null] => {
  const { defaultValue } = opts;
  const flagKey = getFlagKey(flagName);
  const [enableStreamingUpdates] = useStreamingUpdates();

  const [value, setValue] = useState<T | null>(null);

  useEffect(() => {
    flagManager.getValueWhenReady<T>(flagKey, defaultValue).then(newValue => {
      setValue(newValue);
    });
  }, [defaultValue, flagKey]);

  useEffect(() => {
    if (enableStreamingUpdates) {
      return flagManager.notifyOnChanged(flagKey, setValue);
    }
  }, [enableStreamingUpdates, setValue, flagKey]);

  return [value];
};

// Just made it simpler to safely type the return value this way since default is optional in regular hook
export const useRemoteFeatureFlagWithDefault = <T = boolean>(flagName: RemoteFeatureFlags, defaultValue: T): T => {
  const [value] = useRemoteFeatureFlag<T>(flagName, { defaultValue });
  return value ?? defaultValue;
};
