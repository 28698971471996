// ga is not available on window straight away, and getAll is not
// available on ga straight away either, so we protect the

import config from '@rikstv/play-common/src/config';
import { captureExceptionInSentry } from '@rikstv/play-common/src/utils/errorTracker/tracking';

export const getRiksTVAnalyticsHeader = async (): Promise<Record<string, string>> => {
  try {
    const gaClientId = await getGaClientId();
    return {
      ['X-RiksTV-AnalyticsId']: gaClientId ?? '',
    };
  } catch (err) {
    // Not getting a client id should not block further processing of an API call
    return {};
  }
};

// call, blocking until everything is ready or we give up.
let _gaClientId: string | undefined;
export const getGaClientId = (): Promise<string | null> =>
  new Promise((resolve, reject) => {
    let tries = 0;
    if (_gaClientId) {
      resolve(_gaClientId);
      return;
    }

    const retry = () => {
      if (window.gtag) {
        window.gtag('get', config.analytics.targetId, 'client_id', clientId => {
          if (typeof clientId !== 'string') {
            captureExceptionInSentry(new Error(`Unknown analytics clientId ${JSON.stringify(clientId)}`));
          } else {
            _gaClientId = clientId;
            resolve(_gaClientId);
          }
        });
      } else if (tries < 20) {
        tries++;
        setTimeout(retry, 100);
      } else {
        reject();
      }
    };
    retry();
  });
