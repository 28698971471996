const SENDER_SDK_URL = '//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';

let sdkLoadingPromise: Promise<void> | undefined;

export const loadCastSDK = (): Promise<void> => {
  // makes sure we only load script once
  if (!sdkLoadingPromise) {
    sdkLoadingPromise = new Promise((resolve, reject) => {
      window['__onGCastApiAvailable'] = (isAvailable: boolean) => onGCastApiAvailable(isAvailable, resolve);
      internalLoadCastSDK()
        .then(() => {
          console.info('Cast sender lib has been loaded successfully');
        })
        .catch(e => {
          console.warn('Cast sender lib loading failed', e);
          reject(e);
        });
    });
  }
  return sdkLoadingPromise;
};

const internalLoadCastSDK = (): Promise<void> => {
  if (window['cast'] && window['cast']['framework']) {
    return Promise.resolve();
  }
  return loadScriptAsync(SENDER_SDK_URL);
};

const onGCastApiAvailable = (isAvailable: boolean, resolve: () => void): void => {
  if (isAvailable) {
    console.info('Cast is available.');
    resolve();
  } else {
    console.warn('Google cast API is not available yet');
  }
};

interface HMTLScriptElementWithReadyStateChange extends HTMLScriptElement {
  onreadystatechange: ((this: GlobalEventHandlers, ev: Event) => any) | null;
}

function loadScriptAsync(url: string): Promise<any> {
  // Prevents chromecast framework from loading in Chrome on ios.
  // Chromecast makes chrome crash on iPhone 6 /iOS 10.3.3 and React crash on iPhone X / iOS 12 (and presumably others), but not every time it loads.
  // To reproduce, try refreshing the archive or series page several times. It seems to be some kind of memory issue, but since we do not need Chromecast support on mobile as we do not have playback support anyway,
  // we just don’t include the lib instead of spending time debugging this any further.
  if (!(/CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent))) {
    return new Promise((resolve, reject) => {
      let r = false;
      const t = document.getElementsByTagName('script')[0],
        s = document.createElement('script') as HMTLScriptElementWithReadyStateChange;
      s.type = 'text/javascript';
      s.src = url;
      s.async = true;
      s.onload = s.onreadystatechange = function () {
        if (!r && (!(this as any).readyState || (this as any).readyState === 'complete')) {
          r = true;
          resolve(this);
        }
      };
      s.onerror = s.onabort = reject;
      if (t && t.parentNode) {
        t.parentNode.insertBefore(s, t);
      }
    });
  }
  return Promise.reject();
}
