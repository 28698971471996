import { AccessTokenValue } from '@rikstv/play-common/src/forces/auth/auth.types';
import { RiksTVRequestError } from '@rikstv/play-common/src/forces/utils/errors';

import { getProvisioningUrlTV2, putProvisioningContinueTV2 } from '../../../apis/StrimGatewayAPI';

const TV2_PROVISION_CONFLICT = 'CONFLICT';
const NOT_FOUND_AT_TV2 = 'NOT_FOUND_AT_TV2';

const fetchTV2ProvisioningUrl = async (
  redirectUrl: string | null,
  accessTokenValue: AccessTokenValue | null | undefined
): Promise<string> => {
  try {
    const response = await getProvisioningUrlTV2(redirectUrl, accessTokenValue);
    return response.url;
  } catch (e) {
    if (e instanceof RiksTVRequestError && e.statusCode === 409) {
      if (e.response?.body.message === NOT_FOUND_AT_TV2) {
        return Promise.resolve(NOT_FOUND_AT_TV2);
      }
      return Promise.resolve(TV2_PROVISION_CONFLICT);
    }
    return Promise.reject('Unable to get TV 2 Play provisioning url.');
  }
};

const continueTV2PlayProvisioning = async (accessTokenValue: AccessTokenValue | null | undefined): Promise<void> => {
  try {
    await putProvisioningContinueTV2(accessTokenValue);
  } catch {
    return Promise.reject('Unable to continue TV 2 Play provisioning.');
  }
};

export { continueTV2PlayProvisioning, fetchTV2ProvisioningUrl, NOT_FOUND_AT_TV2, TV2_PROVISION_CONFLICT };
