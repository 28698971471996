import { Cache, useSWRConfig } from 'swr';

import { CollectionPage } from '../types';

/**
 *
 * We've set the useCollectionSwimlane to only revalidate the data if the cache is empty
 * So in order to get the swimlanes updated if there is changes, we delete it from the cache
 * if lastUpdated value change between fetching pages
 *
 * @param cacheKey url to currentPage
 * @param currentPage the respons from currentPage
 */
export const useInvalidateSwimlaneCache = (cacheKey: string, currentPage?: CollectionPage) => {
  const cache = useSWRConfig().cache as Cache<CollectionPage>;
  const cachedPage = cache.get(cacheKey)?.data;

  if (cachedPage?.swimlanes && currentPage?.swimlanes) {
    currentPage.swimlanes.map(swimlane => {
      const cachedSwimlane = cachedPage?.swimlanes.find(cachedSwimlane => cachedSwimlane.id === swimlane.id);
      if (cachedSwimlane?.lastUpdatedUtc && cachedSwimlane.lastUpdatedUtc !== swimlane.lastUpdatedUtc) {
        cache.delete(swimlane.link);
      }
    });
  }
};
