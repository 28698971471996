import React, { PropsWithChildren } from 'react';

import { captureExceptionInSentry, errorTracker } from '../../utils/errorTracker/tracking';
import { ErrorPage } from '../errorPage/ErrorPage';

const defaultProps = {
  debugMode: false,
};

type Props = Readonly<typeof defaultProps>;

const ignoredErrors = [/Failed to load image https:\/\/static.rikstv.no\/logopack/];

const errorSolvedByRefresh = [
  /Unable to preload CSS for/i, // happens in firefox and Chrome
  /Loading chunk \d+ failed/i,
  /Loading CSS chunk \d+ failed/i,
  // edge and chrome
  /Failed to fetch dynamically imported module/i,
  // firefox
  /error loading dynamically imported module/i,
  // Safari
  /Importing a module script failed/i,
];

class ErrorBoundaryAppContainer extends React.PureComponent<PropsWithChildren<Props>> {
  state = {
    currentError: null,
    errorInfo: null,
  };

  static defaultProps = defaultProps;

  componentDidCatch(error: Error | null, info: Record<string, any>) {
    error = error || new Error('Missing error!');

    const { message = '' } = error;
    const errorCanBeSolvedByRefresh = errorSolvedByRefresh.some(error => message.match(error) !== null);
    if (errorCanBeSolvedByRefresh) {
      // avoid ending up in reload-loop
      if (shouldTryReloadToSolveError()) {
        // This usually happens because there has been released a new version of the frontend, causing the chunk hashes to change.
        // If a user has an open app instance and doesn't reload the app, outdated chunk IDs will be used, causing load failures.
        // Reloading the page to get the updated chunk references.
        // If this happens multiple times in a row for the same user, we might have introduced a bug that causes reload loops.
        errorTracker.logMessage('Failed to load chunk. Reloading page to try n solve...', {
          tags: { errorMessage: message },
          extra: { errorObject: error, info },
        });
        document.location.reload();
        return;
      } else {
        errorTracker.logMessage('Failed to load chunk. No reload...', {
          tags: { errorMessage: message },
          extra: { errorObject: error, info },
        });
      }
    }

    if (ignoredErrors.some(error => message.match(error) !== null)) {
      return;
    }

    this.setState({
      currentError: error,
      errorInfo: info,
    });

    if (!errorCanBeSolvedByRefresh) {
      try {
        captureExceptionInSentry(error, { extra: { info } });
      } catch (loggingError) {
        console.log('Crashed during logging');
        // nothing to do really, we cannot log an error if the error logger fails.
      }
    }
  }

  render() {
    if (this.state.currentError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

const shouldTryReloadToSolveError = () => {
  const key = 'app.lazy-load.error-refresh-timestamp';
  const lastFailedAttempt = JSON.parse(sessionStorage.getItem(key) || '0');
  if (Date.now() - lastFailedAttempt > 10_000) {
    // more than 10 seconds since last reload-attempt
    sessionStorage.setItem(key, Date.now().toString());
    return true;
  }
  return false;
};

export { ErrorBoundaryAppContainer };
