import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import CoreScroll, { CoreScrollElement } from '@nrk/core-scroll/jsx';
import classNames from 'classnames';

import { PageContext } from '@rikstv/play-common/src/components/pages/PageContext';
import { Swimlane } from '@rikstv/play-common/src/components/pages/types';
import { Section } from '@rikstv/play-common/src/components/section/Section';
import { shouldShowSwimlanePageLink } from '@rikstv/play-common/src/components/swimlane/swimlane.utils';
import { ReactComponent as ChevronIcon } from '@rikstv/play-common/src/icons/svg/chevron-light.svg';
import { Link } from '@rikstv/play-common/src/router/Link';
import { commonRoutes } from '@rikstv/play-common/src/router/router.path';
import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';
import { H2, useIntersectionObserver } from '@rikstv/shared-components';

import { ScrollArrow } from './ScrollArrow';
import { useRovingIndex } from './useRovingIndex';

import styles from './ListScroller.module.css';

interface Props {
  className?: string;
  swimlane: PartialSwimlane;
  hideSectionTitle?: boolean;
  animate?: boolean;
  noPaddingBottom?: boolean;
  defaultSelectedIndex?: number;
  reportVisibility?: (target: HTMLElement) => void;
}
type PartialSwimlane = Pick<Swimlane, 'minItemsForShowMore' | 'id' | 'name' | 'style'>;

export type CoreScrollProps = CoreScroll['props'];

export const CoreScrollClass = styles.coreScrollElement;

export const ListScroller: FCC<{ title: string; className?: string; hideTitle?: boolean }> = ({
  children,
  title,
  className,
  hideTitle = true,
}) => {
  return (
    <ListScrollerSection
      swimlane={{
        id: '',
        name: title,
        style: 'Standard',
        minItemsForShowMore: Number.POSITIVE_INFINITY,
      }}
      className={className}
      hideSectionTitle={hideTitle}>
      {children}
    </ListScrollerSection>
  );
};

export const ListScrollerSection: FCC<Props> = ({
  children,
  swimlane,
  animate,
  reportVisibility,
  className,
  hideSectionTitle,
  defaultSelectedIndex,
  noPaddingBottom = false,
}) => {
  const { style, name: title } = swimlane;
  const arrayChildren = React.Children.toArray(children) as React.ReactElement[];
  const [state, setState] = useState<{ left?: () => void; right?: () => void }>({});
  const rovingIndex = useRovingIndex();
  const pageContext = useContext(PageContext);
  const hideSwimlaneTitle = hideSectionTitle ?? pageContext?.hideSwimlaneTitle ?? false;
  const scrollToInitialItem = useScrollToInitialItem(swimlane.id, defaultSelectedIndex);
  const { reportOnScroll, swimlaneItemTriggerRef } = useReportVisibility(reportVisibility);
  const [autoAnimateRef, enableAutoAnimate] = useAutoAnimate();
  enableAutoAnimate(animate === true);

  const initScrollArrows = useCallback(
    (target: CoreScrollElement | null) => {
      setState({
        left: target?.scrollLeft ? () => target.scroll('left') : undefined,
        right: target?.scrollRight ? () => target.scroll('right') : undefined,
      });
      if (target) {
        scrollToInitialItem(target);
      }
    },
    [scrollToInitialItem]
  );

  const onScroll: CoreScrollProps['onScrollChange'] = ({ target }) => {
    initScrollArrows(target);
    reportOnScroll(target);
  };

  const isStandardPortrait = style === 'StandardPortrait';
  const isHeroPortrait = style === 'HeroPortrait';
  const isSquareMenuItems = style === 'MenuText' || style === 'MenuLogo';
  const isCircleMenuItems = style === 'MenuCircle';
  const isNumberedStandard = style === 'StandardNumbered';
  const isNumberedPortrait = style === 'NumberedPortrait';
  const listId = title ? `swimlane-${title.replace(/\s/g, '-')}` : undefined;

  return (
    <Section type="transparent" noPaddingTop noPaddingBottom={noPaddingBottom}>
      <div className={styles.scrollerTitleWrapper}>
        {title && (
          <H2 id={listId} lookLike="title-3" className={classNames('rds-gutter', { 'sr-only': hideSwimlaneTitle })}>
            {title}
          </H2>
        )}
        <ShowMoreLink swimlane={swimlane} nrOfItems={arrayChildren.length} />
      </div>
      <div className={styles.coreScrollContainer}>
        <ScrollArrow
          className={styles.scrollNavigationArrow}
          disabled={!state.left}
          onClick={state.left}
          withBorder={isSquareMenuItems}
        />
        <CoreScroll
          data-swimlane-wrapper
          forwardRef={swimlaneItemTriggerRef}
          items="li"
          onScrollChange={onScroll}
          className={styles.coreScrollElement}>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <ul
            ref={autoAnimateRef}
            aria-labelledby={listId}
            className={classNames(
              {
                [styles.scrollerListGridStandardPortrait]: isStandardPortrait,
                [styles.scrollerListGridHeroPortrait]: isHeroPortrait,
                [styles.scrollerListFlex]: isSquareMenuItems,
                [styles.scrollerListGridMenuCircle]: isCircleMenuItems,
                [styles.scrollerListGrid]: !isSquareMenuItems && !isSquareMenuItems,
                [styles.scrollerListGridNumberedStandard]: isNumberedStandard,
                [styles.scrollerListGridNumberedPortrait]: isNumberedPortrait,
                [styles.scrollerListGridLive]: style === 'Live',
                [styles.scrollerListGridFeatured]: style === 'Featured' || style === 'MenuThumbnail',
              },
              className
            )}
            // Handle roving index handling
            onKeyDown={rovingIndex.onKeyDown}
            onFocus={rovingIndex.onFocus}
            onMouseDown={rovingIndex.onMouseDown}>
            {arrayChildren.map(child => (
              <li className={styles.scrollerItem} key={'scroller-list-' + child.key}>
                {child}
              </li>
            ))}
            <div className={styles.gutterChild}></div>
          </ul>
        </CoreScroll>
        <ScrollArrow
          className={styles.scrollNavigationArrow}
          disabled={!state.right}
          onClick={state.right}
          right
          withBorder={isSquareMenuItems}
        />
      </div>
    </Section>
  );
};

export const ShowMoreLink: FC<{
  swimlane: PartialSwimlane;
  nrOfItems: number;
}> = ({ swimlane, nrOfItems }) => {
  const pageContext = useContext(PageContext);

  if (!pageContext) {
    return null;
  }

  const { minItemsForShowMore, id } = swimlane;
  const { slug, hideSwimlaneTitle } = pageContext;
  const linkUrl = commonRoutes.swimlane({ slug, swimlaneId: id });
  const showSwimlanePageLink = shouldShowSwimlanePageLink(minItemsForShowMore, hideSwimlaneTitle, nrOfItems);

  if (!showSwimlanePageLink) {
    return null;
  }

  return (
    <Link className={styles.scrollerTitleLink} to={linkUrl}>
      Vis flere
      <span className="sr-only">av {swimlane.name}</span>
      <ChevronIcon className={styles.chevronIcon} height="24px" width="30px" aria-hidden="true" />
    </Link>
  );
};

const useScrollToInitialItem = (swimlaneId: string, defaultSelectedIndex?: number) => {
  const { state: navigationState } = useLocation();
  const setInitialScroll = useRef(true);
  const initialElementIndex =
    navigationState?.scrollRestore?.swimlaneId === swimlaneId
      ? (navigationState.scrollRestore.elementIndex as number)
      : defaultSelectedIndex;

  return useCallback(
    (target: CoreScrollElement) => {
      //only scroll to initial element index on first render
      if (setInitialScroll.current === true && initialElementIndex && initialElementIndex >= 0) {
        target?.scroll(target.items[initialElementIndex]);
      }
      setInitialScroll.current = false;
    },
    [initialElementIndex]
  );
};

const useReportVisibility = (reportVisibility?: (target: HTMLElement) => void) => {
  const [isInViewport, setIsInViewport] = useState(false);
  const [swimlaneItemTriggerRef] = useIntersectionObserver(
    list =>
      list.map(item => {
        if (item.isIntersecting && !isInViewport && reportVisibility) {
          setIsInViewport(true);
          //TODO @adam reportVisibility only needs to be called here the first time it comes into viewport
          reportVisibility(item.target as HTMLElement);
        }
        if (!item.isIntersecting && isInViewport) {
          setIsInViewport(false);
        }
      }),
    { threshold: 1 }
  );

  const reportOnScroll = (target?: (HTMLElement & CoreScrollElement) | null) => {
    if (target && isInViewport && reportVisibility) {
      reportVisibility(target);
    }
  };

  return {
    reportOnScroll,
    swimlaneItemTriggerRef,
  };
};
