import { playbackActions } from '@rikstv/play-player/src/forces/player.slice';
import { selectCurrentPlayable, selectStreamingDevice } from '@rikstv/play-player/src/forces/selectors';

import { searchResultClicked, searchResultViewed } from '../../pages/search/utils/search.actions';
import { externalServiceLaunched } from '../externalService/actions';
import { CommonMiddleware } from '../root.reducer';
import { locationChange } from '../ui/ui.slice';
import { wishListToggleAction } from '../wishlist/actions';

import { swimlaneClickedAction, swimlaneImpressionAction, toggleFavoriteChannelAction } from './commonAnalyticsActions';
import { CommonAnalyticsService } from './commonAnalyticsService';

export function getCommonAnalyticsMiddleware(analyticsService: CommonAnalyticsService): CommonMiddleware {
  return store => next => action => {
    switch (true) {
      case playbackActions.onPlayerStateEvent.match(action): {
        const { payload } = action;
        if (payload.state === 'start') {
          const state = store.getState();
          const streamingDevice = selectStreamingDevice(state);
          const playable = selectCurrentPlayable(state);
          if (playable) {
            analyticsService.streamStart(streamingDevice, playable);
          }
        }
        if (payload.state === 'stop') {
          analyticsService.streamStop(payload.secondsSinceLastEvent);
        }
        if (payload.state === 'pause') {
          analyticsService.streamPause(payload.secondsSinceLastEvent);
        }
        break;
      }
      case playbackActions.onKeepAliveEvent.match(action): {
        analyticsService.streamKeepAlive(action.payload.secondsSinceLastEvent);
        break;
      }
      case locationChange.match(action): {
        const { payload } = action;
        analyticsService.trackScreen(payload.pathname, payload.isLoggedIn);
        break;
      }
      case swimlaneClickedAction.match(action): {
        analyticsService.swimlaneClicked(action.payload);
        break;
      }
      case swimlaneImpressionAction.match(action): {
        analyticsService.swimlaneImpression(action.payload);
        break;
      }
      case wishListToggleAction.match(action): {
        if (action.payload) {
          analyticsService.addedToMyList();
        } else {
          analyticsService.removeFromMyList();
        }
        break;
      }
      case externalServiceLaunched.match(action): {
        const { payload } = action;

        window.dataLayer?.push({
          event: 'launch_external_app',
          service_name: payload.serviceId,
          element_id: payload.elementId,
          element_name: payload.elementName,
        });

        break;
      }
      case searchResultClicked.match(action): {
        const {
          payload: { search, title, position, listTitle, listPosition },
        } = action;
        analyticsService.searchResultClicked(search, title, position, listTitle, listPosition);
        break;
      }
      case searchResultViewed.match(action): {
        analyticsService.searchResultViewed(action.payload.search);
        break;
      }
      case toggleFavoriteChannelAction.match(action): {
        const {
          payload: { channelId, isFavorite },
        } = action;
        analyticsService.toggleFavoriteChannel(channelId, isFavorite);
        break;
      }
      default:
        break;
    }

    return next(action);
  };
}
