import config from '../../config';

import { CollectionPage } from './types';

const fallbackDescription = (title: string) =>
  `Stream tusenvis av timer med TV, sport, film og serier på nett med Strim. Vi samler markedets beste underholdning på ett sted så du enkelt finner all ${title} du trenger.`;
export function getMetaProperties(
  application: typeof config.application,
  page: CollectionPage
): { title: string; description?: string } {
  const title = page.seoMetadata?.title ? page.seoMetadata.title : page.title;
  const fallback = application === 'RiksTV' ? page.description : fallbackDescription(page.title);
  const description = page.seoMetadata?.description ? page.seoMetadata.description : fallback;

  return { title, description };
}
