import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { CoverPageSectionHeader } from '../sections/CoverPageSectionHeader';

import styles from './CoverPageGrid.module.css';

interface Props {
  className?: string;
  children: ReactNode;
  title?: string;
  portrait?: boolean;
}

export const CoverPageGrid: FC<Props> = ({ children, className = '', title, portrait = false }) => {
  return (
    <>
      {title && <CoverPageSectionHeader>{title}</CoverPageSectionHeader>}
      <section className={classNames(styles.grid, className, { [styles.gridPortrait]: portrait })}>{children}</section>
    </>
  );
};
