import { useEffect, useState } from 'react';
import { differenceInHours } from 'date-fns';

import { getLogger } from '../utils/logger/logger';

const MAX_INACTIVE_HOURS = (() => {
  try {
    const override = parseFloat(localStorage.getItem('max_inactive_hours') || '-1');
    if (Number.isFinite(override) && override >= 0 && override < 12) {
      return override;
    }
  } catch {
    /* */
  }
  // Default
  return 10;
})();

const logger = getLogger('[inactivityMonitor]');
let epochWhenBackGrounded = Date.now();

/**
 * @description Returns bool based on how long app has been in the background.
 */
export const useInactivityMonitor = (enabled = true) => {
  const [activeAfterLongInactivity, setActiveAfterLongInactivity] = useState(false);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    logger.info({ MAX_INACTIVE_HOURS });

    const onChange = () => {
      if (document.hidden === true) {
        epochWhenBackGrounded = Date.now();
      }
      if (document.hidden === false) {
        const hoursInBackground = differenceInHours(Date.now(), epochWhenBackGrounded);
        if (hoursInBackground >= MAX_INACTIVE_HOURS) {
          logger.info(`back in action after ${hoursInBackground}s`);
          setActiveAfterLongInactivity(true);
        }
      }
    };
    document.addEventListener('visibilitychange', onChange);
    return () => document.removeEventListener('visibilitychange', onChange);
  }, [enabled]);

  return { activeAfterLongInactivity };
};
