import { playbackActions } from '@rikstv/play-player/src/forces/player.slice';
import { selectCurrentPlayable, selectStreamingSessionId } from '@rikstv/play-player/src/forces/selectors';
import { isPlayableChannel } from '@rikstv/play-player/src/utils/playable';

import { authService } from '../../utils/auth/AuthService';
import { captureExceptionInSentry } from '../../utils/errorTracker/tracking';
import { debounce } from '../../utils/eventHandler/debounce';
import { externalServiceLaunched } from '../externalService/actions';
import { api } from '../externalService/api';
import { CommonMiddleware } from '../root.reducer';

import { updateProgress, updateProgressSync } from './api';

type ProgressActionCreators = typeof playbackActions.onPlayerStateEvent;

type ProgressAction = ReturnType<ProgressActionCreators | typeof externalServiceLaunched>;

type Args = {
  progressUrl: string;
  roundedPosition: number;
  sessionId: string;
};
const debouncedProgressUpdate = debounce(({ progressUrl, roundedPosition, sessionId }: Args) => {
  updateProgress(progressUrl, roundedPosition, sessionId, authService.getToken().value);
}, 500);

// TODO: must this really be middleware? Seems simpler if these functions are called explicitly from the calling code
export const progressUpdateMiddleware: CommonMiddleware = store => next => (action: ProgressAction) => {
  const { type } = action;
  if (playbackActions.onPlayerStateEvent.match(action)) {
    const state = store.getState();
    const playable = selectCurrentPlayable(state);

    if (authService.isAuthenticated() && playable && !isPlayableChannel(playable)) {
      try {
        const { secondsElapsedFromStart } = action.payload;

        const progressUrl = playable.links.progressUrl;

        if (progressUrl && secondsElapsedFromStart != null) {
          const roundedPosition = Math.floor(secondsElapsedFromStart);
          const sessionId = selectStreamingSessionId(state);

          if (action.payload.state === 'destroy') {
            if (action.payload.onBeforeUnload) {
              // The logging must be done synchronously to complete before the window is closed and the request aborted.
              updateProgressSync(progressUrl, roundedPosition, sessionId, authService.getToken().value);
              return next(action);
            }
          }
          debouncedProgressUpdate({
            progressUrl,
            roundedPosition,
            sessionId,
          });
        }
      } catch (error) {
        captureExceptionInSentry(error, { fingerprint: ['updateProgress() failed'] });
      }
    }
  } else if (externalServiceLaunched.toString() === type && action.payload.reportUrl) {
    api.addExternalAssetToContinueWatching(action.payload.reportUrl);
  }

  return next(action);
};
