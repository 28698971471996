import { FC, useEffect, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import classNames from 'classnames';

import { Gutter, H2, Loader } from '@rikstv/shared-components';

import { SwimlaneItem } from '../../forces/assetDetails/assetDetails.types';
import { Swimlane } from '../pages/types';
import { ItemCountReporterFunction } from '../pages/useEmptyCollectionReporting';
import { Section } from '../section/Section';

import { useReportSwimlaneClick } from './coreScrollSwimlane/useImpressionTracking';
import { StandardItem } from './styles/StandardItem';
import { SwimlanePlaceholder } from './swimlanePlaceholder/SwimlanePlaceholder';
import { getPlaceholderHeight } from './utils/getPlaceholderHeight';
import { usePaginatedSwimlane } from './usePaginatedSwimlane';

import styles from './PaginatedSwimlane.module.css';

type Props = {
  animate?: boolean;
  swimlane: Swimlane;
  hideSwimlaneTitle: boolean;
  reportSwimlaneItems?: ItemCountReporterFunction;
};

export const PaginatedSwimlane: FC<Props> = ({ swimlane, hideSwimlaneTitle, reportSwimlaneItems, animate }) => {
  // fetch actual content
  const [swimlaneItems, setSwimlaneItems] = useState<SwimlaneItem[]>([]);
  const { paginationTriggerRef, isLoading } = usePaginatedSwimlane(swimlane, setSwimlaneItems);
  const [autoAnimateRef, enableAutoAnimate] = useAutoAnimate();
  const { reportSwimlaneClick } = useReportSwimlaneClick(swimlane);
  const portrait = swimlane.style === 'StandardPortrait' || swimlane.style === 'SwimmingPoolPortrait';

  enableAutoAnimate(animate === true);

  useEffect(() => {
    reportSwimlaneItems?.(swimlane.id, swimlaneItems.length);
  }, [reportSwimlaneItems, swimlane.id, swimlaneItems.length]);

  return (
    <Section noPaddingTop type="transparent">
      <div>
        <H2 lookLike="title-3" className={classNames('rds-gutter', { 'sr-only': hideSwimlaneTitle })}>
          {swimlane.name}
        </H2>
      </div>
      <Gutter>
        <div
          className={classNames(styles.paginatedSwimlane, {
            [styles.paginatedSwimlanePortrait]: portrait,
          })}
          ref={autoAnimateRef}>
          {swimlaneItems.map((item, idx) => {
            return (
              <StandardItem
                key={swimlane.id + item.id}
                asset={item}
                onClick={() => reportSwimlaneClick(item, idx)}
                swimlaneType={swimlane.type}
                noMargin
                portrait={swimlane.style === 'StandardPortrait' || swimlane.style === 'SwimmingPoolPortrait'}
              />
            );
          })}
        </div>
      </Gutter>
      {isLoading && swimlaneItems.length === 0 && <SwimlanePlaceholder height={getPlaceholderHeight(swimlane.style)} />}
      {swimlaneItems.length > 0 && (
        <>
          {paginationTriggerRef && <div ref={paginationTriggerRef}>&nbsp;</div>}
          <div style={{ position: 'relative', paddingTop: '3rem' }}>{isLoading && <Loader />}</div>
        </>
      )}
    </Section>
  );
};
