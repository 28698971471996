import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { getCommonMiddleware } from '@rikstv/play-common/src/forces/commonMiddleware';
import { captureExceptionInSentry } from '@rikstv/play-common/src/utils/errorTracker/tracking';

import { usePackageStore } from '../../domain/packages/store/usePackageStore';
import rootReducer, { StrimState } from '../../root.reducer';
import rootSaga from '../../root.saga';
import { analyticsMiddleware } from '../../utils/analytics/analyticsMiddleware';
import { strimAnalytics } from '../../utils/analytics/strimAnalytics';
const sagaMiddleware = createSagaMiddleware({
  onError: (error, errorInfo) =>
    captureExceptionInSentry(error, { extra: { errorInfo }, fingerprint: ['saga-middleware'] }),
});

export default (initialState?: Partial<StrimState>) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      sagaMiddleware,
      analyticsMiddleware,
      ...getCommonMiddleware(strimAnalytics),
    ],
    preloadedState: initialState,
  });

  // kick off fetching and root saga
  usePackageStore.getState().fetchPackages();
  sagaMiddleware.run(rootSaga);

  return store;
};
