import { useEffect, useState } from 'react';

import { authService } from '../utils/auth/AuthService';

export const useAuthToken = () => {
  const [token, setToken] = useState<string | null>(authService.getToken().value || null);

  useEffect(() => {
    return authService.onTokenChanged(setToken);
  }, []);

  return token;
};
