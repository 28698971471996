import { useRef } from 'react';
import { Navigate } from 'react-router-dom';

import { errorTracker } from '../../utils/errorTracker/tracking';
import type { InternalRedirectRoute as InternalRedirect } from '../routes.type';

export const InternalRedirectRoute = ({
  path: urlPattern,
  redirectPath,
  options,
}: Omit<InternalRedirect, 'path' | 'type'> & { path: string }) => {
  const isWildcardRedirect: boolean = urlPattern.endsWith('/*') && options?.wildcardKeepPath === true;
  const redirectWithQuery = getFullRedirectUrl({ urlPattern, to: redirectPath, isWildcardRedirect });

  const trackRedirect = useRef(options?.trackRedirect === true);
  if (trackRedirect.current) {
    trackRedirect.current = false;
    // Helps us keep track of redirect usage when moving url's to see if there are many
    // external dependencies that need to be updated. Dashboard available at:
    // https://rikstv-h0.sentry.io/dashboard/820/?environment=prod&project=5783662&project=5783706&statsPeriod=3d
    errorTracker.logMessage(`Internal redirect: ${urlPattern}`, {
      tags: { redirectFrom: location.href.replace(location.origin, ''), redirectTo: redirectWithQuery },
    });
  }

  return <Navigate to={redirectWithQuery} replace />;
};

/**
 * @example: with wildcard: pattern='/apple/*' redirect='/banana' => (URL) "/apple/eat?koko=kaka"  => redirects to => "/banana/eat?koko=kaka"
 */
const getFullRedirectUrl = ({
  urlPattern,
  to,
  isWildcardRedirect,
}: {
  urlPattern: string;
  to: string;
  isWildcardRedirect: boolean;
}): string => {
  if (isWildcardRedirect) {
    const pathSegment = location.pathname.toLowerCase().replace(urlPattern.replace('/*', '').toLowerCase(), '');
    return to + pathSegment + location.search;
  }
  return to + location.search;
};
