import type GatewayAPI from '@strim/gateway-api';
import { SubscriptionsTypes } from '@strim/gateway-api';

import { channelOrderPriority, PACKAGE_URLS, PackageKeys } from './constants';
import { Package, PackageChannel, PackageType } from './packages.types';

type ApiPackage = GatewayAPI.SettingsResponse['packages'][number];
type ApiChannel = ApiPackage['channels'];

const sortChannelsByLogoMapOrder = (channels: PackageChannel[]) => {
  // all channels are not part of map so add 1 to indexOf result and default to something high
  // so that unlisted channels end up last in the list
  const getIdx = (name: string) => channelOrderPriority.indexOf(name) + 1 || 999;
  return channels.sort((a, b) => getIdx(a.name) - getIdx(b.name));
};

const mapToChannels = (channelsResponse: ApiChannel): PackageChannel[] => {
  const channels = channelsResponse.map(channelResponse => ({
    id: channelResponse.id,
    name: channelResponse.name,
    displayName: channelResponse.displayName,
    logo: channelResponse.baseImageName,
  }));
  return sortChannelsByLogoMapOrder(channels);
};

// Export in order to access function in test
export const getCMSPackageUrl = (name: PackageKeys) => {
  return PACKAGE_URLS[name];
};

export const getPackageType = (packageName: SubscriptionsTypes.Ids | undefined): PackageType => {
  if (!packageName) {
    return 'primary';
  }
  const hasViaplayTotal = packageName.includes('VIAPLAY_TOTAL');
  const hasSport = packageName.includes('SPORT');
  const isSportskanaler = packageName.includes('SPORTSKANALER');

  if (hasViaplayTotal && hasSport) {
    return 'sport_premium';
  }
  if (isSportskanaler) {
    return 'sportskanaler';
  }
  if (hasSport) {
    return 'sport';
  }
  return 'primary';
};

const mapToPackage = (response: ApiPackage): Package => {
  return {
    id: response.name,
    name: response.displayName,
    channels: mapToChannels(response.channels),
    price: response.price,
    packageType: getPackageType(response.name),
    url: getCMSPackageUrl(response.name),
    isDiscountable: response.isDiscountable,
    offersTrialPeriod: response.offersTrialPeriod,
    discount: response.discount,
  };
};

export const mapToPackages = (packages: ApiPackage[]) =>
  packages.map(pkg => mapToPackage(pkg)).sort((a, b) => a.price - b.price);
