import commonConfig from '@rikstv/play-common/src/config';
import { commonRouterPaths, commonRoutes } from '@rikstv/play-common/src/router/router.path';
import { replaceParameters } from '@rikstv/play-common/src/router/router.utils';
import { sharedStrimUrls, webAppLinks } from '@rikstv/shared-strim-paths';

import config from '../../config';

const accountRoot = sharedStrimUrls.account;
const packageRoot = '/package';
export const routerPaths = {
  ...commonRouterPaths,
  // Landing page
  app: '/app',

  // help
  help: '/hjelp',

  // company pages
  companyTerms: '/vilkar',
  companyPersonvern: sharedStrimUrls.personvern,

  // SignUp/Register
  signUpPackageSelect: sharedStrimUrls.signup,
  signUpRegisterAccount: `${sharedStrimUrls.signup}/registrer`,

  // Account
  account: accountRoot,
  accountInfoPage: `${accountRoot}/informasjon`,
  accountSubscriptionPage: `${accountRoot}/abonnement`,
  accountExternalServicePage: `${accountRoot}/strommetjenester`,
  accountPaymentPage: `${accountRoot}/betaling`,
  accountUserSettingsPage: `${accountRoot}/innstillinger`,
  paymentMethodChange: `${accountRoot}/betaling/endre`,
  accountReferralPage: `${accountRoot}/vennerabatt`,
  changePassword: `${accountRoot}/passord`,
  resetPassword: `${accountRoot}/passord/reset`,
  changeEmail: `${accountRoot}/epost`,
  changePhoneNumber: `${accountRoot}/telefon`,
  logout: '/logout',
  appletv: '/appletv',
  deviceLogin: '/device',
  logoutCallback: '/logout/:action',
  oAuthCallback: '/app/callback',

  //package change
  packageConfirmCancel: `${packageRoot}/cancel/confirm`,
  packageConfirmedPrewinbackOffer: `${packageRoot}/cancel/confirm/receipt`,

  stopTrialPeriodPage: `${packageRoot}/cancel/trial`,
  // up or downgrade, not modal
  packagesCancelReceipt: `${packageRoot}/cancel/receipt`,
  packagesUpgradeReceipt: `${packageRoot}/upgrade/receipt`,
  packagesDowngradeReceipt: `${packageRoot}/downgrade/receipt`,
  packagesDowngradeAbortedReceipt: `${packageRoot}/downgrade_aborted/receipt`,
  packagesActivateReceipt: `${packageRoot}/activate/receipt`,
  packagesNewReceipt: `${packageRoot}/new/receipt`,

  // payment
  purchaseProducts: `${accountRoot}/kjøp`,
  // TODO: move in under /account/...
  vippsReturnUrl: `/payment/vipps`,

  //activation
  externalActivationCallback: '/external-activation-callback',
  activateViaplay: '/aktiver-viaplay',
  activateTv2: '/aktiver-tv2',
  activateMax: '/aktiver-max',
  activateTV: '/aktiver',

  // external
  requestResetPassword: `${commonConfig.auth.stsUrl}${config.stsResetPasswordPath}`,
} as const;

const CommonRoutesWithStrimPages = {
  ...commonRoutes,
  // Landing page
  frontpage: () => routerPaths.frontpage,
  tvGuide: () => '/tv-guide',

  // Strommetjenester pages owned by cms app
  strommetjenester: () => webAppLinks.strommetjenester,
  nordiskfilm: () => webAppLinks.strommetjenesterNordiskfilm,
  skyshowtime: () => webAppLinks.strommetjenesterSkyShowtime,
  vsport: () => webAppLinks.strommetjenesterVsport,
  max: () => webAppLinks.strommetjenesterMax,
  viaplay: () => webAppLinks.strommetjenesterViaplay,
  nrk: () => webAppLinks.strommetjenesterNrk,
  tv2play: () => webAppLinks.strommetjenesterTv2play,
  tv2sport: () => webAppLinks.strommetjenesterTv2sport,
  eurosport: () => webAppLinks.strommetjenesterEurosport,

  companyTerms: () => routerPaths.companyTerms,
  companyPersonvern: () => routerPaths.companyPersonvern,

  help: () => webAppLinks.hjelp,
  app: () => routerPaths.app,

  // Play
  signUpSelectPackage: () => routerPaths.signUpPackageSelect,
  signUpWithProducts: (productIds: Array<{ id: string }>) => {
    return replaceParameters({
      path: routerPaths.signUpRegisterAccount,
      parameters: {},
      qs: { productIds: productIds.map(p => p.id) },
    });
  },
  resetPassword: () => routerPaths.resetPassword,
  requestResetPassword: () => routerPaths.requestResetPassword,
  changePassword: () => routerPaths.changePassword,
  changeEmail: () => routerPaths.changeEmail,
  changePhoneNumber: () => routerPaths.changePhoneNumber,
  logout: () => routerPaths.logout,
  account: () => routerPaths.account,
  accountExternalServicePage: () => routerPaths.accountExternalServicePage,
  accountSubscriptionPage: () => routerPaths.accountSubscriptionPage,
  accountPaymentPage: () => routerPaths.accountPaymentPage,
  accountPlayerSettingsPage: () => routerPaths.accountUserSettingsPage,
  activateViaplay: () => routerPaths.activateViaplay,
  vippsReturnUrl: () => routerPaths.vippsReturnUrl,
  packageConfirmCancel: () => routerPaths.packageConfirmCancel,
  stopTrialPeriodPage: (products: Array<{ id: string }>) =>
    replaceParameters({
      path: routerPaths.stopTrialPeriodPage,
      parameters: {},
      qs: { productIds: products.map(p => p.id) },
    }),
  packagesUpgradeReceipt: () => routerPaths.packagesUpgradeReceipt,
  packagesDowngradeReceipt: () => routerPaths.packagesDowngradeReceipt,
  packagesDowngradeAbortedReceipt: () => routerPaths.packagesDowngradeAbortedReceipt,
  packagesActivateReceipt: () => routerPaths.packagesActivateReceipt,
  packagesNewReceipt: () => routerPaths.packagesNewReceipt,
  packagesCancelReceipt: () => routerPaths.packagesCancelReceipt,
  purchaseProducts: (productIds: string[]) =>
    replaceParameters({
      path: routerPaths.purchaseProducts,
      parameters: {},
      qs: {
        productIds,
      },
    }),
  logoutSuccess: () =>
    replaceParameters({
      path: routerPaths.logoutCallback,
      parameters: {
        action: 'success',
      },
    }),
};

export default CommonRoutesWithStrimPages;
