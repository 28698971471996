import { FC } from 'react';
import classNames from 'classnames';

import { Body, Gutter, H1 } from '@rikstv/shared-components';

import { getImagePackSet } from '../../../utils/imagePack/imagePack.utils';
import { AssetImage } from '../../assetImage/AssetImage';
import { Breakpoints } from '../../grid/breakpoints';
import { CollectionPage } from '../types';
import { usePages } from '../usePages';

import styles from './PageHeader.module.css';

export const PageHeader: FC<CollectionPage> = ({ title, description, backgroundImage, slug, type }) => {
  const { pages } = usePages();

  const firstSlug = pages && pages[0]?.slug;
  const isStartPage = firstSlug === slug;

  return (
    <>
      {backgroundImage && (
        <div className={styles.imageWrapper}>
          <AssetImage
            imageSet={getImagePackSet({
              url: backgroundImage,
              variations: [
                { width: 1500, breakpoint: Breakpoints.XXL },
                { width: 800, breakpoint: Breakpoints.LG },
                { width: 600, breakpoint: Breakpoints.MD },
                { width: 800, breakpoint: Breakpoints.DEFAULT },
              ],
            })}
            alt={title}
            className={styles.image}
          />
        </div>
      )}
      {!isStartPage && (
        <Gutter
          className={classNames(styles.headingWrapper, {
            [styles.noImage]: !backgroundImage,
            [styles.explorationPage]: type === 'exploration',
          })}>
          <H1 className={styles.title}>{title}</H1>
          {description && <Body className={styles.description}>{description}</Body>}
        </Gutter>
      )}
    </>
  );
};
