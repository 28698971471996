/**
 * Calls provided function with `ms` wait between each call, stops when `fnCondition` returns `completed`
 */
interface PollOptions<TRes> {
  pollingFunction: () => Promise<TRes>;
  condition: (res: TRes, retryAttempt: number) => 'continue' | 'completed';
  /**
   * Milliseconds or a function that returns milliseconds
   */
  sleep: number | ((attempt: number) => number);
}
export const poll = async <TRes>({ pollingFunction, condition, sleep }: PollOptions<TRes>) => {
  let attempt = 0;
  let result = await pollingFunction();
  while (condition(result, attempt++) === 'continue') {
    await delay(sleep instanceof Function ? sleep(attempt) : sleep);
    result = await pollingFunction();
  }
  return result;
};

export const delay = async (ms: number) => {
  await new Promise<void>(resolve => setTimeout(resolve, ms));
};
