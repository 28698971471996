export const isNorwegianMobileNumberRegex = /^(4|9)((\d{7})|(\d\s?\d\s?\d\s?\d\s?\d\s?\d\s?\d\s?)\s*)$/;

export const formatPhoneNumber = (num = '') => {
  if (!isValidPhoneNumber(num)) {
    return num;
  }
  return num
    .split('')
    .reduce((formattedNumber, digit, idx) => `${formattedNumber}${[3, 5].includes(idx) ? ' ' : ''}${digit}`, '');
};

export const normalizePhoneNumber = (phoneNumber: string) => {
  const normalizedNumber = phoneNumber.replace(/\+|\s/g, '').replace(/^00/, '');
  return normalizedNumber.length === 8 ? `47${normalizedNumber}` : normalizedNumber;
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  // Ok with leading "47" + 8 digits
  return /^(47)?\d{8}$/.test(phoneNumber);
};
