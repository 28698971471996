import * as React from 'react';

import { AppRoutes, ROUTE_TYPE_NORMAL } from '@rikstv/play-common/src/router/routes.type';

import { LayoutDark } from '../containers/layout/LayoutDark';

import { routerPaths } from './router.path';

const Personvern = React.lazy(() =>
  import('../../domain/pages/page/Personvern').then(module => ({
    default: module.Personvern,
  }))
);
const Vilkar = React.lazy(() =>
  import('../../domain/pages/page/Vilkar').then(module => ({
    default: module.Vilkar,
  }))
);
const routes: AppRoutes = [
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.companyPersonvern,
    component: Personvern,
    layout: LayoutDark,
    waitForStartup: false,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.companyTerms,
    component: Vilkar,
    layout: LayoutDark,
    waitForStartup: false,
  },
];

export { routes as routesCompany };
