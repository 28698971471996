import { StrimState } from '../../../root.reducer';
import { isNorwegianMobileNumberRegex } from '../../../utils/mobile-number/mobilenumber.utils';

export const selectUserStatusTV2PlayActivation = (state: StrimState) => {
  const { userInfo } = state.user;
  if (!userInfo) {
    return 'INVALID_USER';
  }
  if (!userInfo.personalia.firstName || !userInfo.personalia.lastName) {
    return 'INVALID_USER';
  }
  if (!isNorwegianMobileNumberRegex.test(userInfo.personalia.phoneNumber ?? '')) {
    return 'INVALID_NUMBER';
  }
  if (!userInfo.personalia.email) {
    return 'INVALID_EMAIL';
  }
  return 'READY';
};
