import { FC, useRef } from 'react';
import classNames from 'classnames';

import { commonGoogleTagManager } from '@rikstv/play-common/src/utils/analytics/googleTagManager';
import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';
import { PrimaryButton } from '@rikstv/shared-components';

import { Playable } from '../../utils/playable';

import styles from './SkipContent.module.css';

const HIDE_AFTER_SECONDS = 10; // Default when not displayed the entire timespan

interface Props {
  playable: Playable;
  currentTime: number;
  alwaysVisible?: boolean;
  setCurrentTime(seconds: number): void;
  classes?: Record<'default' | 'visible' | 'hidden', string>;
}

const SkipContent: FC<Props> = ({
  playable,
  currentTime,
  alwaysVisible, // (Don't auto-hide after HIDE_AFTER_SECONDS seconds)
  setCurrentTime,
  classes = { default: styles.playerButton, visible: styles.visible, hidden: '' },
}) => {
  const buttonText = useRef<string>();

  if (!authService.isInternalUser()) {
    return null;
  }

  const seekToPositions = playable?.interactionPoints?.seekToPositions;

  if (!seekToPositions) {
    return null;
  }

  const seekPos = seekToPositions.find(
    seekPos =>
      // Always visible (menu is visible or forced), check if current time is within time span
      (alwaysVisible && currentTime >= seekPos.startOffset && currentTime <= seekPos.endOffset) ||
      // Default, hide after HIDE_AFTER_SECONDS number of seconds
      (currentTime >= seekPos.startOffset && currentTime <= seekPos.startOffset + HIDE_AFTER_SECONDS)
  );

  // Don't loose button text when fade out of button starts
  if (seekPos) {
    buttonText.current = seekPos.label;
  }

  return (
    <PrimaryButton
      className={classNames(classes.default, { [classes.visible]: !!seekPos }, { [classes.hidden]: !seekPos })}
      aria-label={buttonText.current}
      onClick={() => {
        if (seekPos) {
          setCurrentTime(seekPos.skipTo);
          commonGoogleTagManager.skippingContentInPlayer();
        }
      }}>
      {buttonText.current}
    </PrimaryButton>
  );
};

export { SkipContent };
