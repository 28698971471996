import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { captureExceptionInSentry } from '@rikstv/play-common/src/utils/errorTracker/tracking';
import { LooseStringUnion } from '@rikstv/play-common/src/utils/types/typeUtils';

import { getStrimServerConfig } from '../../../apis/StrimGatewayAPI';
import { setExternalServicesFromPackages } from '../../activateExternalService/externalServiceStore';
import { PackageKeys } from '../forces/constants';
import { mapToPackages } from '../forces/mappers';
import { Package } from '../forces/packages.types';

interface PackageStore {
  packages: Package[];
  isLoading: boolean;
  fetchPackages: () => Promise<void>;

  getPackageById: (id: LooseStringUnion<PackageKeys> | null | undefined) => Package | undefined;
}

export const usePackageStore = create<PackageStore>()(
  // persist state to localStorage for lightning fast startup on refresh
  persist(
    (set, get) => ({
      isLoading: false,
      packages: [],
      fetchPackages: async () => {
        set({ isLoading: true });
        try {
          const { packages } = await getStrimServerConfig();
          const mappedPackages = mapToPackages(packages);
          setExternalServicesFromPackages(mappedPackages);
          set({
            packages: mappedPackages,
            isLoading: false,
          });
        } catch (e) {
          captureExceptionInSentry(e);
          // TODO: handle error. Many things wont work without packages...
        } finally {
          set({ isLoading: false });
        }
      },
      getPackageById: id => get().packages.find(p => p.id.toUpperCase() === id?.toUpperCase()),
    }),
    { name: 'packageStore' }
  )
);
