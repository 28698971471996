import { createContext, useContext } from 'react';

import { GetExternalServiceStatusFn } from './hooks/useContentStackEventHandlers';

export type SuperCoverPageCtx = { getExternalServiceStatus: GetExternalServiceStatusFn };
export const SuperCoverPageContext = createContext<SuperCoverPageCtx>({
  getExternalServiceStatus: () => ({ activated: false }),
});

export const useSuperCoverPageContext = () => {
  return useContext(SuperCoverPageContext);
};
