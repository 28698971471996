/* eslint-disable simple-import-sort/imports */
import { FC } from 'react';

import { ProgramState, SwimlaneItem } from '@rikstv/play-common/src/forces/assetDetails/assetDetails.types';
import { getImagePackSet } from '@rikstv/play-common/src/utils/imagePack/imagePack.utils';
import { Body, H2, Meta, Tag } from '@rikstv/shared-components';

import { AssetImage } from '../../assetImage/AssetImage';
import { DurationBar } from '../../durationBar/DurationBar';
import { Breakpoints } from '../../grid/breakpoints';
import { BroadcastTimeOverlay, BroadcastTimePill } from '../../imageOverlays/BroadcastTimeOverlay';
import { ExpiredOverlay } from '../../imageOverlays/ExpiredOverlay';
import { PadlockOverlay } from '../../imageOverlays/PadlockOverlay';
import { ImdbBadge } from '../../imdbBadge/ImdbBadge';
import { AccentPill } from '../../pill/Pill';
import { ProviderImageWrapper } from '../../providerImage';
import { TruncateText } from '../../truncateText/TruncateText';
import { ItemWrapper } from '../styles/ItemWrapper';
import { SwimlaneSettings } from '../Swimlane.types';
import { getLinkTo } from '../swimlane.utils';

import { HeroImageBroadcastOverlay } from '../../imageOverlays/HeroImageBroadCastOverlay';
import { DefaultOverlay } from '../../imageOverlays/DefaultOverlay';

import styles from './HeroSwimlaneItem.module.css';
import classNames from 'classnames';
import { AgeLimitPill } from '../styles/StandardItem';

export const HeroSwimlaneItem: FC<{
  item: SwimlaneItem;
  windowWidth: number;
  preventNavigation?: boolean;
  onClick?: () => void;
}> = ({ item, windowWidth, preventNavigation, onClick }) => {
  const { image, title, subtitle, imdbRating, programState } = item;
  /* at least 1 line, at most 6 lines - 3 when the hero has live info UI */
  const maxLines = programState.type === 'Live' ? 3 : 6;
  const lines = Math.max(1, Math.min(Math.floor(windowWidth / 210), maxLines));

  return (
    <ItemWrapper
      className={classNames(styles.heroSwimlane, 'with-keyboard-focus')}
      preventDefault={preventNavigation}
      linkTo={getLinkTo(item)}
      onClick={onClick}>
      <div className={classNames(styles.heroSwimlaneSection, styles.heroSwimlaneImage)}>
        <AssetImage
          className={styles.heroSwimlaneAssetImage}
          imageSet={getImagePackSet({
            url: image.url,
            variations: [
              { height: 600, breakpoint: Breakpoints.XL },
              { height: 400, breakpoint: Breakpoints.SM },
              { height: 230, breakpoint: Breakpoints.DEFAULT },
            ],
          })}
          alt={title}
          blackWhite={image.bwFilter}
        />
        <HeroImageOverlays state={programState} />
        <ProviderImageWrapper logos={item.logos} isHero={true} />
        <AgeLimitPill ageLimit={item.ageLimit} />
      </div>
      <div className={classNames(styles.heroSwimlaneSection, styles.heroSwimlaneInfo)}>
        <HeroInfoPill state={programState} />
        <H2 className={styles.heroSwimlaneTitle}>{title}</H2>
        <Meta className={styles.heroSwimlaneMeta}>
          {subtitle}
          {imdbRating && (
            <>
              {' · '}
              <ImdbBadge imdbRating={imdbRating} />
            </>
          )}
        </Meta>
        <HeroLiveProgress programState={programState} />
        <TruncateText lines={lines} disableShowMoreButton textStyle={Body} className={styles.heroSwimlaneDescription}>
          {item.description}
        </TruncateText>
      </div>
    </ItemWrapper>
  );
};

const HeroImageOverlays: FC<{
  state: ProgramState;
}> = ({ state }) => {
  if (state.type === 'NoAccess') {
    return <PadlockOverlay label={state.label} />;
  }
  if (state.type === 'Planned') {
    return <BroadcastTimeOverlay label={state.label} className={styles.heroSwimlaneOverlayPill} />;
  }
  if (state.type === 'Live') {
    return <HeroImageBroadcastOverlay state={state} className={styles.heroSwimlaneOverlayPill} />;
  }
  if (state.type === 'Expired') {
    return <ExpiredOverlay label={state.label} iconSize={'large'} />;
  }
  if (state.type === 'Default' && state.label) {
    return <DefaultOverlay label={state.label} className={styles.heroSwimlaneOverlayPill} />;
  }
  return null;
};

const HeroInfoPill: FC<{ state: ProgramState }> = ({ state }) => {
  if (state.type === 'Planned') {
    return <BroadcastTimePill className={styles.heroSwimlanePill} label={state.label} />;
  }
  if (state.type === 'Default' && state.label) {
    return (
      <span className={styles.heroSwimlanePill}>
        <AccentPill style={{ backgroundColor: 'var(--rds-background-secondary)' }}>
          <Tag>{state.label}</Tag>
        </AccentPill>
      </span>
    );
  }
  if (state.type === 'Live' && state.label)
    return (
      <span className={styles.heroSwimlanePill}>
        <AccentPill>
          <Tag className={styles.heroSwimlanePillLabel}>{state.label}</Tag>
        </AccentPill>
      </span>
    );
  return null;
};

const HeroLiveProgress: FC<{ programState: ProgramState }> = ({ programState }) => {
  if (programState.type === 'Live') {
    return (
      <div className={styles.heroSwimlaneLiveDuration}>
        <DurationBar percent={programState.progressPercentage} />
      </div>
    );
  }
  return null;
};

export const swimlaneConfig: Pick<SwimlaneSettings, 'placeholderHeight'> = {
  placeholderHeight: {
    [Breakpoints.XS]: 280 + Math.floor(window.innerHeight / 10),
    [Breakpoints.SM]: Math.floor(window.innerWidth * 0.75),
    [Breakpoints.MD]: 385,
    [Breakpoints.ML]: 285,
    [Breakpoints.LG]: 335,
    [Breakpoints.XL]: 420,
    [Breakpoints.XXL]: 530,
    [Breakpoints.DEFAULT]: 700,
  },
};
