import { AnyAction } from 'redux';
import { io } from 'socket.io-client';

import config from '../../config';
import { isMockUser } from '../../utils/auth/__mocks__/MockAuthService';
import { authService } from '../../utils/auth/AuthService';
import { getDeviceId } from '../../utils/device/device.utils';
import { getLogger } from '../../utils/logger/logger';
import { streamingKickKicked } from '../streamingKick/streamingKick.slice';

import { customerClaimsChanged, newVersionReleased, serviceMaxActivated, wsErrorReceived } from './actions';
import { Socket } from './webSocket.types';

export const wssLogger = getLogger('[wss-api]');

// Singleton-ish
let socketPromise: Promise<Socket>;

export const wssConnect = (accessToken: string): Promise<Socket> => {
  if (socketPromise) {
    return socketPromise;
  }

  socketPromise = new Promise(resolve => {
    const connectionUrl = config.webSocketUrl + '?handshake-auth=1';
    const url = isMockUser ? '' : connectionUrl;

    wssLogger.info('connecting to:', url);

    const socket: Socket = io(url, {
      forceNew: true,
      reconnection: false,
      transports: ['websocket'],
      path: '/2socket.io2',
      auth: {
        token: accessToken,
      },
    });

    socket.on('connect', () => {
      onConnected(socket);
      resolve(socket);
    });
  });

  return socketPromise;
};

const onConnected = (socket: Socket) => {
  const deviceInfo = {
    app: config.appName,
    deviceType: 'desktop',
    swVersion: config.appVersion,
    osVersion: navigator.userAgent,
    deviceId: getDeviceId(),
    features: ['newrelease'],
  };
  wssLogger.info('Connected!', { deviceInfo });
  socket.emit('DeviceInfo', deviceInfo);
};

export const wssSubscribe = (socket: Socket, dispatcher: (a: AnyAction) => void) => {
  socket.on('disconnect', () => wssLogger.info('Web socket disconnected'));

  socket.on('ErrorReceived', (errorMessage: string) => {
    wssLogger.error('Web socket error received: ', errorMessage);
    dispatcher(wsErrorReceived(errorMessage));
    // dispatch custom event so that code outside of redux middleware can listen for these, e.g. zustand-store functions
    document.dispatchEvent(new CustomEvent('wss:error', { detail: { errorMessage } }));
  });

  socket.on('CustomerClaimsChanged', (data: any) => {
    wssLogger.info('Customer claim has changed: ', data);
    dispatcher(customerClaimsChanged());
    // dispatch custom event so that code outside of redux middleware can listen for these, e.g. zustand-store functions
    document.dispatchEvent(new CustomEvent('wss:customer-claims-changed'));
  });

  socket.on('StreamingKick', data => {
    dispatcher(streamingKickKicked(data));
  });

  socket.on('HboMaxActivated', () => {
    wssLogger.info('Max activated');
    dispatcher(serviceMaxActivated());
  });

  socket.on('ForcedLogout', () => {
    authService.logout();
  });

  // Events are posted when deploying from Octopus
  /* Spec: https://bitbucket.org/rikstv-prod/rikstv.wss/src/master/doc/notification.txt
   *  with body "{ version: 'x.y.z', env: 'foo' }"
   */
  socket.once('NewRelease', (data: { version?: string; env?: string }) => {
    const { version = '', env = '' } = data;
    // only care about event targeting current environment, with different version
    const eventEnvironmentMatch = env.toLowerCase() === config.environment.toLowerCase();
    const versionChanged = config.appVersion.toLowerCase() !== version.toLowerCase();
    if (!eventEnvironmentMatch || !versionChanged) {
      return;
    }

    wssLogger.info('new release', { version });
    dispatcher(newVersionReleased());
  });
};
