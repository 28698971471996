import { AssetDetails } from '../../forces/assetDetails/assetDetails.types';
import { Channel } from '../../forces/channels/channel.types';

// NB: These are used directly in the browser url, so they must be pretty (e.g. no postfix) even if
// we lose a bit of type checking versus other enums that also contain 'MOVIE' and 'TVSHOW' etc.
export const CoverPagePaths = {
  LIVE: 'live',
  VOD: 'vod',
  SERIES: 'seriesoverview',
  STACKED_MOVIE: 'film',
  STACKED_SERIES: 'serie',
} as const;

export type CoverPagePath = (typeof CoverPagePaths)[keyof typeof CoverPagePaths];

export interface DialogProps {
  NoAccess: {
    channelId: number;
    posterUrl: string;
  };
  Signup: {
    assetOrChannel: AssetDetails | Channel;
    posterUrl?: string;
  };
  FutureAsset: {
    posterUrl?: string;
    broadcastedTime: Date | undefined;
  };
}

export interface PlayerDialogues {
  noAccessDialogue: React.ComponentType<DialogProps['NoAccess']>;
  signupDialogue: React.ComponentType<DialogProps['Signup']>;
  futureAssetDialogue?: React.ComponentType<DialogProps['FutureAsset']>;
}
