import React from 'react';

import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';

import { CommonButtonProps, InternalActionButton } from './InternalActionButton';

interface Props extends CommonButtonProps {
  to: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const ButtonLink: FCC<Props> = props => (
  <InternalActionButton buttonType="link" iconOnly={false} iconSize="match-text" {...props} />
);

export { ButtonLink };
