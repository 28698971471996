import config from '../../config';

import { InfoBanner } from './TopBanner';

const brand = config.application === 'Strim' ? 'Strim' : 'RiksTV';

export const lgBanner: InfoBanner = {
  id: 'lg-banner',
  type: 'Info',
  from: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
  to: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
  isDismissable: true,
  banner: {
    title: `Nå kan du laste ned ${brand}-appen til din LG-TV!`,
    message: `Bare trykk på appikonet i TV-menyen og søk på ${brand}. Tada!`,
  },
};
