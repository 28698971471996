import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import config from '@rikstv/play-common/src/config';
import { isRendertron } from '@rikstv/play-common/src/utils/device/device.utils';
import { isDevFeatureEnabled } from '@rikstv/play-common/src/utils/featureFlags/devFeatureFlags';
import { CastMiniControls } from '@rikstv/play-player/src/chromecast/CastMiniControls/CastMiniControls';
import { Cookies } from '@rikstv/shared-strim-components';

import { Chatbot } from '../../../utils/chatbot/Chatbot';
import { Footer } from '../../components/footer/Footer';
import { Navigation } from '../../components/header/Navigation';
import { routerPaths } from '../../router/router.path';

import style from './Layout.module.css';
type Theme = Extract<keyof typeof style, `theme${string}`>;

interface Props {
  hasCookies?: boolean;
  hasMiniController?: boolean;
  hasFooter?: boolean;
  children?: ReactNode;
  theme: Theme;
}

export const LayoutBase: FC<Props> = ({ hasMiniController, hasCookies = true, hasFooter = true, children, theme }) => {
  const renderCookieBanner =
    hasCookies &&
    window.location.pathname !== routerPaths.companyPersonvern &&
    !isRendertron &&
    !isDevFeatureEnabled('dropCookies');

  return (
    <div className={`${style.layout} ${style[theme]}`}>
      <Helmet defaultTitle={config.basePageTitle} titleTemplate="%s | Strim">
        {/* block search indexing for !prod  */}
        {config.isDevelopment && <meta name="robots" content="noindex" />}
        <meta property="og:url" content={window.location.href}></meta>
      </Helmet>
      <Navigation />
      <main id="main" className={style.main} tabIndex={-1}>
        {children}
        {hasMiniController && <CastMiniControls />}
      </main>
      {hasFooter && <Footer />}
      {renderCookieBanner && <Cookies />}
      <Chatbot />
    </div>
  );
};
