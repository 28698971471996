import { FC, useState } from 'react';
import classNames from 'classnames';

import { SwimlaneItem } from '@rikstv/play-common/src/forces/assetDetails/assetDetails.types';
import { Body, Gutter, H2, Meta } from '@rikstv/shared-components';

import { BackdropImage } from '../../backdropImage/BackdropImage';
import { ImdbBadge } from '../../imdbBadge/ImdbBadge';
import { Swimlane } from '../../pages/types';
import { ClampLines } from '../../truncateText/ClampLines';
import { ListScrollerSection } from '../listScroller/ListScroller';
import { FeaturedItem } from '../styles/FeaturedItem';

import styles from './SuperHeroSwimlane.module.css';

interface Props {
  swimlane: Swimlane;
  swimlaneItems: SwimlaneItem[];
}

export const SuperHeroSwimlane: FC<Props> = ({ swimlane, swimlaneItems }) => {
  const [selectedSwimlaneItem, setSelectedSwimlaneItem] = useState(swimlaneItems[0]);

  return (
    <div className={styles.superHeroWrapper}>
      <BackdropImage classes={styles.backdrop} fade={false} href={selectedSwimlaneItem.image.url} />

      <div className={styles.superHeroContainer}>
        <Gutter className={styles.infoContainer}>
          <H2 className={styles.title}>{selectedSwimlaneItem.title}</H2>
          <Meta>
            {selectedSwimlaneItem.subtitle} <ImdbBadge imdbRating={selectedSwimlaneItem.imdbRating} />
          </Meta>
          <ClampLines textStyle={Body} lines={4} className={styles.description}>
            {selectedSwimlaneItem.description}
          </ClampLines>
        </Gutter>
        <ListScrollerSection
          hideSectionTitle
          noPaddingBottom
          reportVisibility={() => {}}
          swimlane={swimlane}
          animate={false}>
          {swimlaneItems.map(item => (
            <span
              onMouseEnter={() => {
                setSelectedSwimlaneItem(item);
              }}
              key={'featured-item-span-' + item.id}>
              <FeaturedItem
                classes={classNames(styles.swimlaneItem, {
                  [styles.selectedSwimlaneItem]: item.id === selectedSwimlaneItem.id,
                })}
                asset={item}
                swimlaneType={swimlane.type}
                onClick={() => {}}
                noMargin
              />
            </span>
          ))}
        </ListScrollerSection>
      </div>
    </div>
  );
};
