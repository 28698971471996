import { useState } from 'react';
import classNames from 'classnames';

import { IconButton } from '@rikstv/play-common/src/components/button/IconButton';
import { isMobileTouchDevice } from '@rikstv/play-common/src/utils/device/device.utils';

import { MuteVolumeIcon, VolumeIcon } from '../../icons';
import { language } from '../../language';

import './Volume.scss';

const VolumeRange = {
  min: 0,
  max: 1,
  step: 0.01,
};

interface Props {
  className?: string;
  muted: boolean;
  onClick: () => void;
  onVolumeChange: (volume: number) => void;
  volume: number | undefined;
  showSlider?: boolean;
}

const Volume: React.FC<Props> = ({ muted, onClick, onVolumeChange, volume, showSlider = false, className }) => {
  const [showBar, setShowBar] = useState(false);
  const silent = muted || volume === 0;
  const classes = classNames('slider', { shown: showBar || showSlider });
  return (
    <div
      className={classNames('volume', className)}
      onMouseEnter={() => setShowBar(true)}
      onMouseLeave={() => setShowBar(false)}>
      <IconButton
        id="player-controls-volume"
        onClick={onClick}
        icon={silent ? MuteVolumeIcon : VolumeIcon}
        ariaLabel={language.player.volume}
        buttonStyle="tertiary"
        title="Volumkontroll"
      />
      {!isMobileTouchDevice && (
        <input
          tabIndex={-1}
          className={classes}
          type="range"
          min={VolumeRange.min}
          max={VolumeRange.max}
          step={VolumeRange.step}
          value={!muted ? volume : 0}
          onChange={event => onVolumeChange(event.target.valueAsNumber)}
        />
      )}
    </div>
  );
};

export { Volume, VolumeRange };
