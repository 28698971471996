import logger from '../../utils/logger/logger';

import { RiksTVRequestError } from './errors';

export const parseJSON = async (response: Response) => {
  const bodyText = await response.text();
  // If string starts with "[" or "{", it is probably json
  // no matter what the headers say
  if (/^\[|\{/.test(bodyText) || isHeaderResponseJSON(response.headers)) {
    try {
      const jsonBody = JSON.parse(bodyText);
      return jsonBody;
    } catch {
      /* */
    }
  }
  return bodyText;
};

export const checkStatus =
  (requestStackTrace: string | undefined = '', okStatusCodes: number[] = []) =>
  async (response: Response): Promise<Response> => {
    if (response.ok || okStatusCodes.includes(response.status)) {
      return response;
    } else {
      const requestError = await RiksTVRequestError.FromResponse(response, requestStackTrace);
      logger.info({ response }, requestError.message);

      throw requestError;
    }
  };

export const appendQueryParams = (url: string, queryString?: string) => {
  if (!queryString) {
    return url;
  }

  if (url.indexOf('?') > -1) {
    return `${url}&${new URLSearchParams(queryString).toString()}`;
  } else {
    return `${url}?${new URLSearchParams(queryString).toString()}`;
  }
};

const hasContentType = (headers: Headers) => headers.has('Content-Type');

export const isHeaderResponseJSON = (headers: Headers) => {
  return hasContentType(headers) && headers.get('Content-Type')!.indexOf('json') >= 0;
};

export const isHeaderResponsePDF = (headers: Headers) => {
  return hasContentType(headers) && headers.get('Content-Type')!.indexOf('application/pdf') >= 0;
};
