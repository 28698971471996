import { ProgramState } from '../../forces/assetDetails/assetDetails.types';
import { DurationBar } from '../durationBar/DurationBar';

import { ImageOverlayWrapper } from './ImageOverlayWrapper';
import { LiveBroadcastPill } from './LiveBroadcastOverlay';

export const HeroImageBroadcastOverlay = ({ state, className }: { state: ProgramState; className?: string }) => {
  if (state.type !== 'Live') {
    return null;
  }
  return (
    <span className={className}>
      <ImageOverlayWrapper backdropStyle={'gradient'} style={{ padding: 'var(--rds-spacing--16)' }}>
        <LiveBroadcastPill label={state?.label ?? 'Direkte'} />
        <DurationBar percent={state.progressPercentage} />
      </ImageOverlayWrapper>
    </span>
  );
};
