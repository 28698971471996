import { useEffect } from 'react';

import { getLogger } from '../logger/logger';

import { flagManager, getFlagKey } from './FeatureFlagManager';
import { RemoteFeatureFlags } from './featureFlags';
import { useRemoteFeatureFlag } from '.';

const logger = getLogger('[LD]');

const experimentPattern = /^\d{8}_\d{1,2}\|[12]$/; // yyyymmdd_n|1 or yyyymmdd_n|2
type VariantId = '1' | '2';
type ExperimentFlagValue = false | null | `${string}|${VariantId}`;
type ExperimentFlagKey = Extract<RemoteFeatureFlags, `exp-${string}`>;

/**
 * Returns `null | {experimentId, variant}` for a given experiment flag.
 *
 * Docs on how to set up flags for experiments in: docs/feature-flags.md
 *
 * Automatically pushes experiment impressions to the dataLayer if the experiment is active.
 * @example
 * const experiment = useLaunchDarklyExperiment('exp-1');
 * return (
 *  <div>
 *    {selectExperimentVariant(experiment, <>A</>, <>B</>)}
 *  <div>
 * )
 */
export const useLaunchDarklyExperiment = (flagName: ExperimentFlagKey) => {
  // get experiment flag value
  const [exp] = useRemoteFeatureFlag<ExperimentFlagValue>(flagName, { defaultValue: null });
  usePushExperimentAudienceToDataLayer(exp);

  const [experimentId, variant] = parseExperimentValue(exp);

  if (experimentId) {
    return { experimentId, variant };
  }

  return null;
};
export const getExperimentVariant = (flagName: ExperimentFlagKey) => {
  const value = flagManager.getValue(getFlagKey(flagName)) as ExperimentFlagValue;
  if (value) {
    const [, variant] = parseExperimentValue(value);
    return variant;
  }
};

/**
 * Returns `original` or `challenger` depending on the variant of the experiment.
 * When the experiment is undefined, `original` is returned.
 */
export const selectExperimentVariant = <T>(
  experiment: ReturnType<typeof useLaunchDarklyExperiment>,
  original: T,
  challenger: T
): T => {
  if (experiment?.variant === '1') {
    return original;
  } else if (experiment?.variant === '2') {
    return challenger;
  }
  return original;
};

const parseExperimentValue = (exp: ExperimentFlagValue) => {
  if (exp && experimentPattern.test(exp)) {
    return exp.split('|') as [string, VariantId];
  }
  return [undefined, undefined] as const;
};

const _pushedExperimentAudience: string[] = [];
const usePushExperimentAudienceToDataLayer = (exp: ExperimentFlagValue) => {
  useEffect(() => {
    // parse value inside hook for simpler dependency handling
    const [experiment_id, variant_id] = parseExperimentValue(exp);
    if (experiment_id) {
      // only push impression for a given experiment once
      if (_pushedExperimentAudience.includes(experiment_id)) {
        return;
      } else {
        _pushedExperimentAudience.push(experiment_id);
      }
      // push to dataLayer to create an audience group
      // docs: https://developers.google.com/analytics/devguides/collection/ga4/integration#audiences
      window.dataLayer.push({
        event: 'experience_impression',
        experiment_id,
        variant_id,
        experiment_source: 'LD', // for LaunchDarkly
      });
      logger.log('pushed experiment impression to dataLayer', window.dataLayer.at(-1));
    }
  }, [exp]);
};
