import { parseJSON } from './request.utils';

interface RiksTVRequestErrorResponse {
  status: number;
  statusText: string;
  type: string;
  url: string;
  body?: any;
}
export class RiksTVRequestError extends Error {
  statusCode: number;
  failedUrl: string;
  response: RiksTVRequestErrorResponse;

  constructor(serializedResponse: RiksTVRequestErrorResponse, requestStackTrace: string) {
    const message = `Failed to make network request (${serializedResponse.status})`;
    super(message);

    this.name = 'RiksTVRequestError';
    this.failedUrl = serializedResponse.url;
    this.statusCode = serializedResponse.status;
    this.response = serializedResponse;

    if (requestStackTrace) {
      // Normalize stack (approx) across Chrome/Firefox/Safari
      this.stack = `${RiksTVRequestError.name}: ${message}\n${requestStackTrace.replace(/^Error\n?/, '')}`;
    } else if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RiksTVRequestError);
    } else {
      this.stack = new Error(message).stack;
    }
  }

  static async FromResponse(response: Response, requestStackTrace: string) {
    const parsedResponseBody = await parseJSON(response);
    // Response object does not stringify nicely, explicitly setting properties
    // to get proper logging.
    const serializedResponse: RiksTVRequestErrorResponse = {
      status: response.status,
      statusText: response.statusText,
      type: response.type,
      url: response.url,
      body: parsedResponseBody,
    };
    return new RiksTVRequestError(serializedResponse, requestStackTrace);
  }
}
