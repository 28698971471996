import { createRedirectRoute } from '@rikstv/play-common/src/router/routes.type';

import { AppRoute } from './routes.type';

/**
 * This is a hack since the new content layout api
 * changes the names of the main pages, must be added after routes fetched from api.
 * TODO(@tolu): Track how often these are hot so we know when to remove them!
 */
export const getPageRedirectRoutes = (): AppRoute[] => [
  // new content layout api main pages
  createRedirectRoute('/home', '/start'),
  createRedirectRoute('/archive', '/tv'),
  createRedirectRoute('/series', '/serier'),
  createRedirectRoute('/movies', '/filmer'),
  createRedirectRoute('/children', '/barn'),
];
