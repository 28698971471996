import { Program } from '@rikstv/play-common/src/forces/channels/channel.types';

import styles from './ProgramStartMarkers.module.css';

export interface HoveredProgram {
  title: string;
  imageUrl: string | undefined;
}

export const ProgramStartMarkers = ({
  programs,
  streamDuration,
  setHoveredProgram,
}: {
  programs: Program[];
  streamDuration: number;
  setHoveredProgram: (HoveredProgram: HoveredProgram | undefined) => void;
}) => {
  return (
    <>
      {programs.map(program => {
        return (
          <div
            key={'program-point-' + program.id}
            onMouseEnter={() => setHoveredProgram({ title: program.name, imageUrl: program.image })}
            onMouseLeave={() => setHoveredProgram(undefined)}
            className={styles.programMarker}
            style={{ left: `${getProgramStartPoint(program, streamDuration)}%` }}>
            <div className={styles.programMarkerInner} />
          </div>
        );
      })}
    </>
  );
};

function getProgramStartPoint(program: Program, streamDuration: number) {
  const secondsFromStart = (new Date().getTime() - new Date(program.time).getTime()) / 1000;
  const progressRatio = 1 - secondsFromStart / streamDuration;

  return (progressRatio * 100).toFixed(2);
}
