import { ROUTE_TYPE_NORMAL } from '@rikstv/play-common/src/router/routes.type';

import { NotFound } from '../components/notFound/NotFound';
import { LayoutDark } from '../containers/layout/LayoutDark';

import { devRoute } from './router.dev';
import { routesAccount } from './routes.account';
import { routesAuth } from './routes.auth';
import { routesCompany } from './routes.company';
import { routesLandingPage } from './routes.landingPage';
import { routesPlayApp } from './routes.playApp';

// "404", catches all unknown paths.
const catchAllRoute = {
  type: ROUTE_TYPE_NORMAL,
  path: '*',
  component: NotFound,
  layout: LayoutDark,
  waitForStartup: true,
};

const routes = [
  ...routesLandingPage,
  ...routesCompany,
  ...routesAccount,
  ...routesAuth,
  ...routesPlayApp,
  ...devRoute,
  catchAllRoute,
];
export { routes };
