import { useCallback, useEffect, useState } from 'react';
import useSWR, { unstable_serialize, useSWRConfig } from 'swr';

import { MenuDetails, SwimlaneItem } from '../../forces/assetDetails/assetDetails.types';
import { isMenuDetails, mapResponseToMenuDetails } from '../../forces/assetDetails/mappers';
import { mapResponseToSwimlaneAssetItem } from '../../forces/assetDetails/swimlaneItemMapper';
import { request } from '../../forces/utils/request';
import { useAuthToken } from '../../hooks/useAuthToken';
import { authService } from '../../utils/auth/AuthService';
import { captureExceptionInSentry } from '../../utils/errorTracker/tracking';
import { Swimlane } from '../pages/types';

import { takeMinSwimlaneItems } from './swimlane.utils';

const swrKey = 'useSwimlane';
export const useSwimlane = (swimlane?: Swimlane, isInViewport = true) => {
  const accessToken = useAuthToken();
  const [swimlaneItems, setSwimlaneItems] = useState<SwimlaneItem[] | undefined>(undefined);
  const [menuItems, setMenuItems] = useState<MenuDetails[] | undefined>(undefined);
  const { cache } = useSWRConfig();

  let cacheHit = false;
  const fetcherParams =
    swimlane && isInViewport ? ([swimlane.link, accessToken, swrKey, swimlane.type] as const) : null;
  if (fetcherParams && cache instanceof Map) {
    // cache key is joined from fetcherParams
    // NB: serializedKey != swrCacheKey since swr adds "$swr$" to the start
    const serializedKey = unstable_serialize(fetcherParams);
    cacheHit = [...cache.keys()].some(k => k.includes(serializedKey));
  }

  const shouldRevalidate = ['ContinueWatching', 'OnTvNow'].includes(swimlane?.type ?? '') || !cacheHit;

  const headers = { 'accept-model-version': '2.0' };
  const { data, error, isLoading } = useSWR(
    fetcherParams,
    ([url, token]) => request<RiksTV.ClientAPI.SwimlaneResponse>({ url, headers }, token),
    {
      revalidateIfStale: shouldRevalidate,
      revalidateOnMount: shouldRevalidate,
      revalidateOnFocus: shouldRevalidate,
      revalidateOnReconnect: false,
    }
  );

  const filterSwimlaneItemById = useCallback((itemId: string) => {
    setSwimlaneItems(items => items?.filter(s => s.id !== itemId));
  }, []);

  useEffect(() => {
    if (swimlane && data) {
      const { style, type, minItemsForShowMore } = swimlane;
      if (isMenuDetails(data, type)) {
        setMenuItems(data.map(mapResponseToMenuDetails));
      } else {
        setSwimlaneItems(
          // Don't show more assets than 'minItemsForShowMore' if it is defined
          takeMinSwimlaneItems(
            mapResponseToSwimlaneAssetItem(data, { style, type, loggedIn: authService.isAuthenticated() }),
            style,
            minItemsForShowMore
          )
        );
      }
    }
    if (error) {
      captureExceptionInSentry(error);
    }
  }, [data, error, swimlane]);

  return { swimlaneItems, menuItems, error, filterSwimlaneItemById, isLoading };
};
