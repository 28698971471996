import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { CommonRootState } from '../forces/root.reducer';
import { authService } from '../utils/auth/AuthService';
import { getLogger } from '../utils/logger/logger';

import { useInactivityMonitor } from './useInactivityMonitor';

const logger = getLogger('[auto-reload]');

export const useAutoReloadStaleApp = () => {
  const location = useLocation();
  const enabled = authService.isInternalUser();

  const newVersionAvailable = useSelector((state: CommonRootState) => state.ui.newVersionReleased);
  const { activeAfterLongInactivity } = useInactivityMonitor(enabled);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    logger.log({ newVersionAvailable, activeAfterLongInactivity });

    if (activeAfterLongInactivity) {
      // Trigger full refresh when user opens tab again after long inactivity
      logger.info('full refresh after long inactivity', { location });
      window.location.reload();
    }
    if (newVersionAvailable) {
      // Trigger full refresh on navigation when new version has been released
      logger.info('full refresh on navigation since new version released', { location });
      window.location.reload();
    }
  }, [newVersionAvailable, activeAfterLongInactivity, location, enabled]);
};
