import { StreamingDevice } from '@rikstv/play-player/src/player.types';
import { Playable } from '@rikstv/play-player/src/utils/playable';

import { toPlayContentMeta } from '../../utils/analytics/analytics.mapper';
import type { CommonAnalytics, SwimlaneEventData } from '../../utils/analytics/CommonAnalytics.types';
import { errorTracker } from '../../utils/errorTracker/tracking';

const twentyMinInSeconds = 60 * 20;

export class CommonAnalyticsService {
  analyticsPoster: CommonAnalytics;
  constructor(analyticsPoster: CommonAnalytics) {
    this.analyticsPoster = analyticsPoster;
  }

  // There is something very wrong with the timer that checks keepalive - it looks like it keeps on
  // running even if the computer is in hibernation, and we cannot figure out why. To prevent logs
  // from being screwed up too much we don't allow more than 20 minutes to be logged, as this is
  // the time between keepalive events.
  private secondsSinceLastEventWithLog = (eventName: string, secondsSinceLastEvent: number) => {
    if (secondsSinceLastEvent > twentyMinInSeconds) {
      errorTracker.logMessage(
        `Duration on ${eventName} should never be more than 20 minutes if keep alive works properly.`,
        { tags: { minutesSinceLastEvent: Math.floor(secondsSinceLastEvent / 60) } }
      );
      return twentyMinInSeconds;
    } else {
      return secondsSinceLastEvent;
    }
  };
  streamStart = (streamingDevice: StreamingDevice, playable: Playable) => {
    this.analyticsPoster.streamStart(toPlayContentMeta(streamingDevice, playable));
  };
  streamKeepAlive = (secondsSinceLastEvent: number) => {
    const cappedSeconds = this.secondsSinceLastEventWithLog('streamKeepAlive', secondsSinceLastEvent);
    this.analyticsPoster.streamKeepAlive(cappedSeconds);
  };
  streamStop = (secondsSinceLastEvent: number) => {
    const cappedSeconds = this.secondsSinceLastEventWithLog('streamStop', secondsSinceLastEvent);
    this.analyticsPoster.streamStop(cappedSeconds);
  };
  streamPause = (secondsSinceLastEvent: number) => {
    const cappedSeconds = this.secondsSinceLastEventWithLog('streamPause', secondsSinceLastEvent);
    this.analyticsPoster.streamPause(cappedSeconds);
  };
  trackScreen = (path: string, loggedIn: boolean) => this.analyticsPoster.trackScreen(path, loggedIn);
  swimlaneClicked = (eventData: SwimlaneEventData) => this.analyticsPoster.swimlaneClicked(eventData);
  swimlaneImpression = (eventData: SwimlaneEventData) => this.analyticsPoster.swimlaneImpression(eventData);
  errorReceived = (errorMessage: string, extra: string) => this.analyticsPoster.errorReceived(errorMessage, extra);
  addedToMyList = () => {
    this.analyticsPoster.addedToMyList();
  };
  removeFromMyList = () => this.analyticsPoster.removeFromMyList();
  removeFromContinueWatching = () => this.analyticsPoster.removeFromContinueWatching();
  searchResultClicked = (
    searchText: string,
    title: string,
    position: number,
    listTitle: string,
    listPosition: number
  ) => this.analyticsPoster.searchResultClicked(searchText, title, position, listTitle, listPosition);
  searchResultViewed = (searchText: string) => this.analyticsPoster.searchResultViewed(searchText);
  toggleFavoriteChannel = (channelId: number, isFavorite: boolean) =>
    this.analyticsPoster.toggleFavoriteChannel(channelId.toString(), isFavorite);
}
