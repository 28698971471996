import { relativeTimeFromNow } from '../date/date.utils';
import { isRunningTests } from '../isRunningTests';
import { getLogger } from '../logger/logger';

import { decodeToken } from './jwt.utils';

const log = getLogger('[auth]');
if (isRunningTests()) {
  log.disableAll();
}

export class JwtToken {
  private rawToken?: string | null;
  private decodedToken?: ReturnType<typeof decodeToken> | null;
  private expiresEpoch?: number | null;

  constructor(token: string | null, fromCallback = false) {
    if (token) {
      this.rawToken = token;
      this.decodedToken = decodeToken(token);
      // do we have a expiry?
      if (this.decodedToken && this.decodedToken.exp) {
        const expDate = new Date(0);
        expDate.setUTCSeconds(this.decodedToken.exp);
        this.expiresEpoch = expDate.getTime();
        if (!fromCallback) {
          log.info(`jwtToken expires: ${relativeTimeFromNow(expDate)}`, this.decodedToken);
        }
      }
    }
  }

  get accessToken() {
    return `${this.rawToken ?? ''}`;
  }

  get expires() {
    return this.expiresEpoch || 0;
  }

  isTokenValidFor(msIntoTheFuture: number) {
    if (this.expiresEpoch) {
      return this.expiresEpoch > Date.now() + msIntoTheFuture;
    }
    return false;
  }

  isTokenExpired() {
    if (!this.expiresEpoch) {
      return true;
    }

    return this.expiresEpoch <= Date.now();
  }

  isUserInDomain(domain = '@rikstv.no') {
    return this.decodedToken?.email?.toLowerCase().endsWith(domain) ?? false;
  }

  hasValidCustomerClaim() {
    if (!this.decodedToken) {
      return false;
    }

    // safety catch to avoid infinite redirects to auth server
    // (it was possible to get "valid" token with incorrect customer number claim)
    const claim = 'http://rikstv.no/claims/customernumber/1';
    const validCustomerNumberClaim = this.decodedToken[claim] || 0 > 1;
    return validCustomerNumberClaim;
  }

  valueOf() {
    return this.decodedToken ? { ...this.decodedToken } : null;
  }

  static isTokenValid(accessToken?: string | null) {
    if (accessToken) {
      return new JwtToken(accessToken).isTokenValidFor(0);
    }
    return false;
  }
}
