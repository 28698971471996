export const ROUTE_TYPE_RAW = 'raw' as const;
export const ROUTE_TYPE_NORMAL = 'normal' as const;
export const ROUTE_TYPE_INTERNAL_REDIRECT = 'internal_redirect' as const;
export const ROUTE_TYPE_EXTERNAL_REDIRECT = 'external_redirect' as const;

type Path = string | string[];
interface DirectRoute {
  type: typeof ROUTE_TYPE_NORMAL;
  path: Path;
  component: any;
  layout: any;
  waitForStartup: boolean;
  loginRequired?: boolean; // show page only if user is logged in.
  props?: {
    [key: string]: any;
  };
}

interface ExternalRedirectRoute {
  type: typeof ROUTE_TYPE_EXTERNAL_REDIRECT;
  path: Path;
  redirectPath: string;
}

export interface InternalRedirectRoute {
  type: typeof ROUTE_TYPE_INTERNAL_REDIRECT;
  path: Path;
  redirectPath: string;
  options?: {
    wildcardKeepPath: boolean;
    trackRedirect?: boolean;
  };
}

// Route without any chrome or code wrapping, for complete control of what happens on route.
interface RawRoute {
  type: typeof ROUTE_TYPE_RAW;
  path: Path;
  component: any;
}

export const createExternalRedirectRoute = (from: string, to: string): ExternalRedirectRoute => ({
  type: ROUTE_TYPE_EXTERNAL_REDIRECT,
  path: from,
  redirectPath: to,
});

export const createRedirectRoute = (
  from: string,
  to: string,
  options?: Partial<InternalRedirectRoute['options']>
): InternalRedirectRoute => ({
  type: ROUTE_TYPE_INTERNAL_REDIRECT,
  path: from,
  redirectPath: to,
  options: {
    wildcardKeepPath: options?.wildcardKeepPath === true,
    trackRedirect: options?.trackRedirect === true,
  },
});

export type AppRoute = DirectRoute | InternalRedirectRoute | ExternalRedirectRoute | RawRoute;

export type AppRoutes = readonly AppRoute[];
