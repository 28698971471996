import { FC, useEffect, useState } from 'react';

import { ButtonLink } from '@rikstv/play-common/src/components/button/ButtonLink';
import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';
import { Body } from '@rikstv/shared-components';

import { routerPaths } from '../../router/router.path';

import style from './oauthcallback.module.css';

const OAuthCallback: FC = () => {
  const [loginError, setLoginError] = useState(false);
  useEffect(() => {
    authService.whenReady(state => {
      if (state === 'signInFailed') {
        setLoginError(true);
      }
    });
  }, []);

  return (
    <div className={style.container}>
      <Body>{loginError ? 'Innlogging avbrutt.' : 'Du er logget inn.'}</Body>
      <br />
      <ButtonLink buttonStyle="inline-link" to={routerPaths.frontpage}>
        Til forsiden
      </ButtonLink>
    </div>
  );
};

export default OAuthCallback;
