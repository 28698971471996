import React from 'react';
import classNames from 'classnames';

import { Props as ImageFadeProps, ResponsiveImageFade } from '../imageFade/ResponsiveImageFade';

import styles from './AssetImage.module.css';

export interface AssetImageProps {
  ratio?: '16_9' | 'square' | 'circle' | 'portrait';
  rounded?: boolean;
  blackWhite?: boolean;
  wrapperClassName?: string;
}

type Props = ImageFadeProps & AssetImageProps;
export const AssetImage: React.FunctionComponent<Props> = (props: Props) => {
  const { ratio = '16_9', rounded = false, blackWhite = false } = props;
  const childProps = {
    ...props,
    className: classNames(styles.assetImageImage, props.className),
  };

  const wrapperClassNames = classNames(
    props.wrapperClassName,
    styles.assetImageWrapper,
    { [styles.assetImageWrapper169]: ratio === '16_9' },
    { [styles.assetImageWrapperSquare]: ratio === 'square' },
    { [styles.assetImageWrapperCircle]: ratio === 'circle' },
    { [styles.assetImageWrapperPortrait]: ratio === 'portrait' },
    { [styles.assetImageWrapperRounded]: rounded },
    { [styles.assetImageBlackWhite]: blackWhite }
  );
  return (
    <div className={wrapperClassNames}>
      <ResponsiveImageFade {...childProps} />
    </div>
  );
};
