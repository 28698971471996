import { serviceMaxActivated } from '@rikstv/play-common/src/forces/webSocket/actions';

import { userActions } from '../../domain/user/forces/userInfo.slice';
import { StrimMiddleware } from '../../root.reducer';

import { strimAnalytics } from './strimAnalytics';

export const analyticsMiddleware: StrimMiddleware = () => next => action => {
  switch (true) {
    case userActions.loadedUser.match(action): {
      strimAnalytics.userLoggedIn(action.payload);
      break;
    }
    case userActions.userLoginRegistrationValidationError.match(action): {
      strimAnalytics.errorReceived(action.payload);
      break;
    }
    case serviceMaxActivated.match(action): {
      strimAnalytics.externalServiceActivationCompleted('max');
      break;
    }
  }
  return next(action);
};
