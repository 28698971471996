import { create } from 'zustand';

import { ExternalServiceStatus } from '@rikstv/play-common/src/forces/externalService/types';
import { loadUserInfo } from '@rikstv/play-common/src/forces/loadUserInfo/loadUserInfo.slice';
import { getJwt } from '@rikstv/play-common/src/utils/auth/AuthService';
import { captureExceptionInSentry } from '@rikstv/play-common/src/utils/errorTracker/tracking';
import { getLogger } from '@rikstv/play-common/src/utils/logger/logger';
import { delay, poll } from '@rikstv/play-common/src/utils/polling/poll';
import { isOneOf } from '@rikstv/play-common/src/utils/types/typeUtils';

import { strimAnalytics } from '../../../utils/analytics/strimAnalytics';
import userApi from '../../user/forces/api';
import { UserInfo } from '../../user/forces/user.types';
import { userActions } from '../../user/forces/userInfo.slice';
import {
  continueTV2PlayProvisioning,
  fetchTV2ProvisioningUrl,
  NOT_FOUND_AT_TV2,
  TV2_PROVISION_CONFLICT,
} from '../forces/api';

// Setup error catching logger
const logger = getLogger('[activation][tv2]');
const _logError = logger.error;
logger.error = (error: Error) => {
  _logError(error);
  captureExceptionInSentry(error, { tags: { activation: 'tv2' } });
};

type StatusCode = ExternalServiceStatus['statusCode'];
interface TV2ActivationStore {
  isPolling: boolean;
  hasError: boolean;
  checkTV2ProvisionStatus: (userInfo: UserInfo) => Promise<void>;
  continueProvisionOnTV2Play: (redirectUrl: string, statusCode: StatusCode) => Promise<void>;
  pollForTv2PlayStatus: (statusCode: StatusCode, returnedFromTv2?: boolean) => Promise<void>;
}

const getStoreAsync = () => import('../../../common/store/store').then(mod => mod.default);

/**
 * TV2 Activation basics
 *
 * 1. For "Strim Litt" or right after registration+payment the customer might not have the `customer.tv2` property set yet (waiting for a provisioned account on TV2)
 * 2. Provisioning at TV2 should begin automatically at payment and can be triggered manually (and be deleted) via admin.strim.no
 * 3. Usually the first status a customer gets is "NeedsToSetPassword"/"NeedsConnection"
 * 4. "Connected" is a waiting state that we don't really care about
 * 5. After redirect back to us from TV2-account connection (also triggered by back-navigation which is A PROBLEM) "checkTV2ProvisionStatus" kicks in when userInfo is loaded
 * 5.1. At this point it's about waiting for the state we had before redirecting to TV2 to change so we treat that situation differently
 */

let _retryOnMissingTV2PlayStatus = true;
export const useTV2ActivationStore = create<TV2ActivationStore>()((set, get) => ({
  isPolling: false,
  hasError: false,
  continueProvisionOnTV2Play: async (redirectUrl, currentStatus) => {
    logger.log('provision TV2Play...', { redirectUrl, currentStatus });
    strimAnalytics.externalServiceActivationStarted('tv2play');
    try {
      const response = await fetchTV2ProvisioningUrl(redirectUrl, getJwt());
      if (response === TV2_PROVISION_CONFLICT) {
        await continueTV2PlayProvisioning(getJwt());
        await get().pollForTv2PlayStatus(currentStatus);
      } else if (response === NOT_FOUND_AT_TV2) {
        await get().pollForTv2PlayStatus(currentStatus);
      } else {
        persistCodePreTv2Redirect(currentStatus);
        window.location.href = response;
      }
    } catch (err) {
      logger.error(err);
      set({ hasError: true });
    }
  },
  checkTV2ProvisionStatus: async userInfo => {
    if (userInfo.subscribedPackage?.hasAccessToContent !== true || userInfo.subscribedPackage.id === 'STRIM_MINI') {
      return;
    }
    const preTv2RedirectStatusCode = retrievePreTv2RedirectCode();
    const tv2Status = userInfo.externalServicesStatus?.['tv2play']?.statusCode;

    if (tv2Status && preTv2RedirectStatusCode && preTv2RedirectStatusCode !== tv2Status) {
      // status has already changed, return
      return;
    }

    const hasPreStatus = Boolean(preTv2RedirectStatusCode);
    if (hasPreStatus || tv2Status === 'NeedsToCancelExternalServiceSubscription') {
      await continueTV2PlayProvisioning(getJwt());
      await get().pollForTv2PlayStatus(tv2Status, hasPreStatus);
      return;
    }

    // Provisioning TV2 customer is an async operation
    // that can take a while, so we'll retry once
    // after 2 seconds, otherwise we'll inform the user
    // it will be ready in the future and to check my account
    if (!tv2Status && _retryOnMissingTV2PlayStatus) {
      _retryOnMissingTV2PlayStatus = false;
      logger.info('Missing TV2Play status, retrying...');
      await delay(2_000);
      // dispatch action that updates customer, triggers a re-run of this code
      (await getStoreAsync()).dispatch(loadUserInfo());
    }
  },
  pollForTv2PlayStatus: async (currentStatusCode, returnedFromTV2) => {
    if (get().isPolling) {
      logger.warn('polling already in progress...');
      return;
    }
    // if everything is already complete, we're done
    if (currentStatusCode === 'Completed') {
      set({ isPolling: false });
      strimAnalytics.externalServiceActivationCompleted('tv2play');
      return;
    }
    if (
      isOneOf(currentStatusCode, [
        'NeedsConnection',
        'NeedsToCancelExternalServiceSubscription',
        'NeedsToSetPassword',
      ]) &&
      !returnedFromTV2
    ) {
      // these are all valid statuses except when returning from TV2 when we instead need to poll to see that the status changes
      set({ isPolling: false });
      return;
    }

    logger.log('start polling for TV2Play status...', { currentStatusCode });

    const _startedPolling = Date.now();
    let _code;
    try {
      // Start polling
      set({ isPolling: true });
      const res = await poll({
        pollingFunction: () => userApi.fetchExternalServiceStatus(getJwt(), 'tv2play', true),
        condition: (tv2Status, retryAttempt) => {
          // check if status has changed
          const statusCode = tv2Status?.statusCode;
          logger.log('got TV2Play status:', statusCode);
          // "Connected" is a waiting state, so we can ignore it
          if (currentStatusCode !== statusCode && statusCode !== 'Connected') {
            getStoreAsync().then(store => store.dispatch(userActions.externalServiceStatusUpdated(tv2Status)));
            if (statusCode === 'Completed') {
              strimAnalytics.externalServiceActivationCompleted('tv2play');
            }
            return 'completed';
          }
          // reached max retries
          if (retryAttempt >= 10) {
            return 'completed';
          }
          return 'continue';
        },
        sleep: retry => retry * 750, // 0.75s -> 1.5s -> 2.25s -> etc.
      });
      // Check invalid status after polling completed
      _code = res.statusCode;
      if (!_code || _code === 'Unknown') {
        logger.error(
          new Error(`Polling failed with externalStatusCode ${res?.statusCode}, current status ${currentStatusCode}`)
        );
        set({ hasError: true });
      }
    } catch (error) {
      set({ hasError: true });
      logger.error(error);
    }
    // Were done polling
    logger.info(`ending polling after ${Date.now() - _startedPolling}ms with StatusCode: ${_code}`);
    set({ isPolling: false });
  },
}));

/* Helpers */
const _tv2ProvisionKey = 'provisioning-tv2';
const persistCodePreTv2Redirect = (currentStatus: StatusCode) => {
  window.sessionStorage.setItem(_tv2ProvisionKey, currentStatus || '');
};
const retrievePreTv2RedirectCode = () => {
  const tv2Status = window.sessionStorage.getItem(_tv2ProvisionKey) as StatusCode | null;
  window.sessionStorage.removeItem(_tv2ProvisionKey);
  return tv2Status;
};
