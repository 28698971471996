import { FC, MouseEvent as ReactMouseEvent, useCallback } from 'react';

import { request } from '@rikstv/play-common/src/forces/utils/request';
import { useAuthToken } from '@rikstv/play-common/src/hooks/useAuthToken';
import { commonGoogleTagManager } from '@rikstv/play-common/src/utils/analytics/googleTagManager';
import { getLogger } from '@rikstv/play-common/src/utils/logger/logger';
import { TertiaryButton } from '@rikstv/shared-components';

interface Props {
  url: string;
  closeMenu: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  removeFromCache: () => void;
}
const logger = getLogger('[continue-watching]');

export const RemoveFromContinueWatching: FC<Props> = ({ url, closeMenu, removeFromCache }) => {
  const removeFromContinueWatching = useRemoveFromContinueWatching(url);

  return (
    <TertiaryButton
      type="button"
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        removeFromContinueWatching();
        commonGoogleTagManager.removeFromContinueWatching();
        removeFromCache();
        closeMenu(e);
      }}>
      Fjern fra fortsett å se
    </TertiaryButton>
  );
};

const useRemoveFromContinueWatching = (url: string) => {
  const token = useAuthToken();
  const removeFromContinueWatching = useCallback(() => {
    request({ url, method: 'POST' }, token).catch(err =>
      logger.error('Failed to remove asset from continue watching.', err)
    );
  }, [url, token]);

  return removeFromContinueWatching;
};
