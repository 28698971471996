import { useEffect, useState } from 'react';
import useSWR from 'swr';

import config from '@rikstv/play-common/src/config';
import { useAuthToken } from '@rikstv/play-common/src/hooks/useAuthToken';
import { useQuery } from '@rikstv/play-common/src/hooks/useQuery';

import { request } from '../../../forces/utils/request';
import { captureExceptionInSentry } from '../../../utils/errorTracker/tracking';
import { LoadingStatus } from '../../../utils/types/typeUtils';
import { CollectionPage } from '../types';

import { useInvalidateSwimlaneCache } from './useInvalidateSwimlaneCache';

export const usePageFromSlug = (slug: string) => {
  const url = useExplorationPageFilters(new URL(`${config.contentLayoutApiUrl}/pages/${slug}`));
  return usePage(slug ? url : '');
};

const [filterMenu, sortMenu] = ['filterMenu', 'sortMenu'];
const useExplorationPageFilters = (url: URL) => {
  const params = useQuery();
  if (params.has(filterMenu)) {
    url.searchParams.append(filterMenu, params.get(filterMenu)!);
    if (params.has(sortMenu)) {
      url.searchParams.append(sortMenu, params.get(sortMenu)!);
    }
  }
  return url.toString();
};

export const usePage = (url: string) => {
  const accessToken = useAuthToken();

  const [page, setPage] = useState<CollectionPage>();
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>('INITIAL');

  const { data, error } = useSWR(
    url ? [url, accessToken] : null,
    async ([url, token]) => {
      const page = await request<CollectionPage>({ url }, token);
      page.type = 'filterMenus' in page ? 'exploration' : 'collection';
      return page;
    },
    {
      // wait 30 sec after each request before anything can trigger new fetch
      dedupingInterval: 30_000,
      // wait 1 min for focus revalidation
      focusThrottleInterval: 60_000,
    }
  );

  useInvalidateSwimlaneCache(url, data);

  // Fetch content in menu swimlanes
  useEffect(() => {
    if (data) {
      setPage(data);
      setLoadingStatus('DONE');
    }
    if (error) {
      captureExceptionInSentry(error);
      setLoadingStatus('FAILED');
    }
  }, [data, error]);

  return {
    page,
    loadingStatus,
  };
};
