import React from 'react';

import type { IconComponentType, IconSize } from '../../icons/svgToIconElement';

import { ButtonStyle, InternalActionButton } from './InternalActionButton';

interface Props {
  buttonStyle?: ButtonStyle;
  selected?: boolean;
  className?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon: IconComponentType;
  ariaLabel: string;
  title?: string;
  size?: IconSize;
  disabled?: boolean;
  id?: string;
}

const IconButton: React.FunctionComponent<Props> = ({ size = 'normal', ...rest }) => (
  <InternalActionButton buttonType="button" iconSize={size} iconOnly {...rest} />
);

export { IconButton };
