import { useCallback } from 'react';
import { KeyedMutator } from 'swr';
import useSWR from 'swr';

import config from '@rikstv/play-common/src/config';
import { AccessTokenValue } from '@rikstv/play-common/src/forces/auth/auth.types';
import { request } from '@rikstv/play-common/src/forces/utils/request';
import { wishListToggleAction } from '@rikstv/play-common/src/forces/wishlist/actions';
import { useAuthToken } from '@rikstv/play-common/src/hooks/useAuthToken';
import { useReduxEffect } from '@rikstv/play-common/src/hooks/useReduxEffect';
import { getLogger } from '@rikstv/play-common/src/utils/logger/logger';

import { SuperCoverPageData, VariantSection } from '../contentStack.types';
import { useSuperCoverPageContext } from '../SuperCoverPageContext';

import { getStackContent } from './scp.utils';
import { GetExternalServiceStatusFn } from './useContentStackEventHandlers';
import { useOnModalPlayerClosed } from './useModalPlayerLogic';
import { variantActivationMapper } from './useProviderVariants';

const logger = getLogger('useFetchSCP');

export const useFetchSuperCoverPage = ({ id }: { id: string | null }) => {
  const token = useAuthToken();
  const { getExternalServiceStatus } = useSuperCoverPageContext();
  const fetcherKeys = !id ? null : ([id, token, 'useSuperCoverPage'] as const);
  const { mutate, ...rest } = useSWR(
    fetcherKeys,
    ([id, token]) => fetchSuperCoverPage(id, token, getExternalServiceStatus),
    {
      revalidateOnFocus: false,
    }
  );
  useOnModalPlayerClosed(mutate);
  return { mutate, ...rest };
};

export const fetchSuperCoverPage = async (
  id: string,
  accessTokenValue: AccessTokenValue | null | undefined,
  getExternalServiceStatus: GetExternalServiceStatusFn
): Promise<SuperCoverPageData> => {
  const response = await request<SuperCoverPageData>(
    {
      url: `${config.contentSearchApiUrl}/coverpage/super/${id}/`,
    },
    accessTokenValue
  );

  // make type narrowing easier
  if ('episode' in response) {
    response.type = 'episode';
    response.episode.type = 'episode';
  } else if ('series' in response) {
    response.type = 'series';
    response.series.type = 'series';
  } else {
    response.type = 'singleProgramOrMovie';
    response.singleProgramOrMovie.type = 'singleProgramOrMovie';
  }

  const movieSeriesOrEpisode = getStackContent(response);

  // Assign type-field to each section to support:
  //   s.type === 'providerVariants' (intellisense)
  // instead of
  //   'providerVariants' in s (with no help from IDE)
  movieSeriesOrEpisode.sections.forEach(s => {
    if ('providerVariants' in s) {
      s.type = 'providerVariants';
    } else if ('swimlane' in s) {
      s.type = 'swimlane';
    } else if ('extendedMetadata' in s) {
      s.type = 'extendedMetadata';
    } else if ('sequentialSeries' in s) {
      s.type = 'sequentialSeries';
    } else if ('segmentSeries' in s) {
      s.type = 'segmentSeries';
    } else if ('topicalitySeries' in s) {
      s.type = 'topicalitySeries';
    } else {
      logger.warn('Unsupported section', s);
    }
  });

  // Assign external service activation status since API does not know this
  // CustomerAPI and ClientAPI only shared knowledge encoded in JWT token...
  const allVariants = (
    movieSeriesOrEpisode.sections.filter(s => s.type === 'providerVariants') as VariantSection[]
  ).flatMap(s => s.providerVariants.variants);
  if (
    movieSeriesOrEpisode.defaultProviderVariant &&
    movieSeriesOrEpisode.defaultProviderVariant.behavior !== 'ShowVariants'
  ) {
    allVariants.push(movieSeriesOrEpisode.defaultProviderVariant);
  } else {
    movieSeriesOrEpisode.defaultProviderVariant = {
      behavior: 'ShowVariants',
      state: undefined,
    };
  }
  // Assign activation state for external-playback
  allVariants.forEach(v => variantActivationMapper(v, getExternalServiceStatus));
  allVariants.forEach(v => {
    // set primary btn text for activation variant
    if (v.behavior === 'ActivateExternalService' && v.defaultSelected) {
      movieSeriesOrEpisode.primaryButtonText = v.subtitle;
    }
  });

  return response;
};

type ToggleFavActionType = ReturnType<typeof wishListToggleAction>;
export const useOptimisticFavoriteToggle = (mutate: KeyedMutator<SuperCoverPageData>) => {
  // Listen for add/remove favorite and mutate swr data in cache
  // allows for longer data cache times since we "know" when changes happen
  // just calling mutate wont work here since add/remove favorite is a async op in the backend and fire-and-forget for us
  useReduxEffect(
    useCallback(
      // use callBack to provide stable function to custom hook
      (action: ToggleFavActionType) =>
        mutate(
          data => {
            if (data) {
              const stackContent = getStackContent(data);
              if (stackContent.userData) {
                stackContent.userData.isInMyList = action.payload;
              }
            }
            return data;
          },
          { revalidate: false }
        ),
      [mutate]
    ),
    [wishListToggleAction.type]
  );
};
