import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';

import { getCustomerAccount } from '../../../apis/StrimGatewayAPI';
import { storeLogoutReason } from '../../../utils/oauth.utils';

/**
 * Checks customer account for "isDeleted" flag, which is set by backend on canceled vipps-signUp flow
 */
export const logoutIfUserDeleted = async (token: string, redirectPath: string): Promise<boolean> => {
  try {
    const account = await getCustomerAccount(token);
    if (account.isDeleted === true) {
      await logoutDeletedUser(redirectPath);
      return true;
    }
  } catch {
    /* nothing to do here really */
  }
  return false;
};

export const logoutDeletedUser = async (redirectPath: string) => {
  storeLogoutReason({
    logoutReason: 'PAYMENT_CANCELLED',
    logoutSuccessRedirectPath: redirectPath,
  });
  await authService.logout();
};
