import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';
import { Breadcrumb, BreadcrumbCurrent, Gutter, H1, LightMode } from '@rikstv/shared-components';

import { Link } from '../../router/Link';
import { addParameters } from '../../utils/url/url.utils';
import { BackdropImage, BackdropImageProps } from '../backdropImage/BackdropImage';

import style from './PageFrame.module.css';

interface Meta {
  name: 'author' | 'keyword';
  content: string;
  property?: never;
}

interface OgMeta {
  property: string;
  content: string;
  name?: never;
}

interface Metadata {
  description: string;
  image?: string;
  otherMetaTags?: Array<Meta | OgMeta>;
}

interface Props {
  title:
    | string
    | {
        str: string;
        component: ReactNode;
      };
  meta: 'noindex' | Metadata;
  className?: string;
  tallHeader?: boolean;
  lightBackground?: boolean;
  crumbs?: Array<{ name: string; href: string }>;
  showHeader?: boolean;
  backgroundImage?: BackdropImageProps;
  renderTitle?: boolean;
}

export const PageFrame: FCC<Props> = ({
  title,
  meta,
  className = '',
  tallHeader = false,
  crumbs,
  lightBackground = false,
  showHeader = true,
  backgroundImage = undefined,
  renderTitle = true,
  children,
}) => {
  const titleIsString = typeof title === 'string';
  const Wrapper = lightBackground ? LightMode : 'div';

  return (
    <>
      <Helmet title={titleIsString ? title : title.str}>
        {meta === 'noindex' ? (
          <meta name="robots" content="noindex" />
        ) : (
          <>
            <meta name="description" content={meta.description} />
            <meta property="og:description" content={meta.description} />
            {meta.image && (
              <>
                <meta name="image" content={meta.image} />
                <meta property="og:image" content={addParameters(meta.image, { width: '1200' })} />
              </>
            )}
            {meta.otherMetaTags?.map(meta => {
              if ('name' in meta) {
                return <meta key={meta.name} name={meta.name} content={meta.content} />;
              }
              return <meta key={meta.property} property={meta.property} content={meta.content} />;
            })}
          </>
        )}
      </Helmet>
      <Wrapper>
        {backgroundImage && (
          <div className={style.imageContainer}>
            <BackdropImage {...backgroundImage} />
          </div>
        )}
        <Gutter className={`${className} ${showHeader ? style.wrapper : ''}`}>
          {crumbs && (
            <Breadcrumb
              title="Sidenavigasjon"
              className={style.crumb}
              crumbs={crumbs}
              render={(name, href, current) =>
                !current ? (
                  <Link data-testid={`crumb-${name.replaceAll(' ', '-').toLowerCase()}`} to={href}>
                    {name}
                  </Link>
                ) : (
                  <BreadcrumbCurrent>{name}</BreadcrumbCurrent>
                )
              }
            />
          )}
          {title && renderTitle && (
            <H1
              data-testid="page-title"
              className={`${style.title} ${tallHeader ? style.tallTitle : ''} ${showHeader ? '' : 'sr-only'}`}>
              {titleIsString ? title : title.component}
            </H1>
          )}
          {children}
        </Gutter>
      </Wrapper>
    </>
  );
};
