import { createAction } from '@reduxjs/toolkit';

import type { SwimlaneEventData } from '../../utils/analytics/CommonAnalytics.types';

const swimlaneClickedAction = createAction<SwimlaneEventData>('swimlaneClicked');
const swimlaneImpressionAction = createAction<SwimlaneEventData>('swimlaneImpression');

const toggleFavoriteChannelAction = createAction<{ channelId: number; isFavorite: boolean }>(
  'analytics.toggleFavoriteChannel'
);

export { swimlaneClickedAction, swimlaneImpressionAction, toggleFavoriteChannelAction };
