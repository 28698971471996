import { FC } from 'react';

import { CssBemHelper } from '@rikstv/play-common/src/utils/css/CssBemHelper';
import { Tag } from '@rikstv/shared-components';

import { DurationBar } from '../durationBar/DurationBar';
import { AccentPill } from '../pill/Pill';

import { ImageOverlayWrapper } from './ImageOverlayWrapper';

import './LiveBroadcastOverlay.scss';

const bem = new CssBemHelper('live-broadcast-overlay');

export const LiveBroadcastOverlay: FC<{
  state: RiksTV.ClientAPI.States['value'];
  progress: number;
  label?: string;
}> = ({ state, progress, label = 'Direkte' }) => {
  if (state !== 'Live') {
    return null;
  }

  return (
    <ImageOverlayWrapper className={bem.base} backdropStyle="gradient">
      <LiveBroadcastPill label={label} />
      <div className={bem.element('progress')}>{progress > 0 && <DurationBar percent={progress} />}</div>
    </ImageOverlayWrapper>
  );
};

export const LiveBroadcastPill = ({ label }: { label: string }) => {
  return (
    <div>
      <AccentPill>
        <Tag className={bem.element('label')}>{label}</Tag>
      </AccentPill>
    </div>
  );
};
