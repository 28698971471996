import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { StreamingDevice, ViewMode } from '@rikstv/play-player/src/player.types';

import { Playable } from '../utils/playable';

type PlayerPosition =
  | {
      secondsElapsedFromStart?: number;
      currentDateTime?: never;
    }
  | {
      secondsElapsedFromStart?: never;
      currentDateTime?: Date;
    };

type ManifestError = {
  src: string;
  stage: 1 | 2;
  manifestUrl: string;
  secondaryManifestUrl: string;
};
export type BasePlayerStateEventPayload = {
  manifestUrl: string | undefined;
  domain: string | undefined;
} & PlayerPosition;

export type PlayerStateChangedPayload =
  | (BasePlayerStateEventPayload & { state: 'resume' | 'start' | 'seeked' | 'timeUpdate' | 'bingeDialog' })
  | (BasePlayerStateEventPayload & { state: 'keepAlive' | 'stop' | 'pause'; secondsSinceLastEvent: number })
  | (BasePlayerStateEventPayload & { state: 'destroy'; onBeforeUnload: boolean });

export interface PlaybackState {
  autoPlayRequested: boolean;
  streamingDevice: StreamingDevice;
  viewMode: ViewMode;
  isBingeWatching: boolean;
  sessionId: string;
  playable: Playable | null;
}

const InitialPlaybackState: PlaybackState = {
  autoPlayRequested: false,
  streamingDevice: 'desktop',
  viewMode: 'standard',
  isBingeWatching: false,
  sessionId: uuidv4(),
  playable: null,
};
const playback = createSlice({
  name: 'playback',
  initialState: InitialPlaybackState,
  reducers: {
    onAssetChanged: (state, action: PayloadAction<PlaybackState['playable']>) => {
      state.playable = action.payload;
    },
    onPlayerStateEvent: (state, _action: PayloadAction<PlayerStateChangedPayload>) => {
      return state;
    },
    onManifestError: (state, _action: PayloadAction<ManifestError>) => state,
    onKeepAliveEvent: (state, _action: PayloadAction<{ secondsSinceLastEvent: number }>) => state,
    onChangeStreamingDevice: (state, { payload }: PayloadAction<StreamingDevice>) => {
      state.streamingDevice = payload;
    },
    requestAutoPlay: state => {
      state.autoPlayRequested = true;
    },
    resetAutoPlay: state => {
      state.autoPlayRequested = false;
    },
    onViewModeChanged: (state, { payload }: PayloadAction<ViewMode>) => {
      state.viewMode = payload;
    },
    onSessionStarted: (state, { payload }: PayloadAction<{ sessionId: string }>) => {
      state.sessionId = payload.sessionId;
    },
    onBingewatchStateChanged: (state, { payload }: PayloadAction<{ isBingeWatching: boolean }>) => {
      state.isBingeWatching = payload.isBingeWatching;
    },
    onFullscreenChanged: (state, _action: PayloadAction<{ isFullscreen: boolean }>) => state,
  },
});

const playerSlice = combineReducers({
  playback: playback.reducer,
});

export type PlayerState = { player: ReturnType<typeof playerSlice> };

export const playbackActions = playback.actions;

export default playerSlice;
