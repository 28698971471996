/**
 * Good to know when to let the browser handle the click instead of us,
 * for instance if the user wants to open a link in a new window or tab.
 */
export const isModifierKeyPressed = (event?: KeyboardEvent | MouseEvent | React.MouseEvent | React.KeyboardEvent) => {
  try {
    return event ? event.shiftKey || event.ctrlKey || event.altKey || event.metaKey : false;
  } catch {
    /* om nom nom */
  }
  return false;
};
