import useSWRImmutable from 'swr/immutable';

import { KnownExternalServiceId } from '@rikstv/play-common/src/forces/externalService/externalServiceIds';
import { request } from '@rikstv/play-common/src/forces/utils/request';
import { useAuthToken } from '@rikstv/play-common/src/hooks/useAuthToken';

import { ProviderVariant, VariantSection } from '../contentStack.types';
import { useSuperCoverPageContext } from '../SuperCoverPageContext';

type ExternalServiceStatusFn = ReturnType<typeof useSuperCoverPageContext>['getExternalServiceStatus'];

export const useProviderVariants = (variantsUrl: string) => {
  const token = useAuthToken();
  const { getExternalServiceStatus } = useSuperCoverPageContext();
  return useSWRImmutable(variantsUrl, url => variantFetcher(url, token, getExternalServiceStatus));
};

const variantFetcher = async (url: string, token: string | null, getExternalServiceStatus: ExternalServiceStatusFn) => {
  const res = await request<VariantSection['providerVariants']>({ url }, token);
  res.variants.forEach(v => variantActivationMapper(v, getExternalServiceStatus));
  return res;
};

export const variantActivationMapper = (v: ProviderVariant, getExternalServiceStatus: ExternalServiceStatusFn) => {
  if (v.behavior === 'PlayExternalAsset') {
    const { providerId, displayName } = v._links.externalPlayback;
    const { activated, activationPageUrl } = getExternalServiceStatus(providerId as KnownExternalServiceId);
    if (!activated && activationPageUrl) {
      // Assign activation behaviors/states
      v.behavior = 'ActivateExternalService';
      v._links.externalPlayback.activationPageUrl = activationPageUrl;
      v.subtitle = `Aktiver ${displayName}`;
      v.state = 'NotActivated';
    }
  }
};
