import { v4 as uuidv4 } from 'uuid';

import config from '../../config';
import { getDeviceId } from '../../utils/device/device.utils';
import { AccessToken } from '../auth/auth.types';

import { Request } from './request.type';
import { appendQueryParams, checkStatus, isHeaderResponseJSON, isHeaderResponsePDF, parseJSON } from './request.utils';

export const appSessionId = uuidv4();

export const defaultHeaders = {
  'X-RiksTV-Application': config.request.appId,
  'X-RiksTV-AppSessionId': appSessionId,
  'X-RiksTV-AppInstallationId': getDeviceId(),
};

const applyRewriteRules = (url: string) => {
  if (!config.urlRewrites) {
    return url;
  }

  const matchingRewriteRule = config.urlRewrites.find(rule => {
    return url.startsWith(rule.originalPrefix);
  });

  if (!matchingRewriteRule) {
    return url;
  }

  return url.replace(matchingRewriteRule.originalPrefix, matchingRewriteRule.newPrefix);
};

/**
 * @param okStatusCodes a list of status codes other than 200-399 range that are considered OK, and thus will not throw
 */
export const request = async <T = any>(
  request: Request,
  accessToken?: string | null | undefined,
  okStatusCodes: number[] = []
): Promise<T> => {
  // TODO: test what happens when you provide a full url AND a path? Or if no PATH, will url be overridden? How does the request object do it?
  const { path = '', headers, method = 'GET', url = config.apiBaseURL, queryString, ...parameters } = request;
  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...defaultHeaders,
      ...headers,
      Authorization: '',
    },
    ...parameters,
  };

  const rewrittenUrl = applyRewriteRules(url);

  if (accessToken != null) {
    options.headers = Object.assign({}, { ...options.headers }, { Authorization: `Bearer ${accessToken}` });
  }

  // store current stack to make debugging easier - normal error logging will
  // only log the response stack trace starting with checkStatus.
  const requestStackTrace = new Error().stack;
  // TODO (tolu): retry with backoff before throwing would be nice
  return fetch(appendQueryParams(`${rewrittenUrl}${path}`, queryString), options)
    .then(checkStatus(requestStackTrace, okStatusCodes))
    .then(response => {
      const handleEmptyRes = handleEmptyResponseFactory(response.status, okStatusCodes);
      if (isHeaderResponseJSON(response.headers)) {
        return parseJSON(response).then(handleEmptyRes);
      }
      if (isHeaderResponsePDF(response.headers)) {
        return response.blob();
      }
      return response.text().then(handleEmptyRes);
    });
};

const handleEmptyResponseFactory = (status: number, okStatusCodes: number[]) => {
  return (parsedBody: any) => {
    // Return undefined for empty body responses for non 200-399 range as this is more in line with SWR response usage
    if (okStatusCodes.includes(status) && parsedBody === '') {
      return undefined;
    }
    return parsedBody;
  };
};

const unpackToken = (accessToken?: AccessToken | string | null | undefined) => {
  return typeof accessToken === 'string' ? accessToken : accessToken?.value ?? null;
};

export const getRequestWithAccessToken =
  <T = any>(accessToken?: Parameters<typeof unpackToken>[0]) =>
  (url: string, options: Partial<Omit<Request, 'url'>> = {}, okStatusCodes: number[] = []) =>
    request<T>({ url, ...options }, unpackToken(accessToken), okStatusCodes);

export const getRequestPathWithAccessToken =
  <T = any>(accessToken?: Parameters<typeof unpackToken>[0]) =>
  (path: string, options: Partial<Omit<Request, 'path'>> = {}, okStatusCodes: number[] = []) =>
    request<T>({ path, ...options }, unpackToken(accessToken), okStatusCodes);
