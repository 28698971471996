import { useSWRConfig } from 'swr';

export const useRemoveFromSwimlaneCache = (swimlaneLink: string): (() => void) => {
  const { cache } = useSWRConfig();

  const removeFromCache = () => {
    if (swimlaneLink !== '') {
      if (cache instanceof Map) {
        cache.forEach((_: unknown, key: string) => {
          if (key.includes(swimlaneLink)) {
            cache.delete(key);
          }
        });
      }
    }
  };

  return removeFromCache;
};
