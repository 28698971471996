import { FC } from 'react';

import { SwimlaneItem } from '../../forces/assetDetails/assetDetails.types';

import { StackedProviderImage } from './StackedProviderImage';

export const ProviderImageWrapper: FC<{ logos: SwimlaneItem['logos']; isHero?: boolean; portrait?: boolean }> = ({
  logos,
  isHero = false,
  portrait = false,
}) => {
  const size = isHero ? '8%' : undefined;
  return <StackedProviderImage width={size} variants={logos} portrait={portrait} />;
};
