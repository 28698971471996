import { useState } from 'react';
import classNames from 'classnames';

import { TrailerPlayer } from '@rikstv/play-player/src/components/TrailerPlayer/TrailerPlayer';
import { useTrailerPlayerStore } from '@rikstv/play-player/src/components/TrailerPlayer/useTrailerPlayerStore';
import { useIntersectionObserver } from '@rikstv/shared-components';

import { authService } from '../../utils/auth/AuthService';

import styles from './BackdropImage.module.css';

export interface BackdropImageProps {
  href: string;
  fade?: boolean;
  fixedMinHeight?: boolean;
  isAgeRestricted?: boolean;
  classes?: string;
}

export const BackdropImage = ({
  href,
  fade = false,
  fixedMinHeight = false,
  isAgeRestricted = false,
  classes = '',
}: BackdropImageProps) => {
  const disableBackgroundTrailer =
    useTrailerPlayerStore(s => s.disableBackgroundTrailer) || !authService.isInternalUser();
  const playBackgroundTrailer = useTrailerPlayerStore(s => s.playBackgroundTrailer);
  const trailerSources = useTrailerPlayerStore(s => s.trailerSources);
  const [showTrailer, setShowTrailer] = useState(false);
  const [hasStartedPlaying, setHasStartedPlaying] = useState(false);
  const playerClasses = classNames(styles.trailerPlayer, { [styles.visible]: showTrailer && hasStartedPlaying });

  const [trailerTriggerRef] = useIntersectionObserver(list => list.map(item => setShowTrailer(item.isIntersecting)), {
    threshold: 0.95,
  });

  return (
    <div
      ref={trailerTriggerRef}
      className={classNames(styles.backdropWrapper, classes, { [styles.backdropWrapperFade]: fade })}>
      {trailerSources && !disableBackgroundTrailer && (
        <TrailerPlayer
          className={playerClasses}
          onPlayStart={() => setHasStartedPlaying(true)}
          loop
          muted
          disableHud
          trailerSources={trailerSources}
          play={showTrailer && playBackgroundTrailer && !isAgeRestricted}
        />
      )}

      <picture>
        <source srcSet={`${href}?width=1600`} media="(min-width: 1440px)" />
        <source srcSet={`${href}?width=1200`} media="(min-width: 1024px)" />
        <source srcSet={`${href}?width=900`} media="(min-width: 768px)" />
        <img
          className={classNames(styles.backdropImage, { [styles.fixedMinHeight]: fixedMinHeight })}
          src={`${href}?width=600`}
          alt=""
        />
      </picture>
    </div>
  );
};
