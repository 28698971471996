import { all, takeLatest } from 'redux-saga/effects';

import { fetchAssetDetails } from '@rikstv/play-common/src/forces/assetDetails/api';
import { commonSignUp, commonUpgrade } from '@rikstv/play-common/src/forces/loadUserInfo/actions';
import history from '@rikstv/play-common/src/router/history';
import { captureExceptionInSentry } from '@rikstv/play-common/src/utils/errorTracker/tracking';
import { getLogger } from '@rikstv/play-common/src/utils/logger/logger';
import { AwaitedReturnType } from '@rikstv/play-common/src/utils/types/typeUtils';

import { getNavigationUrlAndRecommendedProducts } from '../../packages/utils/getRecommendedProduct';
import { persistShopperAssetOnSignup } from '../../packages/utils/persistShopperAssetOnSignup';

const logger = getLogger('[signUpAndUpgradeSagas]');

function* startSignupSaga(action: ReturnType<typeof commonSignUp>) {
  try {
    if (action.payload.fromAssetId) {
      const assetDetails: AwaitedReturnType<typeof fetchAssetDetails> = yield fetchAssetDetails(
        action.payload.fromAssetId,
        null
      );
      const { recommendedProducts, cmsUrl, url }: AwaitedReturnType<typeof getNavigationUrlAndRecommendedProducts> =
        yield getNavigationUrlAndRecommendedProducts({ assetDetails });

      if (recommendedProducts) {
        persistShopperAssetOnSignup(assetDetails, recommendedProducts.packageId);
      }

      logger.info(`Navigating to ${url} as a recommentation for asset ${action.payload.fromAssetId}`);

      if (cmsUrl) {
        location.href = cmsUrl;
      } else {
        history.push(url);
      }
    }
  } catch (err) {
    captureExceptionInSentry(err, { fingerprint: ['start-signup-error'], extra: { action } });
  }
}

function* handleUpgradeSaga({ payload }: ReturnType<typeof commonUpgrade>) {
  try {
    if (payload.fromAssetId) {
      const assetDetails: AwaitedReturnType<typeof fetchAssetDetails> = yield fetchAssetDetails(
        payload.fromAssetId,
        null
      );
      const { url }: AwaitedReturnType<typeof getNavigationUrlAndRecommendedProducts> =
        yield getNavigationUrlAndRecommendedProducts({ assetDetails });

      logger.info(`Navigating to ${url} as a recommentation for asset ${payload.fromAssetId}`);

      history.push(url);
    }
  } catch (err) {
    captureExceptionInSentry(err, { fingerprint: ['start-signup-error'], extra: { payload } });
  }
}

export function* signUpAndUpgradeSagas() {
  yield all([takeLatest(commonSignUp.type, startSignupSaga), takeLatest(commonUpgrade.type, handleUpgradeSaga)]);
}
