import { RefObject, useCallback, useEffect } from 'react';

interface Props {
  ref: RefObject<HTMLElement> | null;
  callback: (e: MouseEvent) => void;
  condition?: boolean;
}

export const useClickOutsideListener = ({ ref, callback, condition = true }: Props): void => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (ref?.current && !ref.current.contains(e.target as Node)) {
        callback(e);
      }
    },
    [callback, ref]
  );

  useEffect(() => {
    if (ref && condition) {
      document && document.addEventListener('click', handleClick);
    }
    return () => {
      document && document.removeEventListener('click', handleClick);
    };
  }, [ref, condition, handleClick]);
};
